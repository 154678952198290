/**
 * News Card Slider Component
 */

.news-card-slider {
  // slick slider
  .slick-slider {
    padding-left: 30px;
    padding-right: 30px;
    .slick-prev,
    .slick-next {
      height: 42px;
      width: 20px;
      background-color: transparent;
      // border-radius: 50%;
      z-index: 3;
      //transition:height $transition-linear, width $transition-linear;
      &:before {
        // reset slick styles
        font-family: initial;
        font-size: initial;
        line-height: initial;
        color: initial;
        opacity: initial;
        -webkit-font-smoothing: initial;
        -moz-osx-font-smoothing: initial;
        // end reset
      }
      @include tablet {
        height: 42px;
        width: 20px;
        &:before {
          font-size: 16px;
        }
        // &:hover {
        //   height: 40px;
        //   width: 40px;
        // }
      }
      @include mobile {
        height: 42px;
        width: 20px;
        &:before {
          font-size: 10px;
        }
        // &:hover {
        //   height: 30px;
        //   width: 30px;
        // }
      }
    }
    .slick-prev {
      left: 0px;
      &:before {
        content: url(../images/slick-slider-ui/arrow_left_white.png);
        transition: opacity $transition-linear;
        opacity: .6;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    .slick-next {
      right: 0px;
      &:before {
        content: url(../images/slick-slider-ui/arrow_right_white.png);
        transition: opacity $transition-linear;
        opacity: .6;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  } // end slick slider
  @media screen and (max-width: 1024px) {
    .newsAggregatorElement .item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .newsAggregatorElement .item {
      max-width: unset;
    }
  }
}
