/**
 * Copied from Ironman theme
 * Adds sponsor-row CSS class to theme
 */
.sponsor-50-50 {
  .column {
    padding-left: 0;
    padding-right: 0;
  }

  .pageElement.textBlockElement {
    margin-bottom: 5px;

    & > h3:first-child {
      font-size: 22px;
      line-height: 1.6;
    }
  }

  .heroPhotoElement {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: 133px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    transition: filter $transition-linear;
    filter: grayscale(100%);
    position: relative;
    z-index: 10;

    &:hover {
      filter: grayscale(0);
      z-index: 100;
    }

    a {
      overflow: hidden;
      /* needed for ie 11 */
    }

    img {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .cutline {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .sponsor-50-50 {
    .column-1 {
      width: 50%;
      padding-right: 15px;
    }
    .column-2 {
      padding-left: 15px;
    }
  }
}

.sponsor-row {
  padding-top: 20px;
  padding-bottom: 20px;
  
  .column-1 {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    .pageEl {
      flex-basis: 50%;
      @media only screen and (min-width: $breakpoint-intermediate-min) {
        flex-basis: 33.3333%;
      }
      @media only screen and (min-width: $breakpoint-lg-tablet) {
        flex-basis: 25%;
      }
      @media only screen and (min-width: $breakpoint-sm-desktop) {
        flex-basis: 20%;
      }

      &:first-of-type {
        flex-basis: 100%;

        .pageElement.textBlockElement {
          margin-bottom: 5px;

          & > h3:first-child {
            font-size: 22px;
            line-height: 1.6;
          }
        }
      }

      .heroPhotoElement {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 0;
        margin-bottom: 1px;
        transition: filter $transition-linear, opacity $transition-linear;
        filter: grayscale(100%);
        opacity: 0.6;
        position: relative;
        z-index: 10;
        height: 115px;
        @media only screen and (min-width: $breakpoint-intermediate-min) {
          height: 155px;
        }
        &:hover {
          filter: grayscale(0);
          opacity: 1;
          z-index: 100;
        }

        img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }

        .cutline {
          display: none;
        }
        a {
          height: 100%;
        }
      }
    }
  }
}
