/**
 * Sliders In Tab Element Component
 */

.user_mode .sliders-in-tab-element {

  .contentTabs:not(.rapidTabsConstruction) {
    flex-flow: column;
    @media only screen and (min-width: $breakpoint-intermediate-min) {
      flex-flow: row;
    }
  }
  // Single Photo caption text
  .cutline {
    font-size: 16px;
    font-style: normal;
    text-transform: uppercase;
    color: $color-black;
    background-color: transparent;
    text-align: center;
    padding: 0;
    line-height: 28px;
  }

  .tabContainer{
    .layoutContainer{
      .column {
        overflow: hidden;
        padding-top: 5px;
        padding-left: 0;
        padding-right: 0;
        @include mobile {
          padding-left: 0;
          padding-right: 0;
        }
        &:not(.slick-slider){
          display: flex;
          justify-content: center;
        }
        .pageEl {
          width: 240px;
        }
      }
    }
    > div {
      margin: auto;
    }
  }

  // slick slider
  .slick-slider {
    .slick-list {
      width: 100%;
      margin-left: 1px;
      margin-right: 1px;
      overflow: hidden;
    }
    .slick-prev,
    .slick-next {
      height: 100%;
      width: 45px;
      background-color: white;
      // border-radius: 50%;
      z-index: 3;
      transition: height $transition-linear, width $transition-linear;
      &:before {
        // reset slick styles
        font-family: initial;
        font-size: initial;
        line-height: initial;
        color: initial;
        opacity: initial;
        -webkit-font-smoothing: initial;
        -moz-osx-font-smoothing: initial;
        // end reset
      }
      @include tablet {
        &:before {
          font-size: 16px;
        }

      }
      @include mobile {
        &:before {
          font-size: 10px;
        }

      }
    }
    .slick-prev {
      left: 0;
      &:before {
        content: url(../images/slick-slider-ui/arrow-left-tab-slider.png);
        opacity: .6;
        transition: opacity $transition-linear;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    .slick-next {
      right: 0;
      &:before {
        content: url(../images/slick-slider-ui/arrow-right-tab-slider.png);
        opacity: .6;
        transition: opacity $transition-linear;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }


}

.edit_mode .sliders-in-tab-element {
  .heroPhotoElement img {
    max-width: 150px;
  }
}


