// remove background-color on #panelTwo
body.custom-search-page #panelTwo {
  background-color: transparent;
}
.custom-search-page {
  // hide theme sub nav on custom search page
  .theme-sub-nav {
    display: none !important; // important needed to override inline styled applied by theme js
  }
  // set height of topNavPlaceholder
  .has-main-nav.has-sub-nav & {
    #topNavPlaceholder {
      height: $main-nav-height;
    }
  }
  // force content inside  of race-page-top to wrap
  .race-page-top {
    .hero-slider {
      flex-wrap: wrap;
    }
  }
}
