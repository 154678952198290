/**
 * Image With Text Container & Text With Image Container Component
 * @requires expected to be used with page element applying background image as solid color
 * @requires _image_with_text_container.js
 */

.image-with-text-container,
.text-with-image-container {
  display: flex;

  // zero out element padding
  .user_mode & {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    &.container-with-background-image {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      .column:not(.column-content-container) {
        padding: 0;
        .pageElement {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    .column-content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 600px;
      margin:0 auto;
      padding-left: 30px;
      padding-right:30px;
      padding-top: 30px;
      padding-bottom: 30px;
      height: auto;
      @include touch-device {
        height: 100%!important;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .column {
      width: 100%;
    }
  }
}
.side-by-side-link {
  .linkWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: calc(100% - 60px);
    @include touch-device {
      justify-content: center;
      width:100%;
    }
  }
}
