/**
 * General Race Page Styles
 */

.leftNav {
  @include touch-device {
    display: none;
    height:0;
    opacity: 0;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: none;
    transition: height $transition-standard, opacity $transition-linear;
    
    &.active {
      display: flex;
      height: 100%;
      opacity: 1;
    }
  }
}

.race-details-toggle {
  display:none;
  width: 100%;
  height: 50px;
  background-color: $color-white;
  justify-content: center;
  align-items: center;
  
  @include touch-device {
    display: flex;
  }
  
  span {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
  
    &:after {
      content: '\f107';
      display: inline-block;
      font-family: 'fontawesome';
      font-size: inherit;
      color: $color-primary;
      transition: transform $transition-standard;
      margin-left: 10px;
    }
  }
  &.open {
    span {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

.race-page {

  &.user_mode {
    .layoutContainer {
      padding-left: 30px;
      padding-right: 30px;
      // target tab element layout container
      .layoutContainer {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .race-page-top {
      .layoutContainer {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
      }
    }
     .layoutContainer[class*="lng-"]  {
       display: none; 
       &.active-language {
         display: flex;
       }
      }
  }
}

/**
 * Race Page News Aggregator Styles
 */
 .race-page {
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded {
    min-height: 215px; 
  }
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader {
    @include tablet {
      //min-height: 150px;
      flex-direction: column;
      margin-bottom: 5px;
    }
    > a {
      @include tablet {
        position: absolute;
        max-width: 300px;
      }
    }
    .newsAuthor {
      @include tablet {
        align-items: flex-start !important;
        height: min-content;
        margin-left: 330px;
      }
      @include mobile {
        margin-top: 20px;
      }
    }
    > h2 {
      a {
        @include tablet {
          margin-left: 330px;
        }
      }
    }
  }
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemElement {
    > h4 {
      @include tablet {
        margin-left: 330px;
      }
    }
  }
 }

 .edit_mode.race-page {
  .layoutContainer[class*="lng-"] {
      &:before {
          display:flex;
          max-width: 100%;
          justify-content: flex-start;
          font-family: $font-secondary;
          font-size: 18px;
          font-weight: bold;
          color: $color-secondary;
          background-color: #C3C3C3;
          border: dashed 1px solid #DDA301;
          padding-top: 5px;
          padding-bottom:5px;
          padding-left:5px;
          padding-right:5px;
          text-transform: uppercase;
          
      }
  }

  .layoutContainer{
      &.lng-filipino {
          &:before {
              content:'Filipino';
          }
      }
      &.lng-euskera {
          &:before {
              content:'Basque (Euskera)';
          }
      }
      &.lng-catalan {
          &:before {
              content:'Català (Catalan)';
          }
      }
      &.lng-中文chinese {
          &:before {
              content:'Chinese';
          }
      }
      &.lng-danish {
          &:before {
              content:'Danish';
          }
      }
      &.lng-deutsch {
          &:before {
              content:'Deutsch';
          }
      }
      &.lng-estonian {
          &:before {
              content:'Eesti (Estonian)';
          }
      }
      &.lng-english {
          &:before {
              content:'English';
          }
      }
      &.lng-español   {
          &:before {
              content:'Español';
          }
      }
      &.lng-français   {
          &:before {
              content:'Français';
          }
      }
      &.lng-german   {
          &:before {
              content:'German';
          }
      }
      &.lng-italiano {
          &:before {
              content:'Italiano ';
          }
      }
      &.lng-japanese {
          &:before {
              content:'日本語 (Japanese)';
          }
      }
      &.lng-korean {
          &:before {
              content:'한국어 (Korean)';
          }
      }
      &.lng-portuguese {
          &:before {
              content:'Portuguese';
          }
      }
      &.lng-russian {
          &:before {
              content:'русский (Russian)';
          }
      }
      &.lng-slovenian {
          &:before {
              content:'Slovenian';
          }
      }
      &.lng-swedish {
          &:before {
              content:'Svenska (Swedish)';
          }
      }
      &.lng-tawainesemandarin {
          &:before {
              content:'國語 (Tawainese Mandarin)';
          }
      }
      &.lng-thai {
          &:before {
              content:'Thai';
          }
      }
      &.lng-vietnamese {
          &:before {
              content:'Tiếng Việt (Vietnamese)';
          }
      }
      &.lng-croatian {
          &:before {
              content:'Croatian';
          }
      }
      &.lng-dutch {
          &:before {
              content:'Dutch';
          }
      }

  }
}
