// WYSIWYG Editor Typography Styles
// Ex: Text BLock Elements, News Article Previews, etc...
.text,
.previewText,
.newsText {
  > :last-child {
    margin-bottom: 0;
  }

  // Lists
  > ul, > ol{
    margin-bottom: 1em;
  }
  ul,
  ol {
    list-style-position: inside;
    ul,
    ol {
      margin-left: 1em;
    }
  }

  // Headers
  @include content-typography-headers;

  // Anchors
  @include content-typography-anchors;

  // Blockquote
  @include content-typography-blockquote;

  // Others
  @include content-typography-other;

}
