/**
 * Detect Ad Blocking SCSS Component
 * Adds CSS class 'adblocker-alternative' to available theme CSS classes
 * @requires {_detect_adblocking.js}
 * @requires {blockadblock.js}
 */

.user_mode {
  .adblocker-alternative {
    display: none;
  }
}
