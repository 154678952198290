/**
 * Custom Accordion SCSS, copied from Ironman codebase
 * @requires {_custom_accordion.js}
 */
.accordion {
  position: relative;
  min-height: 0;
  overflow: hidden;
  width: 100%;
  box-sizing: content-box;
  border-top: 1px solid #cdcdcd;
  &:last-of-type {
    border-bottom: 1px solid #cdcdcd;
  }
  &:hover {
    h3 span {
      color: $color-primary;
    }
  }
  .pageElement {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .text {
    color: $color-gray-dk-4;
    display: none;
    background-color: $color-white;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 1px;
    overflow: hidden;
    position: relative;
    height: auto;
    box-sizing: content-box;
    p {
      font-family: $font-secondary;
      font-weight: 400;
      font-size: 16px;
    }
  }
  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: $color-white;
    &:hover {
      cursor: pointer;
    }
    &:after {
      content: url(../images/accordion-ui/accordion-arrow-down.png);
      transition: transform $transition-standard;
      // reset font size and line-height b/c after is in h3 element
      line-height: 0;
      font-size: 0;
      height: 24px;
      width: 24px;
      right: 20px;
      // using border box to allow padding to center image in 24x24px square, this height matches line height of h3, allowing 180 degree rotation
      box-sizing: border-box;
      padding-top: 8px;
      padding-left: 5px;
    }
    &.open {
      &:after {
        transform: rotate(180deg);
      }
      span {
        color: $color-primary;
      }
    }
    span {
      display: inline-block;
      align-items: center;
      color: $color-secondary;
      font-family: $font-primary;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-transform: none;
      flex: 1 1 auto;
      transition: color $transition-linear;
    }
  }
}

.accordion-container {
  .user_mode & {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .accordion-container-title {
      display: none;
    }
  }
  .accordion-header {
    display: flex;
    align-items: center;
    margin-bottom: 1px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    &:hover {
      cursor: pointer;
    }
    &:after {
      content: url(../images/accordion-ui/accordion-arrow-down.png);
      transition: transform $transition-standard;
      // reset font size and line-height b/c after is in h3 element
      line-height: 0;
      font-size: 0;
      height: 24px;
      width: 24px;
      right: 20px;
      // using border box to allow padding to center image in 24x24px square, this height matches line height of h3, allowing 180 degree rotation
      box-sizing: border-box;
      padding-top: 8px;
      padding-left: 5px;
    }
    &.open {
      &:after {
        transform: rotate(180deg);
      }
    }
    background-color: $color-white;
    span {
      display: inline-block;
      align-items: center;
      color: $color-secondary;
      font-family: $font-primary;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.6;
      text-transform: none;
      flex: 1 1 auto;
    }
  }
}
