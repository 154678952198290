/**
 * Custom Utility Classes to be used on page elements and enable additional theme functionality
 */
.no-margin {
  margin: 0;
  .pageElement {
    margin: 0;
  }
}
.subtitle-color-white {
  .user_mode & {
    .pageElement > h3:first-child {
      color: $color-white;
    }
  }
}
.text-color-white {
  .user_mode & {
    .pageElement > h3:first-child {
      color: $color-white;
    }
    &.layoutContainer {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      .pageEl > :not(.linkElement) a:not([name="button"]) {
        color: $color-white;
      }
    }
  }
}
// no padding
.no-padding {
  padding: 0px;
}
.no-vertical-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.no-top-padding {
  padding-top: 0px;
}
.no-bottom-padding {
  padding-bottom: 0px;
}
// extra padding
.extra-vertical-padding {
  padding-top: 70px;
  padding-bottom: 70px;
  @include touch-device {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.extra-top-padding {
  padding-top: 70px;
}
.extra-bottom-padding {
  padding-bottom: 70px;
}
// justify content
// built for document and link elements, can be expanded
.justify-left {
  .documentElement {
    h3 {
      justify-content: flex-start;
    }
  }
  .linkElement {
    h4 {
      text-align: left;
    }
  }
}
.justify-center {
  .documentElement {
    h3 {
      justify-content: flex-end;
    }
  }
  .linkElement {
    h4 {
      text-align: center;
    }
  }
}
.justify-right {
  .documentElement {
    h3 {
      justify-content: flex-end;
    }
  }
  .linkElement {
    h4 {
      text-align: right;
    }
  }
}
.inline-width {
  .linkElement {
    a {
      display: inline-flex;
    }
  }
}
