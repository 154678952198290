.grd-series-container {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  
  .column {
    display: flex;
    flex: 1 1 0;
    justify-content: center;
  }
  
  @media screen and (max-width: 1024px) {
    
    .column {
      padding: 8px;
    }
  }
}

.grd-series-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  border-radius: 15px;
  text-align: initial;
  
  a {
    display: flex;
    flex-flow: column;
    flex-basis: 100%;
    overflow: hidden;
  }
  
  .card-background{
    height: 100%;
    width: fit-content;
    border-radius: 15px;
    position: absolute;
  }
  
  .card-header {
    flex: 1;
  
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    text-align: center;
    padding:    30px 0 20px 0;
    position: relative;
  
    h4{
      font-family:    'Gotham SSm A', 'Gotham SSm B', sans-serif;
      font-size:      42px;
      line-height:    1.156;
      font-weight:    600;
      text-transform: none;
      color:          #fff;
    }
  }

  .card-image{
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    
    text-align: center;
    position: relative;
  
    width: 45%;
    margin: 0 auto 10px auto;
    
    img{
      width: 100%;
    }
  }

  .card-footer {
    flex: 0;
    
    background-color: #000;
    text-align:       center;
    padding: 10px 0 10px 0;
    border-radius:    0 0 15px 15px;
    position: relative;

    p{
      font-family:    'Gotham SSm A', 'Gotham SSm B', sans-serif;
      font-size:      30px;
      line-height:    1.156;
      font-weight:    600;
      text-transform: none;
      color:          #fff;
      margin-bottom: 0;
    }
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    
      .card-header {
        padding: 15px 0 10px 0;
  
        h4 {
          font-size: 14px;
        }
      }
  
      .card-image {
        width: 100%;
        margin: auto;
      }
  
      .card-footer{
        p{
          font-size:     12px;
          margin-bottom: 0;
        }
      }
  }
}

.grd-race-container {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  flex-direction: row;
  
  .column {
    display: flex;
    flex: 1 1 0;
    justify-content: center;
  }
  
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    
    .column {
      flex: 1;
      padding: 8px;
    }
  }
}

.grd-race-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  
  margin: 10px auto;
  border-radius: 15px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  
  font-family:    'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size:      24px;
  line-height:    1.156;
  font-weight:    600;
  text-transform: none;
  color:          #ffffff;
  
  a {
    display: flex;
    flex-flow: column;
    flex-basis: 100%;
    overflow: hidden;
  }
  
  .left {
    float: left;
  }
  
  .right {
    float: right;
  }
  
  .card-header {
    flex: 1;
    text-align: left;
    padding: 26px 0 18px 26px;
    
    p {
      margin-bottom: 0;
    }
    
    .headline {
      font-size: 24px;
      line-height: 1;
      padding: 0 0 12px 0;
    }
    
    .sub-head {
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
    }
  }
  
  .card-image {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    
    img{
      width:      100%;
      height:     100%;
      margin: 0 0 -8px 0;
    }
  }
  
  .card-footer {
    flex: 0;
    background-color: #000000;
    padding: 20px 0 22px 0;
    border-radius: 0 0 15px 15px;
    
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      resize: vertical;
    }
    
    p {
      font-size:      18px;
      line-height:    1.156;
      color: #ffffff;
      padding: 0 26px 0 26px;
      margin-bottom: 0;
    }
    
    i {
      margin-right: 8px;
      font-size: 18px;
      height: 18px;
    }
  }
  
  @media screen and (max-width: 1024px){
    font-size: 14px;
  
    .card-header .headline {
      font-size: 18px;
    }
  
    .card-header .sub-head {
      font-size: 14px;
    }
  
    .card-footer {
      
      .cta {
  
        p {
          padding: 0 0 0 10px;
          font-size: 14px;
        }
      }
  
      .register {
    
        p {
          padding: 0 10px 0 0;
          font-size: 14px;
        }
      }
  
      i {
        font-size: 14px;
        height:    14px;
      }
    }
  }
}
