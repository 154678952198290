/**
 * Column Gradient Container Component
 * @requires {Layout Container} - expected to be used on layout container to define padding and gradient background style
 */
.column-gradient-container:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: calc(100% - (100% - #{$content-area-width}));
  background: linear-gradient(0deg, transparent, #dddddd);
  border-radius: 10px 10px 0px 0px;
}

.column-gradient-container .column:first-of-type {
  padding-left: 35px;
  padding-right: 15px;
}
.column-gradient-container .column {
  padding-left: 15px;
  padding-right: 15px;
}
.column-gradient-container .column:last-of-type {
  padding-left: 15px;
  padding-right: 35px;
}
.column-gradient-container .pageElement {
  margin-top: 30px;
}
.column-gradient-container .videoElement {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .column-gradient-container .column:first-of-type,
  .column-gradient-container .column,
  .column-gradient-container .column:last-of-type {
    padding-left: 30px;
    padding-right: 30px;
  }
}
// target logged out user mode
.user_mode {
  @media screen and (max-width: 1380px) {
    .column-gradient-container {
      padding-left: 20px;
      padding-right: 20px;
    }
    .column-gradient-container:before {
      width: calc(100% - 40px);
    }
  }
}

// target logged in user mode
.logged_in {
  &.user_mode {
    @media screen and (max-width: 1440px) {
      .column-gradient-container {
        padding-left: 20px;
        padding-right: 20px;
      }
      .column-gradient-container:before {
        width: calc(100% - 40px);
      }
    }
  }
}

// target logged in edit mode
.logged_in {
  &.edit_mode {
    .column-gradient-container .column:first-of-type,
    .column-gradient-container .column,
    .column-gradient-container .column:last-of-type {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
