/**
 * Text Block Call To Action Component
 */
.text-block-call-to-action {
  .textBlockElement {
    position: relative;
  }

  // Target all of these .originalTextImage, .leftTextImage, .rightTextimage
  [class$="TextImage"] {
    display: flex;
    flex-flow: column;
    float: none;
    margin: 0;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

    img {
      position: relative;
      width: 100%;
      border-radius: 10px;
      transition: transform $transition-linear;
      z-index: 0;
    }

    // we move everything inside of this div
    a {
      display: flex;
      flex-flow: column;
      align-items:flex-end;

      &:before {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: .2;
        background-color: $color-black;
        transition: opacity $transition-linear;
        z-index: 10;
      }
      
      .pageElement > h3:first-child {
        margin-bottom: 0;
        color: $color-white;
        position: absolute;
        font-size: 24px;
        bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        line-height: 1;
        width: 100%;
      }

      .content-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-right: 30px;
        padding-left: 30px;
        top: 0;
        left: 0;
        z-index: 20;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;

        // specifically target h3 headline
        > h3 {
          text-transform: uppercase;
          margin-bottom: 30px;
          font-size: 24px;
          line-height: 1;
          transition: color $transition-linear, margin $transition-linear;

          &:after {
            content: "";
            height: 2px;
            background-color: $color-primary;
            width: 100%;
            display: block;
            margin-top: 5px;
            opacity: 0;
            transition: opacity $transition-linear, margin $transition-linear;
          }
          @media only screen and (min-width: $breakpoint-md-min) and (max-width: 1023px){
            font-size: 14px; 
            margin-bottom: 15px;
          }

        }

        .text {
          width: 100%;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          a {
            opacity: 1;
          }
        }

        // generically target typography
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          color: $color-white;
        }
      }

      &:hover {
        &:before {
          opacity: .5;
        }
        img {
          transform: scale(1.02);
        }

        .content-wrapper {
          > h3 {
            color: $color-primary;
            margin-bottom: 35px;
            @media only screen and (min-width: $breakpoint-md-min) and (max-width: 1023px){
              margin-bottom: 20px;
            }
            &:after {
              opacity: 1;
              margin-top: 10px;
            }
          }
          .text h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          a {
            opacity: 1;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
