/**
 * Custom Video Component
 * Pulled from ironman theme & modified
 * expected layout for hero text and sub headline text allows us to add a h1 which will be font-weight:700; and subsequent h heading elements will be font-weight:400;
 */

.hero-home {
  margin-bottom: 0;
  position: relative;
  background-size: contain;
  @include mobile {
    margin-bottom: 0;
  }
  .column {
    padding-left: 0;
    padding-right: 0;
    // @include mobile {
    //   height: 199px !important;
    // }
  }
  .home-video {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    min-height: 500px;
    max-height: 100vh;
    overflow: hidden;
    position: relative;
    .has-se-bar & {
      max-height: calc(100vh - 60px);
    }
    @include mobile {
      display: none;
    }

    .pageElement {
      margin-top: 0;
      margin-bottom: 0;
    }
    .codeElement {
      &:after {
        content: "";
        background-image: linear-gradient(
          135deg,
          $color-secondary 0%,
          black 100%
        );
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        opacity: 0.3;
        top: 0;
        left: 0;
      }
    }
    video {
      object-fit: cover;
      width: 100%;
      height: 100vh;
      position: relative;
    }
    // this technique sets display & background visibily when video is present
    // hides background/image on desktop
    // shows image only on mobile
    @media screen and (min-width: 768px) {
      + .video-overlay-text {
        .user_mode & {
          .pageElement {
            position: absolute;
            background-size: 0 0;
          }
        }
      }
    } // end media query
  }
  // target video overlay text default styles
  // if home-video is not present as previous sibling element static image will be present as hero image
  .video-overlay-text {
    .user_mode & {
      .pageElement {
        max-width: 100%;
        padding-left: calc((100% - 1040px) / 2);
        padding-right: calc((100% - 1040px) / 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        z-index: 2;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: center center;

        // on mobile position relative to correlate with height set by JavaScript
        // this is applied as default state but overridden when home-video containe is present
        position: relative;
        background-size: cover;

        .leftTextImage,
        .originalTextImage {
          margin:0;
          img {
            display: none;
          }
        }
        @media screen and (max-width: 1140px) {
          padding-left: 5%;
          padding-right: 5%;
        }
        @media screen and (max-width: 767px) {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            z-index: -1;
          }
        }
        @media screen and (max-width: 768px) {
          padding-left: 20px;
          padding-right: 20px;
        }
        @include touch-device {
          min-height: 208px;
        }
      }
      h3 span {
        color: $color-white;
        font-family: $font-primary;
        font-weight: 700;
        font-size: 60px;
        display: flex;
        align-items: flex-start;
        line-height: 1.167;
        margin-bottom: 0;
        opacity: 1;
        // visibility: hidden;
        @include mobile {
          font-size: 27px;
          line-height: 1.333;
          margin-bottom: 20px;
        }
        @include tablet-sm {
          font-size: 42px;
          margin-bottom: 0;
        }
        @include tablet-sm {
          font-size: 54px;
          margin-bottom: 0;
        }
        @media only screen and (min-width: $breakpoint-lg-tablet) and (max-width: 1300px) {
          font-size: 64px;
          margin-bottom: 0;
        }
      }

      .text {
        h1 {
          @include touch-device {
            font-size: 48px;
            line-height: 60px;
            margin-bottom: 40px;
          }
          @include mobile {
            font-size: 28px;
            line-height: 36px;
          }
        }
        h1 + h2,
        h1 + h3,
        h1 + h4,
        h1 + h5,
        h1 + h6 {
          font-weight: 400;
          @include mobile {
            display: none;
          }
        }
        h1 + h4 {
          @include touch-device {
            font-size: 18px;
          }
        }
      }
      // fix added to hide text on desktop
      h3 {
        //display: none;
        @include mobile {
          display: block;
        }
      }
      p {
        color: $color-white;
        font-family: $font-primary;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.5;
        @include touch-device {
          &:first-of-type {
            display: none;
          }
        }
      }
    }
  }
  .scrollDownIndicator {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15%;
    .loaderArrow {
      font-size: 48px;
      color: $color-primary;
    }
  }
}

