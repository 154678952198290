/* Countdown Element */
// 1. Global Styles
// 2. User Options
// 3. Accomidation for small columns & Mobile adjustments

// 1. Global Styles
.countdownElement {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  position:relative;
  padding: 15px 13px;
  text-align:left;
  background-position: center center;
  align-items: center;
  justify-content: left;
  h2 {
    font-family: $font-primary;
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    text-transform: none;
    color: $color-white;
    margin-bottom: 0px;
    margin-right: 0px;
    order: 1;
    @include mobile {
      font-size:20px;
    }
    &:empty {
      display: none;
    }
  }
  // countdown timer
  abbr {
    background: linear-gradient(-3deg, #454545, #989898);
    align-self: center;
    min-width: 315px;
    position: relative;
    border:none;
    border-radius: 10px;
    line-height:1;
    text-align:center;
    text-decoration:none;
    white-space: nowrap;
    margin-right: 25px;
  }
  span {
    display:inline-block;
    margin:0 6px;
    min-width: 46px;
    @media screen and (min-width: $breakpoint-md-min) {
      min-width: 55px;
    }
  }
  b {
    display: inline-block;
    font-family: $font-primary;
    color: $color-white;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    margin-top: 5px;
  }
  small {
    display: block;
    margin-top: 9px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: $color-white;
    margin-bottom: 14px;
  }
  .minutes {
    small { display: none; }
    &:after { content: 'Min';
        display: block;
        margin-top: 9px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-white;
        margin-bottom: 14px;
      }
  }
  .seconds {
    small { display: none; }
    &:after { content: 'Sec';
      display: block;
      margin-top: 9px;
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 14px;
    }
  } 
}
  
// 2. User options
.countdownElement.countdown-dark {
  background: linear-gradient(90deg, #5D2B82, #D11487);
  border-radius: 10px;
}
.countdownElement.countdown-light{
  background: linear-gradient(90deg, #5D2B82, #D11487);
  border-radius: 10px;
}

// 3. Accomidation for small columns & Mobile adjustments
@media only screen and (min-width: 900px) {
  .col-md-4, .col-md-3{ 
    .countdownElement{
      display: block;
      h2 {
        margin-bottom: 15px;
        width: 100%;
      }
      abbr {
        display: block;
        margin: 0 auto;
        min-width: 0;
        white-space: initial;
      }
      b{
        font-size:rem(36);
      }
      small{
        font-size:rem(11);
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 900px) {
  .col-md-4, .col-md-3{ 
    .countdownElement{
      abbr {
        span{
          width:33%;
          padding:1.5% 0;
        }
      }
      b{
        font-size:rem(36);
      }
      small{
        font-size:rem(11);
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .countdownElement{
    display: block;
    h2 {
      margin-bottom: 15px;
      width: 100%;
    }
    abbr {
      display: block;
      margin: 0 auto;
      min-width: 0;
      white-space: initial;
    }
    b{
      font-size:rem(40);
    }
  }
}
@media only screen and (max-width: 600px) {
  .countdownElement{
    b{
      font-size:rem(32);
    }
    small{
      font-size:rem(11);
    }
  }
}
@media only screen and (max-width: 370px) {
  .countdownElement{
    abbr {
      span{
        width:33%;
        padding:1.5% 0;
      }
    }
  }
}
