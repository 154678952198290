/* Collectors */
// 1. Mixins (Remove when liquid)
// 2. Collector Elements (Global styles)
// 3. News Collection
// 4. Media Queries
// 5. Document Collection and Photo Collection (Please audit these styles)
// Note: News Collection element should mirror news aggregator style.
// Note: Consider breaking this file into multiple files when styling Photo and Document collectors, if it is logical, or delete this note.

// 1. Mixins (Remove when liquid)
@mixin newscol-image {
  display: block;
  float: left;
  img {
    width: 100%;
    display: block;
  }
}

// 2. Collector Elements (Global styles)
.collectorElement {
  display: flex;
  flex-flow: row wrap;
  .tags {
    font-size: em(12);
    line-height: 1.5;
    margin: 0;
  }
  .bottomOptions {
    display: flex;
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    justify-content: flex-end;
  }
}

// 3. News Collection
.collectorNews {
  .item {
    position: relative;
    display: flex;
    flex-flow: column;
    max-width: calc(100% / 3 - 30px);
    width: 100%;
    padding-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    background-color: #FAFAFA;
    border-radius: 10px;
    overflow: hidden;
    @extend %clearfix;
    @media screen and (max-width: $breakpoint-sm-max) {
      max-width: none;
      display: block;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 15px;
    }
    > a { 
      @include newscol-image; 
      display: block;
      position: relative;
      width: 100%;
      max-width: 420px;
      margin-bottom: 30px;
      &:before {
        content: "";
        float: left;
        padding-bottom: aspect-to-percent(2, 1);
      }
      img {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
      }
    }

    > h4 {
      margin-left: 20px;
      margin-top: 7px;
        a { 
          font-size: 24px;
          font-weight: 400;
          color: $color-gray-dk-2;
          transition: color $transition-linear;
          line-height: 32px;
          &:hover {
            color: $color-hover-primary;
          }
          @media screen and (max-width: 1024px) {
              padding-left: 0;
          }
          @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
            font-size: 18px;
          }
      }
    }
  }
  .dateAuthor {
    line-height: 1;
    margin: 0;
  }
  .newsAuthor {
    color: $color-gray-dk;
    font-family: $font-primary;
    font-size: 12px;
    line-height: 12px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      background-image: url('../images/news-article-page-ui/user-circle-solid.svg');
      background-position: center;
      background-repeat: no-repeat;
      width:12px;
      height: 12px;
      font-size: 12px;
      margin-right: 1ch;
      display: inline-block;
  }
  }
  .newsDate {
    display: none;
  }
  .teaser {
    font-family: $font-primary;
    color: $color-gray-dk-6;
    font-size: 16px;
    line-height: 24px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }
  }
  .tags {
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    padding-left: 20px;
    margin-top: auto;
    color: $color-gray-md-3;
    a {
      display: inline-block;
      font-size: 12px;
      color: $color-gray-dk-3;
      margin-top: 0px;
      margin-right: 5px;
      margin-bottom: 5px;
      margin-left: 0px;
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
      background-color: $color-gray;
      transition: color $transition-linear, background-color $transition-linear;
      &:hover {
        color: $color-white;
        background-color: $color-hover-primary;
      }
    }
  }
  .clearAll {
    display: none; // Old markup, remove when liquid
  }
}

// 5. Document Collection and Photo Collection
.collectorDocuments,
.collectorPhotos {
  .item {
    @include list-item;

    .info {
      font-size: 1em;
      font-weight: normal;
      float: left;
      margin-left: 25px; // Offset the icon
    }

    & > a img {
        display: none;
    }
  }

  img {
    margin: 0 10px 10px 0;
    float: left;
  }

  h4 {
    font-size: 1.125em;
  }

  li h4 a {
    font-size: .75em;
    text-decoration: none;
  }

  .dateAuthor {
    color: #666;
    font-size: .75em;
    margin-bottom: .25em;
  }
}

// 6. Pagination
.paginationElement {
  background: transparent;
  border-bottom: none;
  padding: 0;
  margin-top: 50px;
  margin-bottom: 50px;
  @include mobile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .paginationNav {
    font-family: $font-primary;
    @media screen and (max-width: $breakpoint-sm-max) {
      float: left;
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    a {
      color: $color-gray-md-3;
      margin-right: 3px;
      margin-left: 3px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 3px;
      padding-left: 3px;
      border-bottom: 2px solid $color-white;
      transition: border-color $transition-linear, color $transition-linear;
      &:hover {
        color: $color-primary;
        font-style: normal;
        border-bottom: 2px solid $color-primary;
      }
      span {
        font-family: 'fontawesome';
        font-size: 9px;
        display: inline-block;
        transform: translateY(-1px);
      }
      &.previous_page {
        transition: color $transition-linear;
        span {
          padding-right: 10px;
        }
        &:hover {
          color: $color-primary;
        }
      }
      &.next_page {
        span {
          padding-left: 10px;
        }
      }
    }
    .current {
      color: $color-primary;
      font-style: normal;
      margin-right: 3px;
      margin-left: 3px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 3px;
      padding-left: 3px;
      border-bottom: 2px solid $color-primary;
    }
  }
  .results {
    font-size: 12px;
    font-family: $font-primary;
    color: $color-gray-dk;
  }
}
