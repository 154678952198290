/**
 * Hero Slider Component
 */

.hero-slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .user_mode & {
    padding-left: 0;
    padding-right: 0;

    .column {
      padding-left: 0;
      padding-right: 0;
      .hero-slider-text {
        display: none;
      }
    }

    .slick-slider {
      margin-bottom: 0;

      .slick-prev,
      .slick-next {
        height: 80px;
        width: 25px;
        background-color: transparent;
        border-radius: 50%;
        z-index: 3;
        transition: height $transition-linear, width $transition-linear;
      }

      .slick-prev {
        .has-main-nav & {
          @media only screen and (min-width: 1440px) {
            margin-left: calc((100% - #{$content-area-width}) / 2);
            left: 0 !important;
          }
        }
        left: 30px !important;

        @include mobile {
          left: 10px !important;
        }

        &:before {
          content: url(../images/slick-slider-ui/arrow-left-huge.png);
        }
      }

      .slick-next {
        .has-main-nav & {
          @media only screen and (min-width: 1440px) {
            margin-right: calc((100% - #{$content-area-width}) / 2);
            right: 0 !important;
          }
        }
        right: 30px !important;

        @include mobile {
          right: 10px !important;
        }

        &:before {
          content: url(../images/slick-slider-ui/arrow-right-huge.png);
        }
      }

      .slick-slide {
        height: 534px;
        position: relative;
        &:after {
          @include hero-banner-overlay;
        }

        @include mobile {
          height: 125px;
        }

        @include tablet {
          height: 256px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }

        .pageElement {
          height: 100%;
          margin-top: 0;
          margin-bottom: 0;
          overflow: hidden;
          &:before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width:100%;
            display: block;
            height: 100%;
            background-color: rgba(0,0,0,.2);
          }
        }
      }
    }
  }

  .heroSlidetext {
    position: absolute;
    width: 100%;
    z-index: 2;

    // .textBlockElement {
    h1,
    h2,
    h3,
    p {
      text-align: center !important;
      color: $color-white;
      margin-bottom: 0;
    }

    h3 span {
      font-size: 16px;
      letter-spacing: 10px;
      font-family: $font-primary;
      font-weight: 600;
      line-height: 1.5;

      @include mobile {
        font-size: 10px !important;
        letter-spacing: 6.25px !important;
      }

      @include tablet {
        font-size: 14px !important;
        letter-spacing: 8.75px !important;
      }
      @media only screen and (max-width: 1320px) {
        font-size: 16px;
        letter-spacing: 9px;
      }
    }

    h1,
    h1 span {
      font-size: 60px;
      font-family: $font-primary;
      font-weight: 700;
      line-height: 75px;
      max-width: calc(#{$content-area-width / 1.5});
      margin-top: 0;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;

      @include mobile {
        font-size: 28px !important;
      }

      @include tablet {
        font-size: 42px !important;
      }
      @media only screen and (max-width: 1320px) {
        font-size: 54px;
      }
    }
    p {
      font-size: 24px;
      line-height: 36px;
    }

    // }
  }
  .slick-dots {
    display: flex !important;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    @include mobile {
      display: none !important;
    }
    li {
      margin: 0 7px;
      margin-bottom: 83px;
      height: 15px;
      width: 15px;
      button {
        background: $color-white;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        &:before {
          content: none;
        }
      }
      &.slick-active {
        button {
          background: $color-primary;
        }
      }
    }
  }
}

body:not(.custom-search-page) {
  .heroSlidetext {
    h3 {
      margin-bottom: 10px;
      @include tablet {
        margin-bottom: 5px;
      }
      @include mobile {
        margin-bottom: 0;
      }
    }
    h3 span {
      color: $color-white;
      font-family: $font-secondary;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: initial;
      text-transform: none;
      @include mobile {
        display: none;
      }
    }

    .text {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;

      h1 {
        font-size: 72px;
        line-height: 82px;

        @include tablet {
          font-size: 42px;
          line-height: 1.2;
        }

        @include mobile {
          font-size: 20px;
          line-height: 1.1;
        }
      }

      h2 {
        font-size: 36px;
        line-height: 42px;

        @include tablet {
          font-size: 16px;
          line-height: 1.625;
        }

        @include mobile {
          font-size: 12px;
          line-height: 1.25;
        }
      }

      h3 {
        color: $color-white;
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.2;
        margin-top: 10px;
        margin-bottom: 10px;

        @include tablet {
          font-size: 12px;
          line-height: 1.25;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        @include mobile {
          display: none;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      p {
        color: $color-white;
        font-family: $font-primary;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.55;

        .user_mode & {
          display: none;
        }
      }

      .raceDate {
        .user_mode & {
          display: none;
        }
      }
    }
  }

  .race-date-container {
    position: absolute;
    bottom: 0;
    background-color: $color-primary;
    color: $color-white;
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.55;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;

    @include tablet {
      font-size: 16px;
      line-height: 1.625;
    }

    @include mobile {
      font-size: 12px;
      line-height: 1.5;
    }

    .edit_mode & {
      display: none;
    }
  }
}
// .race-sponsors {
//   display: none;
//   padding-left: $mobile-padding;
//   padding-right: $mobile-padding;
//   p {
//       font-family: $font-secondary;
//       font-weight: 400;
//       color:$color-gray-md;
//   }
//   .race-sponsor-text {
//       font-size: 16px;
//       line-height: 1.19;
//       margin-bottom: 10px;
//   }
//   .race-presented-text {
//       font-size: 14px;
//       line-height: 1.2;
//   }
//   @include mobile {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       text-align: center;
//   }
// }

// .custom-search-page {
//   #displayBodyHeader {
//       display: none;
//   }
// }
