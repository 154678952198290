/**
 * CSS styles for News Article Pages
 * @description - CSS styles for News Article Pages
 * CSS is used to set order of article header content
 * @requires {_news_article_page.js} - JS file manipulates DOM to resize article image
 */

.newsPage {
  // background
  .site-background {
    background-image: url("../images/news-article-page-ui/map-background-large.jpg") !important;
  }
  // siteContainer adjustments
  .siteContainer {
    padding-bottom: 0;
    #panelTwo {
      margin-top: 0;
    }
    .yieldPageContent {
      padding-top: 60px;
    }
  }
  // news article header
  .newsItemColumn {
    // Nested items in 'newsItemElement' set order and layout
    .newsItemElement {
      display: flex;
      flex-wrap: wrap;
      .newsArticleThumbnail,
      .titleText {
        width: 100%;
      }
      .authorDetails,
      .sn-share-icons {
        display: flex;
        flex-grow: 1;
        align-items: center;
        order: 1;
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid $color-gray-lt;
      }
      .sn-share-icons {
        justify-content: flex-end;
      }
    }
    // News Article Hero Image
    .newsArticleThumbnail {
      height: 100%;
      border: none;
      float: none;
      margin: 0;
      padding-bottom: 60px;
    }
    // headline styles
    .titleText {
      font-size: 42px;
      line-height: 42px;
      font-weight: 400;
      color: $color-gray-dk-5;
    }

    // subheadline styles
    .teaserText {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      color: $color-gray-dk-6;
    }
    // author details, hide
    .authorDetails {
      font-size: 0;
      line-height: 0;
    }
    // author name styles
    .newsAuthor {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      color: $color-gray-dk;
      display: flex;
      align-items: center;
      &:before {
        content: "";
        background-image: url(../images/news-article-page-ui/user-circle-solid.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 10px;
        height: 14px;
        font-size: 12px;
        margin-right: 1ch;
        display: inline-block;
      }
    }
    .sn-share-icons {
      font-size: 12px;
      line-height: 12px;
      color: $color-gray-md-2;
    }
    // hide publihsed date
    // hide hr
    // hide preview text
    .newsDate,
    hr,
    .previewText {
      display: none;
    }
  } // end newsItemColumn

  // news article content
  .newsContentNode {
    // target the full width column directly after a news article container
    + .column {
      padding-top: 60px;
      padding-bottom: 105px;
      // article tags
      .newsTags {
        font-size: 12px;
        font-weight: 300;
        color: $color-gray-lt-4;
        a {
          padding-top: 5px;
          padding-right: 10px;
          padding-bottom: 5px;
          padding-left: 10px;
          background-color: $color-gray;
          color: $color-gray-dk-3;
          transition: color $transition-linear,
            background-color $transition-linear;
          &:hover {
            color: $color-white;
            background-color: $color-hover-primary;
          }
        }
      }

      // wrapper around sharing & page links
      .bottomOptions {
        display: flex;
        color: $color-gray-lt-4;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        // social sharing widget
        .sharingOptions {
          width: 50%;
          border-top: 0;
          text-align: left;
          font-family: $font-primary;
          font-size: 12px;
          font-weight: 300;
          @media screen and (max-width: 767px) {
            width: 100%;
            text-align: center;
          }
        }
        // Previous Page, Print This Page, Next Page links
        .pageOptions {
          width: 50%;
          align-content: center;
          font-family: $font-primary;
          font-weight: 300;
          @media screen and (max-width: 767px) {
            width: 100%;
            justify-content: center;
          }
          a {
            color: $color-gray-lt-4;
          }
        }
      } // end bottomOptions
    } // end column
  } // end newsContentNode
} // end newsPage
