// .featured-slider {
//     padding-top: $content-area-padding;
//     padding-bottom: $desktop-padding;
//     .column {
//         display: flex;
//         flex-wrap: wrap;
//     }
//     .textBlockElement {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         margin-top: 0;
//         margin-bottom: 0;
//         margin-left: auto;
//         margin-right: auto;
//         > h3 {
//             order: 2;
//             color: $color-secondary;
//             margin-bottom: 5px;
//             margin-top: 20px;
//         }
//         .leftTextImage,.originalTextImage {
//             order: 1;
//             max-width: 200px;
//             max-height: 200px;
//             margin-left: 0;
//             margin-right: 0;
//             margin-top: 0;
//             margin-bottom: 0;
       
//         }
//         .text {
//             order: 3;
//             max-width: 310px;
//             margin-left: auto;
//             margin-right: auto;
//             p {
//                 font-family: $font-primary;
//                 font-weight: 400;
//                 font-size: 13px;
//                 line-height: 1.2;
//                 color: $color-gray-dk;
//             }
//         }
//         img {
//             border-radius: 50%;
//             width: 100%;
//             height: auto;
//         }
//     }
//     .slick-slide {
//         display: flex;
//         justify-content: center;
//     }

//     .slick-arrow {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         height: 50px;
//         width: 50px;
//         background-color: $color-white;
//         border: 1px solid rgba($color-secondary, 0.5);
//         border-radius: 50%;
//         z-index: 10;
//         transform: translate(0, -150%);
//         transition: border $transition-linear;
//         @include mobile {
//             border: 0;
//             background-color: transparent;
//             &:before {
//                 opacity: 1;
//             }
//         }
//         &:before {
//             color:$color-secondary;
//             opacity: 0.5;
//         }
//         &.slick-next {
//             &:before {
//                 content:'\f105';
//                 font-family: 'fontawesome';
//             }
//         }
//         &.slick-prev {
//             &:before {
//                 content:'\f104';
//                 font-family: 'fontawesome';
//             }
//         }
//         &:hover {
//             border: 1px solid rgba($color-secondary, 1);
//             &:before {
//                 opacity: 1;
//             }
//             @include mobile {
//                 border: 0;
//             }
//         }
//     }
    
//     .slick-dots {
//         margin-block-start: 0;
//         margin-block-end: 0;
//         margin-inline-start: 0px;
//         margin-inline-end: 0px;
//         padding-inline-start: 0;
//         @include mobile {
//             display: flex!important;
//             justify-content: center;
//             align-items: center;
//             margin-top: $desktop-padding;
//         }
//         li {
//             height: 12px;
//             width: 12px;
//             button {
//                 height: 12px;
//                 width: 12px;
//                 color: $color-white;
//                 background-color: transparent;
//                 border: 1px solid $color-gray;
//                 border-radius: 50%;
//                 transition: background-color $transition-linear, border-color $transition-linear;
//                 &:before {
//                     content:'';
//                 }
//             }
//             &.slick-active {
//                 button {
//                     height: 12px;
//                     width: 12px;
//                     background-color: $color-primary;
//                     border: 1px solid transparent;
//                     border-radius: 50%;
//                     &:before {
//                         content:'';
//                     }
//                 } 
//             }
//         }
//     }
// }


.featured-slider {
    .slick-slide {

    }
    .heroPhotoElement {
        max-width: 400px;
        width: 100%;
        max-height: 400px;
        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }
        .slick-slide {
        display: flex;
        justify-content: center;
    }
    .slick-dots {
        @include touch-device {
            display: none;
        }
    }

    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background-color: transparent;
        z-index: 10;
        // transform: translate(0, -150%);
        transition: border $transition-linear;
        @include mobile {
            border: 0;
            background-color: transparent;
            &:before {
                opacity: 1;
            }
        }
        &:before {
            color:$color-secondary;
            opacity: 0.5;
            font-size: 36px;
        }
        &.slick-next {
            &:before {
                content:'\f105';
                font-family: 'fontawesome';
            }
        }
        &.slick-prev {
            &:before {
                content:'\f104';
                font-family: 'fontawesome';
            }
        }
        &:hover {
            
            &:before {
                opacity: 1;
            }
            @include mobile {
                border: 0;
            }
        }
    }
}
