// Aggregator News

$agg-news-element-entry-title-font: $font-primary;
$agg-news-element-entry-title-size: 24px;
$agg-news-element-entry-title-weight: 700;
$agg-news-element-entry-title-line-height: 1.25;
$agg-news-element-entry-title-color: $color-secondary;
$agg-news-element-entry-title-color-hover: $color-primary;
$agg-news-element-entry-title-case: null;

$agg-news-element-entry-meta-font: $font-secondary;
$agg-news-element-entry-meta-size: 12px;
$agg-news-element-entry-meta-weight: normal;
$agg-news-element-entry-meta-line-height: 12px;
$agg-news-element-entry-meta-color: $color-gray-md;
$agg-news-element-entry-meta-case: none;
$agg-news-element-entry-meta-padding: 5px 0px 0px 0px;

$agg-news-element-entry-body-font: $font-primary;
$agg-news-element-entry-body-size: 16px;
$agg-news-element-entry-body-weight: normal;
$agg-news-element-entry-body-line-height: 1.5;
$agg-news-element-entry-body-color: $color-gray-dk;
$agg-news-element-entry-body-case: null;
$agg-news-element-entry-body-condensed-padding: 5px 0px 0px 0px;
$agg-news-element-entry-body-expanded-padding: 5px 0px 20px 20px;

$agg-news-element-entry-preview-image-width: 400px;
$agg-news-element-entry-preview-image-aspect-ratio: aspect-to-percent(2, 1);
$agg-news-element-entry-preview-image-margin: 30px;
$agg-news-element-entry-preview-image-float: true;

$agg-news-element-entry-button-padding: 10px 0px 0px 0px;
$agg-news-element-entry-padding-y: 20px;

// RESETS
.newsAggregatorElement{
  .details {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .condensed ul{
    list-style: none;
    @media screen and (max-width: 1024px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    li:empty{ display: none; }
  } 
}


// List Styles
.newsAggregatorElement{
  .condensed {
    border-bottom: none;
    background: $color-gray-lt-5;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 20px;
    display: flex;
    align-items: center;
    @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
      display: flex;
      flex-flow: row;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      padding: 0;
    }
  }
  .expanded {
    border-bottom: none;
    background: $color-gray-lt-5;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 5px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: $breakpoint-sm-max) {
      padding: 0;
    }
  }
  .aggHeader {
    margin-bottom: 20px;
  }
  .aggHeader + .condensed,
  .aggHeader + .expanded {
      border-top: none;
  }
  .extendedOptions {
    // display: none;
  }
}  


.newsAggregatorElement {
  // ENTRY PREVIEW IMAGE
  .condensed > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    @media screen and (max-width: 1024px) {
      max-width: 100px;
      margin-right: 20px;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      max-width: none;
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      @media screen and (max-width: $breakpoint-sm-max) {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  // ENTRY TITLE
  .condensed .details h4 a{
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
      font-size: 18px;
    }
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
    }
  }

  // ENTRY META
  .condensed .dateAuthor{
    display: none;
    // line-height: $agg-news-element-entry-meta-line-height;
    // padding: $agg-news-element-entry-meta-padding;
  }
  .condensed .newsAuthor {
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      background-image: url('../images/news-article-page-ui/user-circle-solid.svg');
      background-position: center;
      background-repeat: no-repeat;
      width:10px;
      height: 14px;
      font-size: 12px;
      margin-right: 1ch;
      display: inline-block;
    }
  }
  .condensed .newsDate{
    display: none;
  }

  // ENTRY BODY (teaser)
  .condensed .teaser{
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-condensed-padding;
    display: none;
  }

  // ENTRY BUTTON
  .condensed .commentsAndMore {
    display: none;
    // padding: $agg-news-element-entry-button-padding;
    // .button-content {
    //   display: inline-block;
    // }
    // .button-commentCount {
    //   display: inline-block;
    //   padding: 0;
    // }
    // .button-image-right{ display: none; }
  }
  // .condensed .readMore .button-small{
  //   @include global-link-style;
  //   @include global-link-container;
  //   @include global-link-text($align: center);
  //   display: none;
  // }
}


// EXPANDED
.newsAggregatorElement{
  .newsItemElement {
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-bottom: 20px;
    }
  }
  // ENTRY PREVIEW IMAGE
  .expanded .newsItemHeader > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    @media screen and (max-width: 1024px) {
      max-width: 100px;
      margin-right: 30px;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      max-width: none;
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      @media screen and (max-width: $breakpoint-sm-max) {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  // ENTRY TITLE
  .expanded .newsItemHeader h2 a {
    display: block;
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-left: 15px;
    }
  }

  // ENTRY META
  .expanded .dateAuthor{
    line-height: $agg-news-element-entry-meta-line-height;
    padding: $agg-news-element-entry-meta-padding;
  }
  .expanded .newsAuthor {
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      background-image: url('../images/news-article-page-ui/user-circle-solid.svg');
      background-position: center;
      background-repeat: no-repeat;
      width:10px;
      height: 14px;
      font-size: 12px;
      margin-right: 1ch;
      display: inline-block;
  }
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-left: 15px;
    }
  }
  .expanded .newsDate {
    display: none;
  }

  // ENTRY BODY (teaser)
  .expanded .newsItemElement > h4 {
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-expanded-padding;
    padding-left: 165px;
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-left: 15px;
    }
  }

  // ENTRY BUTTON
  .expanded .commentsAndMore{
    margin: 0;
    .commentCount{
      padding: $agg-news-element-entry-button-padding;
      display: inline-block;
    }
    .button-content{
      display: inline-block;     
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .expanded .readMore .button-small{
    // @include global-link-style;
    // @include global-link-container;
    // @include global-link-text($align: center);
    display: none;
  }
}

// CUSTOM SINGLE CARD STYLE NEWS AGG
.news-card-slider {
  .pageElement {
    margin-top:0;
  }
  .newsAggregatorElement {
    .condensed,
    .expanded{
      border-bottom: none;
      padding-right: 0;
      flex-flow: row wrap;
    }
    .aggHeader + .condensed,
    .aggHeader + .expanded {
      border-top: none;
    }
    .aggHeader {
      color: $color-secondary;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 30px;
      @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
        transform: translateX(50%);
      }
      @media screen and (max-width: 1024px) {
        font-size: 22px;
        padding-top: 50px;
      }
    }
    .item {
      background-color: $color-white;
      border-radius: 10px;
      overflow: hidden;
      transition: background-color $transition-linear;
       > a {
        max-width: none;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;
        &:before{
          content: "";
          float: left;
          padding-bottom: aspect-to-percent(2, 1);
        }
        img {
          @media screen and (max-width: $breakpoint-sm-max){
            padding: 0;
          }
        }
      }
      .details {
        padding-left: 0;
        padding-right: 20px;
        @media screen and (max-width: 1024px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .details h4 {
        padding-top: 7px;
        padding-left: 30px;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
      }
      .details h4 a {
        font-size: 24px;
        font-weight: 400;
        color: $color-gray-dk-2;
        transition: color $transition-linear;
        line-height: 32px;
        &:hover {
          color: $color-hover-primary;
        }
        @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
          font-size: 18px;
        }
      }
      .newsAuthor {
        color: $color-gray-dk;
        font-family: $font-primary;
        padding-left: 30px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
        &:before {
          content: '';
          background-image: url('../images/news-article-page-ui/user-circle-solid.svg');
          background-position: center;
          background-repeat: no-repeat;
          width:10px;
          height: 12px;
          font-size: 12px;
          margin-right: 1ch;
          display: inline-block;
        }
      }
      .newsDate {
        display: none;
      }
      .teaser {
        display: block;
        font-family: $font-primary;
        color: $color-gray-dk-6;
        font-size: 16px;
        line-height: 24px;
        padding-top: 13px;
        padding-left: 30px;
        max-width: 560px;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
      }
      .readMore {
        padding-left: 30px;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
        &:hover {
          .button-small {
            &:after {
              transform: translateX(2px);
            }
          }
        }
        .button-small {
          display: inline-block;
          background: none;
          color: $color-primary;
          font-size: 14px;
          padding: 0;
          font-weight: 400;
          padding-bottom: 30px;
          &:before {
            display: none;
          }
          &:after {
            content: '\f0da';
            font-family: 'FontAwesome';
            font-size: 14px;
            display: inline-block;
            color: $color-primary;
            margin-left: 1.5ch;
            transition: transform $transition-linear;
          }
        }
      }
    }
    .extendedOptions {
      display: none;
    }
  }
  .slick-track {
    display: flex;
    padding-bottom: 10px;
  }
  .newsAggregatorElement .item .newsAuthor:before {
    content: none;
  }

  .newsAggregatorElement .item .newsAuthor {
    font-weight: 700;
    font-size: 14px;
    color: #999999;
    transition: color $transition-linear;
  }

  .newsAggregatorElement .item .details h4 a {
    font-weight: 700;
    color: #000000;
    transition: color $transition-linear;
  }

  .newsAggregatorElement .item .teaser {
    color: #999999;
    transition: color $transition-linear;
  }

  .newsAggregatorElement .item {
      width: 100%;
      max-width: 400px;
      margin-left: 15px;
      margin-right: 15px;
      display: flex;
      min-height: 100%;
      height: auto;
      align-content: flex-start;
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
      transition: box-shadow $transition-linear, background-color $transition-linear;
  }

  .pageElement {
      display: flex;
      justify-content: center;
  }

  .newsAggregatorElement .extendedOptions {
    display: none;
  }

  .newsAggregatorElement .condensed > a img {
    position: relative;
    height: auto !important;
  }

  .newsAggregatorElement .item:hover { 
    background-color: #0093C8;
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,.5);
    .newsAuthor,
    .details h4 a,
    .details h4 a:hover,
    .teaser {
      color: #ffffff;
    }
    
  }

}

// CUSTOM LIST STYLE AGG
.custom-list-agg {
  margin-top: 55px;
  @media screen and (max-width: 1024px) {
    margin-top: 110px;
  }
  @media screen and (max-width: $breakpoint-sm-max) {
    margin-top: 0;
  }
  .newsAggregatorElement {
    .condensed,
    .expanded{
      border-bottom: none;
      background: $color-white;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 20px;
      @include touch-device{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      @media screen and (max-width: $breakpoint-sm-max) {
        padding: 0;
      }
    }
    .aggHeader + .condensed,
    .aggHeader + .expanded {
      border-top: none;
    }
    .item {
      box-shadow: $box-shadow-active;
      transition: box-shadow $transition-linear;
      &:hover {
        box-shadow: $box-shadow-hover;
      }
      > a {
        max-width: 210px;
        margin-right: 30px;
        margin-left: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        @include touch-device{
          margin-right: 20px;
          margin-left: 10px;
          margin-bottom: 10px;
          margin-top: 10px;
          max-width: 100px;
        }
        @include touch-device {
          max-width: none;
          margin-right: 0;
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 30px;
        }
        &:before {
          content: '';
          float: left;
          padding-bottom: aspect-to-percent(2, 1);
        }
        img {
          @media screen and (max-width: $breakpoint-sm-max) {
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
      .details {
        @media screen and (max-width: $breakpoint-sm-max) {
          padding-bottom: 30px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 0;
        }
      }
      h4 {
        a {
          font-size: 20px;
          color: $color-secondary;
          font-weight: 600;
          line-height: 1.6;
          @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
            font-size: 18px;
          }
        }
      }
      .newsAuthor {
        color: $color-gray-md;
        font-family: $font-secondary;
        display: flex;
        align-items: center;
        &:before {
          content: '';
          background-image: url('../images/news-article-page-ui/user-circle-solid.svg');
          background-position: center;
          background-repeat: no-repeat;
          width:10px;
          height: 14px;
          font-size: 12px;
          margin-right: 1ch;
          display: inline-block;
      }
      }
      .newsDate,
      .teaser,
      .commentsAndMore {
        display: none;
      }
    }
    .extendedOptions {
      display: none;
    }
  }
}

.pageEl:not(.news-card-slider) {
  // Aggregator News

$news-element-entry-title-font: $font-primary;
$news-element-entry-title-size: 24px;
$news-element-entry-title-weight: 700;
$news-element-entry-title-line-height: 1.25;
$news-element-entry-title-color: $color-secondary;
$news-element-entry-title-color-hover: $color-primary;
$news-element-entry-title-case: null;

$news-element-entry-meta-font: $font-primary;
$news-element-entry-meta-size: 14px;
$news-element-entry-meta-weight: 700;
$news-element-entry-meta-line-height: 1;
$news-element-entry-meta-color: $color-gray-dk;
$news-element-entry-meta-case: null;
$news-element-entry-meta-padding: 5px 0px 0px 0px;

$news-element-entry-body-font: $font-primary;
$news-element-entry-body-size: 14px;
$news-element-entry-body-weight: 700;
$news-element-entry-body-line-height: 1.5;
$news-element-entry-body-color: $color-gray-dk;
$news-element-entry-body-case: null;
$news-element-entry-body-padding: 0px;

$news-element-entry-preview-image-width: 400px;
$news-element-entry-preview-image-aspect-ratio: aspect-to-percent(2, 1);
$news-element-entry-preview-image-margin: 0px;
$news-element-entry-preview-image-float: true;

$news-element-entry-button-padding: 20px 0px 0px 0px;
$news-element-entry-padding-y: 20px;
$news-element-entry-padding-x: 15px;

// RESETS
.newsAggregatorElement{
  .condensed ul{
    list-style: none;
    li:empty{ display: none; }
  }
}


// List Styles
.newsAggregatorElement{
  .condensed,
  .expanded{
    background-color: $color-white;
    box-shadow:0px 2px 4px rgba(0,0,0,.1);
    margin-bottom: 10px;
    padding: 30px;
    align-items: flex-start;
    @include touch-device {
      display: flex;
      flex-wrap: wrap;
    }
    .newsAuthor {
      padding-bottom: 10px;
    }
    .newsItemHeader {
      @include touch-device{
        display: flex;
        flex-wrap: wrap;
      }
    }
    .details {
      display: flex;
      flex-wrap: wrap;
      padding-top:0;
      @include touch-device {
        padding-top: 10px;
      }
      li {
        width: 100%;
      }
    }
    &:hover {
      >a {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .aggHeader + .condensed,
  .aggHeader + .expanded {
    margin-top: 10px;
  }
  .newsItemElement {
    width: 100%;
  }
}


.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .condensed > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $news-element-entry-preview-image-width;
    margin: $news-element-entry-preview-image-margin;
    margin-right: 30px;
    overflow: hidden;
    @include touch-device {
      max-width: 100%;
      margin-right: 0;
    }
    @if $news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      transition: transform .2s ease-in-out;
    }
  }

  // ENTRY TITLE
  .condensed .details h4 a{
    font-family: $news-element-entry-title-font;
    font-size: $news-element-entry-title-size;
    font-weight: $news-element-entry-title-weight;
    line-height: $news-element-entry-title-line-height;
    text-transform: $news-element-entry-title-case;
    color: $news-element-entry-title-color;
    &:hover{
      color: $news-element-entry-title-color-hover;
    }
  }

  // ENTRY META
  .condensed .dateAuthor{
    line-height: $news-element-entry-meta-line-height;
    padding: $news-element-entry-meta-padding;
    display: none;
  }
  .condensed .newsAuthor,
  .condensed .newsDate{
    font-family: $news-element-entry-meta-font;
    font-size: $news-element-entry-meta-size;
    font-weight: $news-element-entry-meta-weight;
    line-height: $news-element-entry-meta-line-height;
    text-transform: $news-element-entry-meta-case;
    color: $news-element-entry-meta-color;
    &:before {
      display: none;
    }
  }

  // ENTRY BODY (teaser)
  .condensed .teaser{
    font-family: $news-element-entry-body-font;
    font-size: $news-element-entry-body-size;
    font-weight: $news-element-entry-body-weight;
    line-height: $news-element-entry-body-line-height;
    text-transform: $news-element-entry-body-case;
    color: $news-element-entry-body-color;
    padding: $news-element-entry-body-padding;
  }

  // ENTRY BUTTON
  .condensed .commentsAndMore{
    padding: $news-element-entry-button-padding;
    .button-content {
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .condensed .readMore .button-small{
    display: none;
  }
}


// EXPANDED
.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .expanded .newsItemHeader > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $news-element-entry-preview-image-width;
    margin: $news-element-entry-preview-image-margin;
    margin-right: 30px;
    @include mobile {
      max-width: 100%;
      margin-right: 0;
    }
    @if $news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }
  .expanded .newsItemElement {
    width: 100%;
    @include touch-device {
      width: 100%;
    }
  }

  // ENTRY TITLE
  .expanded .newsItemHeader h2 a {
    display: block;
    font-family: $news-element-entry-title-font;
    font-size: $news-element-entry-title-size;
    font-weight: $news-element-entry-title-weight;
    line-height: $news-element-entry-title-line-height;
    text-transform: $news-element-entry-title-case;
    color: $news-element-entry-title-color;
    &:hover{
      color: $news-element-entry-title-color-hover;
    }
  }

  // ENTRY META
  .expanded .dateAuthor{
    line-height: $news-element-entry-meta-line-height;
    padding: $news-element-entry-meta-padding;
    display: none;
  }
  .expanded .newsAuthor,
  .expanded .newsDate{
    font-family: $news-element-entry-meta-font;
    font-size: $news-element-entry-meta-size;
    font-weight: $news-element-entry-meta-weight;
    line-height: $news-element-entry-meta-line-height;
    text-transform: $news-element-entry-meta-case;
    color: $news-element-entry-meta-color;
  }
  .expanded .newsAuthor {
    &:before {
      display: none;
    }
  }


  // ENTRY BODY (teaser)
  .expanded .newsItemElement > h4 {
    font-family: $news-element-entry-body-font;
    font-size: $news-element-entry-body-size;
    font-weight: $news-element-entry-body-weight;
    line-height: $news-element-entry-body-line-height;
    text-transform: $news-element-entry-body-case;
    color: $news-element-entry-body-color;
    padding: $news-element-entry-body-padding;
    display: flex;
  }

  // ENTRY BUTTON
  .expanded .commentsAndMore{
    margin: 0;
    .commentCount{
      padding: $news-element-entry-button-padding;
      display: inline-block;
    }
    .button-content{
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .expanded .readMore .button-small{
    display: none;
  }
}

.layout-66-33 {
  .column-2 {
    .newsAggregatorElement {
      .condensed > a{
        max-width: 125px;
        @include touch-device {
          max-width: 100%;
          margin-right:0;
        }
      }
    }
    .newsAggregatorElement {
      .expanded .newsItemHeader > a{
        max-width: 125px;
        @include touch-device {
          max-width: 100%;
          margin-right:0;
        }
      }
    }
  }
}
.layout-33-66 {
  .column-1 {
    .newsAggregatorElement {
      .condensed > a{
        max-width: 125px;
        @include touch-device {
          max-width: 100%;
          margin-right:0;
        }
      }
    }
    .newsAggregatorElement {
      .expanded .newsItemHeader > a{
        max-width: 125px;
        @include touch-device {
          max-width: 100%;
          margin-right:0;
        }
      }
    }
  }
}

.layout-33-33-33 {
  .column {
    .newsAggregatorElement {
      .condensed > a{
        max-width: 125px;
        @include touch-device {
          max-width: 100%;
          margin-right:0;
        }
      }
    }
    .newsAggregatorElement {
      .expanded .newsItemHeader > a{
        max-width: 125px;
        @include touch-device {
          max-width: 100%;
          margin-right:0;
        }
      }
    }
  }
}
}

// CUSTOM SINGLE CARD STYLE NEWS AGG
.custom-card-agg.pageEl {
  .pageElement {
    margin-top:0;
  }
  .newsAggregatorElement {
    .condensed,
    .expanded{
      border-bottom: none;
      padding-right: 0;
      flex-flow: row wrap;
    }
    .aggHeader + .condensed,
    .aggHeader + .expanded {
      border-top: none;
    }
    .aggHeader {
      color: $color-secondary;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 30px;
      @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
        transform: translateX(50%);
      }
      @media screen and (max-width: 1024px) {
        font-size: 22px;
        padding-top: 50px;
      }
    }
    .item {
      border-radius: 10px;
      overflow: hidden;
       > a {
        max-width: none;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;
        &:before{
          content: "";
          float: left;
          padding-bottom: aspect-to-percent(2, 1);
        }
        img {
          @media screen and (max-width: $breakpoint-sm-max){
            padding: 0;
          }
        }
      }
      .details {
          padding-left: 20px;
          padding-right: 20px;
      }
      .details h4 {
        padding-top: 7px;
        padding-left: 0;
      }

      .newsAuthor {
        color: $color-gray-dk;
        font-family: $font-primary;
        padding-left: 30px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
        &:before {
          content: '';
          background-image: url('../images/news-article-page-ui/user-circle-solid.svg');
          background-position: center;
          background-repeat: no-repeat;
          width:10px;
          height: 12px;
          font-size: 12px;
          margin-right: 1ch;
          display: inline-block;
        }
      }
      .newsDate {
        display: none;
      }
      .teaser {
        font-family: $font-primary;
        color: $color-gray-dk-6;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: block;
        margin-left: 0;
        padding-top: 15px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 15px;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
      .readMore {
        padding-left: 30px;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
        &:hover {
          .button-small {
            &:after {
              transform: translateX(2px);
            }
          }
        }
        .button-small {
          display: inline-block;
          background: none;
          color: $color-primary;
          font-size: 14px;
          padding: 0;
          font-weight: 400;
          padding-bottom: 30px;
          &:before {
            display: none;
          }
          &:after {
            content: '\f0da';
            font-family: 'FontAwesome';
            font-size: 14px;
            display: inline-block;
            color: $color-primary;
            margin-left: 1.5ch;
            transition: transform $transition-linear;
          }
        }
      }
    }
    .condensed {
      position: relative;
      display: flex;
      flex-flow: column;
      max-width: 100%;
      width: 100%;
      padding: 0 0 30px 0;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 30px;
      background-color: #FAFAFA;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: none;
      @extend %clearfix;
      @media screen and (max-width: $breakpoint-sm-max) {
        max-width: none;
        display: block;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 15px;
      }
      > a {  
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;
        max-height: 310px;
        margin-bottom: 30px;
        &:before {
          content: "";
          float: left;
          padding-bottom: aspect-to-percent(2, 1);
        }
        img {
          position: absolute;
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          object-position: center;
        }
      }
      .newsAuthor {
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        padding-left: 0;
      }
      .details h4 {
        padding: 0;
        margin: 0;
          a { 
            font-size: 24px;
            font-weight: 400;
            color: $color-gray-dk-2;
            transition: color $transition-linear;
            line-height: 32px;
            &:hover {
              color: $color-hover-primary;
            }
            @media screen and (max-width: 1024px) {
                padding-left: 0;
            }
            @media screen and (min-width: $breakpoint-sm-max) and (max-width: 1024px){
              font-size: 18px;
            }
        }
      }
      &:hover > a img {
        transform: scale(1);
      }
    }
    .extendedOptions {
      display: none;
    }
  }
}
.column-1 {
  .custom-card-agg.pageEl {
    padding-right: 0;
    
    @media screen and (min-width: $breakpoint-intermediate-min){
      padding-right: 5px;
    }

  }
}
.column-2 {
  .custom-card-agg.pageEl {
    padding-left: 0;
    @media screen and (min-width: $breakpoint-intermediate-min){
      padding-left: 5px;
    }
  }
}