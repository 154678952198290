// Global Footer
// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
}
.snFooterContainer {
  background-color: $color-secondary;

}

/**
 * 
 */
.custom-footer-extra {
  padding-top: 50px;

  .footer-text {
    margin-bottom: 10px;
    @include touch-device  {
      padding-left: 40px;
      padding-right: 40px;
    }

    h1,h2,h3,h4,h5,h6,
    p {
      color: $color-white;
      font-weight: 700;

    }

    h4 {
      font-size: 30px;
      line-height: 1.2;
      margin-bottom: 24px;
    }

    p {
      font-size: 14px;
      line-height: 14px;

    }

  }

  /**
   * '.footer-button'
   */
  .footer-button {
    margin-bottom: 40px;

    .linkElement {
      text-align: center;

      h4 {
        display: inline-block;
      }

    }

    @media screen and (max-width: 1024px) {

      /** '.footer-button' Styles */
      margin-bottom: 30px;

    }

  }

  /**
   * hr styles
   */
  hr {
    height: 1px;
    background-color: $color-gray-lt-8;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;

    @media screen and (max-width: 1024px) {

      /** 'hr' Styles */
      margin-bottom: 20px;

    }

  }

  /**
   * '.footer-navigation' psuedo class before & after flex based row wrapping can handle 3 rows at desktop viewport width
   */
  .footer-navigation {
    margin-bottom: 44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    .navigation-list {
      padding: 0 20px;

      /**
       * parentNavItem styles
       */
      .parentNavItem {
        margin-top: 39px;

      }

      h3 {
        font-size: 16px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 8px;
        transition: text-decoration $transition-linear;

      }

      /**
       * Link styles
       */
      a {
        font-size: 12px;
        font-weight: 400;
        line-height: 36px;
        color: white;
        display: block;
        transition: text-decoration $transition-linear;

        &:hover {
          text-decoration: underline;

          h3 {
            color: $color-hover-primary;

          }
        }

      }

      .childNavItem {
        display: none;
      }

    }

    @media screen and (max-width: 1024px) {

      /** Footer Navigation Styles */
      flex-wrap: wrap;
      margin-bottom: 14px;

      .navigation-list {
        flex-basis: 100%;
        display: inline-block;
        text-align: center;
        max-width: 100%;

        .parentNavItem {
          margin-top: 20px;

        }
        /** hide child nav items*/
        .childNavItem {
          display: none;

        }

      }

    }

  }

  /**
   * '.footer-logo'
   */
  .footer-logo {
    margin-bottom: 22px;

    img {
      max-width: 252px;

    }

    @media screen and (max-width: 1024px) {

      /** '.footer-logo' Styles */
      margin-bottom: 13px;

    }

  }

  /**
   * '.footer-social'
   */
  .footer-social {
    text-align: center;

    .sn-social-media-list a[class*="icon"] {
      background: transparent;
      margin: 0;
      width: initial;
      height: 20px;
      
      &:hover {
        background-color: transparent;

        &:before {
          color: $color-hover-primary;

        }

      }

      &:before {
        display: block;
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        left: 0;
        padding-right: 10px;
        padding-left: 10px;
        transition: color $transition-linear;

      }

    }

  }

  /**
   * '.legal-footer-links'
   */
  .legal-footer-links {
    text-align: center;

    li {
      display: inline-block;
      padding-left:5px;
      padding-right: 5px;

    }

    a {
      line-height: 12px;
      font-size: 12px;
      color: $color-gray-lt-2;
      transition: color $transition-linear;

      &:hover {
        color: $color-hover-primary;
      }

    }

  }

}