/*
 * Left Rail Component
 * Copied from ironman theme
 */
.left-rail-layout {
  &.user_mode {
    .layoutContainer.row.layout-100.hero-slider-text {
      display: none;
    }
  }
  #panelTwo {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    .leftRail {
      border-bottom: none;
      width: 100%;
      max-width: 322px;
      height: auto;
      padding-right: 40px;
      padding-left: 40px;
      background-color: $color-white;
      margin-right: 2px;
      @include touch-device {
        border-bottom: 2px solid $color-gray-lt-6;
        margin-right: 0;
        max-width: 100%;
        width: 100%;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
      }
      .left-sub-nav {
        width: 100%;
        .column {
          @include touch-device {
            display: none;
          }
        }
      }
      .layoutContainer,
      .column {
        padding-left: 0;
        padding-right: 0;
        @include touch-device {
          padding-bottom: 0;
          padding-top: 0;
        }
      }
    }
    .yieldPageContent {
      background-color: $color-white;
    }
  }
  #errorNotice {
    display: none;
  }
  .left-rail-device-container {
    display: none;
    @include touch-device {
      background-color: white;
      display: block;
      padding-bottom: 30px;
      padding-top: 30px;
      text-align: center;
      width: 100%;
      h3 {
        margin-top: 20px;
        text-align: center !important;
      }
    }
  }
  .yieldPageContent {
    width: 100%;
  }
}
.has-main-nav {
  .user_mode.left-rail-layout {
    .yieldPageContent {
      max-width: calc(100% - 324px);
      flex: 1 1 auto;
      padding-top: 30px;
    }
  }
}
.has-mobile-nav {
  .user_mode.left-rail-layout {
    .yieldPageContent {
      width: 100%;
      flex: 1 1 auto;
      padding-top: 15px;
    }
  }
}
