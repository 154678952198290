/**
 * Merge Into Container SCSS
 */

.layoutContainer .merge-into-container-above,
.layoutContainer .merge-into-container-below {
  @extend %clearfix;

  > .column {
    float: left;
  }
}
