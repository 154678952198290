/**
 * Four Column Container Component
 */
.four-column-container {
  .edit_mode & {
    .columnBounds {
      display: flex;
      flex-wrap: wrap;

      .addPageElementTop {
        width: 100%;
      }

      .drag {
        width: 25%;

        @media screen and (max-width: 1024px) {
          width: 50%;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  .user_mode & {
    .column {
      display: flex;
      flex-wrap: wrap;

      .pageEl {
        width: 25%;
        padding-left: 10px;
        padding-right: 10px;

        @media screen and (max-width: 1024px) {
          width: 50%;

          &.text-block-call-to-action {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
