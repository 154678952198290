.user_mode .featured-events-container {
  background-color: #141414;
  .featured-events-cards {
    max-width: 1340px;
    margin: 0 auto;
    margin-bottom: 10px;
    .event-card {
      transition: transform $transition-linear;
      &:hover {
        transform: translate(0, -10px);
      }

      a {
        font-size: 1rem;
        display: flex;
        flex-flow: column;
        background-color: #555;
        background-size: 62%, 100%;
        background-position: center 38%, center center;
        background-repeat: no-repeat;
        height: 0;
        max-width: 400px;
        padding-bottom: calc(100% - 30px);
        margin: 0 15px;
        border-radius: 10px;
        color: #fff;
        overflow: hidden;
        transition: box-shadow $transition-linear;
        @include cards-mobile {
          background-size: 55%, 100%;
          margin: 0 auto;
        }

        &:hover {
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
        }
        .status {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          span {
            font-size: clamp(0.1rem, 1vw, 1rem);

            @include cards-tablet {
              font-size: clamp(0.1rem, 1.6vw, 1rem);
              letter-spacing: 5px;
              padding: 3px 15px;
            }

            @include cards-mobile {
              font-size: clamp(0.5rem, 3vw, 1rem);
              padding: 4px 20px 4px 20px;
            }
            font-weight: 700;
            letter-spacing: 7px;
            text-transform: uppercase;
            background-color: #0093c8;
            padding: 4px 20px 4px 23px;
            max-height: 30px;
            border-radius: 0 0 5px 5px;
          }
          pointer-events: none;
        }

        .details {
          line-height: 1.2;
          font-weight: 900;
          position: absolute;
          bottom: 0;
          padding-left: clamp(.5rem, 2vw, 1.875rem);
          padding-bottom: clamp(.5rem, 2vw, 1.875rem);
          pointer-events: none;
          .event-name {
            font-size: clamp(1rem, 1.85vw, 1.875rem);
          }
          .event-date {
            font-size: clamp(.8rem, 1.107vw, 1.125rem);
          }
          @include cards-tablet {
            .event-name {
              font-size: clamp(1rem, 3vw, 1.875rem);
            }
            .event-date {
              font-size: clamp(0.8rem, 2vw, 1.125rem);
            }
          }
          @include cards-mobile {
            padding-left: 1rem;
            padding-bottom: 1rem;
            .event-name {
              font-size: clamp(1.5rem, 3vw, 1.875rem);
            }
            .event-date {
              font-size: clamp(1.2rem, 2vw, 1.125rem);
            }
          }
        }
      }
    }
  }
}

.featured-events-container {
  // slick slider
  .slick-slider {
    padding-left: 30px;
    padding-right: 30px;
    .slick-track {
      padding-top: 10px;
    }
    .slick-prev,
    .slick-next {
      height: 42px;
      width: 20px;
      background-color: transparent;
      z-index: 3;
      &:before {
        // reset slick styles
        font-family: initial;
        font-size: initial;
        line-height: initial;
        color: initial;
        opacity: initial;
        -webkit-font-smoothing: initial;
        -moz-osx-font-smoothing: initial;
        // end reset
      }
      @include cards-tablet {
        height: 42px;
        width: 20px;
        &:before {
          font-size: 16px;
        }
      }
      @include cards-mobile {
        height: 42px;
        width: 20px;
        &:before {
          font-size: 10px;
        }
      }
    }
    .slick-prev {
      left: 0px;
      &:before {
        content: url(../images/slick-slider-ui/arrow_left_white.png);
        opacity: 0.6;
        transition: opacity $transition-linear;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    .slick-next {
      right: 0px;
      &:before {
        content: url(../images/slick-slider-ui/arrow_right_white.png);
        opacity: 0.6;
        transition: opacity $transition-linear;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  } // end slick slider

  .slick-dots {
    @include touch-device {
      display: none !important;
    }
  }
}
