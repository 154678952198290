/*
 * Left Sub Nav Component
 * Copied from ironman theme
 */
.left-sub-nav {
  .leftNav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 38px;
    h3 {
      font-family: $font-primary;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 14px;
      font-size: 16px;
      color: $color-secondary;
      padding-top: 50px;
      padding-bottom: 20px;
      &.has-mobile-nav {
        padding-top: 25px;
      }
      &.parentPage {
        a {
          font-family: $font-primary;
          font-weight: 700;
          line-height: 14px;
          font-size: 16px;
          color: $color-secondary;
          padding: 0;
          margin-left: 0;
          transition: color $transition-standard;
          &:after {
            bottom: -4px;
          }
          &:hover {
            color: $color-hover-primary;
            &:after {
              // width is equal to 100%
              width: calc(100%);
              // 0px, no padding to account for on parent page animation
              left: 0px;
            }
          }
        }
      }
    }
    a {
      position: relative;
      font-family: $font-primary;
      font-weight: 400;
      line-height: 1.5;
      font-size: 16px;
      color: $color-gray-dk;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0px;
      padding-right: 20px;
      width: fit-content;
      transition: padding-left $transition-linear, color $transition-linear;
      &:after {
        content: "";
        width: 0%;
        height: 2px;
        background: transparent;
        bottom: 8px;
        position: absolute;
        left: 0px;
        transition: background $transition-linear, width $transition-linear,
        left $transition-linear;
      }
      &:hover {
        color: $color-hover-primary;
        padding-left: 10px;
        &:after {
          background-color: $color-primary;
          // width is equal to 100% minus left and right padding
          width: calc(100% - 30px);
          // 10px matches growth of padding on link element
          left: 10px;
        }
      }
      // current sibling page styles
      &.true {
        color: $color-hover-primary;
        padding-left: 10px;
        &:after {
          // 10px matches current of padding on selected/active link element
          left: 10px;
        }
      }
    }
    @include touch-device {
      justify-content: center;
      padding-bottom: 15px;
      h3, a {
        align-self: center;
      }
      a {
        margin-left: 20px;
      }
    }
  }
  // title above social media element
  .pageElement > h3:first-child {
    font-size: 24px;
  }
  // elements outside of the left nav
  .adElement,
  .heroPhotoElement {
    margin-bottom: 50px;
  }
}
