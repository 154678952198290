/**
 * Race Event Card Container SCSS Component
 * @description Adds 'race-event-card-container' CSS class to available front end theme classes
 * @requires {_race_event_card_container.js}
 * @requires {Site Builder Configuration} - CSS class should be applied to layout container, LC is intended to house one text block element and one link element per column. Text Block Element is expected to use H4 and P tags for title and description information.
 */
.user_mode {
  .race-event-card-container {
    display: flex;
    gap: 20px;
    .column {
      border: 1px solid $color-gray-lt-3;
      border-radius: 10px;
      padding-top: 12px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      margin-bottom: 0;
      //margin-left: $desktop-padding;
      //margin-right: $desktop-padding;
      //width: calc((33.33333% - ($desktop-padding)) - $desktop-padding);
      //width: 33.33333%;
      &:empty {
        border: none;
      }
      @include mobile {
        margin-bottom: 20px;
      }
    }
    h4 {
      margin-bottom: 0px;
    }
  }
}
