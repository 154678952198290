// Page Elements

.pageElement {
  width: 100%;
  @include page-element-margin;
}
.pageEl {
  @extend %clearfix;
  > .pageElement{
    float: left;
    clear: both;
  }
  .user_mode & {
    &.hide {
      display:none;
    }
  }
}

// Eliminate this if possible
.pageElement{
  .pageElement{
    margin-left: 0;
    margin-right: 0;
  }
}
