/**
 * Promotions Page Component
 */

// remove background-color on #panelTwo
.promotions-page {
  // hide theme sub nav on custom search page
  .theme-sub-nav {
    display: none !important; // important needed to override inline styled applied by theme js
  }
  // set height of topNavPlaceholder
  .has-main-nav.has-sub-nav & {
    #topNavPlaceholder {
      height: $main-nav-height;
    }
  }

  // overrides for tab element
  .tabbedElement {
    .tabContainer {
      border: 1px solid #cccccc;
      padding: 40px;
      font-size: 14px;
      color: #192d1a;
      margin-top: -1px;
    }
    .contentTabs:not(.rapidTabsConstruction) a {
      border-radius: 0;
      margin-right: 0;
      padding: 12px 18px;
    }
  }

  // overrides for hero container
  .promotions-hero {
    .column-content-container {
      max-width: 616px;
    }
    .text {
      h2 {
        font-size: 44px;
        line-height: 54px;
      }
      p {
        font-size: 17px;
        line-height: 36px;
      }
    } 
    .linkElement h4 a:focus, .linkElement h4 a:hover {
      border-color: white;
    }
  }
}
