$sponsor-logo-height: 75px;

/**
 * Sponsor Slider Component CSS
 * @requires {SiteBuilder Configuration} - layout container needs to have the CSS class 'sponsor-slider' 
 * @see {_sponsor_slider.js}
 */
.sponsor-slider {
  max-width: 100%;
  overflow: hidden;

  // .column {
  //   max-width: 225px;

  //   // @media only screen and (min-width: 768px) {
  //   //   max-width: 600px;

  //   // }

  //   // @media only screen and (min-width: 1024px) {
  //   //   max-width: 924px;

  //   // }

  //   // @media only screen and (min-width: 1200px) {
  //   //   max-width: 900px;

  //   //   .home & {
  //   //     max-width: 1065px;
  //   //   }

  //   // }

  // }
  position: relative;
  // top: 40px;
  // @media only screen and (min-width: 768px) {
  //   top: 45px;
  // }
}

// Sponsors Container
.sponsor-slider {
  background: #fff;
  padding: 93px 100px;
  .slick-slider {
    .slick-prev,
    .slick-next {
      height: 50px;
      width: 20px;
      background-color: transparent;
      z-index: 1;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #ddd;
      font-size: 36px;
      font-weight: 400;
      transition: color $transition-linear;
    }
    .slick-prev:hover:before,
    .slick-next:hover:before {
      color: $color-primary;
    }
    .slick-prev {
      left: -20px;

      &:before { 
        content: url(../images/slick-slider-ui/arrow_left_gray.png);
        opacity: .6;
        transition: opacity $transition-linear;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    .slick-next {
      right: -20px;
      &:before {
        content: url(../images/slick-slider-ui/arrow_right_gray.png);
        opacity: .6;
        transition: opacity $transition-linear;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    .slick-slide {
      margin-left: 30px;
      margin-right: 30px;
      .heroPhotoElement {
        img {
          // padding: 10px;
          max-height: $sponsor-logo-height;
          max-width: 100%;
          width: auto;
          //   transition: transform $transition-linear;
          filter: grayscale(1) opacity(0.5);
          //transform: scale(1);
          transition: filter $transition-linear, transform 100ms linear;
        }
      }
      &:hover {
        // background-color: $lt_gray;
        img {
          //transform: scale(1.05);
          filter: grayscale(0) opacity(1);
        }
      }
    }
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
    }
  }
  .column {
    padding: 0;
  }
  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: $sponsor-logo-height;
        width: auto;
      }
    }
  }
  .has-mobile-nav & {
    img {
      max-height: $sponsor-logo-height;
      width: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 50px 20px;

    .slick-slider {
      .slick-prev {
        left: 30px;
      }

      .slick-next {
        right: 30px;
      }
    }
  }
}
