/**
 * Race Page Card Component
 */
.race-info-band,
.race-card-badge-images {
  display: none;
}
.race-page-card {
  position: relative;
  display: flex;
  margin-top: -43px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  max-width: 1340px;
  border-radius: 10px;
  background-color: $color-white;
  @media screen and (max-width: 1380px) {
    margin-right: 20px;
    margin-left: 20px;
  }
  .has-mobile-nav {
    margin-top: 20px;
    flex-wrap: wrap;
  }
  @include mobile {
    margin-top: 20px;
    flex-wrap: wrap;
  }
  @at-root .logged_in & {
    @media screen and (max-width: 1440px) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
  .race-card-badge {
    border-radius: 10px;
    max-width: 295px;
    max-height: 265px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      max-width: none;
      padding: 20px 0;
    }
    img {
      height: 206px;
      width: auto;
    }
  }
  .race-card-content {
    width: 100%;
    height: 100%;
    min-height: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 46px;
    margin-right: 46px;
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0;
    }
    .event-date-and-event-location-and-event-sponsor {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 66px;
      @include mobile {
        text-align: center;
        margin-bottom: 24px;
      }
      .event-location,
      .event-presented-by {
        text-transform: uppercase;
        @include mobile {
          font-size: 18px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .event-date {
        font-size: 36px;
        font-weight: 700;
        line-height: 42px;
        color: $color-secondary;
        @include mobile {
          font-size: 30px;
          line-height: 32px;
          margin-bottom: 10px;
        }
      }
      .event-location-and-event-sponsor {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: $color-gray-md;
        margin-bottom: 5px;
        @include mobile {
          margin-bottom: 0;
        }
        @include tablet {
          margin-bottom: 10px;
        }
      }
    }
    .event-stat-icons {
      display: flex;
      align-items: flex-start;
      .stat-group {
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        background-repeat: no-repeat;
        margin-right: 20px;
        @include mobile {
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
      .stat-group:last-of-type {
        margin-right: 0;
      }
      .stat-icon {
        display: inline-block;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        @include mobile {
          height: 32px;
          width: 32px;
          margin-right: 0;
        }
        @include touch-device {
          margin-bottom: 5px;
        }
      }
      .number-of-distances .stat-icon {
        background-image: url(../images/race-page-card-ui/map-icon.svg);
      }
      .avg-air-temps .stat-icon {
        background-image: url(../images/race-page-card-ui/air-temp.svg);
      }
      .airport .stat-icon {
        background-image: url(../images/race-page-card-ui/airport.svg);
      }
      .stat-content {
        display: inline-block;
      }
      .stat-title {
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        color: $color-gray-dk;
      }
      .stat {
        font-size: 21px;
        font-weight: 700;
        line-height: 21px;
        color: $color-secondary;
      }
    }
    .event-distance-crumbs {
      margin-top: 5px;
      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
      }
      @include tablet {
        margin-top: 10px;
      }
      .distance-crumb-title,
      .distance-crumb {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        color: $color-secondary;
      }
      .distance-crumb-title {
        @include mobile {
          margin-bottom: 15px;
        }
      }
      .distance-crumb {
        position: relative;
        color: $color-primary;
        margin-left: 8px;
        margin-right: 8px;
        @include mobile {
          padding-bottom: 14px;
        }
      }
      .distance-crumb-arrow {
        display: inline-block;
        height: 11px;
        width: 6px;
        background-image: url(../images/race-page-card-ui/distances-arrow-right.png);
        background-repeat: no-repeat;
        @include mobile {
          display: none;
        }
      }
    }
  }
  
  .race-card-register-link-and-language-toggle {
    text-align: center;
    width: 100%;
    max-width: 294px;
    border-left: 2px solid $color-off-white-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 25px;
    @include mobile {
      border-left: none;
      max-width: none;
      padding-top: 20px;
    }
    .register-link {
      display: inline-block;
      .pageElement {
        margin-bottom: 30px;
        margin-top: 0;
      }
    }
    .language-toggle {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      justify-content: center;
      list-style: none;
      cursor: pointer;
      @include touch-device {
        margin-left: 0;
        margin-right: 0;
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
      }
      @media only screen and (max-width: 1180px) {
        padding-top: 15px;
      }

      span {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 400;
        color: $color-gray-md;
        height: 45px;
        width: 165px;
        border: 1px solid $color-gray-dk;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
          content: "\f107";
          display: inline-block;
          font-family: "fontawesome";
          font-size: inherit;
          color: inherit;
          transition: transform $transition-standard;
          margin-left: 20px;
        }
      }

      ul {
        display: block;
        height: 0;
        opacity: 0;
        list-style: none;
        width: 0;
        max-height: 300px;
        max-width: 150px;
        //overflow: scroll;
        position: absolute;
        // top: calc(100% - 40px);
        top: 100%;
        left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: $color-off-white;
        border: 1px solid $color-gray;
        box-shadow: $box-shadow-active;
        z-index: -1;
        transition: height 0.6s ease-in, opacity $transition-standard,
          width 0.6s ease-in;
        @include touch-device {
          top: 100%;
          width: 100%;
          position: initial;
          padding-bottom: 0;
          padding-top: 0;
          text-align: center;
        }
        @media only screen and (max-width: 1180px) {
          top: 100%;
        }
      }

      &.active {
        span {
          &:after {
            transform: rotate(180deg);
          }
        }

        ul {
          display: block;
          opacity: 1;
          height: auto;
          width: 100%;
          min-width: 165px;
          z-index: 10;
          @include touch-device {
            padding-top: 20px;
            padding-bottom: 20px;
          }

          &:hover {
            cursor: pointer;
          }

          li {
            font-family: $font-primary;
            font-weight: 400;
            font-size: 14px;
            line-height: 2;
            color: $color-gray-md;
            transition: color $transition-standard;
            text-indent: 0;

            &:hover {
              color: $color-primary;
              cursor: pointer;
            }

            &.selected {
              color: $color-primary;
            }
          }
        }
      }
    }
  }
}
