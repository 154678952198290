/**
 * Quick Link SCSS Component
 * @description - Adds CSS class 'quick-link' to available user facing theme classes
 */
.quick-link {
  // setup default margin for page elements
  // set top margin on first element, first element recieves standard page element bottom margin
  &:first-of-type .pageElement {
    // copied from @mixin page-element-margin, next 4 lines should be kept in sync
    margin-top: $desktop-padding;
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-top: $mobile-padding;
    }
    // end
    margin-bottom: 1px;
  }
  // set middle element margins, stacked elements sit close together
  & + .quick-link .pageElement {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  // set bottom margin on last element, last element recieves standard page element bottom margin
  &:last-of-type .pageElement {
    // copied from @mixin page-element-margin, next 4 lines should be kept in sync
    margin-bottom: $desktop-padding;
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-bottom: $mobile-padding;
    }
    // end
  }
  // default wuick link styles to override the default ghost button styles
  .linkElement h4 a {
    position: relative;
    display: block;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $color-white;
    text-transform: uppercase;
    text-align: left;
    border: none;
    border-radius: unset;
    padding-top: 14px;
    padding-right: 15px;
    padding-bottom: 13px;
    padding-left: 15px;
    background-color: #0093c8;
    transition: background-color $transition-linear;
    // focus & hover states
    &:focus,
    &:hover {
      background-color: #007ba8;
    }
    // add arrow as psuedo element
    &:after {
      position: absolute;
      content: url(../images/quick-link-ui/quick-link-arrow-right.png);
      right: 15px;
      top: 14px;
    }
  }
}
