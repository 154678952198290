@charset "UTF-8";
/* Variables */
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.right-column-ad-wrapper, .pageEl, .row, .has-main-nav .theme-search-bar .theme-search-box, .custom-card-agg.pageEl .newsAggregatorElement .condensed, .collectorNews .item, .newsSlideShow-headline p, .layoutContainer .merge-into-container-above,
.layoutContainer .merge-into-container-below {
  *zoom: 1;
}

.right-column-ad-wrapper:before, .pageEl:before, .row:before, .has-main-nav .theme-search-bar .theme-search-box:before, .custom-card-agg.pageEl .newsAggregatorElement .condensed:before, .collectorNews .item:before, .newsSlideShow-headline p:before, .layoutContainer .merge-into-container-above:before,
.layoutContainer .merge-into-container-below:before, .right-column-ad-wrapper:after, .pageEl:after, .row:after, .has-main-nav .theme-search-bar .theme-search-box:after, .custom-card-agg.pageEl .newsAggregatorElement .condensed:after, .collectorNews .item:after, .newsSlideShow-headline p:after, .layoutContainer .merge-into-container-above:after,
.layoutContainer .merge-into-container-below:after {
  content: " ";
  display: table;
}

.right-column-ad-wrapper:after, .pageEl:after, .row:after, .has-main-nav .theme-search-bar .theme-search-box:after, .custom-card-agg.pageEl .newsAggregatorElement .condensed:after, .collectorNews .item:after, .newsSlideShow-headline p:after, .layoutContainer .merge-into-container-above:after,
.layoutContainer .merge-into-container-below:after {
  clear: both;
}

@-webkit-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #333;
}

body {
  font-size: 100%;
  text-rendering: optimizeLegibility;
}

p {
  color: #192D1A;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 28px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 1.125;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 18px;
}

ul {
  list-style-type: disc;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

small {
  font-size: .75em;
}

mark {
  background-color: yellow;
}

s, strike, del {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

code {
  padding: 2px 4px;
  font-size: .75em;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

blockquote {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  font-style: italic;
  color: #333;
  box-shadow: inset 10px 0px #EDF0F1;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

blockquote p {
  display: inline;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

pre {
  display: block;
  padding: .5em 1em;
  margin: 0 0 1rem 0;
  font-size: .75em;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  background-color: #f5f5f5;
  border: 1px solid #EDF0F1;
  border-radius: 4px;
}

hr {
  background-color: #EDF0F1;
  height: 2px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
}

a {
  color: #CF3338;
  text-decoration: none;
}

table {
  font-size: 12px;
}

.dataTable,
.statTable,
.stat_table {
  border-spacing: 0;
  border-collapse: initial;
  margin-top: 1px;
  width: 100%;
}

.dataTable td,
.statTable td,
.stat_table td {
  background-clip: padding-box !important;
  border-style: solid;
  border-color: #F7F7F7;
  border-width: 0 1px 1px 0;
}

.dataTable td:first-child,
.statTable td:first-child,
.stat_table td:first-child {
  border-left-width: 1px;
}

.dataTable td:last-child,
.statTable td:last-child,
.stat_table td:last-child {
  border-right-width: 1px;
}

.dataTable tr:first-child td,
.statTable tr:first-child td,
.stat_table tr:first-child td {
  border-top-width: 1px;
}

.dataTable tr:last-child td,
.statTable tr:last-child td,
.stat_table tr:last-child td {
  border-bottom-width: 1px;
}

.dataTable tr:nth-child(odd) td,
.statTable tr:nth-child(odd) td,
.stat_table tr:nth-child(odd) td {
  background-color: white;
}

.dataTable tr:nth-child(odd) td.highlight,
.statTable tr:nth-child(odd) td.highlight,
.stat_table tr:nth-child(odd) td.highlight {
  background-color: #f7f7f7;
}

.dataTable tr:nth-child(even) td,
.statTable tr:nth-child(even) td,
.stat_table tr:nth-child(even) td {
  background-color: #F7F7F7;
}

.dataTable tr:nth-child(even) td.highlight,
.statTable tr:nth-child(even) td.highlight,
.stat_table tr:nth-child(even) td.highlight {
  background-color: #efefef;
}

.dataTable th,
.statTable th,
.stat_table th {
  height: 50px;
  padding: 3px 12px;
  background-color: #CF3338;
  white-space: nowrap;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: white;
}

.dataTable th[class*="sorttable"],
.statTable th[class*="sorttable"],
.stat_table th[class*="sorttable"] {
  background-color: #bc2c31;
}

.dataTable td,
.statTable td,
.stat_table td {
  height: 40px;
  padding: 2px 12px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
}

.dataTable th.ajax_sort {
  padding-right: 30px;
  position: relative;
  cursor: pointer;
  transition: color 0.2s linear;
}

.dataTable th.ajax_sort:hover {
  color: rgba(255, 255, 255, 0.7);
}

.dataTable th.ajax_sort:after {
  content: "";
  min-width: 30px;
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataTable th.ajax_sort.ASC:after, .dataTable th.ajax_sort.sorttable_sorted:after {
  content: "";
}

.dataTable th.ajax_sort.DESC:after, .dataTable th.ajax_sort.sorttable_sorted_reverse:after {
  content: "";
}

.dataTable th.ajax_sort .sorted_img {
  display: none;
}

/* Custom Theme Styles */
html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

html, body {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.global-component-edit-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.global-component-title {
  color: #444;
  background-color: #F2F3F5;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  display: flex;
  align-items: center;
  opacity: 0;
  padding: 3px;
  border: 1px solid #DEE4E4;
  border-right: 0;
  transition: opacity 0.2s linear;
}

.global-component-edit-link:hover .global-component-title {
  opacity: 1;
}

.global-component-icon {
  background-color: #F2F3F5;
  border: 1px solid #DEE4E4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.global-component-icon:before {
  content: "";
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2022.0278%2020.4102%22%3E%3Cg%20fill%3D%22%23808F98%22%3E%3Cpath%20d%3D%22M21.528%203.657h-4.775V.5c0-.276-.224-.5-.5-.5H.5C.224%200%200%20.224%200%20.5v15.753c0%20.276.224.5.5.5h4.774v3.157c0%20.276.224.5.5.5h15.754c.276%200%20.5-.224.5-.5V4.157c0-.276-.224-.5-.5-.5zM1%2015.753V1h14.753v2.657H3.523c-.275%200-.5.224-.5.5s.225.5.5.5h1.75v1.25h-1.75c-.275%200-.5.224-.5.5s.225.5.5.5h1.75v1.25h-1.75c-.275%200-.5.225-.5.5s.225.5.5.5h1.75v1.25h-1.75c-.275%200-.5.225-.5.5s.225.5.5.5h1.75v1.252h-1.75c-.275%200-.5.223-.5.5s.225.5.5.5h1.75v2.093H1zm20.028%203.657H6.274v-4.26l3.69-2.5%203.38%202.63c.09.07.198.106.307.106.115%200%20.228-.04.32-.115l7.058-5.864V19.41zm0-11.304l-7.387%206.138-3.35-2.604c-.17-.134-.406-.142-.586-.02l-3.43%202.323V4.657h14.754v3.45z%22%2F%3E%3Cpath%20d%3D%22M13.65%2011.604c1.307%200%202.37-1.062%202.37-2.37s-1.063-2.368-2.37-2.368c-1.305%200-2.368%201.063-2.368%202.37s1.063%202.368%202.37%202.368zm0-3.738c.756%200%201.37.615%201.37%201.37s-.614%201.368-1.37%201.368c-.754%200-1.368-.614-1.368-1.37s.614-1.368%201.37-1.368z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../images/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../images/fonts/slick.eot");
  src: url("../images/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../images/fonts/slick.woff") format("woff"), url("../images/fonts/slick.ttf") format("truetype"), url("../images/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity 0.2s linear;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  opacity: 1;
}

.slick-prev {
  left: 0;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 0;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.6;
}

/* Slider */
.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.ad-wrapper {
  overflow: hidden;
  text-align: center;
  clear: both;
}

*:not(.pageElement) > .ad-wrapper {
  margin: 0 15px;
  padding: 15px 0;
}

.ad-wrapper img {
  display: block;
  margin: auto;
}

.ad-wrapper > * {
  transform-origin: 0 0;
}

#contentTabsLoader:not([style*="display:none"]):not([style*="display: none"]) ~ .right-column-ad-wrapper .right-column-ads {
  display: none;
}

.right-column-ad-content {
  width: 66.666%;
  float: left;
}

@media only screen and (max-width: 800px) {
  .right-column-ad-content {
    width: 100%;
  }
}

.right-column-ads {
  width: 33.333%;
  float: right;
}

@media only screen and (max-width: 800px) {
  .right-column-ads {
    width: 100%;
  }
}

@media only screen and (min-width: 801px) {
  .right-column-ads .ad-wrapper {
    margin-right: 0;
    padding-bottom: 0;
  }
}

.sn-preview {
  display: block;
  font-size: 10px;
  font-weight: 400;
  color: #84929c;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #D5DADD;
  line-height: 1.4;
}

.sn-preview:hover {
  color: #84929c;
  text-decoration: none;
}

.sn-preview.preview-on {
  background-color: #E5E7E8;
}

.sn-preview.preview-on:before, .sn-preview.preview-off:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.sn-preview.preview-off:before {
  background-image: url(../images/eye-off.svg);
}

.sn-preview.preview-on:before {
  background-image: url(../images/eye-on.svg);
}

#PageSearchBox {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  position: relative;
  display: block;
  overflow: hidden;
}

#PageSearchBox .theme-search-input,
#PageSearchBox .theme-search-submit {
  float: left;
  height: 30px;
  line-height: 30px;
  transition: background 0.3s ease-in-out;
}

#PageSearchBox .theme-search-input,
#PageSearchBox .theme-search-close {
  font-size: 16px;
}

#PageSearchBox .theme-search-input {
  width: calc(100% - 65px);
  background: #FFF;
  border: 0 solid transparent;
  border-right: none;
  padding: 0 30px 0 10px;
}

#PageSearchBox .theme-search-input::-ms-clear {
  display: none;
}

#PageSearchBox .theme-search-input:focus {
  outline: none;
  background: #FFF;
}

#PageSearchBox .theme-search-submit {
  text-align: center;
  width: 65px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  border: 0 solid transparent;
  padding: 0 10px;
  background: #CECECE;
}

#PageSearchBox .theme-search-submit:focus, #PageSearchBox .theme-search-submit:hover {
  background: #CECECE;
}

#PageSearchBox .theme-search-clear {
  position: absolute;
  text-decoration: none;
  text-align: center;
  top: 10px;
  right: 75px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

#PageSearchBox .theme-search-clear:before {
  content: "✕";
  color: #9ca8af;
  font-family: FontAwesome;
  font-size: 16px;
}

.theme-search-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.theme-search-modal {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 50%;
  margin-left: -300px;
  width: 600px;
  background: #fff;
  border: 1px solid #d4d9dc;
  border-radius: 4px;
}

@media only screen and (max-width: 650px) {
  .theme-search-modal {
    width: 100%;
    margin-left: 0;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    border: none;
  }
}

.theme-search-modal .theme-search-title {
  display: block;
  font-size: 22px;
  line-height: 22px;
  color: #23282b;
  padding: 24px;
  padding-right: 68px;
}

.theme-search-modal .theme-search-close {
  position: absolute;
  text-decoration: none;
  text-align: center;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
}

.theme-search-modal .theme-search-close:before {
  content: "✕";
  color: #9ca8af;
  font-family: FontAwesome;
  font-size: 14px;
}

.theme-search-modal .theme-search-box {
  position: relative;
  line-height: 44px;
  padding: 0 24px;
  background: #000000;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  height: 44px;
  font-size: 12px;
}

.theme-search-modal .theme-search-input {
  color: #666;
  vertical-align: middle;
  font-size: 16px;
  line-height: 30px;
  padding: 0 30px 0 8px;
  border: 0 solid transparent;
  background: #fff;
  margin-top: -1px;
  width: 100%;
}

.theme-search-modal .theme-search-input::-ms-clear {
  display: none;
}

.theme-search-modal .theme-search-input:focus {
  outline: none;
}

.theme-search-modal .theme-search-clear {
  position: absolute;
  top: 7px;
  right: 24px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  color: #000000;
}

.theme-search-modal .theme-search-clear:before {
  content: "✕";
  color: #9ca8af;
  font-family: FontAwesome;
  font-size: 14px;
}

.theme-search-modal .theme-search-status {
  color: #b8c0c5;
  display: block;
  font-size: 12px;
  text-align: center;
  margin-top: -12px;
  margin-bottom: 12px;
}

.theme-search-modal .theme-search-results {
  position: absolute;
  top: 114px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  overflow: auto;
  list-style: none;
  border-radius: 0 0 4px 4px;
}

.theme-search-modal .theme-search-result {
  cursor: pointer;
  display: table;
  line-height: 1.2;
  width: 100%;
  border-top: 1px solid #d4d9dc;
}

.theme-search-modal .theme-search-result:last-child {
  border-bottom: 1px solid #d4d9dc;
}

.theme-search-modal .theme-search-result-info {
  text-decoration: none !important;
  display: table-cell;
  vertical-align: middle;
  padding: 6px;
  height: 44px;
}

.theme-search-modal .theme-search-result-info:hover {
  background: #f0f1f2;
}

.theme-search-modal .theme-search-result-primary {
  display: block;
}

.theme-search-modal .theme-search-result-secondary {
  display: block;
  font-size: 70%;
  color: #b8c0c5;
}

.theme-nav-style-slide > * {
  width: 100%;
}

html.collapsed-mobile-nav .theme-nav-style-slide {
  font-size: 16px;
  left: auto;
  right: 100%;
  width: 100%;
  background: #ffffff;
  transition: right 200ms ease-in-out;
}

@media (min-width: 769px) {
  html.collapsed-mobile-nav .theme-nav-style-slide {
    width: 400px;
  }
  html.collapsed-mobile-nav.slide-nav-open .theme-nav-style-slide {
    right: calc(100vw - 400px) !important;
  }
  html.collapsed-mobile-nav.slide-nav-open.page-manager-visible .theme-nav-style-slide {
    right: calc(100vw - 400px - 60px) !important;
  }
}

html.collapsed-mobile-nav .theme-slide-nav-overlay {
  display: none;
  position: absolute;
  top: 0;
  width: 9999px;
  background: #FFF;
  height: 100%;
  left: 100%;
  opacity: 0;
  transition: opacity 0.2s linear;
}

html.collapsed-mobile-nav .theme-slide-nav-toggle {
  display: block;
}

html.collapsed-mobile-nav.slide-nav-open {
  overflow: hidden;
}

html.collapsed-mobile-nav.slide-nav-open *:not(.theme-slide-nav-content) + .theme-nav-title .theme-nav-title-text {
  padding-right: 60px;
}

html.collapsed-mobile-nav.slide-nav-open .theme-nav-style-slide {
  right: 0;
}

html.collapsed-mobile-nav.slide-nav-open .theme-slide-nav-overlay {
  display: block;
  opacity: .5;
}

html.collapsed-mobile-nav.slide-nav-open .theme-search-box:first-child, html.collapsed-mobile-nav.slide-nav-open .theme-search-box:first-child ~ .theme-search-box {
  padding-right: 60px;
}

html.collapsed-mobile-nav:not(.slide-nav-open) .theme-nav-style-slide {
  position: absolute;
}

html.collapsed-mobile-nav:not(.slide-nav-open) .theme-slide-nav-toggle {
  display: block;
  background: transparent;
  color: #ffffff;
}

.theme-nav-style-slide {
  position: fixed;
  width: 250px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100vh;
  border-collapse: collapse;
  top: 0;
  left: 0;
  background: #ffffff;
}

.theme-nav-style-slide > * {
  width: 100%;
}

.page-manager-visible .theme-nav-style-slide {
  left: 60px;
}

.theme-nav-style-slide a:hover {
  text-decoration: none;
}

.theme-nav-style-slide .theme-slide-nav-content {
  box-sizing: border-box;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background: #000000;
}

.theme-nav-style-slide .theme-search-box {
  display: block;
  overflow: hidden;
  padding: 10px;
}

.theme-nav-style-slide .theme-search-box:not(:first-child) {
  border-top: 1px solid #EDF0F1;
}

.theme-nav-style-slide .theme-search-input,
.theme-nav-style-slide .theme-search-submit,
.theme-nav-style-slide .theme-search-clear {
  float: left;
  height: 30px;
  transition: background 0.2s linear;
}

.theme-nav-style-slide .theme-search-input,
.theme-nav-style-slide .theme-search-clear {
  font-size: 16px;
}

.theme-nav-style-slide .theme-search-input {
  width: calc(100% - 70px);
  line-height: 28px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
  padding: 0 28px 0 10px;
}

.theme-nav-style-slide .theme-search-input::-ms-clear {
  display: none;
}

.theme-nav-style-slide .theme-search-input:focus {
  outline: none;
  background: white;
}

.theme-nav-style-slide .theme-search-submit {
  cursor: pointer;
  width: 70px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 10px;
  background: black;
}

.theme-nav-style-slide .theme-search-submit:focus, .theme-nav-style-slide .theme-search-submit:hover {
  background: black;
}

.theme-nav-style-slide .theme-search-clear {
  float: left;
  text-decoration: none;
  text-align: center;
  margin-left: -31px;
  margin-right: 1px;
  width: 30px;
  line-height: 30px;
}

.theme-nav-style-slide .theme-search-clear:before {
  content: "✕";
  color: #9ca8af;
  font-family: FontAwesome;
  font-size: 16px;
}

.theme-nav-style-slide .theme-slide-nav-toggle {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  font-size: 20px;
  line-height: 75px;
  height: 75px;
  width: 60px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  color: #ffffff;
}

.theme-nav-style-slide .theme-slide-nav-toggle:before {
  font-family: "FontAwesome";
  content: "\f0c9";
}

.slide-nav-open .theme-nav-style-slide .theme-slide-nav-toggle:before {
  content: "\f00d";
}

.theme-nav-style-slide .theme-slide-nav-toggle:hover {
  color: #ffffff;
}

.theme-nav-style-slide .theme-nav-title {
  box-sizing: border-box;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-transform: none;
  display: table;
  table-layout: fixed;
  text-align: left;
  background: #EEEEEE;
  overflow: hidden;
  position: relative;
}

.theme-nav-style-slide .theme-nav-title .mobile-nav-logo {
  position: absolute;
  top: 15px;
  left: 25px;
}

.theme-nav-style-slide .theme-nav-title .mobile-nav-logo:hover {
  cursor: pointer;
}

.theme-nav-style-slide .theme-nav-title .mobile-nav-logo img {
  visibility: hidden;
  width: 160px;
  height: auto;
}

.theme-nav-style-slide .theme-nav-title:before {
  content: '';
  height: 75px;
  background: #000000;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  display: block;
}

.theme-nav-style-slide .theme-nav-title-text {
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: middle;
  color: #2F373B;
  font-weight: 400;
  text-align: left;
  padding: 15px 10px 15px 30px;
  line-height: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.theme-nav-style-slide .theme-nav-back,
.theme-nav-style-slide .theme-nav-forward {
  font-family: FontAwesome;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.theme-nav-style-slide .theme-nav-back {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #CF3338;
  border-right: 1px solid #C3CACE;
  background: #EEEEEE;
  width: 60px;
  height: 60px;
}

.theme-nav-style-slide .theme-nav-back:before {
  content: "";
}

.theme-nav-style-slide .theme-nav-back:hover {
  background: #CF3338;
  color: #ffffff;
}

.theme-nav-style-slide .theme-nav-forward {
  color: #CF3338;
  border-left: 1px solid #EDF0F1;
  background: transparent;
  width: 60px;
}

.theme-nav-style-slide .theme-nav-forward:before {
  content: "";
}

.theme-nav-style-slide .theme-nav-forward:hover {
  background: #CF3338;
  color: #ffffff;
}

.theme-nav-style-slide .disabled > .theme-nav-forward:hover {
  background: #bfbfbf;
}

.theme-nav-style-slide .theme-nav-menus {
  box-sizing: border-box;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.theme-nav-style-slide .theme-nav-menu {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  padding: 0;
  list-style-type: none;
  background: transparent;
  transition: all 200ms ease-in-out;
  padding-bottom: 180px;
}

.theme-nav-style-slide .theme-nav-parent-in,
.theme-nav-style-slide .theme-nav-parent-out {
  left: -10%;
  opacity: 0;
}

.theme-nav-style-slide .theme-nav-children-in,
.theme-nav-style-slide .theme-nav-children-out {
  left: 100%;
}

.theme-nav-style-slide .theme-nav-parent-out,
.theme-nav-style-slide .theme-nav-children-out {
  max-height: 100%;
  overflow: hidden;
}

.theme-nav-style-slide .theme-nav-item {
  width: 100%;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #EDF0F1;
  border-top: none;
  padding: 0;
  height: 60px;
}

.user_mode .theme-nav-style-slide .theme-nav-item.home {
  display: none;
}

.theme-nav-style-slide .theme-nav-item.paywalled img {
  display: inline-block;
  padding-right: 5px;
  max-width: 40px;
  max-height: 20px;
}

.theme-nav-style-slide .theme-nav-item.selected > .theme-nav-link {
  color: #ffffff;
  background: #CF3338;
}

.theme-nav-style-slide .theme-nav-item > .theme-nav-link:hover {
  color: #ffffff;
  background: #CF3338;
}

.theme-nav-style-slide .theme-nav-item.disabled > * {
  color: #999;
  background: transparent;
}

.theme-nav-style-slide .theme-nav-item.disabled.selected > .theme-nav-link {
  color: #ffffff;
  background: #353E43;
}

.theme-nav-style-slide .theme-nav-item.disabled > *:hover {
  color: #ffffff;
  background: #353E43;
}

.theme-nav-style-slide .theme-nav-link {
  cursor: pointer;
  display: table-cell;
  text-align: left;
  padding: 15px 10px 15px 30px;
  line-height: 2;
  color: #68767F;
  text-transform: uppercase;
  transition: all 0.2s linear;
}

.theme-nav-style-slide .theme-nav-link:hover {
  background: #CF3338;
}

.gsc-control-cse,
.gsc-search-box {
  line-height: initial;
}

.gsc-control-cse, .gsc-control-cse *,
.gsc-search-box,
.gsc-search-box * {
  word-wrap: normal;
  box-sizing: initial;
}

.theme-search-modal .gsc-search-box {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  background: none;
}

.theme-search-modal .gsc-clear-button {
  display: none;
}

.text > :last-child,
.previewText > :last-child,
.newsText > :last-child {
  margin-bottom: 0;
}

.text > ul, .text > ol,
.previewText > ul,
.previewText > ol,
.newsText > ul,
.newsText > ol {
  margin-bottom: 1em;
}

.text ul,
.text ol,
.previewText ul,
.previewText ol,
.newsText ul,
.newsText ol {
  list-style-position: inside;
}

.text ul ul,
.text ul ol,
.text ol ul,
.text ol ol,
.previewText ul ul,
.previewText ul ol,
.previewText ol ul,
.previewText ol ol,
.newsText ul ul,
.newsText ul ol,
.newsText ol ul,
.newsText ol ol {
  margin-left: 1em;
}

.text h1, .text h2, .text h3, .text h4, .text h5, .text h6,
.previewText h1,
.previewText h2,
.previewText h3,
.previewText h4,
.previewText h5,
.previewText h6,
.newsText h1,
.newsText h2,
.newsText h3,
.newsText h4,
.newsText h5,
.newsText h6 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
}

.text h1,
.previewText h1,
.newsText h1 {
  font-size: 48px;
  margin-bottom: 20px;
  line-height: 1.125;
  font-weight: 700;
  color: #000000;
}

.text h2,
.previewText h2,
.newsText h2 {
  font-size: 42px;
  margin-bottom: 20px;
  line-height: 1.286;
  font-weight: 700;
  color: #000000;
}

.text h3,
.previewText h3,
.newsText h3 {
  font-size: 36px;
  margin-bottom: 15px;
  line-height: 1;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .text h3,
  .previewText h3,
  .newsText h3 {
    font-size: 22px;
    line-height: 1.59;
  }
}

.text h4,
.previewText h4,
.newsText h4 {
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 1.33;
  font-weight: 700;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .text h4,
  .previewText h4,
  .newsText h4 {
    font-size: 18px;
    line-height: 1.6;
  }
}

.text h5,
.previewText h5,
.newsText h5 {
  font-size: 24px;
  margin-bottom: 18px;
  line-height: 1.27;
  font-weight: 700;
  color: #000000;
}

.text h6,
.previewText h6,
.newsText h6 {
  font-size: 18px;
  margin-bottom: 16px;
  line-height: 1.4;
  font-weight: 700;
  color: #000000;
  letter-spacing: 7px;
}

.text a,
.previewText a,
.newsText a {
  color: #CF3338;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.2s linear;
}

.text a:hover,
.previewText a:hover,
.newsText a:hover {
  color: #FF595A;
}

.text blockquote,
.previewText blockquote,
.newsText blockquote {
  font-weight: 600;
  font-size: 20px;
  color: #999999;
}

@media only screen and (min-width: 768px) {
  .text blockquote,
  .previewText blockquote,
  .newsText blockquote {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1440px) {
  .text blockquote,
  .previewText blockquote,
  .newsText blockquote {
    font-size: 24px;
  }
}

.toolIcon {
  background-repeat: no-repeat;
  padding: 5px 0 15px 40px;
}

p.tags {
  font-size: .7em;
}

.subNav li a,
.relatedSections li a {
  font-size: .75em;
}

.ellipsis {
  /* Needs a fixed width to work */
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageElement {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .pageElement {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.pageEl > .pageElement {
  float: left;
  clear: both;
}

.user_mode .pageEl.hide {
  display: none;
}

.pageElement .pageElement {
  margin-left: 0;
  margin-right: 0;
}

.layoutContainer {
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .layoutContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.layoutContainer .layoutContainer {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
}

.user_mode .layoutContainer .layoutContainer {
  margin-left: -10px;
  margin-right: -10px;
  width: auto;
}

@media screen and (max-width: 767px) {
  .user_mode .layoutContainer .layoutContainer {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.full-width-container-constrained-content {
  max-width: 100%;
  padding-left: calc((100% - 1340px) / 2);
  padding-right: calc((100% - 1340px) / 2);
  position: relative;
}

.full-width-container {
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.full-width {
  max-width: 100%;
}

.full-width > .column {
  max-width: 100%;
}

.full-width.collapse-padding {
  padding-left: 0;
  padding-right: 0;
}

.full-width.collapse-padding > .column {
  padding-left: 0;
  padding-right: 0;
}

.column {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .column {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .column {
    width: 100%;
  }
}

.edit_mode .column {
  padding-left: 0;
  padding-right: 0;
}

.row > .column {
  float: left;
}

@media screen and (max-width: 767px) {
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66667%;
  }
  .col-xs-10 {
    width: 83.33333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66667%;
  }
  .col-xs-7 {
    width: 58.33333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66667%;
  }
  .col-xs-4 {
    width: 33.33333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66667%;
  }
  .col-xs-1 {
    width: 8.33333%;
  }
}

@media screen and (min-width: 768px) {
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66667%;
  }
  .col-md-10 {
    width: 83.33333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-7 {
    width: 58.33333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66667%;
  }
  .col-md-4 {
    width: 33.33333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66667%;
  }
  .col-md-1 {
    width: 8.33333%;
  }
  .full-width-container .col-md-12 {
    max-width: 1320px;
  }
  .full-width-container .col-md-11 {
    max-width: 1210px;
  }
  .full-width-container .col-md-10 {
    max-width: 1100px;
  }
  .full-width-container .col-md-9 {
    max-width: 990px;
  }
  .full-width-container .col-md-8 {
    max-width: 880px;
  }
  .full-width-container .col-md-7 {
    max-width: 770px;
  }
  .full-width-container .col-md-6 {
    max-width: 660px;
  }
  .full-width-container .col-md-5 {
    max-width: 550px;
  }
  .full-width-container .col-md-4 {
    max-width: 440px;
  }
  .full-width-container .col-md-3 {
    max-width: 330px;
  }
  .full-width-container .col-md-2 {
    max-width: 220px;
  }
  .full-width-container .col-md-1 {
    max-width: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .layout-50-25-25 .col-md-3,
  .layout-25-25-50 .col-md-3 {
    width: 50%;
    max-width: 660px;
  }
  .layout-50-25-25 .col-md-6,
  .layout-25-25-50 .col-md-6 {
    width: 100%;
    max-width: 1320px;
  }
  .layout-25-50-25 .col-md-3,
  .layout-25-50-25 .col-md-6 {
    width: 33.33333%;
    max-width: 440px;
  }
}

.edit_mode .yieldPageContent {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

#displayBodyHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  #displayBodyHeader {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  #displayBodyHeader {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  #displayBodyHeader {
    padding-top: 40px;
    padding-bottom: 0px;
  }
}

.newsItemColumn {
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .newsItemColumn {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.newsContentNode + .column {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .newsContentNode + .column {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.yieldPageContent > .noEntry.pageElement {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .yieldPageContent > .noEntry.pageElement {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.calendarContainer {
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .calendarContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#event_show,
#event_show + .eventTags,
#event_show + .eventTags + .bottomOptions {
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  #event_show,
  #event_show + .eventTags,
  #event_show + .eventTags + .bottomOptions {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#sport-header {
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  #sport-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.user_mode .leaguePage .yieldPageContent > div,
.user_mode .leaguePage .yieldPageContent > ul,
.user_mode .leaguePage .yieldPageContent ~ #rapidTabs,
.user_mode .leaguePage .yieldPageContent ~ #contentTabsLoader,
.user_mode .leaguePage .yieldPageContent ~ #tab_completegamelist_content,
.user_mode .leaguePage .yieldPageContent ~ #tab_boxscores_content,
.user_mode .sportsPage .yieldPageContent > div,
.user_mode .sportsPage .yieldPageContent > ul,
.user_mode .sportsPage .yieldPageContent ~ #rapidTabs,
.user_mode .sportsPage .yieldPageContent ~ #contentTabsLoader,
.user_mode .sportsPage .yieldPageContent ~ #tab_completegamelist_content,
.user_mode .sportsPage .yieldPageContent ~ #tab_boxscores_content,
.user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent > div,
.user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent > ul,
.user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #rapidTabs,
.user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #contentTabsLoader,
.user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #tab_completegamelist_content,
.user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #tab_boxscores_content {
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .user_mode .leaguePage .yieldPageContent > div,
  .user_mode .leaguePage .yieldPageContent > ul,
  .user_mode .leaguePage .yieldPageContent ~ #rapidTabs,
  .user_mode .leaguePage .yieldPageContent ~ #contentTabsLoader,
  .user_mode .leaguePage .yieldPageContent ~ #tab_completegamelist_content,
  .user_mode .leaguePage .yieldPageContent ~ #tab_boxscores_content,
  .user_mode .sportsPage .yieldPageContent > div,
  .user_mode .sportsPage .yieldPageContent > ul,
  .user_mode .sportsPage .yieldPageContent ~ #rapidTabs,
  .user_mode .sportsPage .yieldPageContent ~ #contentTabsLoader,
  .user_mode .sportsPage .yieldPageContent ~ #tab_completegamelist_content,
  .user_mode .sportsPage .yieldPageContent ~ #tab_boxscores_content,
  .user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent > div,
  .user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent > ul,
  .user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #rapidTabs,
  .user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #contentTabsLoader,
  .user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #tab_completegamelist_content,
  .user_mode [id="sport-header"] ~ [id="panelTwo"] .yieldPageContent ~ #tab_boxscores_content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#errorNotice {
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  #errorNotice {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.user_mode .helper-text {
  display: none;
}

.user_mode .layoutContainer.row.hide {
  display: none !important;
}

.page-manager-visible body {
  padding-left: 60px;
}

.page-manager-visible .sn-pageManager {
  display: block;
}

.sn-pageManager {
  display: none;
  position: fixed;
  width: 60px;
  height: 100%;
  left: 0;
  background: #f1f3f4;
  border-right: 1px solid #d5dadd;
  top: 0px;
  z-index: 499;
  font-family: 'Open Sans', sans-serif;
}

.sn-pageManager .adminToggle,
.sn-pageManager #adminNav,
.sn-pageManager .sn-sidebarItem {
  border: 0px solid #d5dadd;
  border-width: 0px 0px 1px 0px;
}

.sn-pageManager .adminToggle {
  width: 100%;
  display: block;
  position: relative;
  text-indent: -99999px;
}

.sn-pageManager .adminToggle,
.sn-pageManager #adminNav,
.sn-pageManager .sn-sidebarItem {
  height: 70px;
}

.sn-pageManager .sn-sidebarLink {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color 0.2s linear;
}

.sn-pageManager .active > .sn-sidebarLink {
  background-color: #e5e7e8;
}

.sn-pageManager .goToAdmin {
  background: none;
  background: url("../images/admin_gear.svg") center 35% no-repeat;
}

.sn-pageManager #adminNav img {
  display: none;
}

.sn-pageManager #adminNav {
  background: url("../images/switch_off.svg") center 35% no-repeat;
}

.sn-pageManager .sn-edit-mode-disabled {
  background: url("https://app-assets1.sportngin.com/app_images/pageManager/switch_off_disabled.png") center 35% no-repeat;
  position: relative;
}

.sn-pageManager .sn-edit-mode-disabled .sn-tooltip {
  position: relative;
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  background: #e03740;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
  left: 57px;
  padding: 10px;
  text-align: center;
  margin-top: 6px;
}

.sn-pageManager .sn-edit-mode-disabled .sn-tooltip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid black;
  border-color: #e03740 transparent transparent #e03740;
  transform: rotate(-45deg);
  box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.3);
  left: -4px;
  z-index: 0;
  top: 23px;
}

.sn-pageManager .sn-togglePages {
  background: url("../images/pages_icon.svg") center center no-repeat;
}

.sn-pageManager #adminNav a {
  display: block;
  overflow: hidden;
  height: 60px;
}

.sn-pageManager .adminToggle:before,
.sn-pageManager #adminNav > a:before,
.sn-pageManager .sn-sidebarItem > a:before {
  content: '';
  text-align: center;
  width: 100%;
  font-size: 10px;
  line-height: initial;
  font-weight: 400;
  color: #84929c;
  text-indent: 0;
  display: block;
  position: absolute;
  margin-top: 43px;
}

.sn-pageManager .adminToggle:before {
  content: 'Admin';
}

.sn-pageManager #adminNav > a:before {
  content: 'Edit Mode';
}

.sn-pageManager .sn-edit-mode-disabled {
  opacity: .5;
}

.sn-pageManager .sn-edit-mode-disabled > a:before {
  content: 'Edit Mode';
}

.sn-pageManager .sn-structure.sn-sidebarItem > a:before {
  content: 'Pages';
}

.sn-pageManager .sn-tabWrapper {
  opacity: 0;
  top: 0px;
  left: -200px;
  position: absolute;
  transition: all 0.2s linear;
  width: 200px;
  height: 100%;
  background: #f9fafb;
  z-index: -1;
  box-shadow: 5px 0px 10px -5px rgba(50, 50, 50, 0.5);
  overflow: auto;
}

.sn-pageManager .active .sn-tabWrapper {
  opacity: 1;
  left: 100%;
  margin: 0 0 0 1px;
}

.sn-pageManager .sn-tabContainer {
  display: none;
  border-top: 1px solid #dedede;
}

.sn-pageManager .sn-nav > li.sn-tab,
.sn-pageManager .sn-nav-tabs > li.sn-tab,
.sn-pageManager .sn-tab {
  position: absolute;
  width: 50%;
  z-index: 1;
}

.sn-pageManager .sn-nav > li.sn-tab.active,
.sn-pageManager .sn-nav-tabs > li.sn-tab.active,
.sn-pageManager .sn-tab.active {
  width: 100%;
  z-index: 0;
}

.sn-pageManager .sn-title {
  width: 100%;
  border-bottom: 5px solid transparent;
  font-size: 14px;
  font-weight: 300;
  color: #394146;
  padding-top: 10px;
  text-align: center;
}

.sn-pageManager .sn-tab.active .sn-title {
  width: 50%;
  border-bottom: 5px solid #1997c8;
}

.sn-pageManager .sn-tab.two .sn-title {
  margin-left: 100%;
}

.sn-pageManager .sn-tab.two.active .sn-title {
  margin-left: 50%;
}

.sn-pageManager .sn-subtitle {
  text-transform: uppercase;
  font-size: 12px;
  padding-top: 20px;
}

.sn-pageManager .sn-subtitle,
.sn-pageManager .sn-subtitle span {
  display: block;
  text-align: center;
  font-size: 9px;
  color: #808f98;
  font-weight: 600;
}

.sn-pageManager .sn-subtitle span {
  font-size: 16px;
  text-transform: capitalize;
}

.sn-pageManager .sn-pages {
  padding: 10px 20px 50px 20px;
}

.sn-pageManager .sn-pages ul {
  padding: 0;
  list-style-type: none;
  position: relative;
}

.sn-pageManager .sn-pages li {
  background-color: white;
  background-repeat: no-repeat;
  border: 1px solid #b9c1c6;
  padding: 0 0 0 14px;
  border-radius: 2px;
  margin: 2px 0;
  font-weight: 300;
  font-size: 12px;
  width: 100% !important;
}

.sn-pageManager .sn-pages li a {
  color: #394146;
  cursor: move;
}

.sn-pageManager .sn-pages li a:hover {
  text-decoration: none;
}

.sn-pageManager .sn-pages li p {
  color: #394146;
  min-height: 24px;
  line-height: 1.5;
  padding-top: 3px;
  margin: 0;
}

.sn-pageManager .sn-pages .private {
  padding-right: 23px;
}

.sn-pageManager .sn-pages .private:after {
  right: 4px;
  width: 15px;
  background-image: url("https://app-assets1.sportngin.com/app_images/pageManager/private.png");
}

.sn-pageManager .sn-pages .disabled {
  padding-right: 23px;
}

.sn-pageManager .sn-pages .disabled:after {
  right: 4px;
  width: 15px;
  background-image: url("https://app-assets1.sportngin.com/app_images/pageManager/disabled.png");
}

.sn-pageManager .sn-pages .private,
.sn-pageManager .sn-pages .disabled,
.sn-pageManager .sn-pages .paywalled {
  position: relative;
}

.sn-pageManager .sn-pages .private:after,
.sn-pageManager .sn-pages .disabled:after,
.sn-pageManager .sn-pages .paywalled:after {
  content: '';
  position: absolute;
  height: 12px;
  top: 50%;
  margin-top: -6px;
  display: inline-block;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.sn-pageManager .sn-pages .disabled a,
.sn-pageManager .sn-pages .private a {
  color: #808f98;
}

.sn-pageManager .sn-pages .drag {
  cursor: move;
  background-image: url("https://app-assets1.sportngin.com/app_images/pageManager/drag.png");
  background-position: 2px center;
  background-repeat: no-repeat;
}

.sn-pageManager:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  top: 0;
  z-index: -1;
}

.edit_mode .sn-pageManager .cmsNavButton {
  padding: 0;
  margin: 20px 0px 0px;
}

.edit_mode .sn-pageManager .cmsNavButton a {
  background: none;
  text-align: center;
  display: block;
  cursor: pointer;
}

.edit_mode #adminNav {
  background: url("../images/switch_on.svg") center 35% no-repeat;
  background-color: #e5e7e8;
}

.sn-assets, .sn-site-guide, .sn-element-guide, .sn-writers-guide, .sn-race-director-instructions, .sn-race-catalog-instructions, .sn-news-article-instructions {
  display: block;
  font-size: 10px;
  font-weight: 400;
  color: #84929c;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #D5DADD;
  line-height: 1.4;
}

.sn-assets:hover, .sn-site-guide:hover, .sn-element-guide:hover, .sn-writers-guide:hover, .sn-race-director-instructions:hover, .sn-race-catalog-instructions:hover, .sn-news-article-instructions:hover {
  color: #84929c;
  text-decoration: none;
}

.sn-assets:before, .sn-site-guide:before, .sn-element-guide:before, .sn-writers-guide:before, .sn-race-director-instructions:before, .sn-race-catalog-instructions:before, .sn-news-article-instructions:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 20px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.sn-assets:before {
  background-image: url("//assets.ngin.com/site_files/_shared/images/_icon_media_content.svg");
}

.sn-site-guide:before {
  background-image: url("//assets.ngin.com/site_files/_shared/images/_icon_book.svg");
}

.sn-element-guide:before {
  background-image: url("//assets.ngin.com/site_files/_shared/images/_icon_containers.svg");
}

.sn-race-director-instructions:before {
  background-image: url("../images/icons/_icon_race_pages.svg");
}

.sn-race-catalog-instructions:before {
  background-image: url("../images/icons/_icon_race_catalog.svg");
}

.sn-news-article-instructions:before {
  background-image: url("../images/icons/_icon_news_articles.svg");
}

html:not(.has-se-bar) body {
  padding-top: 40px;
}

html:not(.has-se-bar) body.noUserBar {
  padding-top: 0;
}

html:not(.has-se-bar) #accountNav {
  top: 0;
}

.has-mobile-nav html:not(.has-se-bar) #accountNav {
  position: relative;
}

#accountNav {
  position: absolute;
  padding: 0 calc(50% - 670px);
  width: 100%;
  z-index: 399;
  left: 0;
  height: 40px;
  line-height: 1;
  text-align: left;
  list-style: none;
  overflow: hidden;
  white-space: nowrap;
  background: #23282b;
}

#accountNav a {
  display: block;
  height: 100%;
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  margin: 0;
  padding: 0 8px;
}

#accountNav > li {
  display: inline-block;
  vertical-align: middle;
  line-height: 40px;
  height: 40px;
}

#accountNav > li.home span.right {
  margin-left: .3em;
  display: inline-block;
  font: normal normal normal 14px FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

#accountNav > li.home span.right:before {
  content: "\f107";
}

#accountNav > li a img {
  vertical-align: middle;
}

#accountNav .nginLogo {
  float: right;
  margin: 0 20px;
}

#accountNav .nginLogo a {
  width: 120px;
  background: url("https://app-assets1.sportngin.com/logo_images/logo.svg") right center no-repeat;
  text-indent: -20000px;
  background-size: 100%;
}

#accountNav .secondary a {
  font-weight: normal;
  font-size: 10px;
  color: #FFF;
}

#accountNav .secondary a:hover {
  color: #cccccc;
}

#accountNav .currentSite a {
  display: block;
  float: left;
}

#accountNav .currentSite a:first-child {
  width: 20px;
}

#accountNav .local-dev-tool {
  float: right;
  line-height: 40px !important;
}

#accountNav .local-dev-tool ~ .nginLogo {
  display: none;
}

.dashboard_nav .count,
#accountNav .count {
  background: #c00;
  border: 1px inset rgba(201, 201, 201, 0.5);
  color: #eee;
  float: right;
  font: bold 10px "Gotham SSm A", "Gotham SSm B", sans-serif;
  min-width: 1.5em;
  padding: 0 2px;
  text-align: center;
  text-shadow: none;
}

#accountNav .count {
  float: none;
  position: relative;
  margin-left: -10px;
  top: -6px;
}

#user_tools_menu_callout .count {
  margin-top: 5px;
}

#accountNav #user_tools_menu_callout,
#user_sites ul,
#tst_options ul,
#help_and_updates ul {
  display: none;
}

#user_sites_menu {
  max-height: 500px;
  overflow: auto;
}

#user_sites a,
#tst_options a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-indent: -999em;
  width: 32px;
}

#user_sites a:after,
#tst_options a:after {
  content: "";
  width: 100%;
  height: 39px;
}

#user_sites a:after {
  background: url("https://app-assets1.sportngin.com/app_images/link_icons.png") 8px -3231px no-repeat;
}

#tst_options a:after {
  background: url("https://app-assets1.sportngin.com/app_images/link_icons.png") 8px -2308px no-repeat;
}

#help_and_updates a.new_help_and_updates:after,
#help_and_updates_menu a.new_help_and_updates:after {
  color: white;
  padding: 0 2px 0 2px;
  font-size: 8px;
  content: 'NEW';
  background: green;
  border-radius: 2px;
  vertical-align: top;
  line-height: 12px;
}

#help_and_updates a.new_help_and_updates {
  position: relative;
}

#help_and_updates a.new_help_and_updates:after {
  position: absolute;
  right: 0;
  top: calc(50% - 14px);
  transform: translateY(-50%);
}

#help_and_updates_menu img {
  width: 12px;
  height: 12px;
}

#help_and_updates_menu a.new_help_and_updates:after {
  position: relative;
  left: 3px;
  display: inline;
}

#help_link a {
  display: flex;
  align-items: center;
}

#help_link .svg-help {
  display: inline-block;
  fill: currentColor;
  height: 1.75em;
  vertical-align: -.5em;
}

.has-mobile-nav #accountNav {
  padding: 0 20px;
  height: 44px;
  margin-top: -44px;
}

.has-mobile-nav #accountNav > li {
  height: 44px;
  line-height: 44px;
  display: none;
}

.has-mobile-nav #accountNav .home {
  display: block;
  float: right;
}

.has-mobile-nav #accountNav #login,
.has-mobile-nav #accountNav #logout {
  display: inline-block;
  float: right;
}

.has-mobile-nav #accountNav #login a,
.has-mobile-nav #accountNav #logout a {
  border: none;
  height: 44px;
  line-height: 44px;
}

html:not(.has-se-bar).has-mobile-nav body {
  position: relative;
  padding-top: 0;
  padding-bottom: 44px;
}

html:not(.has-se-bar).has-mobile-nav body.noUserBar {
  padding-bottom: 0;
}

html:not(.has-se-bar).has-mobile-nav #accountNav {
  bottom: 0;
  top: auto;
  position: absolute;
}

.page-manager-visible.has-main-nav #accountNav {
  padding: 0 calc(50% - 700px);
  width: calc(100% - 60px);
  right: 0;
  left: auto;
}

.page-manager-visible.has-mobile-nav #accountNav {
  padding-left: 80px;
}

#ngin-bar,
.has-se-bar #ngin-bar {
  position: relative;
  z-index: 401;
}

.has-se-bar.has-mobile-nav:not(.slide-nav-open) .theme-nav-style-slide {
  top: 0 !important;
}

.has-main-nav #sn-site-logo {
  position: absolute;
  width: 100%;
  max-width: 1340px;
  height: 0px;
  z-index: 1;
  top: 0;
  padding: 0 calc( 50% - 670px);
}

.has-main-nav #sn-site-logo .sn-site-logo-wrap {
  display: table;
  table-layout: fixed;
  width: 278px;
  height: 125px;
  transition: all 0.2s linear;
  padding: 0px;
  box-sizing: border-box;
}

.has-main-nav #sn-site-logo .sn-site-logo-background {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.has-main-nav .nav-fixed #sn-site-logo .sn-site-logo-wrap {
  width: 278px;
  height: 125px;
}

.has-mobile-nav .sn-site-logo-wrap {
  width: 160px;
  height: 50px;
  margin-left: 25px;
  display: block;
}

.has-mobile-nav .sn-site-logo-background {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  display: block;
}

.theme-search-box {
  display: none;
}

.has-mobile-nav .theme-search-box,
.has-main-nav .theme-search-box {
  display: block;
}

.has-main-nav .theme-search-bar .theme-search-box ~ .theme-search-box {
  margin-left: 8px;
}

.has-main-nav .theme-search-bar .theme-search-input,
.has-main-nav .theme-search-bar .theme-search-submit,
.has-main-nav .theme-search-bar .theme-search-clear {
  float: left;
  height: 26px;
  line-height: 26px;
  transition: background 0.2s linear;
}

.has-main-nav .theme-search-bar .theme-search-input,
.has-main-nav .theme-search-bar .theme-search-clear {
  font-size: 14px;
}

.has-main-nav .theme-search-bar .theme-search-input {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
  padding: 0 24px 0 8px;
}

.has-main-nav .theme-search-bar .theme-search-input::-ms-clear {
  display: none;
}

.has-main-nav .theme-search-bar .theme-search-input:focus {
  outline: none;
  background: white;
}

.has-main-nav .theme-search-bar .theme-search-submit {
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 8px;
  background: #a8272c;
}

.has-main-nav .theme-search-bar .theme-search-submit:focus, .has-main-nav .theme-search-bar .theme-search-submit:hover {
  background: #7e1e21;
}

.has-main-nav .theme-search-bar .theme-search-clear {
  float: left;
  text-decoration: none;
  text-align: center;
  margin-left: -27px;
  margin-right: 1px;
  width: 26px;
}

.has-main-nav .theme-search-bar .theme-search-clear:before {
  content: "✕";
  color: #9ca8af;
  font-family: FontAwesome;
  font-size: 14px;
}

.theme-search-wrapper {
  display: none;
}

.has-mobile-nav .theme-search-wrapper,
.has-main-nav .theme-search-wrapper {
  display: block;
}

.theme-search-wrapper .site-tagline-text {
  display: none;
}

.has-main-nav .theme-search-wrapper {
  background-color: #000000;
}

.has-main-nav .theme-search-wrapper .theme-search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0px;
  transition: height 0.2s linear;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .has-main-nav .theme-search-wrapper .theme-search-bar {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.has-main-nav .site-tagline-text {
  display: none;
}

.has-main-nav .nav-fixed .theme-search-wrapper .theme-search-bar {
  height: 0.01px;
}

.has-main-nav .has-site-logo .theme-search-wrapper .theme-search-bar {
  padding-left: calc(278px + 30px);
  transition: height 0.2s linear, padding 0.2s linear;
}

.has-main-nav .has-site-logo.nav-fixed .theme-search-wrapper .theme-search-bar {
  padding-left: calc(278px + 30px);
}

.edit_mode .custom-title-bar-extra {
  position: relative;
}

.edit_mode .custom-title-bar-extra:after {
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  border: 1px dashed #C1C3C5;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.custom-title-bar-extra .global-component-edit-link {
  top: -5px;
  left: -5px;
  right: -5px;
}

.custom-title-bar-extra:empty {
  display: none;
}

.theme-main-nav {
  position: relative;
  color: #EEEEEE;
  background-color: #000000;
}

.has-site-logo .theme-main-nav .theme-nav {
  transition: padding 0.2s linear, line-height 0.2s linear;
  padding-left: calc(278px + 30px);
}

.nav-fixed .has-site-logo .theme-main-nav .theme-nav {
  padding-left: calc(278px + 30px);
}

.has-site-logo .theme-main-nav .theme-nav {
  padding-right: 0;
}

.nav-fixed .has-site-logo .theme-main-nav .theme-nav {
  padding-right: calc(278px + 30px)-calc(278px + 30px);
}

.theme-main-nav .theme-nav-item {
  padding-left: 15px;
  padding-right: 15px;
}

.theme-main-nav .theme-nav-item {
  position: relative;
  height: 100%;
}

.theme-main-nav .theme-nav-item.theme-nav-more .theme-nav-link:after {
  content: "+";
  margin-left: .7ch;
}

.theme-main-nav .theme-nav-link {
  width: 100%;
  display: block;
}

.theme-main-nav .theme-nav {
  max-width: 1340px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  list-style: none;
  line-height: 125px;
  transition: line-height 0.2s linear;
}

.theme-main-nav .theme-nav .theme-nav-link {
  white-space: nowrap;
  padding: 0;
}

.main-nav-is-loading .theme-main-nav .theme-nav {
  flex-flow: row;
  overflow: hidden;
}

.theme-main-nav .theme-nav-dropdown > .theme-nav-item {
  padding-right: 0;
  padding-left: 0;
}

.theme-main-nav .theme-nav-dropdown > .theme-nav-item .theme-nav-link {
  color: #68767F;
  background-color: transparent;
  transition: color 0.2s linear, background-color 0.2s linear;
  text-align: left;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 1.2;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.theme-main-nav .theme-nav-dropdown > .theme-nav-item:hover .theme-nav-link,
.theme-main-nav .theme-nav-dropdown > .theme-nav-item.dropdown-open .theme-nav-link {
  color: #FF595A;
  background-color: #ffffff;
}

.theme-main-nav .theme-nav-dropdown > .theme-nav-item.selected .theme-nav-link {
  color: #CF3338;
  background-color: #ffffff;
}

.theme-main-nav .theme-nav-dropdown > .theme-nav-item.disabled .theme-nav-link,
.theme-main-nav .theme-nav-dropdown > .theme-nav-item.private .theme-nav-link {
  color: #A7B3BA;
  background-color: transparent;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="2"] {
  background-color: #F8F9F9;
  border-top: 0;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="3"] {
  background-color: #F8F9F9;
}

.theme-main-nav .theme-nav-dropdown .theme-nav-link {
  padding: 9.1px 10px;
}

.theme-main-nav [data-nav-level="1"] .theme-nav-item .theme-nav-link {
  color: #EEEEEE;
  background-color: transparent;
  transition: color 0.2s linear, background-color 0.2s linear;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  position: relative;
}

.theme-main-nav [data-nav-level="1"] .theme-nav-item .theme-nav-link:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  bottom: 42px;
  background-color: #ffffff;
  height: 4px;
  opacity: 0;
  transition: opacity .2s ease-in-out, width .2s ease-in-out, right .2s linear, left .2s linear;
}

.theme-main-nav [data-nav-level="1"] .theme-nav-item:hover .theme-nav-link,
.theme-main-nav [data-nav-level="1"] .theme-nav-item.dropdown-open .theme-nav-link {
  color: #ffffff;
  background-color: transparent;
}

.theme-main-nav [data-nav-level="1"] .theme-nav-item:hover .theme-nav-link:after,
.theme-main-nav [data-nav-level="1"] .theme-nav-item.dropdown-open .theme-nav-link:after {
  opacity: 1;
  left: 0;
  right: auto;
  width: 100%;
  transition: opacity .2s ease-in-out, width .2s ease-in-out, right .2s linear, left .2s linear;
}

.theme-main-nav [data-nav-level="1"] .theme-nav-item.selected .theme-nav-link {
  color: #ffffff;
  background-color: transparent;
}

.theme-main-nav [data-nav-level="1"] .theme-nav-item.selected .theme-nav-link:after {
  opacity: 1;
  left: 0;
  right: auto;
  width: 100%;
  transition: opacity .2s ease-in-out, width .2s ease-in-out, right .2s linear, left .2s linear;
}

.theme-main-nav [data-nav-level="1"] .theme-nav-item.disabled .theme-nav-link,
.theme-main-nav [data-nav-level="1"] .theme-nav-item.private .theme-nav-link {
  color: #A7B3BA;
  background-color: transparent;
}

.theme-main-nav [data-nav-level="3"] .theme-nav-item .theme-nav-link {
  transition: color 0.2s linear, background-color 0.2s linear;
}

.theme-main-nav .theme-nav-dropdown {
  min-width: 200px;
  transition: opacity 0.2s linear, margin 0.2s linear;
  width: 100%;
  position: absolute;
  display: none;
  z-index: 1;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="2"].dropdown-opening {
  margin-top: -20px;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="2"].dropdown-open {
  margin-top: 0;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="3"] {
  margin-top: 0px;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="3"].dropdown-opening {
  margin-left: -20px;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="3"].dropdown-open {
  margin-left: 0;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="2"].dropdown-opening, .theme-main-nav .theme-nav-dropdown[data-nav-level="3"].dropdown-opening {
  display: block;
  opacity: 0;
}

.theme-main-nav .theme-nav-dropdown[data-nav-level="2"].dropdown-open, .theme-main-nav .theme-nav-dropdown[data-nav-level="3"].dropdown-open {
  display: block;
  opacity: 1;
}

.edit_mode .custom-main-nav-extra {
  position: relative;
}

.edit_mode .custom-main-nav-extra:after {
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border: 1px dashed #C1C3C5;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.custom-main-nav-extra .global-component-edit-link {
  top: 0px;
  left: 0px;
  right: 0px;
}

.edit_mode .custom-main-nav-extra {
  padding: 0 10px;
}

.custom-main-nav-extra:empty {
  display: none;
}

.theme-main-nav .custom-main-nav-extra {
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  white-space: nowrap;
  order: 1;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: auto;
  padding-right: 10px;
  margin-left: auto;
}

.theme-main-nav .custom-main-nav-extra:before {
  content: '';
  height: 56px;
  width: 1px;
  background-color: #ffffff;
  margin-right: 60px;
}

.theme-main-nav .custom-main-nav-extra a {
  font-size: 14px;
  font-weight: 700;
  color: #EEEEEE;
  transition: color 0.2s linear, text-decoration 0.2s linear;
}

.theme-main-nav .custom-main-nav-extra a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.theme-main-nav .custom-main-nav-extra p {
  margin-bottom: 0;
}

.theme-main-nav .custom-main-nav-extra:empty {
  display: none;
}

.theme-sub-nav {
  position: relative;
  color: #000000;
  background-color: #ffffff;
}

.home.user_mode .theme-sub-nav {
  display: none !important;
}

.has-site-logo .theme-sub-nav .theme-nav {
  transition: padding 0.2s linear, line-height 0.2s linear;
}

.has-site-logo .theme-sub-nav .theme-nav {
  padding-right: 0;
}

.nav-fixed .has-site-logo .theme-sub-nav .theme-nav {
  padding-right: calc(278px + 30px)-calc(278px + 30px);
}

.theme-sub-nav .theme-nav-item {
  position: relative;
  height: 100%;
}

.theme-sub-nav .theme-nav-item.theme-nav-more .theme-nav-link:after {
  content: "+";
  margin-left: .7ch;
}

.theme-sub-nav .theme-nav-link {
  background-color: transparent;
  color: #000000;
  padding: 0 10px;
  transition: color 0.2s linear;
  width: 100%;
  display: block;
}

.theme-sub-nav .theme-nav {
  max-width: 1340px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  list-style: none;
  line-height: 75px;
  transition: line-height 0.2s linear;
}

.theme-sub-nav .theme-nav .theme-nav-link {
  white-space: nowrap;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  text-transform: none;
  font-weight: 700;
}

.theme-sub-nav .theme-nav-title {
  display: flex;
}

.theme-sub-nav .theme-nav-title a {
  background-color: transparent;
  color: #000000;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0 10px;
  display: block;
  white-space: nowrap;
}

.theme-sub-nav .theme-nav-title:after {
  content: "";
  font-family: "fontawesome";
  font-size: 14px;
  font-weight: 700;
}

.theme-sub-nav .theme-nav-dropdown {
  background-color: #ffffff;
  border-top: 0;
  text-align: left;
}

.theme-sub-nav .theme-nav-dropdown .theme-nav-link {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1;
  text-transform: none;
  padding: 13px 10px;
}

.theme-sub-nav [data-nav-level="1"] .theme-nav-item .theme-nav-link {
  background-color: transparent;
  transition: color 0.2s linear, background-color 0.2s linear;
}

.theme-sub-nav [data-nav-level="1"] .theme-nav-item:hover .theme-nav-link,
.theme-sub-nav [data-nav-level="1"] .theme-nav-item.dropdown-open .theme-nav-link {
  color: #666666;
  background-color: transparent;
}

.theme-sub-nav [data-nav-level="1"] .theme-nav-item.selected .theme-nav-link {
  color: #666666;
  background-color: transparent;
}

.theme-sub-nav [data-nav-level="1"] .theme-nav-item.disabled .theme-nav-link,
.theme-sub-nav [data-nav-level="1"] .theme-nav-item.private .theme-nav-link {
  color: #DFE4E6;
  background-color: transparent;
}

.theme-sub-nav [data-nav-level="2"] .theme-nav-item .theme-nav-link {
  background-color: transparent;
  transition: color 0.2s linear, background-color 0.2s linear;
}

.theme-sub-nav [data-nav-level="2"] .theme-nav-item:hover .theme-nav-link,
.theme-sub-nav [data-nav-level="2"] .theme-nav-item.dropdown-open .theme-nav-link {
  color: #666666;
  background-color: transparent;
}

.theme-sub-nav [data-nav-level="2"] .theme-nav-item.selected .theme-nav-link {
  color: #666666;
  background-color: transparent;
}

.theme-sub-nav [data-nav-level="2"] .theme-nav-item.disabled .theme-nav-link,
.theme-sub-nav [data-nav-level="2"] .theme-nav-item.private .theme-nav-link {
  color: #DFE4E6;
  background-color: transparent;
}

.theme-sub-nav .theme-nav-dropdown {
  min-width: 300px;
  transition: opacity 0.2s linear, margin 0.2s linear;
  width: 100%;
  position: absolute;
  display: none;
  z-index: 1;
}

.theme-sub-nav .theme-nav-dropdown[data-nav-level="2"].dropdown-opening {
  display: block;
  opacity: 0;
  margin-top: -20px;
}

.theme-sub-nav .theme-nav-dropdown[data-nav-level="2"].dropdown-open {
  display: block;
  opacity: 1;
  margin: 0;
}

.has-mobile-nav .topNav {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: left;
  height: 76px;
  position: relative;
  z-index: 200;
  background-color: #000000;
  padding-right: 44px;
}

.has-mobile-nav .theme-search-bar .theme-search-box {
  display: none;
}

.has-mobile-nav.slide-nav-open .theme-slide-nav-toggle {
  top: 0;
  transform: none;
}

.has-mobile-nav.page-manager-visible:not(.slide-nav-open) .theme-slide-nav-toggle {
  right: calc(-100vw + 60px);
}

.has-mobile-nav:not(.slide-nav-open) .theme-slide-nav-toggle {
  right: -100vw;
}

.has-main-nav .topNav {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
}

.has-main-nav .topNav.nav-fixed {
  position: fixed;
  top: 0;
}

.has-main-nav.page-manager-visible .topNav {
  margin-left: 60px;
}

#topNavPlaceholder {
  transition: height 0.2s linear;
}

.has-main-nav #topNavPlaceholder {
  height: 125px;
  background-color: #000000;
}

.has-main-nav.nav-fixed #topNavPlaceholder {
  height: 125.01px;
}

.has-main-nav.has-sub-nav #topNavPlaceholder {
  height: 200px;
  background-color: #ffffff;
}

.has-main-nav.has-sub-nav.nav-fixed #topNavPlaceholder {
  height: 165.01px;
}

.has-main-nav.has-sub-nav .home.user_mode #topNavPlaceholder {
  height: 125px;
  background-color: #000000;
}

.has-main-nav.has-sub-nav.nav-fixed .home.user_mode #topNavPlaceholder {
  height: 125.01px;
}

.has-main-nav.nav-fixed .theme-main-nav .theme-nav {
  line-height: 125px;
}

.has-main-nav.nav-fixed .theme-sub-nav .theme-nav {
  line-height: 40px;
}

.has-main-nav.nav-fixed .theme-search-wrapper .theme-search-bar {
  height: 0.01px;
}

.user_mode .theme-main-nav .theme-nav .theme-nav-item.home,
.user_mode .theme-mobile-nav .theme-nav .theme-nav-item.home.theme-nav-forward {
  display: none !important;
}

.user_mode .theme-main-nav .theme-nav .theme-nav-item[data-title="promotions"],
.user_mode .theme-mobile-nav .theme-nav .theme-nav-item[data-title="promotions"]:not(.home) {
  display: none !important;
}

.page-manager-visible .site-background {
  left: 60px;
}

.site-banner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-size: cover;
  min-height: 100px !important;
  height: 400px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .site-banner-wrapper {
    height: 95px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .site-banner-wrapper {
    height: 200px !important;
  }
}

.site-banner-wrapper:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(135deg, #000000 0%, black 100%);
  z-index: 1;
  opacity: 0.1;
}

.site-banner-wrapper .site-banner-text-wrapper {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 1.52;
  font-weight: 700;
  text-transform: none;
  text-align: center;
  font-size: 20px;
  font-size: 9vw;
}

@media screen and (max-width: 767px) {
  .site-banner-wrapper .site-banner-text-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 400px) {
  .site-banner-wrapper .site-banner-text-wrapper {
    font-size: 36px;
  }
}

@media (min-width: 800px) {
  .site-banner-wrapper .site-banner-text-wrapper {
    font-size: 72px;
  }
}

.site-banner-wrapper .site-banner-text-wrapper h2 {
  color: #ffffff !important;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 75px;
  font-weight: 700;
  text-transform: none;
  text-align: center;
  font-size: 9vw;
}

@media (max-width: 400px) {
  .site-banner-wrapper .site-banner-text-wrapper h2 {
    font-size: 36px;
  }
}

@media (min-width: 800px) {
  .site-banner-wrapper .site-banner-text-wrapper h2 {
    font-size: 72px;
  }
}

.site-banner-wrapper .site-banner-text-wrapper h1 {
  font-size: 72px;
  color: #ffffff !important;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 75px;
  font-weight: 700;
  text-transform: none;
  text-align: center;
}

.site-banner-wrapper .site-banner-text-wrapper h3 {
  font-size: 20px;
  color: #ffffff !important;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 1.6;
  font-weight: 700;
  text-transform: none;
  text-align: center;
}

#displayBodyHeader {
  background-color: 100%;
  position: relative;
  padding-top: 70px;
  padding-bottom: 25px;
}

#displayBodyHeader span {
  display: block;
  color: #000000;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 48px;
  text-transform: initial;
  font-weight: 800;
  text-align: center;
  border-bottom: 0;
}

@media only screen and (max-width: 767px) {
  #displayBodyHeader span {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #displayBodyHeader span {
    font-size: 42px;
  }
}

#displayBodyHeader.displayBodyHeader-image {
  height: auto !important;
  padding-top: 0px !important;
  height: 400px !important;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
}

#displayBodyHeader.displayBodyHeader-image:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(135deg, #000000 0%, black 100%);
  z-index: 1;
  opacity: 0.1;
}

.siteContainer {
  min-height: calc(100vh - 240px);
  padding: 0 0 0 0;
}

body:not(.home) #panelTwo,
body.home.newsPage #panelTwo {
  max-width: 1340px;
  margin-right: auto;
  margin-left: auto;
  background-color: #ffffff;
}

body.home #panelTwo {
  max-width: 100%;
}

body.home.edit_mode #panelTwo {
  background-color: #ffffff;
}

body.race-page.left-rail-layout #panelTwo {
  background-color: transparent;
}

#panelTwo:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
}

#yieldContent {
  position: relative;
}

.sportsPage #yieldContent {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .sportsPage #yieldContent {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.user_mode #panelTwo {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .user_mode #panelTwo {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.sportsPage #panelTwo {
  padding: 0;
}

.extendedOptions {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .extendedOptions {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.extendedOptions .icon {
  background-image: none;
  background-repeat: no-repeat;
  padding: 0;
}

.extendedOptions .icon:before {
  position: inherit;
  top: auto;
  left: auto;
  font-size: inherit;
}

.extendedOptions a {
  margin: 0 1.5ch;
  color: #666666;
  transition: color 0.2s linear;
}

.extendedOptions a:hover {
  color: #FF595A;
}

.extendedOptions a:first-child {
  margin-left: 0;
}

.extendedOptions a:last-child {
  margin-right: 0;
}

.extendedOptions a:before {
  margin-right: 0.7ch;
}

.pageElement > .extendedOptions:last-child {
  margin-bottom: 0;
}

.pageOptions {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: flex-end;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 400;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .pageOptions {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.pageOptions .icon {
  background-image: none;
  background-repeat: no-repeat;
  padding: 0;
}

.pageOptions .icon:before {
  position: inherit;
  top: auto;
  left: auto;
  font-size: inherit;
}

.pageOptions a {
  color: #666666;
  transition: color 0.2s linear;
}

.pageOptions a:hover {
  color: #FF595A;
}

.pageOptions a:before {
  margin-right: 0.7ch;
}

.pageOptions li + li {
  margin-left: 1.5ch;
}

.pageOptions li + li:before {
  content: "|";
  margin-right: 1.5ch;
}

.sharingOptions {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-top: 2px solid #EDF0F1;
}

@media screen and (max-width: 767px) {
  .sharingOptions {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.sn-share-icons {
  display: inline-block;
  width: 100%;
}

.sn-share-icons > div {
  display: inline-flex;
  padding: 5px 0 0 5px;
  vertical-align: middle;
  flex-flow: row wrap;
}

.sn-share-icons .sn-share-text {
  padding: 0;
}

.sn-share-icons .sn-share-text:before {
  content: "\f1e0";
  font-family: "FontAwesome";
  margin-right: 0.7ch;
}

#siteFooter {
  width: 100%;
}

#siteFooter > ul {
  width: 100%;
  max-width: 1340px;
  padding: 15px 15px;
  margin: 0 auto;
  background-color: transparent;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: center;
}

#siteFooter > ul li {
  font-size: 12px;
  color: #ffffff;
  text-transform: none;
  line-height: 1.75;
  padding: 0 5px;
}

#siteFooter > ul li:empty {
  display: none;
}

#siteFooter > ul a {
  color: #ffffff;
  transition: color 0.2s linear;
}

#siteFooter > ul a:hover {
  color: #FF595A;
}

#poweredByNGIN a {
  position: relative;
  display: block;
  transform: translateY(18%);
}

#poweredByNGIN br {
  display: none;
}

#poweredByNGIN img {
  display: block;
  height: 1.25em;
}

.pollElement h3 span {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .pollElement h3 span {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.pollElement ul {
  margin-top: 15px;
}

.pollElement ul li {
  display: block;
  list-style: none;
  padding-bottom: 10px;
}

.pollElement .pollChoice {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.pollElement .pollChoice label {
  vertical-align: middle;
  padding-left: 10px;
  text-transform: none;
  font-weight: normal;
  color: #666;
}

.pollElement .voteButton {
  padding: 0;
}

.pollElement .voteButton input[type='submit'] {
  border: 0;
  width: 100%;
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: block;
  padding: 17px 47px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
}

.pollElement .voteButton input[type='submit']:focus, .pollElement .voteButton input[type='submit']:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .pollElement .voteButton input[type='submit'] {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .pollElement .voteButton input[type='submit'] {
    font-size: 14px;
  }
}

.pollElement .voteButton input[type='submit']:focus, .pollElement .voteButton input[type='submit']:hover {
  outline: 0;
}

.pollElement h5 {
  text-transform: none;
  font-weight: normal;
  color: #666;
}

.pollElement .pollTotal {
  padding: 10px 10px 0 10px;
  border-top: 2px solid #EDF0F1;
}

.pollElement .pollTotal h5 {
  color: #666;
  font-weight: bold;
}

.pollElement .barForeground {
  display: block;
  background-color: #000000;
}

.pollElement .barBackground {
  display: block;
  background-color: gainsboro;
  margin-top: 5px;
}

.pollElement .barBackground p {
  white-space: nowrap;
  margin: 0 0 0 5px;
  font-size: 16px;
  line-height: 2;
}

.yieldPageContent {
  padding: 0;
}

body > img[src*="loggly.com"] {
  display: none;
}

.pageElement input[type="text"],
.pageElement input[type="password"] {
  width: 100%;
  max-width: 480px;
  height: 28px;
  padding: 5px;
  margin-top: 5px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: .75em;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-appearance: none;
  transition: all .1s ease;
}

.pageElement input[type="text"]:hover,
.pageElement input[type="password"]:hover {
  box-shadow: inset 0 1px 3px #ddd;
  border: 1px solid #666;
}

.pageElement input[type="text"]:focus,
.pageElement input[type="password"]:focus {
  box-shadow: inset 0 1px 3px #ddd;
  border: 1px solid #000000;
  outline: 0;
}

.pageElement textarea {
  height: 28px;
  padding: 5px;
  margin-top: 5px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: .75em;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-appearance: none;
  transition: all .1s ease;
}

.pageElement textarea:hover {
  box-shadow: inset 0 1px 3px #ddd;
  border: 1px solid #666;
}

.pageElement textarea:focus {
  box-shadow: inset 0 1px 3px #ddd;
  border: 1px solid #000000;
  outline: 0;
}

.pageElement select {
  height: 28px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 11px;
  color: #333;
  background: #fff;
  border: 1px solid #DFE4E6;
  transition: all .1s ease;
  white-space: normal;
}

.pageElement select:hover {
  box-shadow: 0 1px 3px #ddd;
  border: 1px solid #666;
}

.pageElement select:focus {
  box-shadow: 0 1px 3px #ddd;
  border: 1px solid #000000;
  outline: 0;
}

/* TODO: Get rid of this long and hard to maintain list and just use a clearfix class */
/* Internet Explorer */
.clearfix {
  zoom: 1;
}

/* Everything else */
.clearfix:after,
.boxscore-container:after,
.boxscore-extras:after,
.bracket-game p:after,
#bracket-seed-list p:after,
#bracket-teams p:after,
.bracket-game p:after,
.cmsGameStatus:after,
.cmsGameStatus ul:after,
.cmsPageStatus:after,
.comment-container:after,
.comment-form:after,
.comment:after,
.game-listing:after,
.gameStatusConstruction:after,
.leader-highlight:after,
.login-options:after,
.login-options-body:after,
.login-options-footer:after,
.login-options-header:after,
.newsItemElement:after,
#photoGalleryControls:after,
#photoGalleryControls .layoutContainer:after,
.rapidContentConstruction:after,
.rosterPlayerInfoElement:after,
#megaDropDown .season:after,
.season-title:after,
.season .header:after,
#sport-colors:after,
#sport-slider:after,
.sport-tools:after,
.sport-tools ul:after,
.sport-tools ul li:after,
.subcomment:after,
#megaDropDown .subseason:after,
#survey_steps form:after,
.surveyForwardButton:after,
.tooltip-inner:after,
.tooltip-outer:after,
.widgetFooter:after,
.widgetHierarchy .singleLevel:after,
.widget-teamButtons a:after,
.widget-teamButtons .sport-colors:after,
.widget-leader:after,
.widget-leader table li p:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.boxscore-container table.boxscore, #dayViewCalendarEvents .boxscore,
#month_list .boxscore {
  width: 100%;
  border-spacing: 0;
  border-collapse: initial;
  margin-top: 1px;
  width: 100%;
}

.boxscore-container table.boxscore td, #dayViewCalendarEvents .boxscore td, #month_list .boxscore td {
  background-clip: padding-box !important;
  border-style: solid;
  border-color: #F7F7F7;
  border-width: 0 1px 1px 0;
}

.boxscore-container table.boxscore td:first-child, #dayViewCalendarEvents .boxscore td:first-child, #month_list .boxscore td:first-child {
  border-left-width: 1px;
}

.boxscore-container table.boxscore td:last-child, #dayViewCalendarEvents .boxscore td:last-child, #month_list .boxscore td:last-child {
  border-right-width: 1px;
}

.boxscore-container table.boxscore tr:first-child td, #dayViewCalendarEvents .boxscore tr:first-child td, #month_list .boxscore tr:first-child td {
  border-top-width: 1px;
}

.boxscore-container table.boxscore tr:last-child td, #dayViewCalendarEvents .boxscore tr:last-child td, #month_list .boxscore tr:last-child td {
  border-bottom-width: 1px;
}

.boxscore-container table.boxscore tr:nth-child(odd) td, #dayViewCalendarEvents .boxscore tr:nth-child(odd) td, #month_list .boxscore tr:nth-child(odd) td {
  background-color: white;
}

.boxscore-container table.boxscore tr:nth-child(odd) td.highlight, #dayViewCalendarEvents .boxscore tr:nth-child(odd) td.highlight, #month_list .boxscore tr:nth-child(odd) td.highlight {
  background-color: #f7f7f7;
}

.boxscore-container table.boxscore tr:nth-child(even) td, #dayViewCalendarEvents .boxscore tr:nth-child(even) td, #month_list .boxscore tr:nth-child(even) td {
  background-color: #F7F7F7;
}

.boxscore-container table.boxscore tr:nth-child(even) td.highlight, #dayViewCalendarEvents .boxscore tr:nth-child(even) td.highlight, #month_list .boxscore tr:nth-child(even) td.highlight {
  background-color: #efefef;
}

.boxscore-container table.boxscore th, #dayViewCalendarEvents .boxscore th, #month_list .boxscore th {
  height: 50px;
  padding: 3px 12px;
  background-color: #CF3338;
  white-space: nowrap;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: white;
}

.boxscore-container table.boxscore td, #dayViewCalendarEvents .boxscore td, #month_list .boxscore td {
  height: 40px;
  padding: 2px 12px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
}

.boxscore-container table.boxscore td.team, #dayViewCalendarEvents .boxscore td.team, #month_list .boxscore td.team, .boxscore-container table.boxscore th.team, #dayViewCalendarEvents .boxscore th.team, #month_list .boxscore th.team {
  text-align: left;
}

.boxscore-container table.boxscore td:not(.team), #dayViewCalendarEvents .boxscore td:not(.team), #month_list .boxscore td:not(.team), .boxscore-container table.boxscore th:not(.team), #dayViewCalendarEvents .boxscore th:not(.team), #month_list .boxscore th:not(.team) {
  padding-left: 6px;
  padding-right: 6px;
}

@media only screen and (min-width: 1000px) {
  .col-md-3 .boxscore-container table.boxscore tr > *:not(.team):not(.score-final), .boxscore-container .col-md-3 table.boxscore tr > *:not(.team):not(.score-final), .col-md-3 #dayViewCalendarEvents .boxscore tr > *:not(.team):not(.score-final), #dayViewCalendarEvents .col-md-3 .boxscore tr > *:not(.team):not(.score-final), .col-md-3 #month_list .boxscore tr > *:not(.team):not(.score-final), #month_list .col-md-3 .boxscore tr > *:not(.team):not(.score-final) {
    display: none;
  }
  .col-md-3 .boxscore-container table.boxscore .teamLogo-small, .boxscore-container .col-md-3 table.boxscore .teamLogo-small, .col-md-3 #dayViewCalendarEvents .boxscore .teamLogo-small, #dayViewCalendarEvents .col-md-3 .boxscore .teamLogo-small, .col-md-3 #month_list .boxscore .teamLogo-small, #month_list .col-md-3 .boxscore .teamLogo-small {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .col-md-3 .boxscore-container table.boxscore tr > *:not(.team):not(.score-final), .boxscore-container .col-md-3 table.boxscore tr > *:not(.team):not(.score-final), .col-md-3 #dayViewCalendarEvents .boxscore tr > *:not(.team):not(.score-final), #dayViewCalendarEvents .col-md-3 .boxscore tr > *:not(.team):not(.score-final), .col-md-3 #month_list .boxscore tr > *:not(.team):not(.score-final), #month_list .col-md-3 .boxscore tr > *:not(.team):not(.score-final),
  .col-md-4 .boxscore-container table.boxscore tr > *:not(.team):not(.score-final),
  .boxscore-container .col-md-4 table.boxscore tr > *:not(.team):not(.score-final),
  .col-md-4 #dayViewCalendarEvents .boxscore tr > *:not(.team):not(.score-final),
  #dayViewCalendarEvents .col-md-4 .boxscore tr > *:not(.team):not(.score-final),
  .col-md-4 #month_list .boxscore tr > *:not(.team):not(.score-final),
  #month_list .col-md-4 .boxscore tr > *:not(.team):not(.score-final) {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-50-50 .col-md-6 .boxscore-container table.boxscore tr > *:not(.team):not(.score-final), .boxscore-container .layout-50-50 .col-md-6 table.boxscore tr > *:not(.team):not(.score-final), .layout-50-50 .col-md-6 #dayViewCalendarEvents .boxscore tr > *:not(.team):not(.score-final), #dayViewCalendarEvents .layout-50-50 .col-md-6 .boxscore tr > *:not(.team):not(.score-final), .layout-50-50 .col-md-6 #month_list .boxscore tr > *:not(.team):not(.score-final), #month_list .layout-50-50 .col-md-6 .boxscore tr > *:not(.team):not(.score-final) {
    display: none;
  }
  
  .col-md-4 .boxscore-container table.boxscore tr > *:not(.team):not(.score-final),
  .boxscore-container .col-md-4 table.boxscore tr > *:not(.team):not(.score-final),
  .col-md-4 #dayViewCalendarEvents .boxscore tr > *:not(.team):not(.score-final),
  #dayViewCalendarEvents .col-md-4 .boxscore tr > *:not(.team):not(.score-final),
  .col-md-4 #month_list .boxscore tr > *:not(.team):not(.score-final),
  #month_list .col-md-4 .boxscore tr > *:not(.team):not(.score-final),
  .layout-25-50-25 .boxscore-container table.boxscore tr > *:not(.team):not(.score-final),
  .boxscore-container .layout-25-50-25 table.boxscore tr > *:not(.team):not(.score-final),
  .layout-25-50-25 #dayViewCalendarEvents .boxscore tr > *:not(.team):not(.score-final),
  #dayViewCalendarEvents .layout-25-50-25 .boxscore tr > *:not(.team):not(.score-final),
  .layout-25-50-25 #month_list .boxscore tr > *:not(.team):not(.score-final),
  #month_list .layout-25-50-25 .boxscore tr > *:not(.team):not(.score-final) {
    display: none;
  }
  .col-md-4 .boxscore-container table.boxscore .teamLogo-small, .boxscore-container .col-md-4 table.boxscore .teamLogo-small, .col-md-4 #dayViewCalendarEvents .boxscore .teamLogo-small, #dayViewCalendarEvents .col-md-4 .boxscore .teamLogo-small, .col-md-4 #month_list .boxscore .teamLogo-small, #month_list .col-md-4 .boxscore .teamLogo-small,
  .layout-25-50-25 .boxscore-container table.boxscore .teamLogo-small,
  .boxscore-container .layout-25-50-25 table.boxscore .teamLogo-small,
  .layout-25-50-25 #dayViewCalendarEvents .boxscore .teamLogo-small,
  #dayViewCalendarEvents .layout-25-50-25 .boxscore .teamLogo-small,
  .layout-25-50-25 #month_list .boxscore .teamLogo-small,
  #month_list .layout-25-50-25 .boxscore .teamLogo-small {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .boxscore-container table.boxscore tr > *:not(.team):not(.score-final), #dayViewCalendarEvents .boxscore tr > *:not(.team):not(.score-final), #month_list .boxscore tr > *:not(.team):not(.score-final) {
    display: none;
  }
}

#tab_boxscores_content .boxscore-container {
  margin-top: 30px;
}

.boxscore-container .team {
  white-space: nowrap;
}

.boxscore-container .teamName {
  display: inline-block;
  vertical-align: middle;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boxscore-container .table-wrapper-scroll {
  width: 100%;
  overflow-x: auto;
}

.boxscore-container .teamLogo-small {
  border-radius: 0;
  position: relative;
  display: inline-block;
  float: none;
  background-size: cover !important;
  width: 30px !important;
  vertical-align: middle;
  background: #fff;
  background-size: 100% auto;
  background-position: 50% 50%;
  margin: 2px 6px;
  border: none;
  margin: 2px 6px 2px 0;
}

.boxscore-container .boxscore-inner-container {
  clear: both;
}

.boxscore-container .boxscore-extras {
  font-size: 11px;
  text-transform: uppercase;
  padding: 6px 12px;
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

.boxscore-container .boxscore-extras .datetime {
  margin-right: 6px;
}

.boxscore-container .boxscore-extras .time {
  white-space: nowrap;
}

.boxscore-container .boxscore-extras .location,
.boxscore-container .boxscore-extras .game-id {
  display: inline-block;
  margin-right: 6px;
}

.boxscore-container .boxscore-extras .game-show-link {
  color: #CF3338;
  font-size: 13px;
  text-transform: uppercase;
  font-size: inherit;
  float: right;
}

.boxscore-container .boxscore-extras .game-show-link:after {
  color: #000000;
  content: '\f061';
  display: inline-block;
  font-family: 'FontAwesome';
  margin: -.1em 0 0 .4em;
  font-size: .9em;
}

.sn-table-game-highlight {
  border-spacing: 0;
  border-collapse: initial;
  margin-top: 1px;
  width: 100%;
}

.sn-table-game-highlight td {
  background-clip: padding-box !important;
  border-style: solid;
  border-color: #F7F7F7;
  border-width: 0 1px 1px 0;
}

.sn-table-game-highlight td:first-child {
  border-left-width: 1px;
}

.sn-table-game-highlight td:last-child {
  border-right-width: 1px;
}

.sn-table-game-highlight tr:first-child td {
  border-top-width: 1px;
}

.sn-table-game-highlight tr:last-child td {
  border-bottom-width: 1px;
}

.sn-table-game-highlight tr:nth-child(odd) td {
  background-color: white;
}

.sn-table-game-highlight tr:nth-child(odd) td.highlight {
  background-color: #f7f7f7;
}

.sn-table-game-highlight tr:nth-child(even) td {
  background-color: #F7F7F7;
}

.sn-table-game-highlight tr:nth-child(even) td.highlight {
  background-color: #efefef;
}

.sn-table-game-highlight th {
  height: 50px;
  padding: 3px 12px;
  background-color: #CF3338;
  white-space: nowrap;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: white;
}

.sn-table-game-highlight tbody td {
  height: 40px;
  padding: 2px 12px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
}

.sn-table-game-highlight tfoot {
  background: none;
}

.sn-table-game-highlight tfoot td {
  font-size: 11px;
  text-transform: uppercase;
  padding: 6px 12px;
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

.sn-table-game-highlight tfoot td > div {
  display: inline-block;
}

.sn-table-game-highlight tfoot a {
  color: #CF3338;
  font-size: 13px;
  text-transform: uppercase;
  font-size: inherit;
  float: right;
}

.sn-table-game-highlight tfoot a:after {
  color: #000000;
  content: '\f061';
  display: inline-block;
  font-family: 'FontAwesome';
  margin: -.1em 0 0 .4em;
  font-size: .9em;
}

#layoutsBin .layoutContainer {
  padding-left: 0;
}

/* FCK Editor tweaks
-------------------------------------------------------------------- */
.selectElement {
  display: block;
  list-style-type: none;
  height: 150px;
  position: relative;
  overflow: auto;
  border: 1px solid #CCC;
  margin: 0 25px;
}

.selectElement .odd {
  background-color: #EFEFEF;
}

.selectElement li {
  display: block;
  margin: 0;
  padding: 3px 5px;
  background-repeat: no-repeat;
}

.selectElement input, .selectElement img, .selectElement h4 {
  display: inline;
  margin-left: 5px;
  vertical-align: middle;
  font-weight: normal;
}

/* CMS Button
-------------------------------------------------------------------- */
.cmsButton {
  display: block;
  font-size: 14px;
  line-height: 200%;
  text-decoration: none;
  background-image: url("https://app-assets1.sportngin.com/app_images/cmsButton.gif");
  background-repeat: repeat-x;
  text-align: center;
  font-weight: bold;
}

/* Admin Toggle Button
-------------------------------------------------------------------- */
.goToAdmin {
  background-image: url("https://app-assets1.sportngin.com/app_images/siteAdminToggle.gif");
}

.goToWebsite {
  background-image: url("https://app-assets1.sportngin.com/app_images/websiteToggle.gif");
}

/* ADMIN - Page Options
-------------------------------------------------------------------- */
#adminPageOptions {
  display: block;
  line-height: 18px;
  background-color: #FDE872;
}

#adminPageOptions a {
  text-decoration: none;
}

#adminPageOptions option {
  padding-right: 10px;
}

#adminPageOptions label {
  font-weight: bold;
}

.editPageLink:link, .editPageLink:visited {
  display: block;
  text-decoration: none;
  text-align: center;
  padding: 3px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .15em;
  background-color: #F9D100;
  border-top: solid 1px #DDA301;
  border-bottom: solid 1px #DDA301;
  margin: 0 1px 25px 1px;
}

/* Construction Rapid Content
-------------------------------------------------------------------- */
.rapidContentConstruction {
  padding: 15px 15px 0;
  background: #FDE872 url("https://app-assets1.sportngin.com/app_images/cmsTabFooter.gif") repeat-x bottom left;
  border-bottom: solid 1px #DDA301;
}

.rapidContentConstruction .pageElementAdmin {
  margin-left: 0;
  margin-right: 0;
  background: #FFF3B0;
  border: 1px solid #DDA301;
}

.rapidContentConstruction .pageElementAdmin .pageElement {
  margin: 10px;
}

.rapidTabsConstructionLoader {
  background: #FDE872 url("https://app-assets1.sportngin.com/app_images/cmsTabFooter.gif") repeat-x bottom left;
  border-bottom: solid 1px #DDA301;
}

/* CMS Page Status */
.cmsPageStatus {
  margin-bottom: 15px;
  text-align: center;
}

.cmsPageStatus ul {
  display: block;
  list-style-type: none;
  width: 395px;
  margin: 0 auto;
}

.cmsPageStatus ul li {
  float: left;
}

.cmsPageStatus li a {
  display: block;
  height: 30px;
  height: 0pt;
  overflow: hidden;
  padding-top: 30px;
}

.cmsPageStatus .cmsPageDisabled a {
  width: 103px;
  background-image: url("https://app-assets1.sportngin.com/app_images/publicPrivateToggle_disabled.png");
}

.cmsPageStatus .cmsPagePaywalled a {
  width: 112px;
  background-image: url("https://app-assets1.sportngin.com/app_images/publicPrivateToggle_paywalled.png");
}

.cmsPageStatus .cmsPagePrivate a {
  width: 91px;
  background-image: url("https://app-assets1.sportngin.com/app_images/publicPrivateToggle_private.png");
}

.cmsPageStatus .cmsPagePublic a {
  width: 89px;
  background-image: url("https://app-assets1.sportngin.com/app_images/publicPrivateToggle_public.png");
}

.cmsPageStatus .selected a {
  background-position: 0 -30px;
}

.cmsPageStatus .inlineHelp, .inlineHelp {
  clear: left;
  font-size: .8em;
  line-height: 135%;
  padding: 15px 5px;
}

/* CMS Page Tab Tables */
.rapidContentConstruction .dataTable, .rapidContentConstruction .resultsTable, #profiles_table {
  border: solid 1px #DDA301;
  text-align: left;
  margin-bottom: 15px;
  clear: both;
}

.rapidContentConstruction .odd {
  background-color: #FFF3B0;
}

.rapidContentConstruction .even {
  background-color: #FEFCF4;
}

.rapidContentConstruction .genericPermissions {
  color: #333333;
}

/* White inner container for content construction tabs
-------------------------------------------------------------------- */
.rapidContentConstructionInner {
  background: #fff;
  border: 1px solid #DDA301;
  margin-bottom: 15px;
  padding: 15px;
}

.rapidContentConstructionInner .layoutContainer {
  background: transparent;
  border: none;
}

.rapidContentConstructionInner .pageElement {
  margin-left: 0;
  margin-right: 0;
}

.rapidContentConstructionInner .statTable th,
.rapidContentConstructionInner .statTable td {
  width: auto;
}

.rapidContentConstructionInner .statTable .odd {
  background-color: #f2f2f2;
}

.rapidContentConstruction .rapidContentConstructionInner .odd {
  background-color: #f2f2f2;
}

.rapidContentConstruction .rapidContentConstructionInner .even {
  background-color: #e5e5e5;
}

.rapidContentConstructionInner .pageElement:last-child,
.rapidContentConstructionInner .pageElement:last-child .dataTable {
  margin-bottom: 0;
}

/* Yellow inner container for content construction tabs
-------------------------------------------------------------------- */
.rapidContentConstructionInnerYellow {
  background: #fff3b0;
  border: 1px solid #DDA301;
  clear: both;
  margin-bottom: 15px;
  padding: 15px 0;
}

/* Data Tables
-------------------------------------------------------------------- */
.rapidContentConstruction .dataTable td, .rapidContentConstruction .dataTable th, .rapidContentConstruction .resultsTable tr {
  border: none;
}

.dataTable .odd .permissionDisabled {
  background: #CCC;
}

.dataTable .even .permissionDisabled {
  background: #EFEFEF;
}

.dataTable .key .permissionDisabled {
  background: #999;
}

.dataTable .profilePic {
  float: left;
}

.dataTable .profilePic img {
  float: left;
}

.dataTable .profileSubPic {
  float: left;
  margin: 10px 5px 0 -10px;
}

.dataTable td .profileSubPic img {
  height: 16px;
  width: 16px;
}

.dataTable .persona {
  line-height: 25px;
}

.dataTable .persona input {
  margin: 5px 10px 0 5px;
  float: left;
}

.dataTable .persona .profilePic {
  line-height: 0;
}

.dataTable .persona img {
  line-height: 0;
  width: 25px;
  height: 25px;
}

.dataTable .persona .name {
  font-weight: bold;
  font-size: 12px;
  float: left;
}

#userPage .dataTable .persona {
  line-height: 1.2em;
}

.dataTable .personaSmall .profilePic {
  line-height: 0;
  margin: 5px 5px 0 0;
}

/* used in User section */
.dataTable .savedMessage {
  text-transform: uppercase;
  font-weight: bold;
}

/*  Archived Page Admin Status
-------------------------------------------------------------------- */
.cmsTabHeader {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #666;
  padding-top: 0;
  margin: 0 5px 5px 0;
  float: left;
}

.adminPageSettings {
  width: 50%;
  float: left;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.adminPageStatus {
  width: 40%;
  float: right;
  text-align: right;
  display: block;
}

.adminPageStatus p {
  margin-bottom: 7px;
}

.adminPageStatus li {
  list-style-type: none;
}

.objectAttributesLinkDiv ul {
  padding: 0 10px 10px;
  list-style-type: none;
}

.pageElementMenu {
  clear: both;
  background: #FDE872 url("https://app-assets1.sportngin.com/app_images/cmsTabFooter.gif") repeat-x bottom left;
  border-bottom: 1px solid #DDA301;
  border-top: 1px solid #DDA301;
  margin-bottom: 5px;
  text-align: left;
  padding: 15px;
  text-align: center;
}

.pageElementMenu p {
  margin-bottom: 0;
}

#tab_content_content .pageElementMenu {
  border-top: none;
}

#tab_content_content .pageElementMenu h3 {
  font-size: 1em;
}

.addElementMenu {
  text-align: center;
  margin-bottom: 5px;
}

/* CMS Page Options */
.cmsPageOptions {
  clear: both;
  list-style-type: none;
  margin-bottom: 15px;
  line-height: 200%;
}

.cmsPageOptions label {
  font-weight: bold;
}

/* CMS Node Picker */
.pageElementAdmin .pageElementMenu, .pageElementAdmin .nodePickerContainer {
  margin-left: 0px;
  margin-right: 0px;
}

/* ADMIN - Page Elements
-------------------------------------------------------------------- */
/* PE spacing */
.pageElementAdmin {
  margin: 15px;
  position: relative;
  clear: both;
}

.layoutContainer .pageElementAdmin {
  margin: 0 5px;
}

.collectorElement .pageElementAdmin {
  margin: 0 0 20px 0;
}

.layoutContainer .pageElementAdmin .pageElement, .layoutContainer .pageElementAdmin .formElement, .layoutContainer .pageElementAdmin .hrElement, .pageElementAdmin .pageElement {
  margin: 3px 0 0;
}

.layoutContainer .pageElementAdmin .formElement {
  margin-top: 0;
}

/* Collapse PE Button */
.collapsePE {
  padding-left: 13px;
  background: url("https://app-assets1.sportngin.com/app_images/nav_assets_black.gif") no-repeat 0 5px;
}

.expandPE {
  padding-left: 13px;
  background: url("https://app-assets1.sportngin.com/app_images/nav_assets_black.gif") no-repeat 0 -78px;
}

/* Admin Bar  Delete/Enable */
.elementBar .pageElement_delete,
.containerBar .layoutContainer_delete {
  box-sizing: content-box;
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/pe_trash.png");
  background-position: 3px 3px;
  background-repeat: no-repeat;
  padding: 0 5px !important;
  text-indent: -999em;
  overflow: hidden;
  text-align: left;
}

.elementBar .pageElement_delete:hover {
  background-position: 3px -47px;
}

.containerBar .layoutContainer_delete {
  background-position: 3px -20px;
}

.containerBar .layoutContainer_delete:hover {
  background-position: 3px -45px;
}

.elementBar .pageElement_enable {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/pe_reload.png");
  background-position: 95% 3px;
  background-repeat: no-repeat;
  padding: 0 20px 0 8px !important;
}

.elementBar .pageElement_enable:hover {
  background-position: 95% -47px;
}

.drag .elementBar,
.lock .drag .elementBar,
.dragTwo .containerBar {
  cursor: move;
  position: relative;
}

.pageElementAdmin .lock .elementBar,
.lock .containerBar,
.drag .lock .elementBar,
.drag .pageElement {
  cursor: default;
}

.pageElementAdmin .elementBar,
.photoGalleryThumb .elementBar,
.galleryThumbAdmin .elementBar,
.clientGraphic .elementBar {
  display: table;
  width: 100%;
  height: 24px;
  background: #FCCD22 url("https://app-assets1.sportngin.com/app_images/elementBar.gif") repeat-x;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 22px;
  position: relative;
}

.elementBar > * {
  display: table-cell;
  vertical-align: middle;
}

.elementBar > .handle {
  width: 100%;
  text-transform: none;
  font-weight: normal !important;
  color: #555;
  line-height: 24px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.elementBar .elementTitle {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.elementBar .elementTitle span {
  display: table-cell;
  overflow: hidden;
  padding-right: 8px;
  text-overflow: ellipsis;
  word-wrap: normal;
}

.elementBar,
.elementBar a:link,
.elementBar a:visited {
  color: #333;
  text-decoration: none;
  margin: 1px 0;
}

.elementBar a:hover {
  background-color: #FFDF5F;
}

.pageElementAdmin .elementBarMini {
  display: block;
  width: auto;
  font-size: 10px;
  height: 16px;
  line-height: 14px;
  position: relative;
  text-transform: uppercase;
}

.pageElementAdmin .elementBarMini .buttons {
  line-height: 16px;
}

.pageElementAdmin .elementBarMini .buttons a {
  min-width: 6px;
}

.dragTwo .handle,
.drag .handle,
.lock .handle,
.working .handle {
  height: 24px;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position: 4px 6px;
  line-height: 22px;
  font-weight: bold;
}

.dragTwo .handle, .drag .handle {
  background-image: url("https://app-assets1.sportngin.com/app_images/dragIcon.gif");
}

.lock .handle {
  background-image: url("https://app-assets1.sportngin.com/app_images/lockedIcon.gif");
}

.working .handle {
  background-image: url("https://app-assets1.sportngin.com/app_images/dragIcon_locked.gif");
}

.elementBar .buttons,
.containerBar .buttons {
  display: table;
  margin: 0 0 0 auto;
  line-height: 22px;
  width: auto;
  cursor: auto;
}

.elementBar .buttons a,
.elementBar .buttons span {
  text-align: center;
  white-space: nowrap;
  border-left: solid 1px #DDA301;
  padding: 0 5px;
  display: table-cell;
  min-width: 11px;
}

.elementBar .buttons span {
  color: #EFEFEF;
  cursor: default;
}

.elementBar a.gray_out {
  opacity: 0.4;
}

.buttonImage {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-position: center;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  -webkit-box-sizing: content-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: content-box;
  /* Firefox, other Gecko */
  box-sizing: content-box;
  /* Opera/IE 8+ */
}

.actionButton {
  background-position: center;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  text-indent: -999em;
  text-align: left;
  -webkit-box-sizing: content-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: content-box;
  /* Firefox, other Gecko */
  box-sizing: content-box;
  /* Opera/IE 8+ */
}

.buttonImageAdd {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/image_add.svg");
}

.buttonImageRemove {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/image_remove.svg");
}

.buttonChain {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/link.svg");
}

.buttonPrint {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/print.svg");
}

.buttonAddArticle {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/add_article.svg");
}

.buttonTool {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/tool.svg");
}

.buttonCopy {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/copy.svg");
}

.buttonDelete {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/trash.svg");
}

.buttonDownload {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/download.svg");
}

.buttonEdit {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/edit.svg");
}

.buttonPlayer {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/player.svg");
}

.buttonTrade {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/trade.svg");
}

.buttonEvent {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/event.svg");
}

.buttonFlag {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/flag.svg");
}

.buttonIcalFeed {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/calendar.svg");
}

.buttonGroup {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/group.svg");
}

.buttonMove {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/website_tree.svg");
}

.buttonSave {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/save.svg");
}

.buttonScore {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/score.svg");
}

.buttonUpload {
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/upload.svg");
}

.warning {
  background: #fde872;
  border: 1px solid #FCCD22;
  padding: 10px;
}

/* ADMIN - Layout Container
-------------------------------------------------------------------- */
.collapsed .pageElementAdmin .pageElement {
  height: 35px;
  overflow: hidden;
}

.collapsed .pageElementAdmin .formElement {
  display: none;
}

.layoutContainerAdmin {
  width: 100%;
  margin-bottom: 5px;
}

.layoutContainerAdmin .layoutContainer {
  border: solid 3px #999;
  padding: 3px;
}

.layoutContainerAdmin .layoutContainer .layoutContainer {
  border: none;
}

.containerBar {
  display: table;
  width: 100%;
  height: 25px;
  background-color: #CCC;
  text-transform: uppercase;
  font-size: 11px;
  margin: 0;
  background: url("https://app-assets1.sportngin.com/app_images/layoutContainerBar.gif");
}

.dragTwo .containerBar .handle,
.lock .containerBar .handle {
  display: table-cell;
  width: 100%;
  height: 25px;
  line-height: 25px;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position: 4px 6px;
  font-weight: bold;
  color: #666;
}

.containerBar .buttons a,
.containerBar .buttons span {
  display: table-cell;
  min-width: 11px;
  border-left: solid 1px #999;
  padding: 0 8px;
  text-decoration: none;
  height: 25px;
  line-height: 25px;
  color: #666;
}

.containerBar .buttons a:hover {
  background-color: #666;
  color: #FFF;
}

.columnBounds {
  margin: 0 3px;
  border: dashed 1px #DDA301;
}

.layoutContainerAdmin .columnBounds {
  border: dashed 1px #DDA301;
  margin: 0 0 0 3px;
}

.layoutContainerMirrored {
  background: url("https://app-assets1.sportngin.com/app_images/mirrorBackground-layoutContainer.png");
}

.layoutContainerMirrored .columnBounds {
  border: none;
  margin: 0;
}

.layoutContainerMirrored .mirrorButton {
  display: none;
}

.addPageElement {
  display: block;
  margin: 5px;
  height: 30px;
  text-align: center;
  text-indent: -10000px;
  background: url("https://app-assets1.sportngin.com/app_images/addPE.png") no-repeat center center;
}

.addPageElement:hover {
  font-weight: bold;
  text-decoration: none;
  line-height: 30px;
  text-indent: 0;
  font-size: 10px;
  background: url("https://app-assets1.sportngin.com/app_images/addPE_hover.png") no-repeat center center;
}

.addPageElementTop {
  display: block;
  margin: 5px;
  height: 30px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  line-height: 30px;
  text-indent: 0;
  font-size: 10px;
  background: url("https://app-assets1.sportngin.com/app_images/addPE_hover.png") no-repeat center center;
}

/* ADMIN - Collectors and Aggregators
-------------------------------------------------------------------- */
.pageElementAdmin .collectorElement .item {
  width: 98%;
}

/* ADMIN - Table Builder
-------------------------------------------------------------------- */
.tableBuilder td {
  border: solid 3px #CCC;
  padding: 0;
}

.tableBuilder .input {
  font-size: 10px;
  line-height: 11px;
  width: 75px;
  padding: 2px;
}

.tableBuilder .buildLinks {
  background-color: #EFEFEF;
  font-weight: bold;
  text-align: center;
  width: 51px;
}

.tableBuilder .buildLinks a {
  background-repeat: no-repeat;
  background-position: center center;
  width: 25px;
  display: block;
  height: 15px;
  overflow: hidden;
  float: right;
}

.tableBuilder .buildLinks a:hover {
  background-color: #FFF;
}

.tableBuilder .buildLinks .right {
  background-image: url("https://app-assets1.sportngin.com/app_images/table_builder_right.gif");
  border-left: solid 1px #CCC;
}

.tableBuilder .buildLinks .down {
  background-image: url("https://app-assets1.sportngin.com/app_images/table_builder_down.gif");
}

.tableBuilder .buildLinks .delete {
  background-image: url("https://app-assets1.sportngin.com/app_images/table_builder_x.gif");
  border-left: solid 1px #CCC;
}

.tableBuilder .headerEnabled input {
  background-color: #666;
  color: #FFF;
  font-weight: bold;
}

/* li-edit options */
.liEdit {
  list-style-type: none;
  border-top: solid 1px #CCC;
  background-color: #FFF;
}

.liEdit .liEditLink {
  font-size: 11px;
  text-transform: uppercase;
  padding: 0 5px;
  margin-left: 5px;
  border-left: solid 1px #DDA301;
}

.liEdit .liEditLink:link, .liEdit .liEditLink:visited {
  text-decoration: none;
}

.dragTwo.liEdit {
  background-image: url("https://app-assets1.sportngin.com/app_images/dragIcon.gif");
  background-repeat: no-repeat;
  background-position: 5px center;
  padding-left: 25px;
  cursor: move;
}

.locked.liEdit {
  background-image: url("https://app-assets1.sportngin.com/app_images/lockedIcon.gif");
  background-repeat: no-repeat;
  background-position: 5px center;
  padding-left: 25px;
  cursor: default;
}

.liEdit.disabled {
  color: #999;
  background-color: #EFEFEF;
}

/*  Setting Values
-------------------------------------------------------------------- */
.settingValues {
  display: block;
  margin: -1px 0 0;
  padding: 3px 5px;
  font-size: 10px;
  color: #333;
  list-style-type: none;
  background-color: #fef7d0;
}

.settingValues .settingValues li {
  line-height: 160%;
}

.settingValues .settingValues label {
  font-weight: bold;
}

.settingValues.forForm {
  background-color: transparent;
}

/*  Game Status
-------------------------------------------------------------------- */
.gameStatusConstruction {
  padding: 15px 15px 0;
  background: #FDE872 url("https://app-assets1.sportngin.com/app_images/cmsTabFooter.gif") repeat-x bottom left;
  border-bottom: solid 1px #DDA301;
}

.cmsGameStatus {
  margin: 5px 0 0;
  text-align: center;
}

.cmsGameStatus ul {
  display: block;
  list-style: none;
  margin: 0 auto 4px;
  width: 242px;
}

.cmsGameStatus li {
  float: left;
}

.cmsGameStatus li a {
  background: url("https://app-assets1.sportngin.com/app_images/gameStatusToggle.png") 0 0;
  display: block;
  height: 30px;
}

.cmsGameStatus .cmsGameScheduled a {
  width: 91px;
}

.cmsGameStatus .cmsGameInProgress a {
  background-position: -91px 0;
  width: 96px;
}

.cmsGameStatus .cmsGameFinal a {
  background-position: -187px 0;
  width: 55px;
}

.cmsGameStatus .cmsGameScheduled a.selected,
.cmsGameStatus .cmsGameScheduled a:hover {
  background-position: 0 -30px;
}

.cmsGameStatus .cmsGameInProgress a.selected,
.cmsGameStatus .cmsGameInProgress a:hover {
  background-position: -91px -30px;
}

.cmsGameStatus .cmsGameFinal a.selected,
.cmsGameStatus .cmsGameFinal a:hover {
  background-position: -187px -30px;
}

.cmsGameUnscheduled {
  padding: 0 0 15px;
  width: 100%;
}

.cmsGameButtons {
  float: right;
  text-align: right;
}

/* Misc.
-------------------------------------------------------------------- */
.spinner-inline {
  margin: 0 0 -4px;
}

.status-loading {
  background: url("https://app-assets1.sportngin.com/app_images/miniLoader.gif") 5px 50% no-repeat;
  padding: 5px 0 5px 25px;
}

/*  Flash and Error Notices
-------------------------------------------------------------------- */
#flashNotice, #flashError, #errorExplanation, .formError, .registration_lookup_not_found {
  background-repeat: repeat-x;
  background-color: #FFF6C6;
  clear: both;
  margin-top: 15px;
}

#flashNotice, #flashError, #errorExplanation {
  border: 1px solid #FDE045;
  margin-bottom: 15px;
}

#displayBodyContent .mailSubscribeElement #errorExplanation {
  margin: 0;
}

#lbContent #flashNotice, #lbContent #flashError, #lbContent #errorExplanation {
  margin: 10px 0;
  background-color: #C60005;
  border: 1px solid #FFF;
  background-image: none;
}

#lbContent #flashNotice h2, #lbContent #flashError h2, #lbContent #errorExplanation h2 {
  color: #fff;
}

#errorExplanation, .registration_lookup_not_found {
  padding: 10px 10px 15px 10px;
}

#flashNotice span, .flashNotice span {
  background-image: url("https://app-assets1.sportngin.com/app_images/noticeIcon.gif");
}

#flashError span, #flashNotice span, .flashNotice span {
  display: block;
  padding: 15px 10px 15px 45px;
  background-position: 10px 12px;
  background-repeat: no-repeat;
  background-size: 25px;
}

#flashNotice span, .flashNotice span {
  background-image: url("https://app-assets1.sportngin.com/app_images/noticeIcon.gif");
}

#flashError span {
  background-image: url("https://app-assets1.sportngin.com/app_images/errorIcon.gif");
}

#flashRichMessage span {
  display: block;
  padding: 15px 10px 15px 45px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
  background-image: url("https://app-assets1.sportngin.com/app_images/featureCheckmark.png");
}

#errorExplanation h2, .registration_lookup_not_found span {
  background-image: url("https://app-assets1.sportngin.com/app_images/errorIcon.gif");
  background-repeat: no-repeat;
  padding: 5px 0 5px 45px;
}

#flashNotice h2, #errorExplanation h2, #flashError h2, .flashNotice h2 {
  font-size: 16px;
  line-height: 120%;
  color: #867000;
  font-weight: normal;
  text-transform: capitalize;
}

#flashError, #errorExplanation {
  color: #C60005;
  font-weight: bold;
}

#flashRichMessage {
  background-color: #b5e2af;
}

#lbContent #flashError, #lbContent #flashNotice, #lbContent #errorExplanation {
  color: #FFF;
}

#errorExplanation p {
  margin-top: 5px;
}

#errorExplanation ul {
  margin: 5px 0 0 75px;
}

.draftNotice {
  color: #666;
  font-style: italic;
  font-weight: normal;
}

.miniAlert {
  background: #ffd url("https://app-assets1.sportngin.com/app_images/alert.png") 5px 4px no-repeat;
  background: rgba(255, 255, 204, 0.5) url("https://app-assets1.sportngin.com/app_images/alert.png") 5px 4px no-repeat;
  border: 1px solid #ff9;
  border: 1px solid rgba(255, 255, 153, 0.65);
  border-radius: 2px;
  padding: 5px 5px 5px 25px;
}

/* Form Error Messages
-------------------------------------------------------------------- */
.formError.formElement h4 {
  color: #C60005;
  font-size: 12px;
  background-image: url("https://app-assets1.sportngin.com/app_images/errorIconMini.gif");
  background-repeat: no-repeat;
  padding: 4px 0 15px 26px;
}

/* No Data has been previously entered
-------------------------------------------------------------------- */
.noEntry {
  color: #666;
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
  line-height: 120%;
  padding: 10px;
  border-top: solid 1px #DDDDDD;
  background-color: #EFEFEF;
  font-style: italic;
  font-weight: bold;
}

.rapidContentConstruction .noEntry,
.constructionLightbox .noEntry {
  color: #DDA301;
  background: #FFF3B0;
  border-top: solid 1px #DDA301;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

/* User Actions Pane
-------------------------------------------------------------------- */
.userActionsPane {
  margin-top: 15px;
  width: 100%;
  list-style-type: none;
  line-height: 140%;
}

.userActionsPane li {
  background-color: #FFF6C6;
  background-image: url("https://app-assets1.sportngin.com/app_images/flashBack.jpg");
  background-repeat: repeat-x;
  border: 1px solid #FDE045;
  border-top: none;
  display: block;
  line-height: 120%;
  padding: 10px;
  margin: 0 0 15px;
}

.userActionsPane h5 {
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 7px;
}

.userActionsPane p {
  margin-bottom: 10px;
}

/* Loading Animation
-------------------------------------------------------------------- */
.loadingAnimation {
  font-weight: bold;
  font-style: italic;
}

/* SPORT NGIN LIVE Notice
-------------------------------------------------------------------- */
.snlNotice {
  background: #FDE872 url("https://app-assets1.sportngin.com/app_images/cmsTabFooter.gif") repeat-x scroll left bottom;
  text-align: center;
}

.snlNotice .snlText {
  background: url("https://app-assets1.sportngin.com/logo_images/live.png") no-repeat center 6px;
  font-size: 14px;
  line-height: 120%;
  padding: 45px 10px 15px;
  text-align: center;
  overflow: hidden;
}

.snlNotice .snlText.notLive {
  background: url("https://app-assets1.sportngin.com/logo_images/logo_237x34.png") no-repeat center 6px;
}

.snlScoringOptions {
  display: inline-block;
  zoom: 1;
  *display: inline;
  list-style: none;
  padding: 0 2px;
  border: 3px solid #FFF;
  background: #EEE;
  border-radius: 8px;
  box-shadow: inset 3px 3px 10px -5px #000;
}

.snlScoringOptions li {
  font-size: 11px;
  float: left;
  width: 180px;
  padding: 8px;
  margin: 0 -1px 0 1px;
  border-left: 1px solid #FFF;
  border-left-color: rgba(255, 255, 255, 0.7);
  border-right: 1px solid #DDD;
  border-right-color: rgba(0, 0, 0, 0.1);
  min-height: 10.5em;
}

.snlScoringOptions li:first-child {
  border-left: none;
  border-radius: 2px 0 0 2px;
  margin-left: -1px;
}

.snlScoringOptions li:last-child {
  border-right: none;
  border-radius: 0 2px 2px 0;
}

.snlScoringOptions li.disabled {
  background: #DDD;
  background: rgba(0, 0, 0, 0.05);
}

.snlScoringOptions li.liveScore {
  width: 320px;
}

.snlScoringOptions li p {
  padding: 0 10px;
  font-size: 11px;
  color: #AAA;
  text-align: left;
}

p.snlStatWarning strong {
  color: #FF0000;
}

p.snlStatWarning a {
  color: #000;
}

.snlScoringOptions .button-large {
  display: block;
  background-color: #666;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 15px;
}

.snlScoringOptions .button-large:hover {
  color: #FFF;
  background-color: #333;
}

.snlScoringOptions .button-large:active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
}

.snlScoringOptions li.liveScore .button-large {
  background-color: #111;
  font-size: 18px;
}

.snlScoringOptions li.disabled .button-large,
.snlScoringOptions li.disabled .button-large:hover {
  cursor: default;
  background-color: #CCC;
  color: #EEE;
  box-shadow: none;
  text-shadow: none;
}

/* SPORT NGIN LIVE Notice with Scorekeeper
-------------------------------------------------------------------- */
.ice_hockey .snlNotice .snlText {
  background: none;
  padding: 30px 10px;
}

.ice_hockey .snlScoringOptions {
  padding: 0;
  width: 848px;
  height: 258px;
  border: 4px solid #FFF;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ice_hockey .snlScoringOptions li {
  margin: 0;
  padding: 12px;
  width: 210px;
  border-left: 0;
  border-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ice_hockey .snlScoringOptions li.liveScore {
  width: 420px;
}

.ice_hockey .snlScoringOptions li.scorekeeper {
  background: #000 url("https://app-assets1.sportngin.com/app_images/live/scorekeeper-hockey-bg.jpg") no-repeat center top;
  padding: 0;
  width: 420px;
  height: 250px;
}

.snlScoringOptions .scorekeeper a {
  background: transparent;
  width: 420px;
  height: 250px;
  display: block;
  margin: 0 auto;
  text-indent: -9999px;
}

/* Maintenance Message
-------------------------------------------------------------------- */
#maintenanceMessage {
  background: #fde045 url("https://app-assets1.sportngin.com/app_images/constructionBG.gif") 50% 50% repeat;
  background-image: linear-gradient(to bottom, rgba(252, 205, 34, 0) 0%, rgba(252, 205, 34, 0.5) 100%), url("https://app-assets1.sportngin.com/app_images/constructionBG.png");
  color: #333;
  padding: 15px;
  position: absolute;
  left: 35%;
  right: 35%;
  top: 50px;
  text-align: left;
  z-index: 999;
  box-shadow: 0 10px 50px #000;
  border-radius: 10px;
}

#maintenanceMessage div {
  background: #f2f2f2;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

#maintenanceMessage h2 {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 10px;
  text-shadow: 0 1px 1px #fff;
}

#maintenanceMessage p {
  border-top: 1px solid #fff;
  padding: 5px 0 15px;
}

/* Tool Settings Success/Error message
-------------------------------------------------------------------- */
.form-notice {
  margin: 0 5px;
}

#siteHeader,
#tstRegistrationHeader,
#profilePanelOne {
  position: relative;
}

#siteHeader #adminNav,
#tstRegistrationHeader #adminNav {
  bottom: 0;
  position: absolute;
  right: 0;
  text-align: right;
  width: auto;
}

#siteHeader #adminNav a {
  display: block;
  width: 138px;
  margin: 0 10px 10px 0;
  float: right;
}

#tstRegistrationHeader #adminNav ul {
  list-style-type: none;
}

#tstRegistrationHeader #adminNav ul li {
  display: block;
  float: right;
}

#tstRegistrationHeader #adminNav ul li a {
  overflow: hidden;
  display: block;
  padding-top: 34px;
  height: 0;
  background-position: 0px 34px;
  margin: 0 5px 5px 0;
}

#tstRegistrationHeader #adminNav .admin_nav_user_mode a,
#tstRegistrationHeader #adminNav .admin_nav_edit_mode a {
  width: 51px;
}

#tstRegistrationHeader #adminNav .admin_nav_user_mode a {
  background-image: url("https://app-assets1.sportngin.com/app_images/reg_user_mode.gif");
}

#tstRegistrationHeader #adminNav .admin_nav_edit_mode a {
  background-image: url("https://app-assets1.sportngin.com/app_images/reg_edit_mode.gif");
}

#tstRegistrationHeader #adminNav .admin_nav_preview_mode a {
  width: 76px;
  background-image: url("https://app-assets1.sportngin.com/app_images/reg_preview_mode.gif");
}

#tstRegistrationHeader #adminNav .selected a {
  background-position: 0 0;
}

/* Sub Nav Universal Style */
.subNav li,
.relatedSections li,
.siteUserMenu li {
  list-style-type: none;
  display: block;
}

.subNav a {
  display: block;
  text-decoration: none;
}

.subNav,
.relatedSections {
  background: #eee;
  padding: 10px;
  margin-bottom: 10px;
}

.subNav h4,
.relatedSections h4 {
  margin-bottom: 10px;
}

.user_mode .subNav li,
.user_mode .relatedSections li {
  transition: padding 200ms, background-color 200ms;
}

.user_mode .subNav li:hover,
.user_mode .relatedSections li:hover,
.user_mode #parent_nodes > li:last-child {
  background: #ccc;
  padding: 0 15px;
}

.user_mode .subNav li,
.user_mode .relatedSections li {
  background: #ddd;
  padding: 0 10px;
}

.edit_mode {
  /* Drag or Lock */
  /* Subnav CMS */
}

.edit_mode .subNav li:hover,
.edit_mode #parent_nodes > li:last-child {
  text-decoration: underline;
  padding: 0 10px;
}

.edit_mode .subNav .lock {
  background-image: url("https://app-assets1.sportngin.com/app_images/lockedIcon.gif");
}

.edit_mode .subNav .drag,
.edit_mode .subNav .working,
.edit_mode .subNav .lock,
.edit_mode #sequenceMenu .drag,
.edit_mode #sequenceMenu .working {
  padding: 0 10px;
  background-color: #fccd22;
  /* construction yellow */
  background-position: right center;
  background-repeat: no-repeat;
}

.edit_mode .cmsNavButton a {
  background: url("https://app-assets1.sportngin.com/app_images/buildStripe.gif");
  font-weight: bold;
}

.edit_mode .subNav .drag,
.edit_mode #sequenceMenu .drag {
  cursor: move;
  background-image: url("https://app-assets1.sportngin.com/app_images/dragIcon.gif");
}

.edit_mode .subNav .working,
.edit_mode #sequenceMenu .working {
  background-image: url("https://app-assets1.sportngin.com/app_images/dragIcon_locked.gif");
}

.edit_mode .subNav .cmsNavButton,
.edit_mode .subNav .cmsNavButton:hover {
  padding: 0;
  text-indent: 10px;
}

/* Site User Menu */
.siteUserMenu {
  background: #666;
  font-size: 11px;
}

.siteUserMenu h4 {
  color: #fff;
  background: url("https://app-assets1.sportngin.com/logo_images/stamp_logo.png") no-repeat 3px 5px;
  padding-left: 60px;
  height: 26px;
  line-height: 30px;
  font-weight: bold;
  font-size: 12px;
}

.siteUserMenu a,
.siteUserMenu a:hover {
  display: block;
  padding: 5px;
  color: #fff;
}

#survey_steps a {
  color: #000000;
}

#survey_steps .dragTwo .handle,
#survey_steps .drag .handle {
  height: auto;
}

#survey_steps .siteContainer {
  width: 100%;
  max-width: 990px;
  display: block;
  margin: auto;
}

#survey_steps .siteContainer #accountNav {
  margin: auto;
  padding: 0px;
}

#survey_steps .siteContainer #adminNav {
  top: 93px;
}

#survey_steps .siteContainer .adminToggle {
  display: block;
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  width: 75px;
  height: 75px;
  text-indent: -1000px;
  background-repeat: no-repeat;
  overflow: hidden;
}

#survey_steps .siteContainer #surveyNameHeader h1 {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  background-color: #CF3338;
  font: bold 22px/40px "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding: 0 15px;
}

@media only screen and (min-width: 421px) and (max-width: 767px) {
  #survey_steps .siteContainer #surveyNameHeader h1 {
    padding-left: 180px;
    text-align: right;
  }
}

@media only screen and (max-width: 420px) {
  #survey_steps .siteContainer #surveyNameHeader h1 {
    background-image: none !important;
  }
}

#survey_steps .siteContainer #pageContentContainer #panelOne {
  width: 100%;
  max-width: 30%;
  display: inline-block;
  float: left;
  padding: 5px;
  padding-bottom: 30px;
}

#survey_steps .siteContainer #pageContentContainer #panelTwo {
  width: 100%;
  max-width: 70%;
  display: inline-block;
  float: left;
  padding: 5px;
}

.miniPersona + #survey_steps .textBlockElement {
  margin-top: 1em;
}

#survey_steps .textBlockElement .pageElement .resultsTable {
  margin: .5em 0 1.5em 0;
  width: 100%;
}

#survey_steps .textBlockElement .pageElement .resultsTable tr {
  border: none;
  border: 1px solid #CCC;
}

#survey_steps .textBlockElement .pageElement .resultsTable th, #survey_steps .textBlockElement .pageElement .resultsTable td {
  background: none;
  color: #333;
  border: none;
  text-align: left;
  padding: .45em .75em;
}

#survey_steps .textBlockElement .pageElement .resultsTable th {
  width: 35%;
  border-right: 1px solid #CCC;
}

#survey_steps #siteFooter {
  max-width: 990px;
  background-color: #CF3338;
  padding: 15px;
  text-align: center;
}

#survey_steps #siteFooter a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
  opacity: 0.9;
  font: 100 0.9em/1.5em "Gotham SSm A", "Gotham SSm B", sans-serif;
}

#survey_steps #siteFooter a:hover {
  opacity: 1;
}

#survey_steps.edit_mode .rapidTabsConstruction {
  border-top: 0px;
}

#survey_steps.edit_mode .contentTabs.rapidTabsConstruction a,
#survey_steps.edit_mode .contentTabs.rapidTabsConstruction a:hover {
  background-color: #e1af10;
  color: #666;
}

#survey_steps.edit_mode .contentTabs.rapidTabsConstruction .selected a {
  background-color: #fde771;
  color: #000;
}

#survey_steps.edit_mode .contentTabs.rapidTabsConstruction .selected a:hover {
  background-color: #F7CE29;
  color: #666;
}

#survey_steps.edit_mode .contentTabs.rapidTabsConstruction li span a {
  margin-right: 0px;
}

/* News Article View */
.newsItemElement .titleText {
  margin-bottom: 15px;
}

.newsItemElement .authorDetails {
  font-size: 12px;
  margin: 15px 0;
}

.newsItemElement .teaserText {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1rem;
  color: #666;
}

.newsItemElement .imageOriginal {
  display: block;
  width: 100%;
  border: 2px solid #ddd;
  margin-bottom: 1rem;
}

.newsItemElement .newsArticleThumbnail {
  float: left;
  margin: .35em 1em 0 0;
  border: 2px solid #ddd;
  height: 4em;
}

.newsTags {
  font-size: .75rem;
}

/* Comments */
.wontFit {
  display: none;
}

.commentThreadElement-container > h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .commentThreadElement-container > h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.thread + .thread {
  border-style: solid;
  border-width: 0;
  border-top-color: #EDF0F1;
  border-top-width: 2px !important;
}

.commentStreamContainer.ascending > .thread {
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

.commentStreamContainer.descending > .thread {
  border-style: solid;
  border-width: 0;
  border-top-color: #EDF0F1;
  border-top-width: 2px !important;
}

.comment {
  padding: 30px 0;
}

.comment img {
  height: 60px;
  width: 60px;
  border: 2px solid #ddd;
  float: left;
}

.comment-meta .comment img {
  margin-right: 15px;
}

.subcomments {
  padding-left: 75px;
}

.subcomment {
  border-style: solid;
  border-width: 0;
  border-top-color: #EDF0F1;
  border-top-width: 2px !important;
}

.comment-content {
  padding-left: 75px;
}

.comment-content h3 {
  font-size: 1em;
  color: #666;
  text-transform: none;
}

.comment-content h3 span {
  color: transparent;
}

.comment-content p:last-child {
  margin-bottom: 0;
}

.comment-content .timeago {
  color: #666;
  display: block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  margin: .5em 0 1.5em 0;
}

.comment-content .comment-links {
  margin: 0;
  line-height: 1;
}

.comment-content .comment-links a {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.commentAggregator > h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .commentAggregator > h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.commentAggregator .comment {
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

.commentAggregator .comment:last-of-type {
  border: none;
}

.commentAggregator .comment .comment-meta {
  margin: 0;
}

.commentAggregator .comment .comment-meta img {
  max-height: 2.5em;
  max-width: 2.5em;
  margin: 5px 8px 0 0;
}

.commentAggregator .comment .comment-meta > b {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
  line-height: 1.125;
}

.commentAggregator .comment .comment-meta .timeago {
  display: block;
  font-size: 10px;
  font-style: normal;
  line-height: 1.125;
  text-transform: uppercase;
  margin: .25em 0 0 0;
}

.comment .comment-meta img {
  margin-right: 15px;
}

.commentStreamContainer.ascending .comment-form:nth-child(2) {
  margin-top: 1.5em;
}

.comment-form fieldset {
  min-width: inherit;
  border: none;
}

.comment-form fieldset span.processing {
  display: none;
}

.comment-form fieldset + fieldset {
  margin-top: 10px;
}

.comment-form label {
  font-size: .875em;
  color: #333;
  line-height: initial;
  font-weight: 600;
  display: block;
}

.comment-form input.text {
  width: 100%;
  border: 1px solid #eee;
  padding: 5px;
  margin-top: 5px;
}

.comment-form label + br {
  display: none;
}

.comment-form input + label,
.comment-form textarea + label {
  margin-top: 10px;
}

.comment-form textarea {
  border: 1px solid #eee;
  margin-top: 5px;
  max-width: 100%;
  min-height: 5em;
  padding: 5px;
  width: 480px;
}

.comment-form input#recaptcha_response_field {
  border: 1px solid #eee !important;
  padding: 5px;
  background-color: #fff !important;
}

.comment-form .hideOnProcessing {
  margin-bottom: 5px;
}

.comment_element_recaptcha #recaptcha_area {
  width: 100%;
  overflow-x: auto;
  margin: 5px 0px;
  border: 1px solid #dfdfdf;
}

.comment_element_recaptcha .recaptchatable {
  border: none !important;
}

.comment_element_recaptcha .recaptchatable td.recaptcha_image_cell {
  width: 100%;
}

.comment_element_recaptcha .recaptchatable div#recaptcha_image {
  border: none !important;
  width: 100% !important;
  max-width: 300px;
}

.comment_element_recaptcha .recaptchatable div#recaptcha_image img#recaptcha_challenge_image {
  width: 100%;
  height: auto;
  border: 1px solid #eee;
}

.comment_element_recaptcha .recaptchatable img#recaptcha_logo {
  display: none;
}

.comment_element_recaptcha .recaptchatable span#recaptcha_privacy {
  font-size: .75em;
}

#lightbox .comment-content > h3, #lightbox .comment-content p {
  color: #666 !important;
}

@media only screen and (max-width: 420px) {
  .subcomments {
    padding-left: 2em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .col-md-6 .subcomments {
    padding-left: 2em;
  }
}

@media only screen and (min-width: 768px) {
  .col-md-4 .subcomments {
    padding-left: 2em;
  }
}

@media only screen and (min-width: 768px) {
  .col-md-3 .subcomments {
    padding-left: 2em;
  }
}

@media only screen and (max-width: 320px) {
  .commentThreadElement-container .comment img {
    float: none;
    margin-bottom: 12px;
  }
  .comment-content {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 920px) {
  .col-md-4 .commentThreadElement-container .comment img {
    float: none;
    margin-bottom: 12px;
  }
  .col-md-4 .comment-content {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .col-md-3 .commentThreadElement-container .comment img {
    float: none;
    margin-bottom: 12px;
  }
  .col-md-3 .comment-content {
    padding-left: 0;
  }
}

@media only screen and (min-width: 769px) and (max-width: 999px) {
  .layout-25-25-50 .col-md-3 .commentThreadElement-container .comment img, .layout-25-25-50 .col-md-6 .commentThreadElement-container .comment img,
  .layout-50-25-25 .col-md-3 .commentThreadElement-container .comment img,
  .layout-50-25-25 .col-md-6 .commentThreadElement-container .comment img {
    margin: 0;
    float: left;
  }
  .layout-25-25-50 .col-md-3 .comment-content, .layout-25-25-50 .col-md-6 .comment-content,
  .layout-50-25-25 .col-md-3 .comment-content,
  .layout-50-25-25 .col-md-6 .comment-content {
    padding-left: 75px;
  }
  .layout-25-25-50 .col-md-3 .subcomments, .layout-25-25-50 .col-md-6 .subcomments,
  .layout-50-25-25 .col-md-3 .subcomments,
  .layout-50-25-25 .col-md-6 .subcomments {
    padding-left: 75px;
  }
  .layout-25-50-25 .col-md-3 .commentThreadElement-container .comment img, .layout-25-50-25 .col-md-6 .commentThreadElement-container .comment img {
    margin-right: 1em;
    float: none;
    margin-bottom: 12px;
  }
  .layout-25-50-25 .col-md-3 .comment-content, .layout-25-50-25 .col-md-6 .comment-content {
    padding-left: 0;
  }
  .layout-25-50-25 .col-md-3 .subcomments, .layout-25-50-25 .col-md-6 .subcomments {
    padding-left: 2em;
  }
}

/* Button Styles */
.button-small, .pill-small-left, .pill-small-middle, .pill-small-right {
  background-color: #333333;
  background-position: center top;
  border: 0 solid #000;
  color: #e5e5e5;
  cursor: pointer;
  display: inline-block;
  font: 9px/20px "Gotham SSm A", "Gotham SSm B", sans-serif;
  outline: medium none;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.button-medium, .pill-medium-left, .pill-medium-middle, .pill-medium-right {
  background-color: #333333;
  background-position: center top;
  border: 0 solid #000;
  color: #e5e5e5;
  cursor: pointer;
  display: inline-block;
  font: 12px/30px "Gotham SSm A", "Gotham SSm B", sans-serif;
  outline: medium none;
  padding: 0 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.button-large, .pill-large-left, .pill-large-middle, .pill-large-right {
  background-color: #333333;
  background-position: center top;
  border: 0 solid #000;
  color: #e5e5e5;
  cursor: pointer;
  display: inline-block;
  font: 16px/40px "Gotham SSm A", "Gotham SSm B", sans-serif;
  outline: medium none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.button-small:hover, .pill-small-left:hover, .pill-small-middle:hover, .pill-small-right:hover, .button-medium:hover, .pill-medium-left:hover, .pill-medium-middle:hover, .pill-medium-right:hover, .button-large:hover, .pill-large-left:hover, .pill-large-middle:hover, .pill-large-right:hover {
  background-color: #4c4c4c;
  background-position: center top;
  color: #fff;
  text-decoration: none !important;
}

.button-small:active, .pill-small-left:active, .pill-small-middle:active, .pill-small-right:active, .button-medium:active, .pill-medium-left:active, .pill-medium-middle:active, .pill-medium-right:active, .button-large:active, .pill-large-left:active, .pill-large-middle:active, .pill-large-right:active {
  background-color: #191919;
  background-position: center top;
  color: #fff;
  text-decoration: none !important;
}

.button-small:focus, .pill-small-left:focus, .pill-small-middle:focus, .pill-small-right:focus, .button-medium:focus, .pill-medium-left:focus, .pill-medium-middle:focus, .pill-medium-right:focus, .button-large:focus, .pill-large-left:focus, .pill-large-middle:focus, .pill-large-right:focus {
  text-decoration: none !important;
}

.pill-small-left, .pill-medium-left, .pill-large-left {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pill-small-right, .pill-medium-right, .pill-large-right {
  border-top-left-radius: 0;
}

.button-shell-small {
  display: inline-block;
  padding: 0;
  position: relative;
}

.button-shell-medium {
  display: inline-block;
  padding: 0;
  position: relative;
}

.button-shell-large {
  display: inline-block;
  padding: 0;
  position: relative;
}

a.button-small img {
  vertical-align: middle;
}

.pill-small-left .button-image-left, .pill-small-middle .button-image-left, .pill-small-right .button-image-left, .button-shell-small .button-image-left, .button-small .button-image-left {
  display: block;
  height: 16px;
  left: 2px;
  margin: 0;
  position: absolute;
  top: 2px;
  width: 16px;
  z-index: 1;
}

.pill-small-left .button-image-right, .pill-small-middle .button-image-right, .pill-small-right .button-image-right, .button-shell-small .button-image-right, .button-small .button-image-right {
  display: block;
  height: 16px;
  margin: 0;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 16px;
  z-index: 1;
}

.pill-small-left .button-image-left ~ .button-content, .pill-small-right .button-image-left ~ .button-content, .pill-small-middle .button-image-left ~ .button-content, .button-small .button-image-left ~ .button-content {
  padding-left: 12px;
}

.button-shell-small .button-image-left ~ input.pill-small-left, .button-shell-small .button-image-left ~ input.pill-small-middle, .button-shell-small .button-image-left ~ input.pill-small-right, .button-shell-small .button-image-left ~ input.button-small {
  padding-left: 20px;
}

.pill-small-left .button-image-right ~ .button-content, .pill-small-right .button-image-right ~ .button-content, .pill-small-middle .button-image-right ~ .button-content, .button-small .button-image-right ~ .button-content {
  padding-right: 12px;
}

.button-shell-small .button-image-right ~ input.pill-small-left, .button-shell-small .button-image-right ~ input.pill-small-middle, .button-shell-small .button-image-right ~ input.pill-small-right, .button-shell-small .button-image-right ~ input.button-small {
  padding-right: 20px;
}

a.button-medium img {
  vertical-align: middle;
}

.pill-medium-left .button-image-left, .pill-medium-middle .button-image-left, .pill-medium-right .button-image-left, .button-shell-medium .button-image-left, .button-medium .button-image-left {
  display: block;
  height: 24px;
  left: 3px;
  margin: 0;
  position: absolute;
  top: 3px;
  width: 24px;
  z-index: 1;
}

.pill-medium-left .button-image-right, .pill-medium-middle .button-image-right, .pill-medium-right .button-image-right, .button-shell-medium .button-image-right, .button-medium .button-image-right {
  display: block;
  height: 24px;
  margin: 0;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 24px;
  z-index: 1;
}

.pill-medium-left .button-image-left ~ .button-content, .pill-medium-right .button-image-left ~ .button-content, .pill-medium-middle .button-image-left ~ .button-content, .button-medium .button-image-left ~ .button-content {
  padding-left: 18px;
}

.button-shell-medium .button-image-left ~ input.pill-medium-left, .button-shell-medium .button-image-left ~ input.pill-medium-middle, .button-shell-medium .button-image-left ~ input.pill-medium-right, .button-shell-medium .button-image-left ~ input.button-medium {
  padding-left: 30px;
}

.pill-medium-left .button-image-right ~ .button-content, .pill-medium-right .button-image-right ~ .button-content, .pill-medium-middle .button-image-right ~ .button-content, .button-medium .button-image-right ~ .button-content {
  padding-right: 18px;
}

.button-shell-medium .button-image-right ~ input.pill-medium-left, .button-shell-medium .button-image-right ~ input.pill-medium-middle, .button-shell-medium .button-image-right ~ input.pill-medium-right, .button-shell-medium .button-image-right ~ input.button-medium {
  padding-right: 30px;
}

a.button-large img {
  vertical-align: middle;
}

.pill-large-left .button-image-left, .pill-large-middle .button-image-left, .pill-large-right .button-image-left, .button-shell-large .button-image-left, .button-large .button-image-left {
  display: block;
  height: 32px;
  left: 4px;
  margin: 0;
  position: absolute;
  top: 4px;
  width: 32px;
  z-index: 1;
}

.pill-large-left .button-image-right, .pill-large-middle .button-image-right, .pill-large-right .button-image-right, .button-shell-large .button-image-right, .button-large .button-image-right {
  display: block;
  height: 32px;
  margin: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 32px;
  z-index: 1;
}

.pill-large-left .button-image-left ~ .button-content, .pill-large-right .button-image-left ~ .button-content, .pill-large-middle .button-image-left ~ .button-content, .button-large .button-image-left ~ .button-content {
  padding-left: 26px;
}

.button-shell-large .button-image-left ~ input.pill-large-left, .button-shell-large .button-image-left ~ input.pill-large-middle, .button-shell-large .button-image-left ~ input.pill-large-right, .button-shell-large .button-image-left ~ input.button-large {
  padding-left: 42px;
}

.pill-large-left .button-image-right ~ .button-content, .pill-large-right .button-image-right ~ .button-content, .pill-large-middle .button-image-right ~ .button-content, .button-large .button-image-right ~ .button-content {
  padding-right: 26px;
}

.button-shell-large .button-image-right ~ input.pill-large-left, .button-shell-large .button-image-right ~ input.pill-large-middle, .button-shell-large .button-image-right ~ input.pill-large-right, .button-shell-large .button-image-right ~ input.button-large {
  padding-right: 42px;
}

.button-image-left ~ .button-content:empty {
  padding-left: 1px !important;
}

.button-image-right ~ .button-content:empty {
  padding-right: 1px !important;
}

button.button-small::-moz-focus-inner, input.button-small::-moz-focus-inner, input.pill-small-left::-moz-focus-inner, input.pill-small-middle::-moz-focus-inner, input.pill-small-right::-moz-focus-inner, button.pill-small-left::-moz-focus-inner, button.pill-small-middle::-moz-focus-inner, button.pill-small-right::-moz-focus-inner, button.button-medium::-moz-focus-inner, input.button-medium::-moz-focus-inner, input.pill-medium-left::-moz-focus-inner, input.pill-medium-middle::-moz-focus-inner, input.pill-medium-right::-moz-focus-inner, button.pill-medium-left::-moz-focus-inner, button.pill-medium-middle::-moz-focus-inner, button.pill-medium-right::-moz-focus-inner, button.button-large::-moz-focus-inner, input.button-large::-moz-focus-inner, input.pill-large-left::-moz-focus-inner, input.pill-large-middle::-moz-focus-inner, input.pill-large-right::-moz-focus-inner, button.pill-large-left::-moz-focus-inner, button.pill-large-middle::-moz-focus-inner, button.pill-large-right::-moz-focus-inner {
  border: 0 none;
  padding: 0;
}

.button-cancel {
  background-color: #cc0000;
  background-position: center top;
  color: #e5e5e5;
}

.button-cancel:hover {
  background-color: #e50000;
  background-position: center top;
  color: #fff;
}

.button-cancel:active {
  background-color: #191919;
  background-position: center top;
  color: #fff;
}

.button-edit {
  background-color: #fccd22;
  background-position: center top;
  color: #333;
}

.button-edit:hover {
  background-color: #fde045;
  background-position: center top;
  color: #1a1a1a;
}

.button-edit:active {
  background-color: #fccd22;
  background-position: center top;
  color: #fff;
}

.button-construction {
  background: #fccd22 url("/app_images/cmsTabFooter.gif") repeat-x scroll 0 50%;
  border: 1px solid #dda301;
  color: #807540;
}

.button-construction:hover {
  background: #fccd22 url("/app_images/cmsTabFooter.gif") repeat-x scroll 0 50%;
  color: #594100;
}

.button-construction:active {
  background-color: #fccd22;
  background-position: center top;
  color: #594100;
}

.button-siteColor {
  background-color: #0b478d;
  background-position: center top;
  color: #e5e5e5;
}

.button-siteColor:hover {
  background-color: #0b478d;
  background-position: center top;
  color: #fff;
}

.button-siteColor:active {
  background-color: #0b478d;
  background-position: center top;
  color: #fff;
}

.button-subtle {
  background-color: transparent;
  background-position: center top;
  border: 1px solid #ccc;
  color: #777;
}

.button-subtle:hover, .button-subtle:active {
  background-color: transparent;
  background-position: center top;
  color: #888;
}

.button-subtle:active {
  background-position: center top;
}

.button-commentCount .button-image-right {
  background: rgba(0, 0, 0, 0) url("/app_images/pageElements/newsAggregator/icon.png?1432043856") no-repeat scroll 0 50%;
  top: 1px;
}

.button-open {
  background: transparent none repeat scroll 0 0;
  color: #1a1a1a;
}

.button-open:hover {
  background: transparent none repeat scroll 0 0;
  color: #262626;
}

.button-open:active {
  background: transparent none repeat scroll 0 0;
  color: #000;
}

.button-open .button-content {
  text-decoration: underline;
}

.button-small.button-disabled, .button-small[disabled], .button-small.button-disabled:hover, .button-small[disabled]:hover, .button-small.button-disabled:active, .button-small[disabled]:active, .button-medium.button-disabled, .button-medium[disabled], .button-medium.button-disabled:hover, .button-medium[disabled]:hover, .button-medium.button-disabled:active, .button-medium[disabled]:active, .button-large.button-disabled, .button-large[disabled], .button-large.button-disabled:hover, .button-large[disabled]:hover, .button-large.button-disabled:active, .button-large[disabled]:active {
  background: #e5e5e5 linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0)) repeat scroll 0 0;
  color: #b3b3b3 !important;
  cursor: default;
}

.button-tool {
  background-color: #f1f1f1;
  background-position: center top;
  border: 1px solid #ccc;
  color: #333 !important;
  z-index: 1;
}

.button-tool:hover {
  background-color: #ffffff;
  background-position: center top;
  border: 1px solid #999;
  color: #333 !important;
  z-index: 2;
}

.button-tool:active {
  background-color: #d8d8d8;
  background-position: center top;
  color: #333 !important;
  z-index: 2;
}

.button-tool.pill-medium-middle, .button-tool.pill-medium-right {
  margin-left: -1px;
}

.button-cancel {
  background-color: #cc0000;
  background-position: center top;
  color: #e5e5e5;
}

.button-cancel:hover {
  background-color: #e50000;
  background-position: center top;
  color: #fff;
}

.button-cancel:active {
  background-color: #191919;
  background-position: center top;
  color: #fff;
}

.football-real-time-button-score-live {
  border: 1px solid rgba(251, 203, 34, 0);
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  width: 248px;
}

.football-real-time-button-score-live:hover {
  background-color: #fde872;
  background-position: center top;
  border: 1px solid rgba(251, 203, 34, 0.5);
  color: #000;
}

.football-real-time-button-quick-score, .football-real-time-button-edit-stats {
  background-position: center top;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  width: 115px;
}

.button-football-offline {
  padding-bottom: 0.3em;
}

#sport-header ~ .layoutContainer .readMore .button-small, #sport-header ~ .layoutContainer .readMore .pill-small-left, #sport-header ~ .layoutContainer .readMore .pill-small-middle, #sport-header ~ .layoutContainer .readMore .pill-small-right, #sport-header ~ .layoutContainer .readMore .button-medium, #sport-header ~ .layoutContainer .readMore .pill-medium-left, #sport-header ~ .layoutContainer .readMore .pill-medium-middle, #sport-header ~ .layoutContainer .readMore .pill-medium-right, #sport-header ~ .layoutContainer .readMore .button-large, #sport-header ~ .layoutContainer .readMore .pill-large-left, #sport-header ~ .layoutContainer .readMore .pill-large-middle, #sport-header ~ .layoutContainer .readMore .pill-large-right {
  color: #e5e5e5;
}

#sport-header ~ .layoutContainer .commentCount .button-small, #sport-header ~ .layoutContainer .commentCount .pill-small-left, #sport-header ~ .layoutContainer .commentCount .pill-small-middle, #sport-header ~ .layoutContainer .commentCount .pill-small-right, #sport-header ~ .layoutContainer .commentCount .button-medium, #sport-header ~ .layoutContainer .commentCount .pill-medium-left, #sport-header ~ .layoutContainer .commentCount .pill-medium-middle, #sport-header ~ .layoutContainer .commentCount .pill-medium-right, #sport-header ~ .layoutContainer .commentCount .button-large, #sport-header ~ .layoutContainer .commentCount .pill-large-left, #sport-header ~ .layoutContainer .commentCount .pill-large-middle, #sport-header ~ .layoutContainer .commentCount .pill-large-right {
  color: #595959;
}

.topNav [class*="button-"],
.siteContainer [class*="button-"] {
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: block;
  padding: 17px 47px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
}

.topNav [class*="button-"]:focus, .topNav [class*="button-"]:hover,
.siteContainer [class*="button-"]:focus,
.siteContainer [class*="button-"]:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .topNav [class*="button-"],
  .siteContainer [class*="button-"] {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .topNav [class*="button-"],
  .siteContainer [class*="button-"] {
    font-size: 14px;
  }
}

.button-add:hover, .button-remove:hover {
  border: none;
  border: 1px solid #fdea88;
}

/* Tables */
#lightboxBody .tableElement {
  _width: 660px;
}

.dataTable * >,
.statTable * >,
.stat_table * > {
  box-sizing: border-box;
}

.NginTableScroll,
.tableElement .tableWrapper,
.textBlockElement .text > .tableWrapper {
  overflow-x: auto;
}

.dataTable {
  width: 100%;
  position: relative;
}

.dataTable th:first-child, .dataTable td:first-child {
  text-align: left;
}

.dataTable tfoot:after {
  content: '';
  position: absolute;
  display: block;
  height: 0;
  width: 100%;
  border-bottom: 2px dotted #eee;
}

.dataTable tfoot:empty:after {
  display: none;
}

.statTable .condensedView {
  display: none;
}

.statTable .sorted_img,
.statTable .sort-arrow {
  display: none;
}

.statTable .name span,
.statTable .statTeam span,
.statTable .statPlayer span {
  display: block;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statTable .scheduleListResult,
.statTable .scheduleListScore {
  display: inline-block;
}

.statTable .scheduleListResult + .scheduleListScore {
  margin-left: .5rem;
}

.statTable .winner {
  background: url("https://app-assets1.sportngin.com/app_images/game_center/winnersNotch-statTable.png") no-repeat left 60%;
}

.statTable th.name span, .statTable th.statTeam span, .statTable th.statPlayer span {
  width: 140px;
  margin-left: -.5em;
}

.statTable td.name span, .statTable td.statTeam span, .statTable td.statPlayer span {
  width: 140px;
}

.statTable .extra-space {
  width: 100%;
  padding: 0 !important;
}

#TeamStatsDiv .statTable th > img,
#PlayerStatsDiv .statTable th > img {
  display: none;
}

#TeamStatsDiv .statTable td,
#PlayerStatsDiv .statTable td {
  white-space: nowrap;
}

#TeamStatsDiv .statTable th:first-child {
  width: 140px;
}

#TeamStatsDiv .statTable .teamName {
  width: 140px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

#PlayerStatsDiv .statTable th:nth-child(2), #PlayerStatsDiv .statTable th:nth-child(3) {
  text-align: left;
  width: 140px;
}

#PlayerStatsDiv .statTable .jersey-number {
  text-align: center;
  font-weight: bold;
}

#PlayerStatsDiv .statTable td:nth-child(2) > a {
  width: 140px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

#PlayerStatsDiv .statTable td .teamName {
  width: 140px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

#PlayerStatsDiv .statTable td.statPlayer {
  text-align: left;
}

#TeamInstanceStatsDiv .theme-stat-table td {
  white-space: nowrap;
}

#TeamInstanceStatsDiv .theme-stat-table th:first-child:not(.jersey-number),
#TeamInstanceStatsDiv .theme-stat-table .statPlayer:not(.jersey-number) {
  width: 140px;
}

#TeamInstanceStatsDiv .theme-stat-table th.statPlayer {
  width: 140px;
  text-align: left;
}

#TeamInstanceStatsDiv .theme-stat-table .jersey-number {
  text-align: center;
  font-weight: bold;
}

#TeamInstanceStatsDiv .theme-stat-table .teamName,
#TeamInstanceStatsDiv .theme-stat-table .statPlayer > a {
  width: 140px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.playerStatLeaderElement th:first-child {
  width: 4em;
}

.standingsElement .sportTableLabel {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .standingsElement .sportTableLabel {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.standingsElement .sportTableLabel h4 {
  color: #fff;
}

.standingsElement .statTable th:first-child {
  width: 180px;
}

.standingsElement .statTable th:first-child, .standingsElement .statTable td:first-child {
  text-align: left;
}

.standingsElement .NginTableWrapper:not(:last-child) {
  margin-bottom: 15px;
}

/* Countdown Element */
.countdownElement {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  position: relative;
  padding: 15px 13px;
  text-align: left;
  background-position: center center;
  align-items: center;
  justify-content: left;
}

.countdownElement h2 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  text-transform: none;
  color: #ffffff;
  margin-bottom: 0px;
  margin-right: 0px;
  order: 1;
}

@media only screen and (max-width: 767px) {
  .countdownElement h2 {
    font-size: 20px;
  }
}

.countdownElement h2:empty {
  display: none;
}

.countdownElement abbr {
  background: linear-gradient(-3deg, #454545, #989898);
  align-self: center;
  min-width: 315px;
  position: relative;
  border: none;
  border-radius: 10px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  margin-right: 25px;
}

.countdownElement span {
  display: inline-block;
  margin: 0 6px;
  min-width: 46px;
}

@media screen and (min-width: 768px) {
  .countdownElement span {
    min-width: 55px;
  }
}

.countdownElement b {
  display: inline-block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #ffffff;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  margin-top: 5px;
}

.countdownElement small {
  display: block;
  margin-top: 9px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 14px;
}

.countdownElement .minutes small {
  display: none;
}

.countdownElement .minutes:after {
  content: 'Min';
  display: block;
  margin-top: 9px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 14px;
}

.countdownElement .seconds small {
  display: none;
}

.countdownElement .seconds:after {
  content: 'Sec';
  display: block;
  margin-top: 9px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 14px;
}

.countdownElement.countdown-dark {
  background: linear-gradient(90deg, #5D2B82, #D11487);
  border-radius: 10px;
}

.countdownElement.countdown-light {
  background: linear-gradient(90deg, #5D2B82, #D11487);
  border-radius: 10px;
}

@media only screen and (min-width: 900px) {
  .col-md-4 .countdownElement, .col-md-3 .countdownElement {
    display: block;
  }
  .col-md-4 .countdownElement h2, .col-md-3 .countdownElement h2 {
    margin-bottom: 15px;
    width: 100%;
  }
  .col-md-4 .countdownElement abbr, .col-md-3 .countdownElement abbr {
    display: block;
    margin: 0 auto;
    min-width: 0;
    white-space: initial;
  }
  .col-md-4 .countdownElement b, .col-md-3 .countdownElement b {
    font-size: 36px;
  }
  .col-md-4 .countdownElement small, .col-md-3 .countdownElement small {
    font-size: 11px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
  .col-md-4 .countdownElement abbr span, .col-md-3 .countdownElement abbr span {
    width: 33%;
    padding: 1.5% 0;
  }
  .col-md-4 .countdownElement b, .col-md-3 .countdownElement b {
    font-size: 36px;
  }
  .col-md-4 .countdownElement small, .col-md-3 .countdownElement small {
    font-size: 11px;
  }
}

@media only screen and (max-width: 900px) {
  .countdownElement {
    display: block;
  }
  .countdownElement h2 {
    margin-bottom: 15px;
    width: 100%;
  }
  .countdownElement abbr {
    display: block;
    margin: 0 auto;
    min-width: 0;
    white-space: initial;
  }
  .countdownElement b {
    font-size: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .countdownElement b {
    font-size: 32px;
  }
  .countdownElement small {
    font-size: 11px;
  }
}

@media only screen and (max-width: 370px) {
  .countdownElement abbr span {
    width: 33%;
    padding: 1.5% 0;
  }
}

#payWall {
  background: #ffffcc;
  border: solid 1px #ffcc00;
  border-radius: 4px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

#payWall h3 {
  color: #000;
}

#payWall .layoutContainer {
  margin-right: 0;
}

#payWall > .layoutContainer > .column {
  padding: .05px 0;
}

#payWall > .layoutContainer > .column > .pageEl > .pageElement {
  margin: 15px;
}

#payWall .login h3 {
  background: url("https://app-assets1.sportngin.com/app_images/alert-gold.png") no-repeat 0 5px;
  padding-left: 35px;
}

#payWall .subscribe {
  border-left: solid 1px #ffcc00;
  overflow: hidden;
}

#payWall .subscribe .pricing {
  text-align: center;
}

#payWall .subscribe .pricing .pageElement {
  padding: 10px 0;
  color: #666666;
}

#payWall .subscribe .pricing p {
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  width: 100%;
}

#payWall .subscribe .pricing .change {
  font-size: 16px;
  vertical-align: top;
}

#payWall .subscribe .pricing .length {
  font-size: 12px;
  font-weight: normal;
  vertical-align: top;
}

#payWall .subscribe .pricing p b {
  background: url("https://app-assets1.sportngin.com/app_images/paywall-pricing.png") no-repeat 50% 50%;
  display: block;
  font-weight: bold;
  font-size: 9px;
  margin: 8px 0;
  width: 100%;
}

#payWall .button-large {
  display: block;
  margin-top: 25px;
}

#payWall .subscribe .button-large {
  background: #ffdd3a;
  background-image: linear-gradient(to bottom, #ffdd3a 0%, #ffcc00 100%);
  color: black;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
}

#payWall .subscribe .button-large:hover {
  background: #3f3f3f;
  background-image: linear-gradient(to bottom, #666666 0%, #3f3f3f 100%);
  color: white;
  text-shadow: 0px -1px 0px black;
}

#payWall .subscribe .button-large:active {
  background: #4c4c4c;
  background-image: linear-gradient(to bottom, #262626 0%, #4c4c4c 100%);
  color: white;
  text-shadow: 0px -1px 0px black;
}

/*.subNav li.paywalled a:before,
.relatedSections li.paywalled a:before,
#topNav a.paywalled:before {
  content: url("../images/REPLACE.gif");
  margin: -1px 2px 0 -11px;
  float: left;
}*/
.collectorElement a.paywalled:before,
.newsAggregatorElement a.paywalled:before {
  content: "";
  display: block;
  float: left;
  width: 40px;
  height: 1.5em;
  margin-right: .375em;
  background: url("https://app-assets1.sportngin.com/app_images/paywall-icon.svg") no-repeat center center;
  background-size: contain;
  -webkit-background-size: contain;
}

#payWall .subscribe a {
  background: #ffcc00;
  background-image: linear-gradient(to bottom, #ffdd3a 0%, #ffcc00 100%);
  color: black;
  text-shadow: 0px 1px 0px rgba(221, 221, 221, 0.5);
  transition: none;
}

/* Logged In View */
#payWall.logged-in .subscribe {
  margin-top: 0;
}

#payWall.logged-in .subscribe a {
  margin-top: 21px;
}

#payWall.logged-in .pricing {
  border-left: solid 1px #FC0;
  margin: 0 15px 15px 0;
}

.sn-social-media-list a[class*="icon"] {
  background: #CF3338;
}

.icon {
  background-repeat: no-repeat;
  padding: 0px 0 2px 20px;
  background-image: url("https://app-assets1.sportngin.com/app_images/link_icons.png");
}

.rssLink {
  position: relative;
  background: none;
}

.rssLink:before {
  content: "\f09e";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.groupLink {
  background-position: 0px -348px;
}

.goToLink {
  position: relative;
  background: none;
}

.goToLink:before {
  content: "\f061";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.goBackLink {
  position: relative;
  background: none;
}

.goBackLink:before {
  content: "\f060";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.monthViewLink,
.iconDayCal,
.alphaLink,
.siteMapLink {
  background-image: none;
}

.linkLink {
  background-position: 0px -928px;
}

.deleteLink {
  background-position: 0px -1044px;
}

.editLink {
  background-position: 0px -1160px;
}

.printLink {
  position: relative;
  background: none;
}

.printLink:before {
  content: "\f02f";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.attachedLink {
  background-position: 0px -1392px;
}

.reloadLink {
  position: relative;
  background: none;
}

.reloadLink:before {
  content: "\f021";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.iCalLink {
  position: relative;
  background: none;
}

.iCalLink:before {
  content: "\f073";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.excelLink {
  position: relative;
  background: none;
}

.excelLink:before {
  content: "\f1c3";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.tagLink {
  position: relative;
  background: none;
}

.tagLink:before {
  content: "\f02b";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.emailLink {
  position: relative;
  background: none;
}

.emailLink:before {
  content: "\f003";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.read .emailLink {
  background-position: 0px -2088px;
}

.newLink {
  background-position: 0px -2204px;
}

.tstAdminLink {
  background-position: 0px -2320px;
}

.addLink {
  background-position: 0px -2436px;
}

.lockedCheck {
  background-position: 0px -2552px;
}

.gear {
  background-position: 0px -2668px;
  font-size: initial;
  padding-right: 4px;
  cursor: pointer;
}

.photo {
  background-position: 0px -2784px;
}

.dibsLink {
  background-image: url("/app_images/icon-dibs-small.svg");
  background-position: 2px center;
}

.mirrorLink {
  background-position: 0 -2900px;
}

.myAccess {
  background-image: url("https://app-assets1.sportngin.com/app_images/permissions.gif");
  padding-left: 32px;
}

.orderLink {
  background: url("https://app-assets1.sportngin.com/app_images/dragIcon.gif") 3px 3px no-repeat;
}

.fullscreenLink {
  background: url("https://app-assets1.sportngin.com/app_images/icons/fullscreenIcon.png") 0 1px no-repeat;
}

.connectionLink {
  background-position: 0 -3015px;
}

.saveLink {
  background-position: 0 -3128px;
}

.checkLink {
  background-position: 0 -3363px;
}

.fbLink {
  position: relative;
  background: none;
}

.fbLink:before {
  content: "\f09a";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.twitterLink {
  position: relative;
  background: none;
}

.twitterLink:before {
  content: "\f099";
  position: absolute;
  font-family: FontAwesome;
  font-size: 14px;
  top: 0;
  left: 5px;
}

.pdfLink {
  background-position: 0 -3679px;
}

.transferLink {
  background-position: 0 -3796px;
}

.sponsorElement .goToLink:before {
  padding: 5px 10px 5px 0px;
  content: "\f061";
}

.contactElement .icon,
.sn-social-media-list .sn-social-media-icon {
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1.2em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  height: 1em;
  line-height: 1em;
  margin: .1em;
  padding: 0;
  text-align: center;
  text-decoration: none;
  text-rendering: auto;
  transform: translate(0, 0);
  transition: background-color .25s ease-in-out;
  width: 1em;
}

.contactElement .icon:before,
.sn-social-media-list .sn-social-media-icon:before {
  display: block;
  font-size: .6em;
  line-height: 1.66em;
  width: 1.66em;
  left: 0;
}

.contactElement .icon {
  background-color: #999;
  font-size: 27px;
}

.contactElement .icon:hover {
  background-color: #000000;
}

.sn-social-media-list:not(.light):not(.dark) .sn-social-media-icon:hover {
  background-color: #999;
}

.sn-social-media-list.dark .icon-email:hover,
.sn-social-media-list.dark .icon-link:hover {
  background-color: #999;
}

.collectorElement .title {
  font-weight: bold;
  position: relative;
}

.collectorElement .title:before {
  content: "\f030";
  position: absolute;
  font-family: FontAwesome;
  font-size: 1.2em;
  left: -25px;
}

.contentTabs + .collectorTree.siteMapElement a[href*="/photo_gallery/"] + a[href*="/photo_gallery/"]:before {
  content: "\f030";
  position: absolute;
  font-family: FontAwesome;
  font-size: 1.2em;
  left: -25px;
}

a[href*="/document/"] + .info .title:before {
  content: "\f15b";
  position: absolute;
  font-family: FontAwesome;
  font-size: 1.2em;
  left: -25px;
}

/* Photo Gallery Element */
.gallery_info {
  display: none;
}

.galleryElement_gallery {
  position: relative;
}

.galleryElement_gallery:before {
  content: '';
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 10;
  position: absolute;
}

.galleryElement {
  position: relative;
}

.galleryElement_gallery {
  box-sizing: border-box;
}

.galleryElement_gallery.formatted {
  font-size: inherit;
  position: relative;
  padding: 0px;
  margin-bottom: 0px;
  line-height: 120%;
  padding: 0px;
  background: url(/gallery_skins/tn3e/grad.png) center center no-repeat;
}

.galleryElement_gallery.formatted img {
  display: none;
}

.galleryElement_gallery .image_title {
  display: none !important;
}

.galleryElement_gallery .crop {
  padding-top: 75%;
  height: 0;
  overflow: hidden;
  position: relative;
}

.galleryElement_gallery .slide,
.galleryElement_gallery .image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.galleryElement_gallery .slide {
  width: 100%;
  height: 100%;
}

.galleryElement_gallery .overlay,
.galleryElement_gallery .link {
  position: absolute;
  width: 100%;
  height: 100%;
}

.galleryElement_gallery .overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 7;
}

.galleryElement_gallery .link {
  z-index: 8;
}

.galleryElement_link {
  background: url(/gallery_skins/tn3e/grad.png) 70% 80% no-repeat;
}

.galleryElement_link a {
  display: block;
  text-decoration: none;
  overflow: hidden;
}

.galleryElement_link.single_photo {
  overflow: hidden;
}

.galleryElement_link.single_photo a {
  width: 100%;
  padding: 3%;
  text-align: center;
}

.galleryElement_link.single_photo a:hover {
  background: url(/gallery_skins/tn3e/grad.png) no-repeat center center;
}

.galleryElement_link.single_photo a img {
  width: 100%;
}

.galleryElement_link.single_photo .gallery_title {
  display: block;
  position: absolute;
  bottom: 3%;
  left: 0;
  margin-left: 3%;
  width: 94%;
  height: 40px;
  padding: 8px 6%;
  text-align: center;
  cursor: default;
  z-index: 10;
}

.galleryElement_link.single_photo .gallery_title span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.galleryElement_link.gallery_button {
  padding: 0;
}

.galleryElement_link.gallery_button a {
  padding: 0;
  height: 65px;
}

.galleryElement_link.gallery_button a:hover {
  background: url(/gallery_skins/tn3e/grad.png) no-repeat 70% 60%;
}

.galleryElement_link.gallery_button img {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  height: 65px;
  clip: rect(0, 65px, 65px, 0);
}

.galleryElement_link.gallery_button .gallery_title {
  display: block;
  margin-top: 0;
  background: rgba(0, 0, 0, 0.7);
  padding-left: 72px;
}

.galleryElement_link.gallery_button .gallery_title span {
  display: block;
  height: 65px;
  line-height: 65px;
  padding-right: 8px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.galleryElement_gallery .image_title,
.galleryElement_link .gallery_title {
  display: block;
  background: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 600;
  color: #fff !important;
  text-align: center;
  text-shadow: none !important;
}

.galleryElement_gallery .image_title span,
.galleryElement_link .gallery_title span {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  line-height: 1.2em;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.gallery_info,
.photo_gallery_preview,
#mediaGallery-description {
  position: relative;
  padding: 20px 0;
  margin-bottom: 15px;
  text-align: left;
}

.gallery_info:after,
.photo_gallery_preview:after,
#mediaGallery-description:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  line-height: 0;
  background-color: #EDF0F1;
}

.gallery_info h3,
.gallery_info h1,
.gallery_info .title,
.photo_gallery_preview h3,
.photo_gallery_preview h1,
.photo_gallery_preview .title,
#mediaGallery-description h3,
#mediaGallery-description h1,
#mediaGallery-description .title {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  line-height: 1.2;
  margin: 0 0 .1em 0;
  text-align: left;
  text-transform: none;
}

.gallery_info.rapidContentConstruction h1,
.photo_gallery_preview.rapidContentConstruction h1,
#mediaGallery-description.rapidContentConstruction h1 {
  text-align: center;
}

.gallery_info p,
.gallery_info .description,
.photo_gallery_preview p,
.photo_gallery_preview .description,
#mediaGallery-description p,
#mediaGallery-description .description {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  margin: 0 0 .5em 0;
}

.edit_mode .galleryElement_link.gallery_button img {
  top: 25px;
}

.galleryElement_gallery.maximized {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.galleryElement_gallery.maximized .crop {
  padding-top: 0;
  position: absolute;
  height: auto;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 120px;
}

.galleryElement_gallery.maximized .image_title {
  position: absolute;
  bottom: 60px;
  left: 5%;
  right: 5%;
  height: 60px;
  width: 90%;
}

.galleryElement_gallery.maximized .image_title span {
  padding-top: 18px;
}

.galleryElement_gallery.maximized .next_btn {
  right: 5.5%;
  bottom: 70px;
}

.galleryElement_gallery.maximized .prev_btn {
  left: 5.5%;
  bottom: 70px;
}

#theMediaGallery.galleryElement_link .gallery_title, #theMediaGallery.formatted .image_title, #theMediaGallery_gallery .tn3e-image-title {
  font-size: 18px !important;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif !important;
  font-weight: 600 !important;
  text-shadow: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

#theMediaGallery_gallery.tn3e-gallery {
  width: 100% !important;
}

#theMediaGallery_gallery .tn3e-text {
  height: 70px;
  min-height: 70px;
}

#theMediaGallery_gallery .tn3e-image-description {
  font-size: 14px !important;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif !important;
  font-weight: 400 !important;
  text-shadow: none !important;
}

.mediaGalleryHeader {
  font-size: 0.5em;
}

.mediaGalleryHeader h1 {
  float: left;
  line-height: 1.5em;
}

.mediaGalleryHeader .button-large {
  float: right;
}

#tab_slideshow_content {
  border-bottom: none;
  padding: 0;
}

#mediaGalleryThumbnails {
  background: #fff;
  padding: 15px;
}

#slideshowContainer .rapidContentConstruction {
  padding: 15px;
}

#mediaGalleryUploader {
  height: 210px;
}

#galleryThumbBin {
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
}

.photoGalleryThumb {
  display: inline-block;
  width: 100px;
  margin: 5px;
}

.photoGalleryThumbImage {
  padding: 10px;
  text-align: center;
}

#galleryThumbBin .photoGalleryThumbImage {
  padding: 10px;
}

#galleryThumbBin .photoGalleryThumbImage img {
  margin: 0 0 10px;
}

#mediaGallery-solo {
  padding: 15px 0 0;
}

#mediaGallery-solo .pageElementAdmin {
  margin-top: 0;
}

#mediaGallery-solo .aside {
  padding: 15px;
}

#mediaGallery-solo .aside h1 {
  padding: 0 0 15px;
  line-height: 1.2em;
}

#mediaGallery-solo .aside p {
  padding: 0 0 15px;
}

.tn3e-gallery .tn3e-thumbs {
  overflow-y: visible;
  overflow-x: auto;
}

.galleryElement_gallery .controls {
  display: none !important;
}

.galleryElement_gallery .prev_btn,
.galleryElement_gallery .next_btn,
.tn3e-next,
.tn3e-prev {
  display: block;
  position: absolute;
  width: 25px !important;
  height: 54px !important;
  background: transparent !important;
  top: 50% !important;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 11;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}

.galleryElement_gallery .prev_btn:hover,
.galleryElement_gallery .next_btn:hover,
.tn3e-next:hover,
.tn3e-prev:hover {
  opacity: 1;
}

.galleryElement_gallery .prev_btn:after,
.galleryElement_gallery .next_btn:after,
.tn3e-next:after,
.tn3e-prev:after {
  display: block;
  text-align: center;
}

.galleryElement_gallery .prev_btn {
  bottom: 0;
  left: 20px;
}

.galleryElement_gallery .prev_btn:after {
  content: url(../images/photo-gallery-ui/photo-gallery-arrow-left.png);
}

.tn3e-prev {
  left: 20px !important;
}

.tn3e-prev:after {
  content: url(../images/photo-gallery-ui/photo-gallery-arrow-left.png);
}

.galleryElement_gallery .next_btn {
  bottom: 0;
  right: 20px;
}

.galleryElement_gallery .next_btn:after {
  content: url(../images/photo-gallery-ui/photo-gallery-arrow-right.png);
}

.tn3e-next {
  right: 20px !important;
}

.tn3e-next:after {
  content: url(../images/photo-gallery-ui/photo-gallery-arrow-right.png);
}

.tn3e-gallery .tn3e-control-bar {
  position: absolute;
  width: 160px;
  height: 50px;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.tn3e-gallery .tn3e-control-bar .tn3e-fullscreen,
.tn3e-gallery .tn3e-control-bar .tn3e-play,
.tn3e-gallery .tn3e-control-bar .tn3e-show-albums {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: transparent !important;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}

.tn3e-gallery .tn3e-control-bar .tn3e-fullscreen:hover,
.tn3e-gallery .tn3e-control-bar .tn3e-play:hover,
.tn3e-gallery .tn3e-control-bar .tn3e-show-albums:hover {
  opacity: 1;
}

.tn3e-gallery .tn3e-control-bar .tn3e-fullscreen:after,
.tn3e-gallery .tn3e-control-bar .tn3e-play:after,
.tn3e-gallery .tn3e-control-bar .tn3e-show-albums:after {
  display: block;
  width: 100%;
  height: 100%;
  color: #ddd;
  line-height: 50px;
  font-family: FontAwesome;
  font-size: 24px;
  text-align: center;
}

.tn3e-gallery .tn3e-control-bar .tn3e-fullscreen:after {
  content: "\f065";
}

.tn3e-gallery .tn3e-control-bar .tn3e-play:after {
  content: "\f04b";
}

.tn3e-gallery .tn3e-control-bar .tn3e-play.tn3e-play-active:after {
  content: "\f04c";
}

.tn3e-gallery .tn3e-control-bar .tn3e-show-albums:after {
  content: "\f03e";
}

.mediaGallery-pageElementToolbar {
  padding: 10px;
}

body .mediaGallery-dark .mediaGallery-pageElementToolbar a {
  color: #e5e5e5;
}

body .mediaGallery-dark .mediaGallery-pageElementToolbar a:hover {
  color: #fff;
}

body .mediaGallery-dark .mediaGallery-pageElementToolbar a:active {
  color: #e5e5e5;
}

.button-mediaGallery-pe-previous, .button-mediaGallery-pe-playPause, .button-mediaGallery-pe-next {
  float: left;
}

.button-mediaGallery-pe-viewAll {
  float: right;
}

#mediaGallery-toolbar {
  background: #f2f2f2;
  border-bottom: 1px solid #e1e1e1;
  line-height: 24px;
  padding: 0;
}

#mediaGallery-toolbar .layoutContainer {
  padding: 10px 15px;
}

#mediaGallery-toolbar .threeColumn333333 .column2 {
  text-align: center;
}

#mediaGallery-toolbar .threeColumn5050 .column2 {
  text-align: right;
}

#mediaGallery-cartControls {
  display: inline-block;
}

#mediaGallery-cartControls a,
#mediaGallery-cartControls button,
#mediaGallery-cartControls form,
#mediaGallery-cartControls #button-mediaGallery-buyPhotos,
#mediaGallery-cartControls .button-mediaGallery-buyPhotos {
  display: inline-block;
}

#mediaGallery-cart {
  padding: 0;
  margin: 0;
}

#mediaGallery-cart .header {
  float: left;
}

#mediaGallery-cart .header h1 {
  margin-bottom: 5px;
}

#mediaGallery-cart form {
  float: right;
}

#mediaGallery-cartInnerContainer {
  padding: 15px;
  margin: 15px 0 0;
}

#button-mediaGallery-emptyCart {
  margin: 0 11px;
}

#mediaGallery-cartItems {
  clear: both;
  margin: 15px 0 0;
}

.mediaGallery-cartItem {
  position: relative;
  background: #e5e5e5;
  margin: 5px 10px 5px 0;
  float: left;
  padding: 5px;
  text-align: center;
}

.mediaGallery-cartItem img {
  margin: 0 0 5px;
}

.mediaGallery-cartItem small {
  color: #999;
}

.pageElement .mediaGallery-cartItem p {
  line-height: 18px;
  margin: 0 0 5px;
}

#mediaGallery-shareByEmail .layoutContainer {
  padding: 5px 5px 0 15px;
  margin: 0;
}

#mediaGallery-shareByEmail .layoutContainer .pe50 {
  padding: 0 2% 0 0;
  width: 48%;
}

#mediaGallery-shareByEmail .layoutContainer .pe100 {
  padding: 0 1% 0 0;
  width: 98%;
}

#mediaGallery-shareByEmail .footer .layoutContainer {
  padding: 0 0 15px 15px;
}

#mediaGallery-shareByEmail .footer .column2 p {
  text-align: right;
}

#mediaGallery-shareByEmail label {
  padding: 5px 0;
}

#mediaGallery-shareByEmail label span {
  color: #666;
  font-size: 14px;
}

#mediaGallery-shareByEmail input[type="text"],
#mediaGallery-shareByEmail .input-text,
#mediaGallery-shareByEmail .valid,
#mediaGallery-shareByEmail .hasError {
  width: 100%;
  max-width: 480px;
  height: 28px;
  padding: 5px;
  margin-top: 5px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: .75em;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-appearance: none;
  transition: all .1s ease;
}

#mediaGallery-shareByEmail input[type="text"]:hover,
#mediaGallery-shareByEmail .input-text:hover,
#mediaGallery-shareByEmail .valid:hover,
#mediaGallery-shareByEmail .hasError:hover {
  box-shadow: inset 0 1px 3px #ddd;
  border: 1px solid #666;
}

#mediaGallery-shareByEmail input[type="text"]:focus,
#mediaGallery-shareByEmail .input-text:focus,
#mediaGallery-shareByEmail .valid:focus,
#mediaGallery-shareByEmail .hasError:focus {
  box-shadow: inset 0 1px 3px #ddd;
  border: 1px solid #000000;
  outline: 0;
}

#mediaGallery-shareByEmail .hasError {
  background: #fee;
  border: 1px solid #c99;
}

#mediaGallery-shareByEmail #email_message {
  max-width: 100%;
}

#mediaGallery-tabContents .footer {
  margin-top: 15px;
}

/* Play/Pause - gallery */
#button-mediaGallery-playPause .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/playPause-medium-open-blueHover.png");
}

#button-mediaGallery-playPause[data-state="paused"] .button-image-left {
  background-position: 0 0;
}

#button-mediaGallery-playPause[data-state="paused"]:hover .button-image-left {
  background-position: 0 -24px;
}

#button-mediaGallery-playPause[data-state="paused"]:active .button-image-left {
  background-position: 0 -48px;
}

#button-mediaGallery-playPause[data-state="playing"] .button-image-left {
  background-position: -24px 0;
}

#button-mediaGallery-playPause[data-state="playing"]:hover .button-image-left {
  background-position: -24px -24px;
}

#button-mediaGallery-playPause[data-state="playing"]:active .button-image-left {
  background-position: -24px -48px;
}

/* Play/Pause - page element */
.button-mediaGallery-pe-playPause .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/playPause-small-open-blueHover.png");
}

.button-mediaGallery-pe-playPause[data-state="paused"]:hover .button-image-left {
  background-position: 0 -16px;
}

.button-mediaGallery-pe-playPause[data-state="paused"]:active .button-image-left {
  background-position: 0 -32px;
}

.button-mediaGallery-pe-playPause[data-state="playing"] .button-image-left {
  background-position: -16px 0;
}

.button-mediaGallery-pe-playPause[data-state="playing"]:hover .button-image-left {
  background-position: -16px -16px;
}

.button-mediaGallery-pe-playPause[data-state="playing"]:active .button-image-left {
  background-position: -16px -32px;
}

/* small */
.button-mediaGallery-pe-previous .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/arrowLeft-small-open-blueHover.png");
}

.button-mediaGallery-pe-next .button-image-right {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/arrowRight-small-open-blueHover.png");
}

.button-mediaGallery-pe-viewAll .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/viewSlideshow-small-open.png");
}

.button-mediaGallery-pe-previous:hover .button-image-left,
.button-mediaGallery-pe-next:hover .button-image-right,
.button-mediaGallery-pe-viewAll:hover .button-image-left {
  background-position: 0 -16px;
}

.button-mediaGallery-pe-previous:active .button-image-left,
.button-mediaGallery-pe-next:active .button-image-right,
.button-mediaGallery-pe-viewAll:active .button-image-left {
  background-position: 0 -32px;
}

/* medium */
#button-mediaGallery-viewCart {
  text-decoration: underline;
}

#button-mediaGallery-previous .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/arrowLeft-medium-open-blueHover.png");
}

#button-mediaGallery-next .button-image-right {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/arrowRight-medium-open-blueHover.png");
}

#button-mediaGallery-share {
  background: transparent;
  border: none;
}

#button-mediaGallery-share:hover {
  background: transparent;
  border: none;
}

#button-mediaGallery-share .button-image-left {
  display: none;
}

#button-mediaGallery-share .button-content {
  position: relative;
  background: transparent;
  border: none;
}

#button-mediaGallery-share .button-content:hover {
  background: transparent;
  border: none;
}

#button-mediaGallery-share .button-content:before {
  position: absolute;
  font-family: FontAwesome;
  font-size: 1.2em;
  left: 0px;
  content: "\f003";
}

#button-mediaGallery-addToCart .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/cart-medium-circle-green.png");
}

#button-mediaGallery-emptyCart .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/X-medium-circle-red.png");
}

#button-mediaGallery-buyPhotos-shell .button-image-left {
  background: url("https://app-assets1.sportngin.com/app_images/buttons/arrowRight-medium-circle-green.png");
}

#mediaGallery-shareByEmailForm .tab-close {
  background: transparent;
  border: none;
}

#mediaGallery-shareByEmailForm .tab-close:hover {
  background: transparent;
  border: none;
}

#mediaGallery-shareByEmailForm .tab-close .button-image-left {
  display: none;
}

#mediaGallery-shareByEmailForm .tab-close .button-content {
  position: relative;
  background: transparent;
  border: none;
}

#mediaGallery-shareByEmailForm .tab-close .button-content:hover {
  background: transparent;
  border: none;
}

#mediaGallery-shareByEmailForm .tab-close .button-content:before {
  position: absolute;
  font-family: FontAwesome;
  font-size: 1.2em;
  left: 0px;
  content: "\f00d";
}

#button-mediaGallery-return:hover .button-image,
#button-mediaGallery-previous:hover .button-image-left,
#button-mediaGallery-next:hover .button-image-right,
#button-mediaGallery-share:hover .button-image-left,
#button-mediaGallery-addToCart:hover .button-image-left,
#button-mediaGallery-emptyCart:hover .button-image-left,
#button-mediaGallery-buyPhotos-shell:hover .button-image-left,
.tab-close:hover .button-image-left {
  background-position: 0 -24px;
}

#button-mediaGallery-return:active .button-image,
#button-mediaGallery-previous:active .button-image-left,
#button-mediaGallery-next:active .button-image-right,
#button-mediaGallery-share:active .button-image-left,
#button-mediaGallery-addToCart:active .button-image-left,
#button-mediaGallery-emptyCart:active .button-image-left,
#button-mediaGallery-buyPhotos-shell:active .button-image-left,
.tab-close:active .button-image-left {
  background-position: 0 -48px;
}

/* large */
#button-mediaGallery-addPhotos .button-image-left,
#button-mediaGallery-doneAdding .button-image-left {
  background-image: url("https://app-assets1.sportngin.com/app_images/photo_gallery/add_photo.png");
}

#button-mediaGallery-return .button-image-left {
  display: none;
}

#button-mediaGallery-return:hover .button-image-left {
  background-position: 0 -32px;
}

#button-mediaGallery-return:active .button-image-left {
  background-position: 0 -64px;
}

#button-mediaGallery-return {
  background: transparent;
  border: none;
}

#button-mediaGallery-return:hover {
  background: initial;
}

#button-mediaGallery-return .button-image-left {
  display: none;
}

#button-mediaGallery-return .button-content {
  position: relative;
  background: transparent;
  border: none;
}

#button-mediaGallery-return .button-content:hover {
  background: none;
  border: none;
}

#button-mediaGallery-return .button-content:before {
  position: absolute;
  font-family: FontAwesome;
  font-size: .875em;
  left: 5px;
  content: "\f060";
}

.edit_mode .galleryElement_link {
  background: url("https://app-assets1.sportngin.com/app_images/cmsTabFooter.gif") repeat-x left bottom #FDE872 !important;
}

.galleryElement .rapidContentConstruction {
  padding: 15px;
}

.galleryElement .rapidContentConstruction img {
  margin: 0 0 15px;
}

.galleryElement .rapidContentConstruction p {
  padding: 15px 0 0;
  margin: 0;
}

.photo_gallery_preview img {
  width: 100%;
}

.photo_gallery_preview .button-medium {
  display: none;
}

#mediaGallery-tabs, #mediaGallery-status {
  display: inline;
}

#mediaGalleryUploaderNotes {
  margin: 15px 0 0 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .tn3e-image {
    /* height:280px !important; */
  }
  #theMediaGallery_gallery .tn3e-gallery {
    height: 400px !important;
  }
  #mediaGallery-tabs, #mediaGallery-status {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 40px 0 20px 0;
  }
}

.galleryElement a[class*="button-"] {
  box-shadow: none;
  border-radius: 0;
}

#lbWrapper.galleryLightbox {
  display: none;
  position: fixed;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  width: auto;
  height: auto;
}

.galleryLightbox #lightboxBody {
  position: absolute;
  background: transparent;
  overflow: hidden;
  top: 0;
  bottom: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.galleryItemWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  background: radial-gradient(center, ellipse cover, #333333 0%, black 100%);
  padding: 0 300px 0 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  margin: auto;
}

.galleryLightbox.portrait .galleryItemWrapper {
  padding: 0;
}

.galleryItemContent {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.galleryLightbox .galleryItemContent {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 300px;
  bottom: 0;
  margin: auto;
}

.galleryLightbox.fullscreen .galleryItemContent {
  z-index: 6;
}

.galleryItemContent .video_aspect_ratio {
  background: none;
}

.galleryLightbox.fullscreen .galleryItemNext,
.galleryLightbox.fullscreen .galleryItemPrev {
  display: none;
}

.galleryItemLoader {
  position: absolute;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -200px;
  top: 50%;
  left: 50%;
  background: url("https://app-assets1.sportngin.com/app_images/loader2.gif") center center no-repeat;
}

.galleryLightbox.portrait .galleryItemLoader {
  margin-left: -50px;
  top: 25%;
}

.galleryLightbox.portrait .galleryItemContent {
  bottom: 50%;
  right: 0;
}

.galleryItemContent .galleryItem,
.galleryItemContent .galleryItem.photo img {
  display: block;
  width: 100%;
  height: 100%;
}

.galleryItemSummary {
  text-align: left;
  transition: all 300ms;
  position: absolute;
  top: -38px;
  left: 0;
  right: 0;
  padding: 5px;
  z-index: 3;
  overflow: visible;
  line-height: 18px;
}

.galleryLightbox .galleryItemSummary {
  color: #FFF;
  background: #111;
  background: rgba(0, 0, 0, 0.7);
  transition: all 300ms;
  right: 300px;
  opacity: 0;
}

.galleryLightbox.portrait .galleryItemSummary {
  right: 0;
}

.galleryItemWrapper.hover .galleryItemSummary {
  opacity: 1;
  top: 0;
}

.galleryItemWrapper.hover.hoverSidebar .galleryItemSummary {
  opacity: 0;
  top: -38px;
}

.galleryItemSummary h3 {
  float: left;
  font-size: 14px;
  margin: 0;
  padding: 5px;
}

.galleryItemWrapper .galleryItemSummary h3 a {
  color: #FFF;
  text-decoration: none;
}

.galleryItemSummary .count {
  font-size: 10px;
  color: #999;
}

.galleryItemLinks {
  float: right;
  line-height: 18px;
  white-space: nowrap;
  padding: 5px;
}

.galleryLightbox.portrait .galleryItemLinks {
  padding-right: 25px;
}

.galleryItemLinks > li {
  list-style: none;
  display: inline;
  margin-left: 10px;
}

.galleryItemShareMenu {
  text-align: left;
  position: absolute;
  z-index: 4;
  top: 0;
  right: 10px;
  display: none;
  background: #FFF;
  padding: 5px 10px;
  transition: all 300ms;
}

.galleryItemWrapper .galleryItemShareMenu {
  right: 310px;
  opacity: 0;
  background: #111;
  background: rgba(0, 0, 0, 0.7);
}

.galleryItemWrapper.hover .galleryItemShareMenu {
  opacity: 1;
  top: 38px;
}

.galleryItemWrapper.hover.hoverSidebar .galleryItemShareMenu {
  opacity: 0;
  top: 0;
}

.galleryLightbox.portrait .galleryItemShareMenu {
  right: 10px;
}

.galleryItemShareMenu h3 {
  display: none;
}

.galleryItemShareMenu .share_links {
  list-style: none;
}

.galleryItemShareMenu .share_links li {
  margin-bottom: 5px;
}

#lb_galleryPhotoImg {
  display: block;
  height: 100%;
  width: 100%;
}

.galleryItemSidebar {
  background-color: #FFF;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 300px;
  z-index: 3;
}

.galleryLightbox.portrait .galleryItemSidebar {
  width: 100%;
  top: 50%;
  height: 50%;
}

.galleryLightbox .layoutContainer {
  margin: 0;
  padding: 0 0 15px 15px;
}

.galleryLightbox .layoutContainer .column {
  margin: 0;
  padding: 0;
}

.galleryLightbox .layoutContainer .pageElement {
  margin: 15px 15px 0 0;
  padding: 0;
}

.edit_mode .galleryLightbox .galleryItemText {
  margin: 0 0 15px -15px !important;
  padding: 15px !important;
  background: #FDE872 url(/app_images/cmsTabFooter.gif?1285077954) repeat-x bottom left;
  border-bottom: solid 1px #DDA301;
}

.galleryItemText .byline {
  font-size: 14px;
  font-weight: normal;
}

.galleryItemText .byline .name {
  font-size: 18px;
  font-weight: bold;
}

.galleryItemText .meta {
  color: #999;
}

.galleryItemText .description {
  font-size: 14px;
  line-height: 21px;
}

.galleryItemText textarea.description {
  width: 94%;
  height: 120px;
  border: 1px solid #DDA301;
  padding: 3%;
  margin: 0 -1px;
}

.galleryItemSidebar .formButtons {
  text-align: center;
  margin-top: 10px;
}

.galleryItemSidebar .formButtons .button-medium {
  min-width: 30%;
}

.galleryItemSidebar .formButtons .button-medium:disabled {
  border: 1px solid #DDA301;
  margin: -1px;
}

.galleryLightbox #lightboxClose {
  display: none;
}

.galleryItemWrapper #lightboxClose {
  display: block;
  right: 0;
  width: 21px;
  height: 23px;
  border: none;
  background-color: #FFF;
  background-position: 1px -1043px;
  z-index: 3;
  border-radius: 0 0 0 70%;
}

.galleryLightbox.portrait #lightboxClose {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
}

.galleryLightbox #lightboxClose:hover {
  background-color: #FFF;
}

.edit_mode .galleryLightbox #lightboxClose {
  background-color: #FDE872;
}

.galleryLightbox .galleryItemPrev,
.galleryLightbox .galleryItemNext {
  display: none;
}

.galleryLightbox .galleryItemPrev,
.galleryLightbox .galleryItemNext {
  display: block;
  position: absolute;
  width: 25%;
  height: 20%;
  top: 40%;
}

.galleryItemNext {
  right: 0;
}

.galleryLightbox .galleryItemPrev span,
.galleryLightbox .galleryItemNext span {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 0;
  padding-top: 40px;
  margin-top: -20px;
  overflow: hidden;
  background-color: black;
  border-radius: 5%;
  background-image: url("https://app-assets1.sportngin.com/app_images/white_arrows.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 300ms;
}

.galleryLightbox .galleryItemPrev span {
  left: 20px;
}

.galleryLightbox .galleryItemNext span {
  right: 20px;
  background-position: -34px 10px;
}

.galleryItemContent:hover .galleryItemPrev span,
.galleryItemContent:hover .galleryItemNext span {
  opacity: .3;
}

.galleryLightbox .galleryItemPrev:hover span,
.galleryLightbox .galleryItemNext:hover span {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}

.galleryItemText form .processing {
  display: none;
  height: 40px;
  background: url("/app_images/loaderBar.gif") center center no-repeat;
}

/*/ GALLERY ITEM SHOW /*/
.galleryItemElement {
  background: #000;
  background: radial-gradient(center, ellipse cover, #333333 0%, black 100%);
  position: relative;
  overflow: visible;
}

.layoutContainer .galleryItemElement {
  margin-top: 50px;
}

.galleryItemElement .galleryItem.photo img {
  max-width: 100%;
  max-height: 500px;
  display: block;
  margin: auto;
}

.galleryLightbox .commentThreadElement {
  padding-top: 20px;
}

#lightbox .galleryLightbox .commentThreadElement-container > h3 {
  padding-left: 10px;
  font-size: 14px;
}

.galleryLightbox .pageElementAdmin {
  margin: 0 15px 15px 0;
}

/*/ ADMIN THUMBS /*/
#galleryList,
.galleryItemList {
  padding: 2%;
}

#mediaGallery-description + #galleryList,
#mediaGallery-description + .galleryItemList {
  padding-top: 0;
}

.galleryThumbAdmin {
  vertical-align: top;
  display: inline-block;
  zoom: 1;
  *display: inline;
  margin: 2% .9%;
  padding: 0;
  width: 27.5%;
}

.galleryThumbDate {
  font-size: 11px;
  margin: -2% 8% 0;
}

.galleryThumbAdmin .actions {
  text-align: center;
  overflow: hidden;
  padding: 10px 0 6%;
}

.galleryThumbAdmin .actions a:first-child:not(:last-child) {
  float: left;
}

.galleryThumbAdmin .actions a:last-child:not(:first-child) {
  float: right;
}

.galleryThumbAdmin .galleryThumb,
.galleryThumbAdmin .galleryItemThumb {
  display: block;
  margin: 0;
  width: auto;
  margin: 6% 8%;
}

/*/ THUMBS /*/
.galleryThumb,
.galleryItemThumb {
  vertical-align: top;
  display: inline-block;
  zoom: 1;
  *display: inline;
  margin: 1.8%;
  width: 29%;
  background: #DDD;
}

.galleryThumb a,
.galleryItemThumb a,
.galleryThumb a:hover,
.galleryItemThumb a:hover {
  display: block;
  text-decoration: none;
  position: relative;
  background: #FFF;
  padding: 7px;
  color: #111;
}

.galleryThumb .image,
.galleryItemThumb .image {
  display: block;
  height: 0;
  padding: 33% 0;
  overflow: hidden;
  line-height: 0;
  background: #DDD;
}

.galleryThumb img,
.galleryItemThumb img {
  display: inline-block;
  vertical-align: middle;
  margin: -40% 0 -38%;
  width: 100%;
}

.galleryItemThumb + div {
  font-size: .875em;
}

/*/ GALLERY THUMBS ONLY /*/
.galleryThumb a {
  margin: -1px -2px 1px;
}

.galleryThumb .name {
  padding: 0 5px;
  margin-top: 10px;
  display: block;
  font-weight: bold;
}

.galleryThumb .date {
  padding: 0 5px;
  display: block;
  color: #999;
}

.galleryThumb .count {
  position: absolute;
  bottom: 7px;
  right: 7px;
  color: #999;
}

/*/ GALLERY ITEMS THUMBS ONLY /*/
.galleryItemThumb {
  width: 22.5%;
  margin: .9%;
}

.galleryItemList .galleryThumbAdmin {
  width: 19%;
}

/*/ SIDE BAR /*/
#gallerySidebar {
  line-height: 140%;
  background: #EEE;
  border-radius: 5px;
}

#gallerySidebar .summary {
  padding: 15px;
  background: #DDD;
  border-radius: 5px 5px 0 0;
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
}

#gallerySidebar .summary .button-medium {
  display: block;
}

#gallerySidebar .summary ul {
  display: table;
  width: 100%;
  margin: 15px 0 -14px;
}

#gallerySidebar .summary li {
  display: table-cell;
  list-style: none;
  border-left: 1px solid #EEE;
  border-right: 1px solid #CCC;
  padding: 0 10px 10px;
}

#gallerySidebar .summary li:first-child {
  border-left: none;
}

#gallerySidebar .summary li:last-child {
  border-right: none;
}

#gallerySidebar .summary li label {
  display: block;
  color: #999;
  font-size: .75em;
}

#gallerySidebar .summary li span {
  color: #333;
  font-size: 18px;
}

#gallerySidebar h3 {
  margin: 25px 15px 15px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

#gallerySidebar .meta {
  border-bottom: 1px solid #FFF;
}

#gallerySidebar .meta li {
  font-weight: bold;
  font-size: 12px;
  list-style: none;
  padding: 10px 10px 10px 40px;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #DDD;
  background-image: url("https://app-assets1.sportngin.com/app_images/icons/metaDataIcons.png");
  background-repeat: no-repeat;
}

#gallerySidebar .meta li.date {
  background-position: 10px 6px;
}

#gallerySidebar .meta li.user {
  background-position: 10px -94px;
}

#gallerySidebar .meta li.location {
  background-position: 10px -194px;
}

#gallerySidebar .meta li.file_size {
  background-position: 10px -294px;
}

#gallerySidebar .meta li.gallery {
  background-position: 10px -394px;
}

#gallerySidebar .share_links {
  list-style: none;
  margin: 10px 15px 15px;
  padding: 0 0 5px;
}

#gallerySidebar .share_links li {
  font-size: 11px;
  margin: 10px 5px;
}

#gallerySidebar .share_links a {
  line-height: 120%;
  display: block;
  text-decoration: none;
  padding: 2px 5px 2px 25px;
}

#lightbox #gallerySidebar .fb_edge_comment_widget.fb_iframe_widget {
  left: auto !important;
  right: 0;
}

body .tn3e-gallery {
  background: none !important;
  width: 100% !important;
}

.tn3e-prev, .tn3e-next {
  bottom: 50%;
}

.tn3e-text {
  display: block !important;
  padding: 0 5%;
  margin: 0 auto !important;
  height: 60px;
  min-height: 60px;
  width: 100% !important;
  bottom: 0 !important;
  left: 0 !important;
  overflow: hidden;
  text-align: center;
}

body .tn3e-thumbs {
  display: block;
  background: transparent !important;
}

.tn3e-thumb > div {
  margin: 2px 0 0 2px;
}

.tn3e-thumb img {
  border: 2px solid #ddd;
}

.unlimitedHierarchy {
  background: url("https://app-assets1.sportngin.com/app_images/hierarchyLines.gif");
  margin-left: 5px;
  display: block;
  width: 95%;
}

.unlimitedHierarchy li {
  display: block;
  padding: 0 0 0 5px;
  clear: both;
  list-style: none;
  background: url("https://app-assets1.sportngin.com/app_images/hierarchyAttach.gif") no-repeat 0 12px;
}

.unlimitedHierarchy li li {
  margin-left: 65px;
}

.unlimitedHierarchy li.selected > div h3 a {
  border: 2px solid #FCCD22;
  background: #FEEC8F;
  font-weight: bold;
  padding: 2px;
  text-decoration: none;
}

.unlimitedHierarchy .nodeTitle {
  margin-left: 5px;
  line-height: 15px;
  font-weight: bold;
}

.unlimitedHierarchy .subtle .nodeTitle {
  font-weight: normal;
  text-decoration: none;
  color: #666;
}

.unlimitedHierarchy .subtle .nodeTitle:hover {
  text-decoration: underline;
}

.unlimitedHierarchy .permissions span {
  display: block;
  float: left;
  text-align: center;
  margin-right: 2px;
}

.unlimitedHierarchy .permissions span .enabled {
  cursor: pointer;
  cursor: hand;
}

.unlimitedHierarchy li div {
  background: #CCC;
  padding: 5px;
}

.unlimitedHierarchy li .nodeAsset {
  padding: 0 5px 4px 10px;
  font-weight: bold;
}

.unlimitedHierarchy span {
  vertical-align: top;
}

.unlimitedHierarchy .lastLi {
  background: url("https://app-assets1.sportngin.com/app_images/hierarchyAttachLast.gif") no-repeat 0 12px;
}

.cmsAccess .private {
  color: #CC0000;
  padding-right: 16px;
  background: url("https://app-assets1.sportngin.com/app_images/disabledPrivatePublicIcon.png") no-repeat right -116px;
}

.cmsAccess .disabled {
  padding-right: 16px;
  background: url("https://app-assets1.sportngin.com/app_images/disabledPrivatePublicIcon.png") no-repeat right 0;
}

.cmsAccess .public {
  padding-right: 16px;
  background: url("https://app-assets1.sportngin.com/app_images/disabledPrivatePublicIcon.png") no-repeat right -232px;
}

.unlimitedHierarchy .disabled, .unlimitedHierarchy .disabled a {
  color: #777;
  font-style: italic;
}

.cmsAccess .permissions .disabled {
  padding: 0;
  background: none;
}

.permissionsHeader {
  font-size: 14px;
  line-height: 140%;
  clear: both;
  margin: 10px 0 5px;
  background: none;
}

/* Collector Tree */
.collectorTree img {
  display: none;
}

.collectorTree .item .info {
  font-size: 12px;
  font-weight: normal;
}

.collectorTree h4 {
  font-size: 1em;
}

.collectorTree li h4 a {
  text-decoration: none;
}

.collectorTree a {
  font-weight: 600;
  font-size: 1em;
}

/* on white */
.unlimitedHierarchyWhite {
  background: url("https://app-assets1.sportngin.com/app_images/hierarchyLinesWhite.gif");
}

.unlimitedHierarchyWhite li div {
  background: #FFF;
}

.unlimitedHierarchyWhite .lastLi {
  background-image: url("https://app-assets1.sportngin.com/app_images/hierarchyAttachLastWhite.gif");
}

/* CMS tweaks */
.rapidContentGeneral .editPermissions li div {
  background: #fef7d0;
}

.rapidContentGeneral .editPermissions .unlimitedHierarchy {
  background-image: url("https://app-assets1.sportngin.com/app_images/hierarchyLinesCMS.gif");
}

.rapidContentGeneral .editPermissions .unlimitedHierarchy li {
  background-image: url("https://app-assets1.sportngin.com/app_images/hierarchyAttachCMS.gif");
}

.rapidContentGeneral .editPermissions .unlimitedHierarchy .lastLi {
  background-image: url("https://app-assets1.sportngin.com/app_images/hierarchyAttachLastCMS.gif");
}

#lightbox .nodePickerSlideBox {
  border: solid 1px #FCCD22;
  border-top: none;
  height: 300px;
  background: #FFF;
}

#lightbox .nodePickerSlideBox h3 {
  font-size: 10px;
  font-weight: normal;
}

#lightbox .unlimitedHierarchy li div {
  padding: 5px;
  background: #fef7d0;
}

#lightbox .unlimitedHierarchy {
  background: #fef7d0 url("https://app-assets1.sportngin.com/app_images/hierarchyLinesCMS.gif");
}

#lightbox .unlimitedHierarchy .lastLi {
  background-image: url("https://app-assets1.sportngin.com/app_images/hierarchyAttachLastCMS.gif");
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy li div {
  padding: 5px;
  background: #FFF;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy {
  background: #FFF url("https://app-assets1.sportngin.com/app_images/hierarchyLinesWhite.gif");
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy .lastLi {
  background-image: url("https://app-assets1.sportngin.com/app_images/hierarchyAttachLastWhite.gif");
}

.permissionsHelp {
  display: block;
  clear: both;
  background: no-repeat url("https://app-assets1.sportngin.com/app_images/permissionsHelp.png");
  text-indent: 43px;
  font-size: 11px;
  line-height: 16px;
  font-weight: normal;
  margin-bottom: 5px;
}

.pageEl .video .jwplayer {
  width: 100% !important;
  height: 100% !important;
}

.pageEl .jwplayer {
  width: 100% !important;
}

.contentTabs:not(.rapidTabsConstruction) {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
}

.pageEl.center .contentTabs:not(.rapidTabsConstruction) {
  justify-content: center;
}

.pageEl.fill .contentTabs:not(.rapidTabsConstruction) li {
  flex: 1 1;
}

@media screen and (max-width: 767px) {
  .contentTabs:not(.rapidTabsConstruction) li {
    flex: 1 1;
  }
}

.contentTabs:not(.rapidTabsConstruction) a {
  color: #333;
  background-color: white;
  border: 1px solid #DDDDDD;
  transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;
  display: block;
  text-align: center;
  white-space: nowrap;
  padding: 4px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin-right: 5px;
  border-radius: 5px;
}

.contentTabs:not(.rapidTabsConstruction) a:hover {
  background-color: #FF595A;
  color: white;
  border: 1px solid #FF595A;
}

.contentTabs:not(.rapidTabsConstruction) .selected a {
  background-color: #CF3338;
  color: white;
  border: 1px solid #CF3338;
}

.contentTabs:not(.rapidTabsConstruction) li:last-of-type {
  margin-right: 0px;
}

.rapidTabsConstruction {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  background-color: #FDDD40;
}

.rapidTabsConstruction li {
  border-right: 1px solid #DDA301;
}

.rapidTabsConstruction a {
  color: #444;
  background-color: transparent;
  transition: background-color 0.2s linear, color 0.2s linear;
  display: block;
  text-align: center;
  white-space: nowrap;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
}

.rapidTabsConstruction a:hover {
  background-color: #FDE872;
  color: #333;
}

.rapidTabsConstruction .selected a {
  background-color: #FDE872;
  color: #333;
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs {
  margin: 0;
}

.dataTable .disabled {
  color: #000;
  text-decoration: none;
}

.dataTable .disabled:hover {
  text-decoration: underline;
}

#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}

/*  Shared Styles
-------------------------------------------------------------------- */
#monthViewCalendar .vevent .noEntry,
#month_list .vevent .noEntry,
#dayViewCalendar .vevent .noEntry,
#event_show .vevent .noEntry {
  margin-top: initial;
  font-size: .75em;
  line-height: initial;
  border-top: initial;
}

#monthViewCalendar .caption,
#month_navigation h2,
#dayViewCalendar h2 {
  padding-top: 32px;
  padding-bottom: 15px;
  font-size: 36px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 1.35;
  font-weight: 700;
  text-align: center;
  color: #333;
}

@media only screen and (max-width: 640px) {
  #monthViewCalendar .caption,
  #month_navigation h2,
  #dayViewCalendar h2 {
    font-size: 28px;
  }
}

#monthViewCalendar .caption a,
#month_navigation h2 a,
#dayViewCalendar h2 a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-size: 0;
}

#monthViewCalendar .caption a:after,
#month_navigation h2 a:after,
#dayViewCalendar h2 a:after {
  font: normal normal normal 1.5rem/1 FontAwesome;
  vertical-align: bottom;
  color: #000000;
}

#monthViewCalendar .caption a:first-child:after,
#month_navigation h2 a:first-child:after,
#dayViewCalendar h2 a:first-child:after {
  content: "\f060";
  margin-right: 1vw;
}

#monthViewCalendar .caption a:last-child:after,
#month_navigation h2 a:last-child:after,
#dayViewCalendar h2 a:last-child:after {
  content: "\f061";
  margin-left: 1vw;
}

#month_navigation h2,
#dayViewCalendar h2 {
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

/*  Month View
-------------------------------------------------------------------- */
#monthViewCalendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}

#monthViewCalendar th,
#monthViewCalendar td {
  border: solid 2px #fff;
  padding: 4px;
  vertical-align: top;
  line-height: 1;
}

#monthViewCalendar th {
  text-align: center;
  background-color: #000000;
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  #monthViewCalendar th {
    font-size: 0;
  }
  #monthViewCalendar th:first-letter {
    font-size: initial;
  }
}

@media only screen and (max-width: 640px) {
  #monthViewCalendar th {
    display: none;
  }
}

#monthViewCalendar td {
  height: 75px;
  background-color: #eee;
}

@media only screen and (max-width: 640px) {
  #monthViewCalendar td {
    display: block;
    height: auto;
  }
}

#monthViewCalendar ul,
#monthViewCalendar li {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 1.4;
  text-align: left;
  font-weight: 400;
}

#monthViewCalendar .vevent {
  position: relative;
  overflow: hidden;
  margin: 10px 0;
  line-height: 1.2;
}

#monthViewCalendar .summary {
  font-size: inherit;
  margin-bottom: .25em;
}

#monthViewCalendar .description,
#monthViewCalendar .type {
  display: none;
}

#monthViewCalendar .tags {
  font-style: italic;
}

#monthViewCalendar .today {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #000000;
}

#monthViewCalendar .dateLink {
  display: block;
}

#monthViewCalendar .dateLink,
#monthViewCalendar .otherMonth {
  text-align: right;
  font-size: 16px;
  font-weight: bold;
}

#monthViewCalendar .otherMonth {
  color: #ddd;
}

/*  Day View
-------------------------------------------------------------------- */
#dayViewCalendarEvents .eventAggregatorElement .summary,
#dayViewCalendarEvents .eventAggregatorElement .details {
  padding-left: 0;
}

/*  Month List View
-------------------------------------------------------------------- */
#month_list .vevent .location,
#event_show .vevent .location {
  text-transform: none;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
}

#month_list .showMoreEvents,
#event_show .showMoreEvents {
  background: #eee;
  border-top: 1px solid #ccc;
  padding: 5px 5px 5px 40px;
  text-align: left;
}

/*  Event Show
-------------------------------------------------------------------- */
#event_show + .eventTags {
  margin-top: -25px;
}

#event_show ~ .eventTags {
  font-size: 12px;
  padding-left: 95px;
}

@media only screen and (max-width: 360px) {
  #event_show ~ .eventTags {
    padding-left: 0;
  }
}

#event_show ~ .eventContentNode {
  border-style: solid;
  border-width: 0;
  border-top-color: #EDF0F1;
  border-top-width: 2px !important;
}

#event_show ~ .eventContentNode ~ .eventTags {
  padding-top: 15px;
  border-style: solid;
  border-width: 0;
  border-top-color: #EDF0F1;
  border-top-width: 2px !important;
}

/*  Dib Item
-------------------------------------------------------------------- */
.dibItem .location,
.dibItem .tags,
.dibItem .type {
  font-size: 11px;
  color: #666;
}

.dibItem .time {
  font-weight: bold;
}

.dibItem .vevent li {
  display: block;
  list-style-type: none;
  margin: 0;
}

/*  iCal Events
-------------------------------------------------------------------- */
.ical_icon {
  float: right;
  margin: 0.5em 0 0 0.3em;
  position: relative;
  top: -4px;
}

@media only screen and (max-width: 640px) {
  #monthViewCalendar .ical_icon {
    float: none;
    margin: 0 .3em 0 0;
    position: static;
  }
}

.ics_file_download,
.aggregatorElement a .ics_file_download {
  float: none;
  width: auto;
  border: none;
}

/*  Show More Link
-------------------------------------------------------------------- */
.showMoreEvents small {
  float: right;
}

/* Dib Aggregator */
.aggregatorElement.dibAggregatorElement .vevent .dateImage,
.aggregatorElement.dibAggregatorElement .vevent .dateImage img {
  background: none;
  border: none;
}

.dibAggregatorElement .vevent {
  border-top: 2px solid #ddd;
  padding: 26px 0px;
}

.dibAggregatorElement .vevent:first-of-type {
  border-top: none;
}

.dibAggregatorElement .dateImage {
  width: 75px;
  float: left;
  margin-right: 10px;
  list-style-type: none;
}

.dibAggregatorElement .summary,
.dibAggregatorElement .details {
  padding-left: 85px;
}

.dibAggregatorElement .details li {
  list-style: none;
}

.dibAggregatorElement .date {
  display: inline;
}

.dibAggregatorElement .time {
  font-size: .75em;
  font-weight: 600;
  color: #666;
}

.dibAggregatorElement .credits {
  color: #999;
  float: right;
  font-size: 18px;
  letter-spacing: -1px;
  padding: 10px 0;
  text-align: center;
  width: 40px;
  text-transform: uppercase;
  margin: 0 0 10px 0;
  line-height: 10px;
}

.dibAggregatorElement .credits span {
  font-size: 9px;
}

/* Dib Session */
.dibSession {
  padding: 1em;
  background: #EFEFEF;
  margin: .5em 0 1em 0;
  position: relative;
}

.dibSession:before {
  content: '';
  position: absolute;
  background: url("/app_images/icon-dibs-hero-light.svg") center center no-repeat;
  background-size: contain;
  padding: 1em;
  top: 50%;
  transform: translateY(-50%);
}

.dibSession h3 {
  display: inline-block;
  font-size: 1.125em;
  margin: 0 0 0 2.5rem;
}

.dibSession > ul {
  margin: 0 0 0 2.5rem;
  line-height: 1.5;
  font-weight: 400;
}

.dibSession .creditStatus {
  font-size: .75em;
  color: #666;
}

.dibSession .creditStatus strong {
  text-decoration: underline;
}

.dibSession .description {
  clear: both;
}

.dibSession li {
  display: block;
  list-style-type: none;
  margin: 0;
}

.dibSession li p {
  margin: 0;
}

#tab_dib_items_user_content {
  padding-top: .75em;
  clear: both;
}

/* Dib Table */
#dib_items_table {
  overflow-x: scroll;
}

#dib_items_table input[type="checkbox"] {
  margin: 0;
}

#dib_items_table .ajax_sort > img {
  display: none;
}

#dib_items_table td {
  white-space: nowrap;
}

.user_mode #dib_items_table .ajax_sort:first-child {
  text-align: left;
}

.user_mode #dib_items_table td:first-child {
  vertical-align: middle;
}

.user_mode #dib_items_table td:first-child strong {
  text-align: left;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
}

.edit_mode #dib_items_table .ajax_sort:nth-child(2) {
  text-align: left;
}

.edit_mode #dib_items_table td:nth-child(2) {
  vertical-align: middle;
}

.edit_mode #dib_items_table td:nth-child(2) strong {
  text-align: left;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
}

#dib_items_div .actionLinks {
  height: auto;
  margin: 1em 0;
  font-size: 0.875em;
}

#dib_items_div .actionLinks a {
  color: #333;
}

#dib_items_div .actionLinks span {
  float: none;
}

#dib_items_div .actionLinks .linkSet1 {
  margin-right: 1em;
}

#dib_items_div .actionLinks .linkSet2 .icon {
  display: inline-block;
  margin-left: 0;
  margin-right: 1em;
}

#dib_items_div form#dib_item_form {
  overflow-x: scroll;
  position: relative;
  clear: both;
}

/* Dib Item */
.dibItem .vevent {
  width: 60%;
  float: left;
}

.dibItem .vevent ul {
  margin-bottom: 10px;
}

.dibItem .summary {
  font-size: 1.5em;
  padding: 0;
  margin-left: 1.25em;
  position: relative;
}

.dibItem .summary:before {
  content: '';
  position: absolute;
  background: url("/app_images/icon-dibs-hero-light.svg") center center no-repeat;
  background-size: contain;
  padding: .75em;
  top: 50%;
  left: -1.5em;
  transform: translateY(-50%);
}

.dibItem .details {
  width: 30%;
  float: right;
  border: solid 1px #ccc;
  border-bottom: none;
  background: #EFEFEF;
  list-style-type: none;
}

.dibItem .details li {
  display: block;
  padding: 10px;
  border-bottom: solid 1px #ccc;
}

.dibItem .details label {
  font-weight: bold;
}

/* Dibs Calendar Mods */
#tab_dib_item_month_content #monthViewCalendar .today {
  background: #EEE;
}

#tab_dib_item_month_content #monthViewCalendar .today a {
  color: #000000;
}

/* Dib Layout Mods */
#dibsTool #pageContentContainer #displayBodyContent {
  background: url(<%= asset_path_for("/app_images/dibs_header.jpg") %>) repeat-x top center;
  padding-top: 100px;
}

#dibsTool #panelOne {
  display: none;
}

#dibsTool #siteContainer2 #panelTwo {
  width: 100%;
}

#dibsTool #siteContainer2 #displayBodyHeader {
  display: none;
}

/* Dib Item Filter */
.dibsFilter {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 15px;
}

.dibsFilter:before {
  display: block;
}

.dibsFilter label {
  display: inline-block;
  font-size: 12px;
  font-weight: 900;
}

.dibsFilter input {
  font-size: 12px;
  font-family: inherit;
}

.dibsFilter input[type="checkbox"] {
  margin: 0 3px 0 0;
  width: auto;
}

.dibsFilter input[type="text"] {
  border: 1px solid #ddd;
  margin-bottom: -4px;
  padding: 3px 5px;
  transition: border-color .1s ease;
}

.dibsFilter input[type="text"]:hover {
  border-color: #666;
}

.dibsFilter input[type="text"]:focus {
  border-color: #000000;
  outline: 0;
}

.dibsFilter select {
  border: 1px solid #ddd;
  padding-right: 4px;
  max-width: 130px;
  white-space: nowrap;
}

.dibsFilter small {
  font-size: 12px;
}

.dibsFilter .filter-column {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 20px 7px 0;
}

.dibsFilter .filter-column:nth-last-child(2) {
  padding-right: 0;
}

.dibsFilter .filter-button {
  width: 100%;
}

.dibsFilter .filter-dates label {
  min-width: 3em;
}

.dibsFilter #credits {
  margin-right: 3px;
  max-width: 4em;
}

@media (max-width: 880px) {
  .dibsFilter .filter-column {
    -webkit-flex-basis: 33.3%;
    -ms-flex-preferred-size: 33.3%;
    flex-basis: 33.3%;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .dibsFilter .filter-button {
    padding-top: 5px;
  }
}

@media (max-width: 480px) {
  .dibsFilter .filter-column {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

/* Profile Tokens
-------------------------------------------------------------------- */
/* Tokens and MiniTokens */
.profileToken, .profileMiniToken {
  background: #444;
}

.pageElementAdmin .profileToken {
  margin-top: -1px;
}

.profileToken img, .profileMiniToken img, #profileAvatarDisplay img {
  _margin-bottom: -2px;
}

.profileMiniToken {
  height: auto;
  padding: 8px;
  margin-bottom: 10px;
}

.profileToken .image, .profileMiniToken .image {
  position: relative;
  float: left;
  margin: 0 10px 0 5px;
  width: 30px;
  height: 30px;
  border: 2px solid #DDD;
  overflow: hidden;
  border-radius: 50%;
}

.profileToken .image img, .profileMiniToken .image img {
  display: block;
  top: -1%;
  left: -1%;
  width: 103%;
}

.profileToken .info {
  height: 70px;
  float: left;
  list-style-type: none;
}

.subPanelA .profileToken .info .profileName {
  width: 200px;
}

.profileMiniToken .info {
  list-style-type: none;
  text-align: right;
}

.profileToken .info li {
  display: block;
}

.info .profileName, .info .profileName a {
  line-height: 120%;
  font-weight: bold;
  margin-top: 2px;
}

.profileToken .info .profileName {
  font-size: 20px;
  color: #FFF;
}

.profileMiniToken .info .profileName {
  font-size: 1em;
  line-height: 30px;
  float: left;
  margin-top: 0;
  color: #FFF;
}

.profileMiniToken .info .profileName a {
  color: #FFF;
}

.profileToken .info .profileName .type, .miniPersona .type {
  font-size: 11px;
  font-weight: normal;
  color: #666;
}

.info .profileName a:hover {
  text-decoration: underline;
}

.profileToken .info .networkLabel, .profileMiniToken .info .networkLabel {
  color: #CCC;
  font-size: 10px;
  line-height: 120%;
  font-weight: bold;
}

.profileToken .info .networkLabel a {
  color: #FFF;
}

.profileMiniToken .info .networkBadges {
  float: right;
  margin: 5px 5px 0 0;
}

.info .networkBadges img {
  padding: 1px;
  background: #666;
}

.profileToken .tokenActions {
  background: url("https://app-assets1.sportngin.com/app_images/profile_token_actions.jpg") no-repeat top right;
  width: 100%;
  height: 100%;
}

.profileMiniToken .info .tokenActions {
  display: inline-block;
  padding: 1px 5px;
}

.profileMiniToken .tokenActions a {
  color: #fff;
  font-size: 0.8em;
  padding-left: 22px;
}

.profileToken .tokenHierarchy {
  background: url("https://app-assets1.sportngin.com/app_images/profile_token_hierarchy.jpg") no-repeat 400px 0;
  width: 100%;
  height: 100%;
}

.profileToken .actions {
  float: right;
  text-align: right;
  list-style-type: none;
  padding: 3px 3px 0 0;
  color: #CCC;
}

.profileToken .actions a {
  color: #CCC;
}

.profileToken .actions a:hover {
  color: #FFF;
}

#survey_steps .profileToken .actions {
  color: #666;
}

.profileTokenAction {
  text-align: center;
}

.profileTokenAction a {
  display: block;
  font-size: 14px;
  margin-top: 25px;
}

#new_full_name {
  display: block;
  text-align: left;
  margin-left: 66px;
}

@media only screen and (max-width: 900px) {
  .profileMiniToken .info {
    text-align: left;
  }
  .profileMiniToken .info .tokenActions {
    width: 100%;
    padding: 10px 5px;
  }
}

/* Owner */
.profileMiniToken .owner .profileName {
  padding-right: 17px;
  margin-right: 7px;
  background: url("https://app-assets1.sportngin.com/app_images/mini_token_hierarchy.jpg") no-repeat top right;
}

.profileMiniToken .owner .image {
  margin: 0;
}

.profileMiniToken .owner img {
  width: 20px;
  height: 20px;
  margin: 5px 7px 5px 5px;
}

.profileMiniToken .owner .profileName {
  line-height: 25px;
  height: 30px;
}

.profileMiniToken .owner .profileName a {
  color: #CCC;
  font-size: 12px;
}

/* Master Token */
.masterProfile .masterProfileToken {
  border: none;
}

/* Sub Tokens */
.subProfileToken {
  background: #b2b2b2 url("https://app-assets1.sportngin.com/app_images/profile_token_back_sub.jpg") repeat-x;
  border: solid 2px #BABABA;
  overflow: hidden;
}

.subProfileToken .image img, .profileToken .image img {
  display: inline-block;
  width: 70px;
  height: 70px;
}

.subProfileToken .tokenActions {
  background: url("https://app-assets1.sportngin.com/app_images/profile_token_actions_sub.jpg") no-repeat top right;
}

.subProfileToken .info .profileName, .subProfileToken .info .profileName a {
  font-size: 14px;
}

.subProfileToken .info .profileName {
  color: #000;
}

.subProfileToken .actions {
  font-size: 11px;
}

.subProfileToken .actions a {
  color: #666;
}

.subProfileToken .actions a:hover {
  color: #333;
}

.subProfileToken .info .networkLabel {
  color: #666;
}

.subProfileToken .info {
  width: 50%;
}

.subProfileToken .info .networkBadges {
  margin: 3px 0 0;
}

.subProfileToken .info .networkBadges img {
  border-color: #666;
}

.transferOptions {
  margin: -2px 0 2px;
  padding: 2px 0 5px;
  text-align: center;
  background: #CCC;
}

/* miniPersona
-------------------------------------------------------------------- */
.miniPersona {
  border: solid 1px #CCC;
  margin-bottom: 2px;
  background: #DDD url("https://app-assets1.sportngin.com/app_images/user/subtle_gradient_1.png") repeat-x top left;
  border-radius: 2px;
}

.miniPersona.selected {
  border-color: #999;
  background-color: #666;
}

.miniPersonaForm {
  clear: both;
  background: #f2f2f2;
  border: 1px solid #CCC;
  padding: 10px 20px;
  position: relative;
  top: -4px;
  border-radius: 0 0 4px 4px;
}

.miniPersonaForm .formElement {
  background-color: transparent !important;
}

.miniPersona.selected + .miniPersonaForm {
  border-color: #999;
}

.miniPersona input {
  margin: 8px 10px 0 8px;
  _margin: 5px 10px 0 8px;
  float: left;
}

.miniPersona .toggle_membership {
  margin: 5px 5px 0 0;
  float: right;
}

.miniPersona label {
  display: block;
  line-height: 13px;
}

.miniPersona .profilePic {
  float: left;
}

.miniPersona img {
  width: 30px;
  height: 30px;
}

.miniPersona .name {
  font-weight: bold;
  font-size: 13px;
  line-height: 30px;
  margin-left: 5px;
}

.miniPersona .name a {
  color: #900;
  float: right;
  padding-right: 10px;
}

.miniPersona .name a:hover {
  color: #f00;
}

.miniPersona.pending {
  background: #EFEFEF;
  border-color: #CCC;
}

.miniPersona.owner .name {
  line-height: 110%;
}

.miniPersona.pending .name {
  font-size: 11px;
  line-height: 150%;
  padding-top: 2px;
}

.miniPersona em {
  margin-left: 5px;
  color: #666;
  font-style: italic;
  line-height: 120%;
}

.miniPersona .required em {
  font-style: normal;
  font-weight: bold;
  color: #ff0000;
}

.miniPersona ul {
  list-style-type: none;
}

.miniPersona .name input {
  margin: 0;
  float: none;
}

.resultsTable .miniPersona {
  margin-bottom: 0;
}

.dataTable .miniPersona {
  border: none;
  border-bottom: solid 2px #FFF;
}

.dataTable .miniPersona td {
  padding: 2px;
  border: none;
  vertical-align: top;
}

.dataTable .miniPersona .moreInfo {
  width: 50%;
  text-align: right;
  vertical-align: middle;
  padding: 3px 5px 3px 2px;
}

/* New Communication Email
-------------------------------------------------------------------- */
.newCommunicationEmail {
  font-size: 13px;
  padding-bottom: 5px;
}

/* Member Directory View
--------------------------------------------------------------------- */
.profileToken .adminOptions {
  float: right;
  text-align: right;
  padding: 8px;
}

.profileToken .adminOptions a {
  position: relative;
  top: -2px;
}

.profileToken .adminOptions .assume_user {
  margin-left: 8px;
}

/* Member Status */
.profilePicker .status a {
  position: relative;
  top: -2px;
}

.profilePicker .status a.help img {
  float: right;
}

.profilePicker .status {
  text-align: center;
  background: #999 url("https://app-assets1.sportngin.com/app_images/user/button_bg.png") left center;
  border: 1px solid #999;
  padding: 7px 6px 2px;
  color: #FFF;
  font-size: 16px;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.profilePicker .help img {
  vertical-align: middle;
  background: #333;
  background: rgba(0, 0, 0, 0.3);
  padding: 7px 7px 6px 5px;
  margin: -7px -6px -7px 1px;
  border-radius: 0 9px 9px 0;
}

.profilePicker .status.active {
  background-color: #090;
}

.profilePicker .status.blacklisted {
  background-color: #900;
}

.profilePicker .status.active-incomplete {
  background-color: #696;
}

.profilePicker .status.approval-needed {
  background-color: #970;
}

.profilePicker .status.processing {
  background-image: url("https://app-assets1.sportngin.com/app_images/processing_bg.gif");
}

/* Pagination
-------------------------------------------------------------------- */
.paginationElement, .directorySearchElement, .filterElement {
  background: #CCC url("https://app-assets1.sportngin.com/app_images/pageOptionsBody.jpg") repeat-x;
  padding: 5px 0;
  border-bottom: solid 1px #CCCCCC;
}

.paginationElement, .filterElement {
  background-position: 0 -80px;
}

.filterElement {
  padding: 5px 15px;
}

.paginationElement h4 {
  float: left;
  padding-left: 10px;
}

.paginationNav {
  float: right;
  padding-right: 10px;
}

.paginationNav .current, .paginationNav em {
  padding: 0 3px 1px 3px;
}

.paginationNav .disabled {
  display: none;
}

.paginationElement .results {
  clear: both;
  margin-left: 10px;
  padding-top: 2px;
}

/* Tool Pagination
--------------------------------------------*/
.tool_pagination {
  cursor: default;
  padding: 10px;
  border-top: 1px solid #DDD;
  line-height: 11px;
  font-size: 11px;
  color: #999;
  text-align: right;
  white-space: nowrap;
}

.tool_pagination a,
.tool_pagination span,
.tool_pagination em {
  text-decoration: none;
  font-weight: bold;
  color: #999;
  border: 1px solid transparent;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 0;
  width: 21px;
  text-align: center;
  border-radius: 4px;
}

.tool_pagination a:hover {
  color: #333;
  background: #EEE;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 50%, rgba(0, 0, 0, 0.1) 51%, rgba(0, 0, 0, 0) 100%);
  border-color: #999;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
}

.tool_pagination em {
  font-style: normal;
  background: #DDD;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  border-color: #BBB;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
}

.tool_pagination .previous_page,
.tool_pagination .next_page {
  padding: 5px 10px;
  width: auto;
}

/* Pulldown Menu
-------------------------------------------------------------------- */
.pulldownSelectMenu {
  padding: 15px 10px 10px;
  font-size: 0.875em;
  text-align: right;
}

.rapidContentConstruction .pulldownSelectMenu {
  padding: 5px 0px;
  background: none;
}

.pulldownSelectMenu span {
  float: left;
  text-align: left;
}

.pulldownSelectMenu span strong {
  font-weight: 500;
}

/* Node Picker
-------------------------------------------------------------------- */
.nodePickerSlideBox {
  clear: both;
  height: 480px;
  font-size: .875em;
  line-height: 1.5em;
  overflow: auto;
  background: #DFE4E6;
  padding: 1em;
  white-space: nowrap;
  border-bottom: 1em solid #DFE4E6;
  border-top: 1px solid #CCCCCC;
  position: relative;
}

#lightbox .nodePickerSlideBox {
  border: none;
  border-bottom: 1em solid #EEE;
  border-top: 1px solid #AAA;
  background: #EEE;
}

.nodePickerSiteBox {
  padding-bottom: 1em;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy,
.nodePickerSlideBox .unlimitedHierarchy {
  background: none;
  margin: 0;
  width: auto;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy ul,
.nodePickerSlideBox .unlimitedHierarchy ul {
  margin-left: .75em;
  border-left: 1px solid #AAA;
  padding-bottom: 1em;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy li,
.nodePickerSlideBox .unlimitedHierarchy li {
  display: block;
  padding: 0 0 0 5px;
  clear: both;
  list-style: none;
  background: none;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy li li,
.nodePickerSlideBox .unlimitedHierarchy li li {
  margin-left: 2em;
  padding: 0;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy li div,
.nodePickerSlideBox .unlimitedHierarchy li div {
  padding: 5px;
  background: none;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy li input[type=checkbox],
.nodePickerSlideBox .unlimitedHierarchy li input[type=checkbox] {
  margin: 0 .5em 0 0;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy .lastLi,
.nodePickerSlideBox .unlimitedHierarchy .lastLi {
  background: none;
}

#lightbox .nodePickerSlideBox .unlimitedHierarchy .lastLi > ul,
.nodePickerSlideBox .unlimitedHierarchy .lastLi > ul {
  padding-bottom: 0;
  margin-bottom: 1em;
}

.nodePicker {
  padding: 10px 10px 0 25px;
}

.nodePicker ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 30px;
}

.nodePicker li {
  display: block;
  list-style-type: none;
  border-top: 1px solid #FFF;
  line-height: 18px;
  font-size: 11px;
  font-weight: bold;
  color: #666;
  clear: both;
  _height: 18px;
  /* IE 6 hack */
}

.accessTree li {
  color: #333;
  border-top: 1px solid #FCCD22;
  padding: 2px 0;
}

.nodePicker a:link, .nodePicker a:visited {
  font-size: 10px;
  text-decoration: none;
}

.nodePicker a:hover {
  text-decoration: underline;
}

.nodePicker label {
  font-weight: bold;
  margin: 0 3px;
}

.nodePicker .selectLink {
  float: left;
}

.nodePickerContainer .tab,
#nodePickerContainer_news_aggregator_lb .tab {
  position: relative;
  width: auto;
  margin: 0px 1em -1em 1em;
  float: right;
  transform: translateY(-200%);
  line-height: 1;
}

.nodePickerContainer .tab a,
#nodePickerContainer_news_aggregator_lb .tab a {
  color: #FFF;
  background: #2F373B;
  font-size: 11px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  padding: .5em .8em;
  margin: 0;
  border-radius: 4px;
  display: inline-block;
  transition: .2s 0s ease-in-out;
}

.nodePickerContainer .tab a.upArrow:after,
#nodePickerContainer_news_aggregator_lb .tab a.upArrow:after {
  content: "\f077";
}

.nodePickerContainer .tab a.downArrow:after,
#nodePickerContainer_news_aggregator_lb .tab a.downArrow:after {
  content: "\f078";
}

.nodePickerContainer .tab a:after,
#nodePickerContainer_news_aggregator_lb .tab a:after {
  font-family: 'Fontawesome';
  display: inline-block;
  position: relative;
  margin-left: .5em;
}

.nodePickerContainer .tab a:hover,
#nodePickerContainer_news_aggregator_lb .tab a:hover {
  color: #DDD;
}

#main_calendar_form #tabShow_main {
  margin: 0 1em;
  transform: translateY(0);
  padding: 0;
  width: auto;
  background: transparent;
}

#main_calendar_form .nodePickerContainer {
  margin: 15px 0;
  float: none;
}

.nodePickerContainer:after {
  content: " ";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1025px) {
  .col-md-3 .nodePickerContainer .tab,
  .col-md-3 #nodePickerContainer_news_aggregator_lb .tab, .col-md-4 .nodePickerContainer .tab,
  .col-md-4 #nodePickerContainer_news_aggregator_lb .tab, .pe25 .nodePickerContainer .tab,
  .pe25 #nodePickerContainer_news_aggregator_lb .tab, .pe33 .nodePickerContainer .tab,
  .pe33 #nodePickerContainer_news_aggregator_lb .tab {
    transform: translateY(0px);
    margin: -.75em 0 0 0;
    padding: 0px 1em 1em 1em;
    width: 100%;
    text-align: left;
    background: #EEE;
  }
  .col-md-3 .nodePickerContainer .tab#tabHide_main,
  .col-md-3 #nodePickerContainer_news_aggregator_lb .tab#tabHide_main, .col-md-4 .nodePickerContainer .tab#tabHide_main,
  .col-md-4 #nodePickerContainer_news_aggregator_lb .tab#tabHide_main, .pe25 .nodePickerContainer .tab#tabHide_main,
  .pe25 #nodePickerContainer_news_aggregator_lb .tab#tabHide_main, .pe33 .nodePickerContainer .tab#tabHide_main,
  .pe33 #nodePickerContainer_news_aggregator_lb .tab#tabHide_main {
    margin: 0px;
  }
}

@media only screen and (max-width: 380px) {
  .nodePickerContainer .tab,
  #nodePickerContainer_news_aggregator_lb .tab {
    transform: translateY(0px);
    margin: -.75em 0 0 0;
    padding: 0px 1em 1em 1em;
    width: 100%;
    text-align: left;
    background: #EEE;
  }
  .nodePickerContainer .tab#tabHide_main,
  #nodePickerContainer_news_aggregator_lb .tab#tabHide_main {
    margin: 0px;
  }
  #main_calendar_form .tab#tabHide_main {
    text-align: right;
  }
}

/* Node Picker Form Controls
-------------------------------------------------------------------- */
.loading {
  float: right;
  margin: 0 4px 0 0;
  text-align: right;
  width: 16px;
}

.nodePicker-formControls {
  margin: -1.85em 0 0 0;
}

.nodePicker-button {
  background: #2F373B;
  font-size: 11px;
  font-weight: 400;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding: .5em .8em;
  margin: 0 .5em .45em 0;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  color: #FFF;
  transition: .2s 0s ease-in-out;
}

.nodePicker-button:hover {
  text-decoration: none;
  color: #DDD;
}

#form_values_match_option {
  float: right;
  margin: -4px 0 0 0;
}

/* Single Select Picker
-------------------------------------------------------------------- */
.singleSelectBodyHeader {
  text-align: center;
  padding: 1px 10px;
}

.singleSelectBodyHeader a {
  font-size: 16px;
  font-weight: bold;
  line-height: 120%;
}

.singleSelectBodyHeader span {
  text-indent: -2000px;
}

.singleSelectBodyHeader .disabled {
  color: #666;
}

.previousSingleSelect {
  float: left;
  width: 5%;
}

.singleSelectBodyHeader #displayBodyHeader {
  float: left;
  margin: 0;
  padding: 0;
  width: 89.5%;
  display: block;
  border-left: solid 1px #FFF;
  border-right: solid 1px #FFF;
  background-position: -10px -2px;
}

.singleSelectBodyHeader #displayBodyHeader a {
  padding: 5px 0;
  display: block;
  text-decoration: none;
}

.nextSingleSelect {
  float: right;
  width: 5%;
}

.npSingleSelect {
  z-index: 200;
  position: absolute;
  left: 168px;
}

.pagePush {
  position: relative;
  z-index: 0;
  left: 0;
}

#siteContainer3 .npSingleSelect {
  width: 592px;
}

#siteContainer2 .npSingleSelect {
  width: 762px;
}

#siteContainer3 .pageElementAdmin .npSingleSelect, #siteContainer2 .pageElementAdmin .npSingleSelect {
  width: auto;
}

.npSingleSelect .nodePickerSlideBox {
  padding-bottom: 10px;
  border-bottom: solid 1px #666;
}

/* Selected Nodes
-------------------------------------------------------------------- */
.selectedNodes {
  min-height: 6em;
  padding: 1em;
  background-color: #EEE;
}

.selectedNodes h3 {
  font-size: 12px;
  padding: 0;
  margin: 0;
  color: #2F373B;
  line-height: 12px;
  padding: .5em 0;
}

.selectedNodeChoices_main, .selectedNodeChoices_lb {
  margin: 1em 0 3em 0;
}

.pageElementAdmin .selectedNodes {
  border-top: none;
}

#focusContainer #selectedNodeChoices {
  padding-left: 25px;
  margin-top: 0;
}

body .selectedNodes p,
#lightboxBody .selectedNodes p {
  color: #666;
  line-height: 20px;
  margin: 0;
  font-style: italic;
}

.nodePicker-preselectedTags {
  display: none;
}

.tag {
  display: inline-block;
  font-size: 12px;
  color: #2F373B;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #DFE4E6;
  transition: color 0.2s linear, background-color 0.2s linear;
}

.tag:hover {
  color: #ffffff;
  background-color: #FF595A;
}

/* Tags & Tag Groups
-------------------------------------------------------------------- */
dl.tagGroup {
  border-bottom: 1px dotted #f2f2f2;
  line-height: 1.75em;
  margin: 0;
  padding: 5px;
}

dl.tagGroup dt {
  display: block;
  float: left;
  margin: 2px 5px 0 0;
  text-align: center;
  width: 15px;
}

dl.tagGroup dd {
  margin: 0 0 0 20px;
}

#lightboxBody dl.tagGroup {
  border-bottom: 1px dotted #fde686;
}

#lightboxBody dl.tagGroup .tag {
  background: #fcf0ab;
  background-image: linear-gradient(#fcf0ab, #fde686);
  border: 1px solid #fde686;
  border-radius: 3px;
}

#lightboxBody .listItem {
  margin: 30px;
  padding: 20px;
  background: #FFF;
  border: 1px solid #999;
}

#lightboxBody .listItem h2 {
  margin: 0 0 0.5em;
}

/* Cross-Site Node Picker
-------------------------------------------------------------------- */
.nodePicker-site {
  border-bottom: 1px dotted #999;
  margin: 10px 0 0;
  padding: 0 0 10px;
}

.nodePicker-site:last-child {
  border: none;
}

.selectedNodes .nodePicker-site-name {
  line-height: 16px;
  margin: 0 0 5px;
}

.selectedNodes .nodePicker-site-name a {
  color: #333;
  float: none;
  font-weight: bold;
  font-size: 12px;
}

.selectedNodes .nodePicker-site-name img {
  display: block;
  float: left;
  margin: 0 4px 0 0;
  height: 16px;
  width: 16px;
}

.nodePicker-crossSite {
  background: #e5e5e5;
  padding: 5px 10px;
}

/* Lobar styles */
.lobar-container {
  position: fixed;
  z-index: 498;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  background: #13759b;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6);
  transform: translateY(0);
  transition: transform 100ms linear, opacity 100ms linear, visibility 100ms linear;
}

.lobar-container.lobar-exit {
  transform: translateY(-25%);
  opacity: 0;
  visibility: hidden;
}

.page-manager-visible .lobar-container {
  left: 60px;
}

.lobar-content {
  background: url("https://app-assets1.sportngin.com/app_images/marketing/emblem-lobar.png") no-repeat center left;
  background-size: 20px;
  background-position-x: 10px;
  max-width: 1000px;
  padding: .5em;
  margin: 0 auto;
  position: relative;
}

.lobar-content p {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 2.15rem;
  text-align: center;
  font: 400 14px/100% 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #fff;
  line-height: 1.6;
}

.lobar-content p a,
.lobar-content p a:link,
.lobar-content p a:hover {
  display: inline-block;
  position: relative;
  top: -1px;
  height: 23px;
  line-height: 24px;
  margin: 0 0 0 15px;
  padding: 0 7px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 2px;
  transition: all 350ms ease;
}

.lobar-link,
.lobar-link:link,
.lobar-link:visited {
  background: #13aa37;
  color: #fff;
}

.lobar-link:hover {
  color: #ccc;
  background: #0e852b;
}

.lobar-close,
.payment-lobar-close {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 30px;
  height: 36px;
  margin: 0;
  transform: translateY(-50%);
  background: url("https://app-assets1.sportngin.com/app_images/marketing/icon-close.png") no-repeat center center;
}

/* Payment Status Lobar styles */
.payment-lobar .lobar-content {
  background: url("https://app-assets1.sportngin.com/app_images/marketing/emblem-lobar.png") no-repeat 16px center;
  background-size: 20px;
}

.payment-invoices-button {
  color: #fff;
  font-size: 9px;
  line-height: 24px;
  height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 0 0 15px;
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
  border-radius: 4px;
  margin-top: -5px;
}

.payment-invoices-button:hover {
  color: #ccc;
}

.lobar-green {
  color: #fff;
  background-color: #13aa37;
}

.lobar-yellow {
  color: #22272b;
  background-color: #fccd41;
  border-color: #8f7834;
}

.lobar-red {
  color: #fff;
  background-color: #ca1c1b;
  border-color: #761b25;
}

.lobar-green .payment-invoices-button {
  background-color: #0a601f;
}

.lobar-yellow .payment-invoices-button {
  background-color: #86702b;
}

.lobar-red .payment-invoices-button {
  background-color: #6d1718;
}

/* - - - - - - - - - - - - - - - - - - - - -

Title : Lightbox CSS
Author : Kevin Hale
URL : http://particletree.com/features/lightbox-gone-wild/
Created : January 13, 2006
Modified : January 18, 2008 (TST Version)

- - - - - - - - - - - - - - - - - - - - - */
/*
#lightbox{
  display:none;
  position: absolute;
  top:50%;
  left:50%;
  z-index:9999;
  width:700px;
  margin:-220px 0 0 -350px;
  text-align:left;
}
*/
#lightbox {
  z-index: 9999;
  display: none;
}

#lbLoadMessage {
  text-align: center;
}

#lightbox[id] {
  position: fixed;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: #000;
}

#overlay[id] {
  position: fixed;
}

#overlay.loading {
  background: #000 url("https://app-assets1.sportngin.com/app_images/loader2.gif") 50% 50% no-repeat;
  color: #fff;
}

#overlay.loading p {
  padding: 45% 0;
  text-align: center;
}

#lightbox.done #lbLoadMessage {
  display: none;
}

#lightbox.done #lbContent {
  display: block;
}

#lightbox.loading #lbContent {
  display: none;
}

#lightbox.loading #lbLoadMessage {
  display: block;
}

/*  NGIN Lightbox CSS
-------------------------------------------------------------------- */
#lightboxHeader {
  height: 20px;
  border-bottom: solid 1px #DDA301;
}

#lightboxHeader h1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 90%;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  color: #333;
  background-image: url("https://app-assets1.sportngin.com/app_images/lbTopLeft.gif");
  background-repeat: no-repeat;
}

#lightboxHeader a {
  position: absolute;
  right: 0;
  top: 0;
  width: 10%;
  font-size: 11px;
  line-height: 20px;
  border-left: solid 1px #DDA301;
  text-align: center;
  text-decoration: none;
  color: #333;
  background-image: url("https://app-assets1.sportngin.com/app_images/lbTopRight.gif");
  background-position: top right;
  background-repeat: no-repeat;
}

#lightboxHeader a:hover {
  color: #000;
}

#lightboxPreview {
  max-height: 125px;
  background: #FFFFD7;
  border-bottom: solid 1px #DDA301;
  padding: 10px;
  overflow: auto;
}

.elementMenuOption {
  text-align: left;
}

#lightboxBody {
  padding: 0 10px 10px;
  background: #fef7d0;
  color: #333;
  display: block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.submitLightbox {
  border: solid 1px #fccd22;
  background-color: #feec8f;
  clear: both;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  line-height: 140%;
}

.submitLightbox .embedded_link {
  font-size: 80%;
  margin-top: .3em;
}

.lbHighlight {
  border: solid 1px #feec8f;
  background-color: #fdf4bc;
  border-radius: 4px;
}

#lightboxBody .elementMenuOption {
  border-bottom: solid 1px #fccd22;
}

#lightbox #waiting_text, #lightbox .waiting_text {
  color: #DDA301;
}

#lightbox .helperLightbox #waiting_text {
  color: #666;
}

#lightboxSubmit .previous {
  float: left;
}

#lightboxSubmit .next {
  float: right;
}

#lightboxFooter {
  position: absolute;
  height: 14px;
  border-top: solid 1px #DDA301;
  background-image: url("https://app-assets1.sportngin.com/app_images/lbBottomRight.gif");
  background-position: bottom right;
  background-repeat: no-repeat;
}

#lightboxFooter #lightboxFeedback {
  position: relative;
  width: 95%;
  font-size: 10px;
  height: 13px;
  background-image: url("https://app-assets1.sportngin.com/app_images/lbBottomLeft.gif");
  background-position: bottom left;
  background-repeat: no-repeat;
}

.helperLightbox #lightboxBody .formElement {
  background: #e0e0e0;
}

/*  Recurring Events Display
-------------------------------------------------------------------- */
#human_readable_recur_string {
  background: #feec8f;
  padding: 5px;
  font-style: italic;
  font-size: 14px;
  font-weight: 900;
  border: 1px solid #fccd22;
  color: #333;
  display: block;
  width: 84%;
  line-height: 120%;
}

/*  Feed Links
-------------------------------------------------------------------- */
.feedLink {
  width: 99%;
  height: 50px;
  margin: 20px 0;
  text-align: center;
}

/*  User Mode Modifications
-------------------------------------------------------------------- */
.helperLightbox #lightboxHeader {
  border-bottom: solid 1px #999;
}

.helperLightbox #lightboxHeader h1 {
  background-image: url("https://app-assets1.sportngin.com/app_images/lbTopLeftUserMode.gif");
}

.helperLightbox #lightboxHeader a {
  background-image: url("https://app-assets1.sportngin.com/app_images/lbTopRightUserMode.gif");
  border-left: solid 1px #999;
}

.helperLightbox #lightboxBody .elementMenuOption {
  border-bottom: solid 1px #999;
}

.helperLightbox #lightboxFooter {
  border-top: solid 1px #999;
  background-image: url("https://app-assets1.sportngin.com/app_images/lbBottomRightUserMode.gif");
}

.helperLightbox #lightboxFooter #lightboxFeedback {
  background-image: url("https://app-assets1.sportngin.com/app_images/lbBottomLeftUserMode.gif");
}

.helperLightbox #lightboxBody {
  background: #EFEFEF;
}

.helperLightbox .submitLightbox {
  border: solid 1px #999;
  background: #CCC;
}

.lightboxHelp {
  color: #777;
  font-size: 11px;
  font-style: italic;
  padding-top: 3px;
}

.siteLightboxBanner {
  display: block;
  margin: 0 auto 5px;
}

/*  Edit Survey Entry
-------------------------------------------------------------------- */
#lightboxBody .formElement {
  margin: 5px 0;
  background-color: #FEEC8F;
  border-top: none;
}

#lightboxBody #profiles_table {
  background-color: #FEEC8F;
}

#lightboxBody #profiles_table td, #lightboxBody #profiles_table th {
  border: solid 1px #FEF7D0;
}

/* User Search
-------------------------------------------------------------------- */
#profiles_table {
  clear: none;
}

#lightboxBody .user_search, #lightboxBody .spinner {
  float: left;
  margin-right: 5px;
}

#lightboxBody #profiles_table {
  border: none;
}

#lightboxBody .personaSelect {
  margin: 20px 20px 0;
}

/* Buttons
-------------------------------------------------------------------- */
/* basics */
.button-add, .button-add:hover {
  color: #393;
}

.button-remove, .button-remove:hover {
  color: #900;
}

.button-add, .button-remove {
  background: #fffbe8;
  border: 1px solid #fdea88;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 3px;
  text-decoration: none;
}

.button-add img, .button-remove img {
  background: url("https://app-assets1.sportngin.com/app_images/uiAddRemove.png") 9px 0;
  cursor: pointer;
  height: 9px;
  width: 9px;
}

.button-add span, .button-remove span {
  font-size: 10px;
}

/* specifics */
.button-add img {
  background-position: 0 0;
}

.button-remove {
  margin: 0 0 0 5px;
}

/* link states */
.button-add:hover, .button-remove:hover {
  background: #fff;
}

.button-add:hover img {
  background-position: 0 -9px;
}

.button-add:active img {
  background-position: 0 -18px;
}

.button-remove:hover img {
  background-position: 9px -9px;
}

.button-remove:active img {
  background-position: 9px -18px;
}

/* Lists
-------------------------------------------------------------------- */
#scroll_content ul.pad {
  margin: 1em 1.5em;
}

/* Checkboxes
-------------------------------------------------------------------- */
.fcForm .checkboxes {
  margin: 0 auto;
}

.fcForm .checkboxes tr {
  border-bottom: 1px dotted #feec8f;
}

.fcForm .checkboxes th {
  color: #7f7b68;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  padding: 5px 10px 5px 0;
  width: auto;
  text-align: left;
}

.fcForm .checkboxes td {
  padding: 5px;
}

.fcForm .checkboxes b {
  color: #000;
  font-size: 12px;
}

/* Sliders
-------------------------------------------------------------------- */
.lbSlider-input {
  float: left;
  text-align: right;
  width: 35px;
}

.lbSlider-track {
  background: url("https://app-assets1.sportngin.com/app_images/sliderControls.png");
  float: left;
  height: 5px;
  margin: 5px 10px 0;
  position: relative;
  width: 350px;
}

.lbSlider-handle {
  background: url("https://app-assets1.sportngin.com/app_images/sliderControls.png") 0 -5px;
  cursor: move;
  height: 20px;
  position: absolute;
  top: -8px;
  width: 20px;
}

.lbSlider-handle:hover {
  background-position: -20px -5px;
  cursor: move;
}

.lbSlider-handle:active {
  background-position: -40px -5px;
  cursor: move;
}

/* Sliders
-------------------------------------------------------------------- */
#pageNode-columns {
  /*line-height:18px;*/
  margin: 4px 0 0;
}

#pageNode-columns img,
#pageNode-columns input {
  float: left;
  margin: 0 4px 0 0;
}

#lightboxBody #pageNode-columns p {
  margin: 0 0 8px;
}

/* Tabbed Element Form
-------------------------------------------------------------------- */
.tabElementForm .layout_tab:first-child .button-move-up {
  display: none;
}

.tabElementForm .layout_tab:last-child .button-move-down {
  display: none;
}

.tabElementForm .layout_tab .button-move-up, .tabElementForm .layout_tab .button-move-down {
  background: #fffbe8;
  border: 1px solid #fdea88;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 3px;
  text-decoration: none;
  font-size: 10px;
  color: #5F6566;
}

.tabElementForm .layout_tab .button-move-up:hover, .tabElementForm .layout_tab .button-move-down:hover {
  background: #fff;
}

.tabElementForm .layout_tab .button-move-up:active, .tabElementForm .layout_tab .button-move-down:active {
  background: #ffe98c;
}

.tabElementForm .layout_tab .button-move-up img, .tabElementForm .layout_tab .button-move-down img {
  position: relative;
  height: 14px;
  width: 14px;
  top: 2px;
}

.tabElementForm .layout_tab .button-move-up span, .tabElementForm .layout_tab .button-move-down span {
  position: relative;
  top: -2px;
  line-height: 0;
}

.helperLightbox #lightboxHeader {
  border-bottom: solid 1px #999;
  display: block;
  width: 100%;
  height: auto;
  background: #DDD;
  padding: 4px 0;
}

.helperLightbox #lightboxHeader h1 {
  position: relative;
  font-size: 11px;
  line-height: 1;
  text-align: center;
  width: 85%;
  color: #333;
  background: none;
}

.helperLightbox #lightboxHeader a {
  background: transparent;
  border-left: solid 1px #999;
}

.helperLightbox #lightboxBody h4 {
  border-top-color: #999;
}

.helperLightbox #lightboxFooter {
  background: #DDD;
  position: relative;
  height: auto;
}

.helperLightbox #lightboxFooter #lightboxFeedback {
  background: #DDD;
}

.helperLightbox .feedLink {
  max-width: 100%;
}

#lightbox {
  width: 800px;
  min-width: initial !important;
  max-width: 100%;
}

#lightbox .galleryLightbox .galleryItemWrapper {
  width: 100% !important;
}

#lightbox .galleryLightbox #lightboxBody {
  margin: 0 auto;
  max-width: 800px;
}

#lightbox .galleryLightbox .commentStreamContainer {
  margin: 10px 0 15px;
}

#lightbox .galleryLightbox .commentStreamContainer.noComments p {
  margin: 0;
}

.pageElement.streamingVideo {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}

.pageElement.streamingVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#survey_steps .vevent {
  margin-top: 20px;
  margin-bottom: 30px;
}

#survey_steps .dateImage {
  width: 75px;
  float: left;
  margin-right: 10px;
  list-style-type: none;
  text-align: center;
}

#survey_steps .dateImage .month {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  background-color: #000000;
  color: #fff;
}

#survey_steps .dateImage .date {
  font-size: 48px;
  line-height: 1;
  background-color: #eee;
  font-weight: 700;
}

#survey_steps .summary {
  font-size: 18px;
}

#survey_steps .status {
  font-weight: bold;
}

#survey_steps .details {
  list-style: none;
}

#survey_steps .description {
  font-size: 12px;
}

#survey_steps .surveyButtonWrap {
  margin: 0;
}

.secureCheckoutMessage {
  margin-bottom: 1.5rem;
}

.theme-network-bar {
  padding: 0 15px;
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  word-wrap: normal;
  box-sizing: border-box;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}

.theme-network-bar .theme-network-bar-site-title {
  display: none;
}

.theme-network-bar:not(.compact-style) .theme-network-bar-title {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  font-weight: 700;
}

.theme-network-bar:not(.compact-style) .theme-network-bar-sites {
  display: inline-table;
  vertical-align: top;
}

.theme-network-bar:not(.compact-style) .theme-network-bar-site {
  padding: 5px 3px;
  color: #FFF;
  display: table-cell;
  vertical-align: middle;
  line-height: 30px;
  text-decoration: none !important;
}

.theme-network-bar:not(.compact-style) .theme-network-bar-site:hover {
  background: rgba(255, 255, 255, 0.3);
}

.theme-network-bar:not(.compact-style) .theme-network-bar-site-logo {
  display: block;
  width: 30px;
  height: 30px;
  border: 0;
}

.theme-network-bar:not(.compact-style) .theme-network-bar-site-logo + .theme-network-bar-site-name {
  display: none;
}

.theme-network-bar:not(.compact-style) .theme-network-bar-site-name {
  padding: 0 4px;
}

.theme-network-bar.compact-style .theme-network-bar-title {
  cursor: pointer;
  line-height: normal;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: black;
  padding: 2px 5px;
}

.theme-network-bar.compact-style .theme-network-bar-title:hover {
  background: black;
}

.theme-network-bar.compact-style .theme-network-bar-title:after {
  margin-left: 5px;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f107";
}

.NGIN_tip.theme-network-bar-dropdown {
  margin-top: -1px;
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.15);
  max-width: none !important;
}

.NGIN_tip.theme-network-bar-dropdown .container {
  padding: 0;
}

.NGIN_tip.theme-network-bar-dropdown .close_tip {
  display: none;
}

.NGIN_tip.theme-network-bar-dropdown .theme-network-bar-sites {
  display: block;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.NGIN_tip.theme-network-bar-dropdown .theme-network-bar-sites .theme-network-bar-site {
  font-size: 14px;
  padding: 4px 8px;
  display: block;
  color: white;
}

.NGIN_tip.theme-network-bar-dropdown .theme-network-bar-sites .theme-network-bar-site:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.NGIN_tip.theme-network-bar-dropdown .theme-network-bar-sites .theme-network-bar-site:hover {
  text-decoration: none;
  background: #000000;
}

.NGIN_tip.theme-network-bar-dropdown .theme-network-bar-sites .theme-network-bar-site-logo {
  display: none;
}

#networkNavMenuTitle {
  width: 100%;
  border-bottom: none;
}

#networkNavMenuTitle, #networkNavMenuTitle .theme-nav-back {
  background: black;
}

#networkNavMenuTitle .theme-nav-title-text {
  padding-right: 60px;
}

#networkNavMenuSites {
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 101;
  transition: left 200ms ease-in-out;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

#networkNavMenuSites > * {
  width: 100%;
}

#networkNavMenuSites, #networkNavMenuSites .theme-nav-title {
  background: black;
}

.network-nav-expanded #networkNavMenuSites {
  left: 0;
}

/* Page Element Defaults
-------------------------------------------------------------------- */
.pageElementAdmin {
  overflow: visible;
}

.pageElement .inputForm h3 {
  margin-bottom: 0;
}

.pageElement td p {
  margin-bottom: 0;
}

.leftTextImage {
  float: left;
  margin: 5px 15px 10px 0;
}

.rightTextImage {
  float: right;
  margin: 5px 0 10px 10px;
}

.textImageSmall {
  width: 150px;
}

.textImageMedium {
  width: 200px;
}

.textImageLarge {
  width: 250px;
}

body .cutline {
  margin: auto;
  padding: 10px 20px;
  background-color: #eee;
  color: #666;
  font-size: 12px;
  font-style: italic;
}

/* Mirror Button
-------------------------------------------------------------------- */
.mirrorButton {
  float: left;
}

.mirrorButton a {
  background: url("http://app-assets3.sportngin.com/app_images/mirror_content_button.svg?1468434667") no-repeat;
  border: solid transparent;
  border-width: 6px 7px;
  border-radius: 4px;
  box-sizing: content-box;
  color: inherit;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: 22px;
  letter-spacing: .1em;
  line-height: 22px;
  padding-left: 25px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in;
}

/* Page Element Margins
-------------------------------------------------------------------- */
#panelTwo .mirrorOriginLink,
#panelTwo .mirrorButton {
  margin-top: 3px;
}

.layoutContainer .pageElementAdmin {
  margin: 0 10px;
}

.layoutContainer .column1 .pageElementAdmin .pageElement,
.layoutContainer .column1 .pageElementAdmin .hrElement {
  margin-left: 0;
}

/* Page Element Typography
-------------------------------------------------------------------- */
.boardMemberElement h3,
.photoGalleryElement h3,
.directorySearchElement h3,
.documentElement h3 {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 14px;
}

.markdownElement h1 {
  margin-bottom: .5em;
}

.paginationElement h4,
.paginationNav,
.searchResultsElement h3 {
  font-size: 14px;
  font-weight: bold;
  color: #68767F;
}

.rosterElement h4 {
  margin: 6px 0;
}

.boardMemberElement .position,
.paginationElement .results,
.searchBlock label,
.calPreviewEvent .time {
  font-size: 10px;
  line-height: 11px;
  color: #666;
}

.boardMemberElement .position {
  text-transform: uppercase;
}

.galleryElement p {
  font-size: 11px;
  line-height: 120%;
}

.linkElement .text,
.contactFormElement .contactEmail,
.sideGraphicLink {
  font-size: 11px;
  font-style: italic;
  color: #666;
}

.helpText {
  color: #666;
  font-style: italic;
}

.markdownElement pre {
  padding: 10px;
  border: solid 1px #333;
  background: #666;
  color: #fff;
}

.markdownElement pre {
  margin-bottom: 1.5em;
}

/* Page Element Mirrors
-------------------------------------------------------------------- */
.elementPreview {
  background: #3C4046 url("https://app-assets1.sportngin.com/app_images/mirror_background.jpg") no-repeat;
  _height: 100px;
  border: solid 1px #333;
  padding: 5px;
  color: #fff;
}

#lightbox .elementPreview {
  margin-top: 10px;
}

.elementPreviewTitle {
  background-repeat: no-repeat;
  padding: 2px 0 3px 25px;
  font-size: 14px;
  line-height: 16px;
}

.elementPreviewImg {
  margin: 0 0 5px 25px;
}

.mirrorSiteHeader {
  margin-bottom: 3px;
}

.mirrorOriginLink {
  font-size: 9px;
  line-height: 10px;
  background-repeat: no-repeat;
  padding: 3px 0 3px 20px;
  display: inline-block;
}

#lightbox .elementPreview .mirrorOriginLink,
#panelTwo .elementPreview .mirrorOriginLink,
.elementPreview .mirrorOriginLink:hover {
  margin-top: 3px;
  display: block;
  color: #fff;
  border-top: solid 1px #666;
  background-position: 4px 5px;
  padding: 7px 0 3px 25px;
}

.mirrorOrigin {
  font-size: 9px;
  line-height: 10px;
  background-repeat: no-repeat;
  color: #999;
  padding: 3px 0 3px 25px;
  background-position: 3px 0px;
}

.mirrorOption {
  padding-left: 5px;
}

/* Mirrored Pages
-------------------------------------------------------------------- */
.elementPreviewTitle a,
.elementPreviewTitle a:hover {
  color: #fff;
}

.rapidContentMirror p {
  margin: 1em 0;
}

/* Display Body Header
-------------------------------------------------------------------- */
.displayBodyHeader-image {
  padding: 0;
  text-indent: -9999px;
}

/* Document Page Element */
.documentElement h3 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 1em 0;
  padding: 10px 0 0 0px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 700;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: left center;
  line-height: 1.2;
  text-transform: none;
}

.documentElement h3 a {
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: block;
  padding: 17px 47px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.documentElement h3 a:focus, .documentElement h3 a:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .documentElement h3 a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .documentElement h3 a {
    font-size: 14px;
  }
}

/* Link Page Element */
.linkElement h4 a {
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: block;
  padding: 17px 47px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.linkElement h4 a:focus, .linkElement h4 a:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .linkElement h4 a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .linkElement h4 a {
    font-size: 14px;
  }
}

.linkElement .goToLink:before,
.linkElement .emailLink:before {
  display: none;
}

.linkElement .text {
  font-size: 13px;
  line-height: 1.5em;
  margin: auto;
  padding: 10px;
  background-color: #eee;
}

.cke_editable.cke_contents_ltr a[name],
.cke_editable.cke_contents_ltr a[data-cke-saved-name],
.text a[name="button"] {
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: block;
  padding: 17px 47px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: inline-block;
}

.cke_editable.cke_contents_ltr a[name]:focus, .cke_editable.cke_contents_ltr a[name]:hover,
.cke_editable.cke_contents_ltr a[data-cke-saved-name]:focus,
.cke_editable.cke_contents_ltr a[data-cke-saved-name]:hover,
.text a[name="button"]:focus,
.text a[name="button"]:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .cke_editable.cke_contents_ltr a[name],
  .cke_editable.cke_contents_ltr a[data-cke-saved-name],
  .text a[name="button"] {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .cke_editable.cke_contents_ltr a[name],
  .cke_editable.cke_contents_ltr a[data-cke-saved-name],
  .text a[name="button"] {
    font-size: 14px;
  }
}

.cke_editable.cke_contents_ltr a[name],
.cke_editable.cke_contents_ltr a[data-cke-saved-name] {
  background: none;
}

.admin .linkElement h4 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  font-weight: 700;
  background: transparent;
  padding: 10px 0 5px 0;
}

.admin .linkElement h4:hover {
  background: transparent;
}

.admin .linkElement h4 a {
  display: inline;
  color: #000000;
  padding: 0;
  margin-bottom: 0px;
  background: transparent;
  text-decoration: none;
  opacity: 1;
}

.admin .linkElement h4 a:hover {
  text-decoration: underline;
}

.admin .linkElement .goToLink:before, .admin .linkElement .goToLink:after {
  display: none;
}

.admin .linkElement .text {
  font-size: 13px;
  padding: 0 0 10px 0;
  background-color: #fff;
}

/* Aggregator Elements */
.aggregatorElement h6 {
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  margin: 4px 0;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.aggregatorElement .newsImage img {
  position: relative;
}

.aggregatorElement .boxscore {
  margin: 3px 0;
}

.aggregatorElement .gameArea .details, .aggregatorElement .gameArea .vevent .summary {
  padding-left: 0;
}

.aggregatorElement .vevent .statTable {
  background: #FFF;
}

.aggregatorElement .newsImageCaption {
  display: none;
}

.aggregatorElement .paid {
  background: url("https://app-assets1.sportngin.com/app_images/paidStamp.gif") no-repeat center right;
}

.newsPreviewThumb {
  margin: 0 10px 10px 0;
  float: left;
  clear: left;
}

.newsPreviewText {
  display: none;
}

#gameNewsAggregator {
  border: none;
}

#gameNewsAggregator .teaser, #gameNewsAggregator .commentsAndMore {
  display: none;
}

.threeColumn502525 .column2 .newsImage,
.threeColumn502525 .column3 .newsImage,
.threeColumn255025 .column1 .newsImage,
.threeColumn255025 .column3 .newsImage,
.threeColumn252550 .column1 .newsImage,
.threeColumn252550 .column2 .newsImage,
.threeColumn333333 .column3 .newsImage,
.twoColumn6633 .column2 .newsImage,
.twoColumn3366 .column1 .newsImage,
.threeColumn333333 .column1 .newsImage,
.threeColumn333333 .column2 .newsImage {
  width: 100%;
  float: none;
  margin: 0 0 10px 0;
}

.twoColumn5050 .newsImage {
  width: 50%;
}

.threeColumn502525 .column2 .newsImage img,
.threeColumn502525 .column3 .newsImage img,
.threeColumn255025 .column1 .newsImage img,
.threeColumn255025 .column3 .newsImage img,
.threeColumn252550 .column1 .newsImage img,
.threeColumn252550 .column2 .newsImage img,
.threeColumn333333 .column3 .newsImage img,
.twoColumn6633 .column2 .newsImage img,
.twoColumn3366 .column1 .newsImage img,
.threeColumn333333 .column1 .newsImage img,
.threeColumn333333 .column2 .newsImage img,
.twoColumn5050 .newsImage img {
  width: 100%;
}

.threeColumn502525 .column2 .eventPreview .notToday,
.threeColumn502525 .column3 .eventPreview .notToday,
.threeColumn255025 .column1 .eventPreview .notToday,
.threeColumn255025 .column3 .eventPreview .notToday,
.threeColumn252550 .column1 .eventPreview .notToday,
.threeColumn252550 .column2 .eventPreview .notToday,
.threeColumn333333 .column3 .eventPreview .notToday,
.twoColumn6633 .column2 .eventPreview .notToday,
.twoColumn3366 .column1 .eventPreview .notToday,
.threeColumn333333 .column1 .eventPreview .notToday,
.threeColumn333333 .column2 .eventPreview .notToday {
  display: none;
}

.gameArea .item strong {
  margin-right: 4px;
  font-size: 10px;
  padding: 0 2px;
  color: #FFF;
}

.more {
  font-size: 10px;
  display: block;
  text-align: right;
  font-style: italic;
  font-weight: bold;
}

.regulations {
  margin-top: 5px;
  font-size: 10px;
  color: #666;
}

.regulations li {
  margin-left: 30px;
}

.ineligible {
  color: #cc0000;
  font-weight: bold;
}

.eligible {
  color: #009933;
  font-weight: bold;
}

.standingsAgg {
  text-align: left;
}

.standingsAgg h4 {
  margin: 5px 5px 0;
}

.standingsAgg .statTable {
  font-size: 10px;
  width: auto;
  margin: 5px;
}

.standingsAgg .statTable td, .standingsAgg .statTable th {
  border: none;
  width: 7%;
}

.standingsAgg .statTable .standingsTeamName {
  text-align: left;
  width: 40%;
}

.standingsAgg .vevent {
  padding: 5px;
}

.standingsAgg .vevent .statTable {
  margin: 0;
}

.standingsResultsLinks a {
  display: block;
  margin-left: 5px;
}

.failed {
  background: pink;
}

.failed a {
  color: red;
}

.eventAggregatorElement ul {
  list-style: none;
}

.eventAggregatorElement ul li:empty {
  display: none;
}

.eventAggregatorElement abbr {
  text-decoration: none;
}

.eventAggregatorElement .vevent {
  border-bottom: 2px solid #EDF0F1;
  padding: 20px 0px;
}

.eventAggregatorElement .aggHeader + .vevent {
  margin-top: 15px;
  border-top: 2px solid #EDF0F1;
}

.eventAggregatorElement .vevent > h5 a {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  text-transform: none;
  color: #000000;
  transition: color 0.2s linear;
}

.eventAggregatorElement .vevent > h5 a:hover {
  color: #CF3338;
}

.eventAggregatorElement .vevent .time,
.eventAggregatorElement .vevent .location {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  color: #666666;
  padding: 5px 0px 0px 0px;
}

.eventAggregatorElement .vevent .description {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  color: #666666;
  padding: 5px 0px 0px 0px;
}

.eventAggregatorElement .vevent .description:empty {
  display: none;
}

.eventAggregatorElement .vevent .tags {
  padding: 10px 0px 0px 0px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.eventAggregatorElement .vevent .tags a {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #CF3338;
  transition: color 0.2s linear;
}

.eventAggregatorElement .vevent .tags a:hover {
  color: #CF3338;
}

.eventAggregatorElement .vevent .dateImage {
  background-color: #000000;
  margin: 0 10px 10px 0;
  width: 100%;
  max-width: 80px;
  min-height: 80px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  float: left;
}

.eventAggregatorElement .vevent .dateImage .month {
  font-size: 18px;
  line-height: 1;
  color: white;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.eventAggregatorElement .vevent .dateImage .date {
  font-size: 36px;
  line-height: 1;
  color: white;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 900;
  text-align: center;
}

.eventAggregatorElement .vevent {
  position: relative;
}

.eventAggregatorElement .formElement {
  padding: 10px 0;
}

.eventAggregatorElement .formElement [id$="_start_date_day"],
.eventAggregatorElement .formElement [id$="_end_date_day"] {
  width: 8em;
}

@media only screen and (max-width: 350px) {
  .eventAggregatorElement .dateImage {
    float: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1010px) {
  .col-md-4 .eventAggregatorElement .dateImage {
    float: none;
  }
}

@media only screen and (min-width: 768px) {
  .col-md-3 .eventAggregatorElement .dateImage {
    float: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50 .col-md-6 .eventAggregatorElement .dateImage,
  .layout-25-25-50 .col-md-3 .eventAggregatorElement .dateImage,
  .layout-50-25-25 .col-md-6 .eventAggregatorElement .dateImage,
  .layout-50-25-25 .col-md-3 .eventAggregatorElement .dateImage {
    float: left;
  }
  .layout-25-50-25 .col-md-6 .eventAggregatorElement .dateImage,
  .layout-25-50-25 .col-md-3 .eventAggregatorElement .dateImage {
    float: left;
  }
}

.eventPreview {
  text-align: left;
  width: 100%;
  table-layout: fixed;
}

.eventPreview th {
  height: 50px;
  padding: 3px 12px;
  background-color: #CF3338;
  white-space: nowrap;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: white;
  word-break: break-word;
}

.eventPreview th a {
  color: #FFF;
  font-weight: 600;
}

.eventPreview td {
  font-size: 12px;
  padding: 10px;
  background: #eee;
  vertical-align: top;
  word-break: break-word;
}

.eventPreview td:before {
  content: attr(data-week-view-date) "";
  display: none;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 7px 12px;
  border-bottom: 2px solid #FFF;
  background: #000000;
  color: #fff;
  text-transform: uppercase;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 1em;
  line-height: 1.1;
  font-weight: 600;
}

.eventPreview td,
.eventPreview th {
  border: 2px solid #FFF;
}

.eventPreview td:first-child,
.eventPreview th:first-child {
  border-left: 0;
}

.eventPreview td:last-child,
.eventPreview th:last-child {
  border-right: 0;
}

.eventPreview .event + .event {
  padding-top: 10px;
}

.eventPreview .noentry {
  word-break: normal;
}

@media only screen and (max-width: 450px) {
  .eventPreview {
    border-top: 2px solid #FFF;
  }
  .eventPreview,
  .eventPreview tbody,
  .eventPreview tr,
  .eventPreview td {
    display: block;
  }
  .eventPreview thead {
    display: none;
  }
  .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 2px;
    word-break: word-break;
  }
  .eventPreview td:before {
    display: block;
  }
  .eventPreview .event {
    padding: 10px;
  }
  .eventPreview .event + .event {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 880px) {
  .col-md-6 .eventPreview {
    border-top: 2px solid #FFF;
  }
  .col-md-6 .eventPreview,
  .col-md-6 .eventPreview tbody,
  .col-md-6 .eventPreview tr,
  .col-md-6 .eventPreview td {
    display: block;
  }
  .col-md-6 .eventPreview thead {
    display: none;
  }
  .col-md-6 .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 2px;
    word-break: word-break;
  }
  .col-md-6 .eventPreview td:before {
    display: block;
  }
  .col-md-6 .eventPreview .event {
    padding: 10px;
  }
  .col-md-6 .eventPreview .event + .event {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .col-md-4 .eventPreview {
    border-top: 2px solid #FFF;
  }
  .col-md-4 .eventPreview,
  .col-md-4 .eventPreview tbody,
  .col-md-4 .eventPreview tr,
  .col-md-4 .eventPreview td {
    display: block;
  }
  .col-md-4 .eventPreview thead {
    display: none;
  }
  .col-md-4 .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 2px;
    word-break: word-break;
  }
  .col-md-4 .eventPreview td:before {
    display: block;
  }
  .col-md-4 .eventPreview .event {
    padding: 10px;
  }
  .col-md-4 .eventPreview .event + .event {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .col-md-3 .eventPreview {
    border-top: 2px solid #FFF;
  }
  .col-md-3 .eventPreview,
  .col-md-3 .eventPreview tbody,
  .col-md-3 .eventPreview tr,
  .col-md-3 .eventPreview td {
    display: block;
  }
  .col-md-3 .eventPreview thead {
    display: none;
  }
  .col-md-3 .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 2px;
    word-break: word-break;
  }
  .col-md-3 .eventPreview td:before {
    display: block;
  }
  .col-md-3 .eventPreview .event {
    padding: 10px;
  }
  .col-md-3 .eventPreview .event + .event {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50 .col-md-6 .eventPreview,
  .layout-50-25-25 .col-md-6 .eventPreview {
    display: table;
    border-top: 0;
  }
  .layout-25-25-50 .col-md-6 .eventPreview thead,
  .layout-50-25-25 .col-md-6 .eventPreview thead {
    display: table-header-group;
  }
  .layout-25-25-50 .col-md-6 .eventPreview tbody,
  .layout-50-25-25 .col-md-6 .eventPreview tbody {
    display: table-row-group;
  }
  .layout-25-25-50 .col-md-6 .eventPreview tr,
  .layout-50-25-25 .col-md-6 .eventPreview tr {
    display: table-row;
  }
  .layout-25-25-50 .col-md-6 .eventPreview td,
  .layout-25-25-50 .col-md-6 .eventPreview th,
  .layout-50-25-25 .col-md-6 .eventPreview td,
  .layout-50-25-25 .col-md-6 .eventPreview th {
    display: table-cell;
  }
  .layout-25-25-50 .col-md-6 .eventPreview td,
  .layout-50-25-25 .col-md-6 .eventPreview td {
    padding: 10px;
    margin-bottom: none;
    word-break: word-break;
  }
  .layout-25-25-50 .col-md-6 .eventPreview td:before,
  .layout-50-25-25 .col-md-6 .eventPreview td:before {
    display: none;
  }
  .layout-25-25-50 .col-md-6 .eventPreview .event,
  .layout-50-25-25 .col-md-6 .eventPreview .event {
    padding: 0;
  }
  .layout-25-50-25 .col-md-6 .eventPreview {
    border-top: 2px solid #FFF;
  }
  .layout-25-50-25 .col-md-6 .eventPreview,
  .layout-25-50-25 .col-md-6 .eventPreview tbody,
  .layout-25-50-25 .col-md-6 .eventPreview tr,
  .layout-25-50-25 .col-md-6 .eventPreview td {
    display: block;
  }
  .layout-25-50-25 .col-md-6 .eventPreview thead {
    display: none;
  }
  .layout-25-50-25 .col-md-6 .eventPreview td {
    border: 0;
    padding: 0;
    margin-bottom: 2px;
    word-break: word-break;
  }
  .layout-25-50-25 .col-md-6 .eventPreview td:before {
    display: block;
  }
  .layout-25-50-25 .col-md-6 .eventPreview .event {
    padding: 10px;
  }
  .layout-25-50-25 .col-md-6 .eventPreview .event + .event {
    padding-top: 0;
  }
}

@media only screen and (min-width: 891px) and (max-width: 999px) {
  .layout-25-25-50 .col-md-3 .eventPreview,
  .layout-50-25-25 .col-md-3 .eventPreview {
    display: table;
    border-top: 0;
  }
  .layout-25-25-50 .col-md-3 .eventPreview thead,
  .layout-50-25-25 .col-md-3 .eventPreview thead {
    display: table-header-group;
  }
  .layout-25-25-50 .col-md-3 .eventPreview tbody,
  .layout-50-25-25 .col-md-3 .eventPreview tbody {
    display: table-row-group;
  }
  .layout-25-25-50 .col-md-3 .eventPreview tr,
  .layout-50-25-25 .col-md-3 .eventPreview tr {
    display: table-row;
  }
  .layout-25-25-50 .col-md-3 .eventPreview td,
  .layout-25-25-50 .col-md-3 .eventPreview th,
  .layout-50-25-25 .col-md-3 .eventPreview td,
  .layout-50-25-25 .col-md-3 .eventPreview th {
    display: table-cell;
  }
  .layout-25-25-50 .col-md-3 .eventPreview td,
  .layout-50-25-25 .col-md-3 .eventPreview td {
    padding: 10px;
    margin-bottom: none;
    word-break: word-break;
    border: 2px solid #FFF;
  }
  .layout-25-25-50 .col-md-3 .eventPreview td:first-child,
  .layout-50-25-25 .col-md-3 .eventPreview td:first-child {
    border-left: 0;
  }
  .layout-25-25-50 .col-md-3 .eventPreview td:last-child,
  .layout-50-25-25 .col-md-3 .eventPreview td:last-child {
    border-right: 0;
  }
  .layout-25-25-50 .col-md-3 .eventPreview td:before,
  .layout-50-25-25 .col-md-3 .eventPreview td:before {
    display: none;
  }
  .layout-25-25-50 .col-md-3 .eventPreview .event,
  .layout-50-25-25 .col-md-3 .eventPreview .event {
    padding: 0;
  }
}

.newsAggregatorElement .details {
  padding-top: 20px;
  padding-bottom: 20px;
}

.newsAggregatorElement .condensed ul {
  list-style: none;
}

@media screen and (max-width: 1024px) {
  .newsAggregatorElement .condensed ul {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.newsAggregatorElement .condensed ul li:empty {
  display: none;
}

.newsAggregatorElement .condensed {
  border-bottom: none;
  background: #FAFAFA;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .newsAggregatorElement .condensed {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .condensed {
    padding: 0;
  }
}

.newsAggregatorElement .expanded {
  border-bottom: none;
  background: #FAFAFA;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 5px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .expanded {
    padding: 0;
  }
}

.newsAggregatorElement .aggHeader {
  margin-bottom: 20px;
}

.newsAggregatorElement .aggHeader + .condensed,
.newsAggregatorElement .aggHeader + .expanded {
  border-top: none;
}

.newsAggregatorElement .condensed > a {
  display: block;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 30px;
  float: left;
}

.newsAggregatorElement .condensed > a:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 1024px) {
  .newsAggregatorElement .condensed > a {
    max-width: 100px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .condensed > a {
    max-width: none;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.newsAggregatorElement .condensed > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

.newsAggregatorElement .condensed > a img {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .condensed > a img {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.newsAggregatorElement .condensed .details h4 a {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #000000;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .newsAggregatorElement .condensed .details h4 a {
    font-size: 18px;
  }
}

.newsAggregatorElement .condensed .details h4 a:hover {
  color: #CF3338;
}

.newsAggregatorElement .condensed .dateAuthor {
  display: none;
}

.newsAggregatorElement .condensed .newsAuthor {
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  text-transform: none;
  color: #999999;
  display: flex;
  align-items: center;
}

.newsAggregatorElement .condensed .newsAuthor:before {
  content: '';
  background-image: url("../images/news-article-page-ui/user-circle-solid.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 14px;
  font-size: 12px;
  margin-right: 1ch;
  display: inline-block;
}

.newsAggregatorElement .condensed .newsDate {
  display: none;
}

.newsAggregatorElement .condensed .teaser {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #666666;
  padding: 5px 0px 0px 0px;
  display: none;
}

.newsAggregatorElement .condensed .commentsAndMore {
  display: none;
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .newsItemElement {
    padding-bottom: 20px;
  }
}

.newsAggregatorElement .expanded .newsItemHeader > a {
  display: block;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 30px;
  float: left;
}

.newsAggregatorElement .expanded .newsItemHeader > a:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 1024px) {
  .newsAggregatorElement .expanded .newsItemHeader > a {
    max-width: 100px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .expanded .newsItemHeader > a {
    max-width: none;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.newsAggregatorElement .expanded .newsItemHeader > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

.newsAggregatorElement .expanded .newsItemHeader > a img {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .expanded .newsItemHeader > a img {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.newsAggregatorElement .expanded .newsItemHeader h2 a {
  display: block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #000000;
}

.newsAggregatorElement .expanded .newsItemHeader h2 a:hover {
  color: #CF3338;
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .expanded .newsItemHeader h2 a {
    margin-left: 15px;
  }
}

.newsAggregatorElement .expanded .dateAuthor {
  line-height: 12px;
  padding: 5px 0px 0px 0px;
}

.newsAggregatorElement .expanded .newsAuthor {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  text-transform: none;
  color: #999999;
  display: flex;
  align-items: center;
}

.newsAggregatorElement .expanded .newsAuthor:before {
  content: '';
  background-image: url("../images/news-article-page-ui/user-circle-solid.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 14px;
  font-size: 12px;
  margin-right: 1ch;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .expanded .newsAuthor {
    margin-left: 15px;
  }
}

.newsAggregatorElement .expanded .newsDate {
  display: none;
}

.newsAggregatorElement .expanded .newsItemElement > h4 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #666666;
  padding: 5px 0px 20px 20px;
  padding-left: 165px;
}

@media screen and (max-width: 767px) {
  .newsAggregatorElement .expanded .newsItemElement > h4 {
    margin-left: 15px;
  }
}

.newsAggregatorElement .expanded .commentsAndMore {
  margin: 0;
}

.newsAggregatorElement .expanded .commentsAndMore .commentCount {
  padding: 10px 0px 0px 0px;
  display: inline-block;
}

.newsAggregatorElement .expanded .commentsAndMore .button-content {
  display: inline-block;
}

.newsAggregatorElement .expanded .commentsAndMore .button-commentCount {
  display: inline-block;
  padding: 0;
}

.newsAggregatorElement .expanded .commentsAndMore .button-image-right {
  display: none;
}

.newsAggregatorElement .expanded .readMore .button-small {
  display: none;
}

.news-card-slider .pageElement {
  margin-top: 0;
}

.news-card-slider .newsAggregatorElement .condensed,
.news-card-slider .newsAggregatorElement .expanded {
  border-bottom: none;
  padding-right: 0;
  flex-flow: row wrap;
}

.news-card-slider .newsAggregatorElement .aggHeader + .condensed,
.news-card-slider .newsAggregatorElement .aggHeader + .expanded {
  border-top: none;
}

.news-card-slider .newsAggregatorElement .aggHeader {
  color: #000000;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .aggHeader {
    transform: translateX(50%);
  }
}

@media screen and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .aggHeader {
    font-size: 22px;
    padding-top: 50px;
  }
}

.news-card-slider .newsAggregatorElement .item {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  transition: background-color 0.2s linear;
}

.news-card-slider .newsAggregatorElement .item > a {
  max-width: none;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

.news-card-slider .newsAggregatorElement .item > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

@media screen and (max-width: 767px) {
  .news-card-slider .newsAggregatorElement .item > a img {
    padding: 0;
  }
}

.news-card-slider .newsAggregatorElement .item .details {
  padding-left: 0;
  padding-right: 20px;
}

@media screen and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .item .details {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.news-card-slider .newsAggregatorElement .item .details h4 {
  padding-top: 7px;
  padding-left: 30px;
}

@media screen and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .item .details h4 {
    padding-left: 0;
  }
}

.news-card-slider .newsAggregatorElement .item .details h4 a {
  font-size: 24px;
  font-weight: 400;
  color: #333;
  transition: color 0.2s linear;
  line-height: 32px;
}

.news-card-slider .newsAggregatorElement .item .details h4 a:hover {
  color: #FF595A;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .item .details h4 a {
    font-size: 18px;
  }
}

.news-card-slider .newsAggregatorElement .item .newsAuthor {
  color: #666666;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .item .newsAuthor {
    padding-left: 0;
  }
}

.news-card-slider .newsAggregatorElement .item .newsAuthor:before {
  content: '';
  background-image: url("../images/news-article-page-ui/user-circle-solid.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 12px;
  font-size: 12px;
  margin-right: 1ch;
  display: inline-block;
}

.news-card-slider .newsAggregatorElement .item .newsDate {
  display: none;
}

.news-card-slider .newsAggregatorElement .item .teaser {
  display: block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #475157;
  font-size: 16px;
  line-height: 24px;
  padding-top: 13px;
  padding-left: 30px;
  max-width: 560px;
}

@media screen and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .item .teaser {
    padding-left: 0;
  }
}

.news-card-slider .newsAggregatorElement .item .readMore {
  padding-left: 30px;
}

@media screen and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .item .readMore {
    padding-left: 0;
  }
}

.news-card-slider .newsAggregatorElement .item .readMore:hover .button-small:after {
  transform: translateX(2px);
}

.news-card-slider .newsAggregatorElement .item .readMore .button-small {
  display: inline-block;
  background: none;
  color: #CF3338;
  font-size: 14px;
  padding: 0;
  font-weight: 400;
  padding-bottom: 30px;
}

.news-card-slider .newsAggregatorElement .item .readMore .button-small:before {
  display: none;
}

.news-card-slider .newsAggregatorElement .item .readMore .button-small:after {
  content: '\f0da';
  font-family: 'FontAwesome';
  font-size: 14px;
  display: inline-block;
  color: #CF3338;
  margin-left: 1.5ch;
  transition: transform 0.2s linear;
}

.news-card-slider .newsAggregatorElement .extendedOptions {
  display: none;
}

.news-card-slider .slick-track {
  display: flex;
  padding-bottom: 10px;
}

.news-card-slider .newsAggregatorElement .item .newsAuthor:before {
  content: none;
}

.news-card-slider .newsAggregatorElement .item .newsAuthor {
  font-weight: 700;
  font-size: 14px;
  color: #999999;
  transition: color 0.2s linear;
}

.news-card-slider .newsAggregatorElement .item .details h4 a {
  font-weight: 700;
  color: #000000;
  transition: color 0.2s linear;
}

.news-card-slider .newsAggregatorElement .item .teaser {
  color: #999999;
  transition: color 0.2s linear;
}

.news-card-slider .newsAggregatorElement .item {
  width: 100%;
  max-width: 400px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  min-height: 100%;
  height: auto;
  align-content: flex-start;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s linear, background-color 0.2s linear;
}

.news-card-slider .pageElement {
  display: flex;
  justify-content: center;
}

.news-card-slider .newsAggregatorElement .extendedOptions {
  display: none;
}

.news-card-slider .newsAggregatorElement .condensed > a img {
  position: relative;
  height: auto !important;
}

.news-card-slider .newsAggregatorElement .item:hover {
  background-color: #0093C8;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
}

.news-card-slider .newsAggregatorElement .item:hover .newsAuthor,
.news-card-slider .newsAggregatorElement .item:hover .details h4 a,
.news-card-slider .newsAggregatorElement .item:hover .details h4 a:hover,
.news-card-slider .newsAggregatorElement .item:hover .teaser {
  color: #ffffff;
}

.custom-list-agg {
  margin-top: 55px;
}

@media screen and (max-width: 1024px) {
  .custom-list-agg {
    margin-top: 110px;
  }
}

@media screen and (max-width: 767px) {
  .custom-list-agg {
    margin-top: 0;
  }
}

.custom-list-agg .newsAggregatorElement .condensed,
.custom-list-agg .newsAggregatorElement .expanded {
  border-bottom: none;
  background: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .custom-list-agg .newsAggregatorElement .condensed,
  .custom-list-agg .newsAggregatorElement .expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .custom-list-agg .newsAggregatorElement .condensed,
  .custom-list-agg .newsAggregatorElement .expanded {
    padding: 0;
  }
}

.custom-list-agg .newsAggregatorElement .aggHeader + .condensed,
.custom-list-agg .newsAggregatorElement .aggHeader + .expanded {
  border-top: none;
}

.custom-list-agg .newsAggregatorElement .item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s linear;
}

.custom-list-agg .newsAggregatorElement .item:hover {
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.custom-list-agg .newsAggregatorElement .item > a {
  max-width: 210px;
  margin-right: 30px;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .custom-list-agg .newsAggregatorElement .item > a {
    margin-right: 20px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 100px;
  }
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .custom-list-agg .newsAggregatorElement .item > a {
    max-width: none;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.custom-list-agg .newsAggregatorElement .item > a:before {
  content: '';
  float: left;
  padding-bottom: 50%;
}

@media screen and (max-width: 767px) {
  .custom-list-agg .newsAggregatorElement .item > a img {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .custom-list-agg .newsAggregatorElement .item .details {
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
  }
}

.custom-list-agg .newsAggregatorElement .item h4 a {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  line-height: 1.6;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .custom-list-agg .newsAggregatorElement .item h4 a {
    font-size: 18px;
  }
}

.custom-list-agg .newsAggregatorElement .item .newsAuthor {
  color: #999999;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  display: flex;
  align-items: center;
}

.custom-list-agg .newsAggregatorElement .item .newsAuthor:before {
  content: '';
  background-image: url("../images/news-article-page-ui/user-circle-solid.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 14px;
  font-size: 12px;
  margin-right: 1ch;
  display: inline-block;
}

.custom-list-agg .newsAggregatorElement .item .newsDate,
.custom-list-agg .newsAggregatorElement .item .teaser,
.custom-list-agg .newsAggregatorElement .item .commentsAndMore {
  display: none;
}

.custom-list-agg .newsAggregatorElement .extendedOptions {
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed ul {
  list-style: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed ul li:empty {
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed,
.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 30px;
  align-items: flex-start;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .newsAggregatorElement .condensed,
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded {
    display: flex;
    flex-wrap: wrap;
  }
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .newsAuthor,
.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsAuthor {
  padding-bottom: 10px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .newsItemHeader,
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader {
    display: flex;
    flex-wrap: wrap;
  }
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .details,
.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .details {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .details,
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .details {
    padding-top: 10px;
  }
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .details li,
.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .details li {
  width: 100%;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed:hover > a img,
.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded:hover > a img {
  transform: scale(1.1);
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .aggHeader + .condensed,
.pageEl:not(.news-card-slider) .newsAggregatorElement .aggHeader + .expanded {
  margin-top: 10px;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .newsItemElement {
  width: 100%;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed > a {
  display: block;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0px;
  margin-right: 30px;
  overflow: hidden;
  float: left;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed > a:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .newsAggregatorElement .condensed > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed > a img {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform .2s ease-in-out;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .details h4 a {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #000000;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .details h4 a:hover {
  color: #CF3338;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .dateAuthor {
  line-height: 1;
  padding: 5px 0px 0px 0px;
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .newsAuthor,
.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .newsDate {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #666666;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .newsAuthor:before,
.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .newsDate:before {
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .teaser {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #666666;
  padding: 0px;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .commentsAndMore {
  padding: 20px 0px 0px 0px;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .commentsAndMore .button-content {
  display: inline-block;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .commentsAndMore .button-commentCount {
  display: inline-block;
  padding: 0;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .commentsAndMore .button-image-right {
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .condensed .readMore .button-small {
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader > a {
  display: block;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0px;
  margin-right: 30px;
  float: left;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader > a:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 767px) {
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader > a img {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemElement {
  width: 100%;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemElement {
    width: 100%;
  }
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader h2 a {
  display: block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #000000;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader h2 a:hover {
  color: #CF3338;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .dateAuthor {
  line-height: 1;
  padding: 5px 0px 0px 0px;
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsAuthor,
.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsDate {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #666666;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsAuthor:before {
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemElement > h4 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #666666;
  padding: 0px;
  display: flex;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .commentsAndMore {
  margin: 0;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .commentsAndMore .commentCount {
  padding: 20px 0px 0px 0px;
  display: inline-block;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .commentsAndMore .button-content {
  display: inline-block;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .commentsAndMore .button-commentCount {
  display: inline-block;
  padding: 0;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .commentsAndMore .button-image-right {
  display: none;
}

.pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .readMore .button-small {
  display: none;
}

.pageEl:not(.news-card-slider) .layout-66-33 .column-2 .newsAggregatorElement .condensed > a {
  max-width: 125px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .layout-66-33 .column-2 .newsAggregatorElement .condensed > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.pageEl:not(.news-card-slider) .layout-66-33 .column-2 .newsAggregatorElement .expanded .newsItemHeader > a {
  max-width: 125px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .layout-66-33 .column-2 .newsAggregatorElement .expanded .newsItemHeader > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.pageEl:not(.news-card-slider) .layout-33-66 .column-1 .newsAggregatorElement .condensed > a {
  max-width: 125px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .layout-33-66 .column-1 .newsAggregatorElement .condensed > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.pageEl:not(.news-card-slider) .layout-33-66 .column-1 .newsAggregatorElement .expanded .newsItemHeader > a {
  max-width: 125px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .layout-33-66 .column-1 .newsAggregatorElement .expanded .newsItemHeader > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.pageEl:not(.news-card-slider) .layout-33-33-33 .column .newsAggregatorElement .condensed > a {
  max-width: 125px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .layout-33-33-33 .column .newsAggregatorElement .condensed > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.pageEl:not(.news-card-slider) .layout-33-33-33 .column .newsAggregatorElement .expanded .newsItemHeader > a {
  max-width: 125px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .pageEl:not(.news-card-slider) .layout-33-33-33 .column .newsAggregatorElement .expanded .newsItemHeader > a {
    max-width: 100%;
    margin-right: 0;
  }
}

.custom-card-agg.pageEl .pageElement {
  margin-top: 0;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed,
.custom-card-agg.pageEl .newsAggregatorElement .expanded {
  border-bottom: none;
  padding-right: 0;
  flex-flow: row wrap;
}

.custom-card-agg.pageEl .newsAggregatorElement .aggHeader + .condensed,
.custom-card-agg.pageEl .newsAggregatorElement .aggHeader + .expanded {
  border-top: none;
}

.custom-card-agg.pageEl .newsAggregatorElement .aggHeader {
  color: #000000;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .custom-card-agg.pageEl .newsAggregatorElement .aggHeader {
    transform: translateX(50%);
  }
}

@media screen and (max-width: 1024px) {
  .custom-card-agg.pageEl .newsAggregatorElement .aggHeader {
    font-size: 22px;
    padding-top: 50px;
  }
}

.custom-card-agg.pageEl .newsAggregatorElement .item {
  border-radius: 10px;
  overflow: hidden;
}

.custom-card-agg.pageEl .newsAggregatorElement .item > a {
  max-width: none;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

.custom-card-agg.pageEl .newsAggregatorElement .item > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

@media screen and (max-width: 767px) {
  .custom-card-agg.pageEl .newsAggregatorElement .item > a img {
    padding: 0;
  }
}

.custom-card-agg.pageEl .newsAggregatorElement .item .details {
  padding-left: 20px;
  padding-right: 20px;
}

.custom-card-agg.pageEl .newsAggregatorElement .item .details h4 {
  padding-top: 7px;
  padding-left: 0;
}

.custom-card-agg.pageEl .newsAggregatorElement .item .newsAuthor {
  color: #666666;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .custom-card-agg.pageEl .newsAggregatorElement .item .newsAuthor {
    padding-left: 0;
  }
}

.custom-card-agg.pageEl .newsAggregatorElement .item .newsAuthor:before {
  content: '';
  background-image: url("../images/news-article-page-ui/user-circle-solid.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 12px;
  font-size: 12px;
  margin-right: 1ch;
  display: inline-block;
}

.custom-card-agg.pageEl .newsAggregatorElement .item .newsDate {
  display: none;
}

.custom-card-agg.pageEl .newsAggregatorElement .item .teaser {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #475157;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-left: 0;
  padding-top: 15px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
}

@media screen and (max-width: 1024px) {
  .custom-card-agg.pageEl .newsAggregatorElement .item .teaser {
    font-size: 12px;
  }
}

.custom-card-agg.pageEl .newsAggregatorElement .item .readMore {
  padding-left: 30px;
}

@media screen and (max-width: 1024px) {
  .custom-card-agg.pageEl .newsAggregatorElement .item .readMore {
    padding-left: 0;
  }
}

.custom-card-agg.pageEl .newsAggregatorElement .item .readMore:hover .button-small:after {
  transform: translateX(2px);
}

.custom-card-agg.pageEl .newsAggregatorElement .item .readMore .button-small {
  display: inline-block;
  background: none;
  color: #CF3338;
  font-size: 14px;
  padding: 0;
  font-weight: 400;
  padding-bottom: 30px;
}

.custom-card-agg.pageEl .newsAggregatorElement .item .readMore .button-small:before {
  display: none;
}

.custom-card-agg.pageEl .newsAggregatorElement .item .readMore .button-small:after {
  content: '\f0da';
  font-family: 'FontAwesome';
  font-size: 14px;
  display: inline-block;
  color: #CF3338;
  margin-left: 1.5ch;
  transition: transform 0.2s linear;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed {
  position: relative;
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: 100%;
  padding: 0 0 30px 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 30px;
  background-color: #FAFAFA;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .custom-card-agg.pageEl .newsAggregatorElement .condensed {
    max-width: none;
    display: block;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed > a {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 310px;
  margin-bottom: 30px;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed > a img {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed .newsAuthor {
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  padding-left: 0;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed .details h4 {
  padding: 0;
  margin: 0;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed .details h4 a {
  font-size: 24px;
  font-weight: 400;
  color: #333;
  transition: color 0.2s linear;
  line-height: 32px;
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed .details h4 a:hover {
  color: #FF595A;
}

@media screen and (max-width: 1024px) {
  .custom-card-agg.pageEl .newsAggregatorElement .condensed .details h4 a {
    padding-left: 0;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .custom-card-agg.pageEl .newsAggregatorElement .condensed .details h4 a {
    font-size: 18px;
  }
}

.custom-card-agg.pageEl .newsAggregatorElement .condensed:hover > a img {
  transform: scale(1);
}

.custom-card-agg.pageEl .newsAggregatorElement .extendedOptions {
  display: none;
}

.column-1 .custom-card-agg.pageEl {
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  .column-1 .custom-card-agg.pageEl {
    padding-right: 5px;
  }
}

.column-2 .custom-card-agg.pageEl {
  padding-left: 0;
}

@media screen and (min-width: 768px) {
  .column-2 .custom-card-agg.pageEl {
    padding-left: 5px;
  }
}

.pageEl .audioElement h3 {
  margin-bottom: .5em;
}

/* Collectors */
.collectorElement {
  display: flex;
  flex-flow: row wrap;
}

.collectorElement .tags {
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
}

.collectorElement .bottomOptions {
  display: flex;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: flex-end;
}

.collectorNews .item {
  position: relative;
  display: flex;
  flex-flow: column;
  max-width: calc(100% / 3 - 30px);
  width: 100%;
  padding-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  background-color: #FAFAFA;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .collectorNews .item {
    max-width: none;
    display: block;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.collectorNews .item > a {
  display: block;
  float: left;
  display: block;
  position: relative;
  width: 100%;
  max-width: 420px;
  margin-bottom: 30px;
}

.collectorNews .item > a img {
  width: 100%;
  display: block;
}

.collectorNews .item > a:before {
  content: "";
  float: left;
  padding-bottom: 50%;
}

.collectorNews .item > a img {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

.collectorNews .item > h4 {
  margin-left: 20px;
  margin-top: 7px;
}

.collectorNews .item > h4 a {
  font-size: 24px;
  font-weight: 400;
  color: #333;
  transition: color 0.2s linear;
  line-height: 32px;
}

.collectorNews .item > h4 a:hover {
  color: #FF595A;
}

@media screen and (max-width: 1024px) {
  .collectorNews .item > h4 a {
    padding-left: 0;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .collectorNews .item > h4 a {
    font-size: 18px;
  }
}

.collectorNews .dateAuthor {
  line-height: 1;
  margin: 0;
}

.collectorNews .newsAuthor {
  color: #666666;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  line-height: 12px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.collectorNews .newsAuthor:before {
  content: '';
  background-image: url("../images/news-article-page-ui/user-circle-solid.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  font-size: 12px;
  margin-right: 1ch;
  display: inline-block;
}

.collectorNews .newsDate {
  display: none;
}

.collectorNews .teaser {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #475157;
  font-size: 16px;
  line-height: 24px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}

@media screen and (max-width: 1024px) {
  .collectorNews .teaser {
    font-size: 12px;
  }
}

.collectorNews .tags {
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding-left: 20px;
  margin-top: auto;
  color: #68767F;
}

.collectorNews .tags a {
  display: inline-block;
  font-size: 12px;
  color: #2F373B;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #DFE4E6;
  transition: color 0.2s linear, background-color 0.2s linear;
}

.collectorNews .tags a:hover {
  color: #ffffff;
  background-color: #FF595A;
}

.collectorNews .clearAll {
  display: none;
}

.collectorDocuments .item,
.collectorPhotos .item {
  padding: 25px 0;
  border-style: solid;
  border-width: 0;
  border-top-color: #EDF0F1;
  border-top-width: 2px !important;
}

.collectorDocuments .item:nth-of-type(1),
.collectorPhotos .item:nth-of-type(1) {
  border: none;
}

.collectorDocuments .item .info,
.collectorPhotos .item .info {
  font-size: 1em;
  font-weight: normal;
  float: left;
  margin-left: 25px;
}

.collectorDocuments .item > a img,
.collectorPhotos .item > a img {
  display: none;
}

.collectorDocuments img,
.collectorPhotos img {
  margin: 0 10px 10px 0;
  float: left;
}

.collectorDocuments h4,
.collectorPhotos h4 {
  font-size: 1.125em;
}

.collectorDocuments li h4 a,
.collectorPhotos li h4 a {
  font-size: .75em;
  text-decoration: none;
}

.collectorDocuments .dateAuthor,
.collectorPhotos .dateAuthor {
  color: #666;
  font-size: .75em;
  margin-bottom: .25em;
}

.paginationElement {
  background: transparent;
  border-bottom: none;
  padding: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .paginationElement {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.paginationElement .paginationNav {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
}

@media screen and (max-width: 767px) {
  .paginationElement .paginationNav {
    float: left;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.paginationElement .paginationNav a {
  color: #68767F;
  margin-right: 3px;
  margin-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
  padding-left: 3px;
  border-bottom: 2px solid #ffffff;
  transition: border-color 0.2s linear, color 0.2s linear;
}

.paginationElement .paginationNav a:hover {
  color: #CF3338;
  font-style: normal;
  border-bottom: 2px solid #CF3338;
}

.paginationElement .paginationNav a span {
  font-family: 'fontawesome';
  font-size: 9px;
  display: inline-block;
  transform: translateY(-1px);
}

.paginationElement .paginationNav a.previous_page {
  transition: color 0.2s linear;
}

.paginationElement .paginationNav a.previous_page span {
  padding-right: 10px;
}

.paginationElement .paginationNav a.previous_page:hover {
  color: #CF3338;
}

.paginationElement .paginationNav a.next_page span {
  padding-left: 10px;
}

.paginationElement .paginationNav .current {
  color: #CF3338;
  font-style: normal;
  margin-right: 3px;
  margin-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
  padding-left: 3px;
  border-bottom: 2px solid #CF3338;
}

.paginationElement .results {
  font-size: 12px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #666666;
}

/* Feed Reader */
.feeder > h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@media only screen and (max-width: 767px) {
  .feeder > h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.feeder > div:nth-of-type(1) > div {
  padding: 25px 0;
  border-style: solid;
  border-width: 0;
  border-top-color: #EDF0F1;
  border-top-width: 2px !important;
}

.feeder > div:nth-of-type(1) > div:nth-of-type(1) {
  border: none;
}

.feeder > div:nth-of-type(1) > div > ul li:last-child > p:last-child {
  margin-bottom: 0;
}

.feeder .even > ul, .feeder .odd > ul {
  list-style-type: none;
}

.feeder .even > ul ul, .feeder .odd > ul ul {
  list-style-type: disc;
  padding-left: .5em;
  margin-bottom: 1em;
}

.feeder .even > ul ol, .feeder .odd > ul ol {
  margin-bottom: 1em;
}

.feeder .even > ul ul li, .feeder .even > ul ol li, .feeder .odd > ul ul li, .feeder .odd > ul ol li {
  margin-left: 1em;
}

.feeder blockquote {
  float: none;
  color: #666;
  font-size: initial;
  width: initial;
  padding: 0 1em;
  border-left: 2px solid #EEE;
  margin: 1em;
}

.feeder blockquote:before, .feeder blockquote:after {
  display: none;
}

.feeder img {
  max-width: 100%;
}

.feeder table {
  max-width: 100%;
  overflow-x: scroll;
  display: block;
}

.contactElement img {
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
  max-width: 50px;
  max-height: 100px;
}

.contactElement img + div {
  max-width: calc(100% - 70px);
}

.contactElement img + div h3 {
  margin-top: -3px;
}

.contactElement h3 {
  color: #000000;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: none;
}

.contactElement p {
  color: #666;
  font-size: .75em;
  font-weight: inherit;
  line-height: 1.2em;
  margin-top: 4px;
  margin-bottom: 5px;
  text-align: left;
}

.contactElement p:last-of-type {
  margin-bottom: 9px;
}

.contactElement p:last-child {
  margin-bottom: 0;
}

.contactElement .title {
  border: none;
  padding: 0;
}

.contact-element-first-name {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
}

.contact-element-last-name {
  display: block;
  font-size: 24px;
  margin-top: 2px;
}

.pageElementAdmin .sponsorElement {
  padding-bottom: 10px;
}

.sponsorElement {
  border-top: none;
}

.sponsorElement .description > h3 {
  text-transform: none;
}

.sponsorElement .description .goToLink {
  font-size: .75em;
  font-weight: 600;
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 5px 10px 5px 20px;
  display: inline-block;
  background-position-y: -460px;
  margin: .5em 0;
}

.sponsorElement .imageLink {
  float: left;
  text-align: center;
  margin: 5px 10px 10px 0;
  display: block;
}

.sponsorElement .imageLink img {
  display: block;
}

.sponsorElement.hasImage .description {
  padding-left: 110px;
}

@media only screen and (min-width: 1000px) {
  .col-md-3 .sponsorElement.hasImage .imageLink {
    float: none;
  }
  .col-md-3 .sponsorElement.hasImage .description {
    padding: 0;
  }
}

@media only screen and (min-width: 769px) {
  .col-md-4 .sponsorElement.hasImage .imageLink {
    float: none;
  }
  .col-md-4 .sponsorElement.hasImage .description {
    padding: 0;
  }
}

@media only screen and (max-width: 350px) {
  .sponsorElement.hasImage .imageLink {
    float: none;
  }
  .sponsorElement.hasImage .description {
    padding: 0;
  }
}

.contentTabs + .collectorTree.siteMapElement {
  display: inline-block;
  width: 100%;
  border-top: 2px solid #fff;
}

.contentTabs + .collectorTree.siteMapElement a[href*="/document/"] + .info a.title {
  position: relative;
}

.contentTabs + .collectorTree.siteMapElement a[href*="/document/"] + .info a.title:before {
  top: -.2em;
  left: -20px;
}

.contentTabs + .collectorTree.siteMapElement a[href*="/photo_gallery/"] {
  position: relative;
}

.contentTabs + .collectorTree.siteMapElement a[href*="/photo_gallery/"]:before {
  top: -.2em;
  left: -20px;
}

.siteMapElement {
  background: #eee;
}

.siteMapElement h3, .siteMapElement h4 {
  color: #666;
  font-size: .875em;
  font-weight: normal !important;
  padding: 0;
  margin: 0;
  text-transform: none;
}

.siteMapElement .unlimitedHierarchy {
  margin-left: 0;
  padding: 15px;
  background: none;
}

.siteMapElement .unlimitedHierarchy li {
  display: block;
  position: relative;
  list-style-type: none;
  background: none;
  margin-left: 0;
  padding: 0;
}

.siteMapElement .unlimitedHierarchy li div {
  background: none;
}

.siteMapElement .unlimitedHierarchy li li {
  position: relative;
  padding-left: 30px;
}

.siteMapElement .unlimitedHierarchy li li:before, .siteMapElement .unlimitedHierarchy li li:not(.lastLi):after {
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  display: block;
  width: 25px;
  height: 100%;
  border-left: 1px solid #CCC;
}

.siteMapElement .unlimitedHierarchy li li:before {
  height: 1em;
  border-bottom: 1px solid #CCC;
}

.siteMapElement .unlimitedHierarchy .lastLi {
  background: none;
}

/* News Slideshow Styles */
.drag .newsSlideShow {
  cursor: pointer;
}

.newsSlideShow-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 2.5rem;
}

.newsSlideShow-article {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 2.5rem);
  border: 2px solid #ddd;
  background-color: #fff;
}

.newsSlideShow-article.current {
  z-index: 1;
}

.newsSlideShow-article img {
  position: absolute;
}

.newsSlideShow-headline {
  position: absolute;
  bottom: .25rem;
  z-index: 1;
  width: 100%;
  margin: 0;
}

.newsSlideShow-headline h1 {
  position: relative;
  display: block;
  width: calc(96% - 7px);
  margin: 0 0 2px 7px;
  padding: 20px;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.75);
  text-transform: none;
}

.newsSlideShow-headline h1:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -7px;
  height: 100%;
  width: 5px;
  background-color: #CF3338;
}

.newsSlideShow-headline h1:after {
  content: "";
  clear: both;
}

.newsSlideShow-headline h1 a {
  display: block;
  width: 100%;
  color: #fff;
  font-size: 32px;
  line-height: 1.2;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .newsSlideShow-headline h1 a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .col-md-3 .newsSlideShow-headline h1 a {
    -webkit-line-clamp: 1;
  }
}

.newsSlideShow-headline p {
  display: block;
  width: 96%;
  padding-left: 7px;
  margin: 0 0 2px 0;
  line-height: 1.5em;
  font-size: 12px;
  font-style: italic;
}

.newsSlideShow-headline p span {
  display: block;
  float: left;
  width: auto;
  max-width: calc(100% - 142px);
  height: 2.25rem;
  padding-right: 10px;
  line-height: 2.25rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  text-indent: 10px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.newsSlideShow-headline p span:empty {
  display: none;
}

.newsSlideShow-headline p a {
  float: left;
  width: 140px;
  margin: 0;
  height: 2.25rem;
  line-height: 2.25rem;
}

.newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  width: calc(100% - 5rem);
  height: 2.5rem;
  box-shadow: 0 -2px #ddd;
  list-style-type: none;
  line-height: 1;
  text-align: center;
  background-color: #fff;
  cursor: default;
  transform: translateX(-50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-md-3 .newsSlideShow-thumbnails {
  font-size: .5em;
}

@media only screen and (max-width: 450px) {
  .newsSlideShow-thumbnails {
    font-size: .5em;
  }
}

.newsSlideShow-thumbnails li {
  display: inline-block;
}

.newsSlideShow-thumbnails a {
  display: block;
  padding: 4px;
}

.col-md-3 .newsSlideShow-thumbnails a {
  padding: 6px;
}

@media only screen and (max-width: 450px) {
  .newsSlideShow-thumbnails a {
    padding: 6px;
  }
}

.newsSlideShow-thumbnails a:before {
  content: '';
  display: block;
  width: .75em;
  height: .75em;
  border-radius: 100%;
  background-color: #000000;
  transition: transform .1s ease-in-out, background-color .1s ease-in-out;
}

.newsSlideShow-thumbnails a:hover:before, .newsSlideShow-thumbnails a.current:before {
  background-color: #CF3338;
  transform: scale(1.375, 1.375);
}

.newsSlideShow-thumbnails img {
  display: none;
  width: 100%;
}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
  position: absolute;
  z-index: 10;
  bottom: 0;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 -2px #ddd;
  font-size: 1em;
  text-align: center;
  color: #000000;
}

.newsSlideShow-prev.pill-small-left:hover,
.newsSlideShow-next.pill-small-right:hover {
  color: #CF3338;
  background-color: #fff;
}

.newsSlideShow-prev.pill-small-left:hover:before,
.newsSlideShow-next.pill-small-right:hover:before {
  transform: scale(1.375);
}

.newsSlideShow-prev.pill-small-left:before,
.newsSlideShow-next.pill-small-right:before {
  line-height: 2.5;
  content: '\f060';
  display: block;
  color: inherit;
  font-family: 'fontawesome';
  transition: transform .1s ease-in-out, color .1s ease-in-out;
}

.newsSlideShow-prev.pill-small-left {
  left: 0;
}

.newsSlideShow-next.pill-small-right {
  right: 0;
}

.newsSlideShow-next.pill-small-right:before {
  content: '\f061';
}

.newsSlideShow-empty {
  text-align: center;
}

.newsSlideShow-empty > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.newsSlideShow-more.button-small {
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: block;
  padding: 17px 47px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.newsSlideShow-more.button-small:focus, .newsSlideShow-more.button-small:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .newsSlideShow-more.button-small {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .newsSlideShow-more.button-small {
    font-size: 14px;
  }
}

.newsSlideShow-stop,
.newsSlideShow-play {
  display: none !important;
}

.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 {
  height: initial !important;
}

.aspect_ratio_original:before {
  content: '';
  float: left;
  padding-bottom: 56.25%;
}

.aspect_ratio_original:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_1_1:before {
  content: '';
  float: left;
  padding-bottom: 100%;
}

.aspect_ratio_1_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_4_3:before {
  content: '';
  float: left;
  padding-bottom: 75%;
}

.aspect_ratio_4_3:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_16_9:before {
  content: '';
  float: left;
  padding-bottom: 56.25%;
}

.aspect_ratio_16_9:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_2_1:before {
  content: '';
  float: left;
  padding-bottom: 50%;
}

.aspect_ratio_2_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_3_1:before {
  content: '';
  float: left;
  padding-bottom: 33.33333%;
}

.aspect_ratio_3_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_4_1:before {
  content: '';
  float: left;
  padding-bottom: 25%;
}

.aspect_ratio_4_1:after {
  content: '';
  display: table;
  clear: both;
}

.aspect_ratio_8_5:before {
  content: '';
  float: left;
  padding-bottom: 62.5%;
}

.aspect_ratio_8_5:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (min-width: 767px) {
  .col-md-3 .newsSlideShow-headline h1 a,
  .col-md-4 .newsSlideShow-headline h1 a {
    font-size: 18px;
  }
  .col-md-3 .newsSlideShow-headline p,
  .col-md-4 .newsSlideShow-headline p {
    display: none;
  }
  .col-md-3 .aspect_ratio_4_1 .newsSlideShow-headline,
  .col-md-3 .aspect_ratio_3_1 .newsSlideShow-headline,
  .col-md-3 .aspect_ratio_2_1 .newsSlideShow-headline,
  .col-md-4 .aspect_ratio_4_1 .newsSlideShow-headline,
  .col-md-4 .aspect_ratio_3_1 .newsSlideShow-headline,
  .col-md-4 .aspect_ratio_2_1 .newsSlideShow-headline,
  .col-md-6 .aspect_ratio_4_1 .newsSlideShow-headline,
  .col-md-6 .aspect_ratio_3_1 .newsSlideShow-headline,
  .col-md-6 .aspect_ratio_2_1 .newsSlideShow-headline,
  .col-md-8 .aspect_ratio_4_1 .newsSlideShow-headline,
  .col-md-8 .aspect_ratio_3_1 .newsSlideShow-headline,
  .col-md-8 .aspect_ratio_2_1 .newsSlideShow-headline {
    padding-bottom: 2em;
  }
}

@media only screen and (max-width: 450px) {
  .aspect_ratio_4_1 .newsSlideShow-headline,
  .aspect_ratio_3_1 .newsSlideShow-headline,
  .aspect_ratio_2_1 .newsSlideShow-headline {
    padding-bottom: 2em;
  }
  .newsSlideShow-headline h1 a {
    font-size: 18px;
  }
  .newsSlideShow-headline p {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  .newsSlideShow-headline p {
    display: none;
  }
}

/* Weather Element Styles */
.weatherElement {
  padding: 20px 0;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #666;
  border-top-color: #ddd;
  border-bottom-color: #ddd;
  border-width: 3px 0 3px 0;
  border-image: repeating-linear-gradient(-72deg, #AAA 0px, transparent 1px, transparent 3px, #AAA 4px) 3 3;
}

.weatherElement.weather-noForecast .weather-currentConditions {
  width: 100%;
  border: none;
}

.weatherElement.weather-noForecast .weather-currentConditions .weather-range {
  display: block;
}

.weatherElement .weather-5dayForecast li {
  width: 100%;
  padding: 0;
  font-size: 18px;
  height: 34px;
}

.weatherElement .weather-5dayForecast li .clearfix {
  width: initial;
  float: right;
  clear: none;
}

.weatherElement .weather-5dayForecast li b {
  float: left;
  display: inline;
  clear: none;
  width: 42px;
  font-size: 16px;
  float: left;
  font-weight: 700;
  line-height: 32px;
}

.weatherElement .weather-5dayForecast li img {
  float: left;
  display: inline;
  clear: none;
  width: 30px;
  float: left;
  margin-left: 0;
  margin-top: 5px;
}

.weatherElement .weather-5dayForecast .weather-high, .weatherElement .weather-5dayForecast .weather-low {
  display: inline;
}

.weatherElement .weather-currentConditions {
  text-align: right;
  background: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-shadow: none;
  padding: 15px;
  border: none;
  width: 55%;
  float: left;
}

.weatherElement .weather-currentConditions .weather-icon {
  float: left;
  margin: 0 0 0 -5px;
  width: 33%;
  max-width: 180px;
}

.weatherElement .weather-currentConditions .weather-icon img {
  display: block;
  margin: 0 auto;
  width: 100%;
  opacity: .7;
}

.weatherElement .weather-currentConditions .weather-temperature {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 52px;
  font-weight: 700;
  color: #666;
  line-height: 1;
  margin: 0;
}

.weatherElement .weather-currentConditions .weather-description {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  color: #666;
}

.weatherElement .weather-currentConditions .weather-range {
  font-size: 32px;
  margin: 0;
}

.weatherElement .weather-currentConditions .weather-location {
  font-size: 12px;
  line-height: 1em;
}

.weatherElement .weather-currentConditions p.weather-range {
  display: none;
}

.weatherElement .weather-currentConditions p.weather-location {
  margin: 0;
  line-height: 1.4;
}

.weather-5dayForecast {
  border: 0;
  clear: none;
  width: 45%;
  margin: 0;
  padding: 5px 15px;
  font-size: 16px;
  float: right;
  border-left: 2px solid #ddd;
}

.weather-5dayForecast ul {
  border-radius: 0;
  font-weight: 400;
  list-style: none;
  margin: 0;
  padding: 0;
}

.weather-5dayForecast .weather-high {
  line-height: 32px;
  margin-right: 10px;
}

.weather-5dayForecast .weather-low {
  line-height: 32px;
}

@media only screen and (min-width: 768px) {
  .col-md-3 .weather-currentConditions,
  .col-md-4 .weather-currentConditions,
  .col-md-6 .weather-currentConditions {
    text-align: left;
    width: 100%;
    border: 0;
  }
  .col-md-3 .weather-currentConditions .weather-temperature,
  .col-md-4 .weather-currentConditions .weather-temperature,
  .col-md-6 .weather-currentConditions .weather-temperature {
    font-size: 42px;
    padding-left: 35%;
  }
  .col-md-3 .weather-currentConditions .weather-description,
  .col-md-3 .weather-currentConditions .weather-range,
  .col-md-3 .weather-currentConditions .weather-location,
  .col-md-4 .weather-currentConditions .weather-description,
  .col-md-4 .weather-currentConditions .weather-range,
  .col-md-4 .weather-currentConditions .weather-location,
  .col-md-6 .weather-currentConditions .weather-description,
  .col-md-6 .weather-currentConditions .weather-range,
  .col-md-6 .weather-currentConditions .weather-location {
    padding-left: 35%;
  }
  .col-md-3 .weather-5dayForecast,
  .col-md-4 .weather-5dayForecast,
  .col-md-6 .weather-5dayForecast {
    width: 100%;
    border-left: none;
  }
  .col-md-3 .weather-currentConditions .weather-icon {
    width: 100%;
  }
  .col-md-3 .weather-currentConditions .weather-temperature {
    font-size: 36px;
    padding-left: 0;
  }
  .col-md-3 .weather-currentConditions .weather-description {
    font-size: 16px;
    padding-left: 0;
  }
  .col-md-3 .weather-currentConditions .weather-location {
    font-size: 10px;
    padding-left: 0;
  }
  .col-md-3 .weather-currentConditions .weather-range {
    padding-left: 0;
  }
  .col-md-3 .weather-5dayForecast {
    width: 100%;
    border-left: none;
  }
}

@media (max-width: 1024px) {
  .col-md-4 .weatherElement .weather-5dayForecast li,
  .col-md-3 .weatherElement .weather-5dayForecast li {
    font-size: 14px;
  }
  .col-md-4 .weatherElement .weather-5dayForecast li b,
  .col-md-3 .weatherElement .weather-5dayForecast li b {
    width: 36px;
  }
  .col-md-4 .weatherElement .weather-5dayForecast li img,
  .col-md-3 .weatherElement .weather-5dayForecast li img {
    display: block;
  }
}

@media (max-width: 1000px) and (min-width: 768px) {
  .layout-25-50-25 .col-md-3 .weather-currentConditions .weather-icon {
    float: none;
  }
}

@media only screen and (max-width: 540px) {
  .weatherElement .weather-currentConditions {
    width: 100%;
    border: 0;
  }
  .weatherElement .weather-5dayForecast {
    width: 100%;
    border-left: none;
  }
}

/* pe_survey_report.css **************************************************************************************************************************************** */
#defaultReport {
  clear: both;
}

.report-container {
  clear: both;
  overflow: auto;
  position: relative;
  border: 1px solid #ccc;
}

.report-scroll {
  border: 1px solid #CCC;
}

.itemVariations .columnName {
  display: inline-block;
  width: 50%;
  padding: 5px 0;
}

.reportTableElement .filtered_survey_actions {
  float: right;
  list-style: none;
  margin-left: 10px;
}

.reportTableElement .filtered_survey_actions .icon {
  color: #666;
  font-size: 14px;
  float: left;
  text-indent2: -999em;
  padding: 0 0 0 20px;
  margin: 3px 6px 0 0;
}

.reportTableElement .report_results_count {
  background: url("https://app-assets1.sportngin.com/app_images/registration/gloss_shadow_light.png") repeat-x 0 center #EEE;
  border: 1px solid #DDD;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #666;
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
  margin: 0;
  padding: 5px 4px 4px;
}

.reportTableElementHeader, .reportTableElementHeader h3, .reportTableElement .filtered_survey_actions li {
  display: inline;
}

.reportTable {
  margin-bottom: 0;
}

.reportTable tbody tr:last-child td {
  border-bottom: none;
}

.reportTable tbody tr td:first-child {
  border-left: none;
}

.reportTable tbody tr td:last-child {
  border-right: none;
}

/* fullscreen report popup */
.fullscreen-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 99999;
}

.fullscreen-popup {
  background: #FFF;
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  padding: 20px;
}

.fullscreen-popup .fullscreen-close {
  line-height: 20px;
  position: fixed;
  z-index: 99999;
  top: 20px;
  font-weight: bold;
  right: 20px;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  color: #999;
  text-align: center;
}

.fullscreen-popup .fullscreen-close:hover {
  color: #c00;
}

/* Venue Status Page Element */
.venueElement .dataTable .heading .title {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  white-space: normal;
}

.venueElement .dataTable .venue_heading {
  margin-right: 5px;
}

.venueElement .dataTable td {
  text-align: left;
}

.venueElement .dataTable .venue_name {
  font-weight: bold;
}

.venueElement .dataTable .txt_open,
.venueElement .dataTable .txt_closed,
.venueElement .dataTable .txt_pending {
  text-align: center;
}

.venueElement .dataTable .txt_open {
  color: #02A218;
}

.venueElement .dataTable .txt_closed {
  color: #E50000;
}

.venueElement .dataTable .txt_pending {
  color: #929292;
}

.venueElement .dataTable .img_open,
.venueElement .dataTable .img_closed,
.venueElement .dataTable .img_pending {
  display: inline-block;
  height: .6em;
  width: .6em;
  margin-right: .5em;
  border-radius: 100%;
  display: none;
}

.venueElement .dataTable .img_open {
  background-color: #02A218;
}

.venueElement .dataTable .img_closed {
  background-color: #E50000;
}

.venueElement .dataTable .img_pending {
  background-color: #929292;
}

@media (max-width: 480px) {
  .venueElement .dataTable td {
    border-right: none;
    display: block;
    width: 100%;
    height: auto;
  }
  .venueElement .dataTable .venue_name {
    font-weight: bold;
  }
  .venueElement .dataTable .txt_open,
  .venueElement .dataTable .txt_closed,
  .venueElement .dataTable .txt_pending {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .col-md-3 .venueElement .dataTable td,
  .col-md-4 .venueElement .dataTable td {
    border-right: none;
    display: block;
    width: 100%;
    height: auto;
  }
  .col-md-3 .venueElement .dataTable .venue_name,
  .col-md-4 .venueElement .dataTable .venue_name {
    font-weight: bold;
  }
  .col-md-3 .venueElement .dataTable .txt_open,
  .col-md-3 .venueElement .dataTable .txt_closed,
  .col-md-3 .venueElement .dataTable .txt_pending,
  .col-md-4 .venueElement .dataTable .txt_open,
  .col-md-4 .venueElement .dataTable .txt_closed,
  .col-md-4 .venueElement .dataTable .txt_pending {
    text-align: left;
  }
}

@media (min-width: 769px) and (max-width: 999px) {
  .layout-25-50-25 .column .venueElement .dataTable td {
    border-right: none;
    display: block;
    width: 100%;
    height: auto;
  }
  .layout-25-50-25 .column .venueElement .dataTable .venue_name {
    font-weight: bold;
  }
  .layout-25-50-25 .column .venueElement .dataTable .txt_open,
  .layout-25-50-25 .column .venueElement .dataTable .txt_closed,
  .layout-25-50-25 .column .venueElement .dataTable .txt_pending {
    text-align: left;
  }
}

/* Power Rankings Element */
.rankingsElement {
  overflow-x: auto;
  overflow-y: hidden;
}

.rankingsElement > h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  margin-left: 2px;
}

@media only screen and (max-width: 767px) {
  .rankingsElement > h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.rankingsElement .statTable .expandedView {
  display: table-cell;
}

.rankingsElement .statTable td.name, .rankingsElement .statTable th.name {
  text-align: left;
}

.rankingsElement .statTable th:nth-child(1) {
  width: 5%;
}

.rankingsElement .statTable th.logoLink {
  width: 0%;
}

.rankingsElement .statTable th.name {
  width: 65%;
}

.rankingsElement .statTable th:nth-child(4) {
  width: 10%;
}

.rankingsElement .statTable th:nth-child(5) {
  width: 10%;
}

.rankingsElement .statTable th:nth-child(6) {
  width: 10%;
}

.rankingsElement .statTable td:nth-child(1) {
  font-weight: bold;
}

.rankingsElement .statTable td.name a {
  min-width: 180px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rankingsElement .statTable td.logoLink {
  padding: 0;
}

.rankingsElement .statTable td.logoLink .teamLogo-large_square {
  position: relative;
  display: inline-block;
  float: none;
  background-size: cover !important;
  width: 30px !important;
  vertical-align: middle;
  background: #fff;
  background-size: 100% auto;
  background-position: 50% 50%;
  margin: 2px 6px;
  border: none;
}

.rankingsElement .logoLink .teamLogo-medium {
  float: none;
  margin: 0 auto;
}

.rankingsElement .up,
.rankingsElement .down,
.rankingsElement .noChange {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: transparent;
  text-align: center;
}

.rankingsElement .up:after,
.rankingsElement .down:after,
.rankingsElement .noChange:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: FontAwesome;
  font-size: 14px;
}

.rankingsElement .up:after {
  content: "\f062";
  color: #2ac258;
}

.rankingsElement .down:after {
  content: "\f063";
  color: #c22a2a;
}

.rankingsElement .noChange:after {
  content: "\f07e";
  color: #666;
}

/* Single Image Element */
.heroPhotoElement img {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.heroPhotoElement .cutline {
  margin: 0;
  width: 100%;
}

/* Survey Link Element */
.pageElement.surveyLinkElement {
  background: transparent;
  background-image: none;
  text-align: center;
}

.pageElement.surveyLinkElement a {
  display: block;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  background: #000000;
  border-bottom: 3px solid black;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  transition: background 0.3s ease-in-out;
}

.pageElement.surveyLinkElement a:hover {
  background: black;
}

.pageElement.surveyLinkElement a span {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

.pageElement.surveyLinkElement a span:after {
  content: "\f061";
  display: inline-block;
  margin-left: 6px;
  height: 1rem;
  line-height: 1rem;
  font-size: 13px;
  font-family: FontAwesome;
  color: #CF3338;
  cursor: pointer;
  transform: translate(0, -1px);
  transition: color 0.3s ease-in-out;
}

.pageElement.surveyLinkElement a small {
  font-size: 16px;
  font-weight: 300;
}

.pageElement.surveyLinkElement a:hover > span:after {
  color: #fff;
}

.sn-call-to-action,
.sn-call-to-action * {
  box-sizing: border-box;
}

.sn-call-to-action {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.pageElement[id*=callToAction] {
  height: 100%;
}

.sn-call-to-action {
  border-radius: 10px;
  transition: all .2s ease-in-out;
  max-height: 225px;
  height: 100%;
}

.sn-call-to-action:hover {
  transform: translate(0, -10px);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
}

.sn-call-to-action .sn-call-to-action-title {
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 24px;
  line-height: 1.167;
  font-weight: 700;
  text-transform: normal;
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sn-call-to-action .sn-call-to-action-title {
    font-size: 24px;
  }
  .sn-call-to-action .sn-call-to-action-title:after {
    bottom: -10px;
  }
}

.sn-variation-slide-up .sn-call-to-action .sn-call-to-action-title:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #CF3338;
  display: block;
  position: absolute;
  bottom: -15px;
  left: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sn-variation-slide-up .sn-call-to-action .sn-call-to-action-title:after {
    bottom: -10px;
  }
}

.sn-call-to-action .sn-call-to-action-subtitle {
  color: white;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
}

.sn-call-to-action .sn-call-to-action-subtitle a,
.sn-call-to-action .sn-call-to-action-subtitle span {
  margin: 0 .25em;
  color: white;
  display: inline-block;
}

.sn-call-to-action .sn-call-to-action-subtitle a {
  display: block;
  padding: 17px 47px;
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: intrinsic;
  /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;
  /* Firefox/Gecko */
  width: -webkit-max-content;
  margin: 0 auto;
}

.sn-call-to-action .sn-call-to-action-subtitle a:focus, .sn-call-to-action .sn-call-to-action-subtitle a:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .sn-call-to-action .sn-call-to-action-subtitle a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .sn-call-to-action .sn-call-to-action-subtitle a {
    font-size: 14px;
  }
}

.sn-call-to-action .sn-call-to-action-overlay-text {
  text-align: left;
  width: 100%;
  padding: 15px;
}

.sn-call-to-action {
  display: flex;
  justify-content: center;
  position: relative;
}

.sn-call-to-action:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 70%, black 100%);
  display: block;
}

.sn-variation-border {
  align-items: stretch;
}

.sn-variation-test-zoom {
  align-items: stretch;
}

.sn-variation-zoom {
  align-items: stretch;
}

.sn-call-to-action-overlay {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  padding: 15px 15px;
}

.sn-call-to-action-overlay-inner {
  width: 100%;
}

.sn-call-to-action-subtitle {
  padding-top: 5px;
}

.sn-call-to-action img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  height: 400px !important;
  width: 100%;
}

.sn-call-to-action .sn-call-to-action-overlay {
  position: relative;
}

.sn-call-to-action .aspect-element {
  display: block;
  float: left;
  width: 1px;
  margin-left: -1px;
  height: 0;
}

.sn-variation-zoom img {
  transform: scale(1);
  transition: transform 0.2s linear;
}

.sn-variation-zoom:hover img {
  transform: scale(1.2);
}

.sn-variation-text-zoom .sn-call-to-action-overlay-text {
  transform: scale(1);
  transition: transform 0.2s linear;
}

.sn-variation-text-zoom:hover .sn-call-to-action-overlay-text {
  transform: scale(1.1);
}

.sn-variation-border .sn-call-to-action-overlay {
  box-shadow: inset 0 0 0 0 transparent;
  transition: box-shadow 0.2s linear;
}

.sn-variation-border:hover .sn-call-to-action-overlay {
  box-shadow: inset 0 0 0 10px rgba(207, 51, 56, 0.95);
}

.sn-variation-slide-up {
  position: relative;
  display: block;
  max-height: 400px !important;
  height: 400px !important;
}

.sn-variation-slide-up:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%, black 100%);
  display: block;
}

.sn-variation-slide-up .sn-call-to-action-overlay {
  display: block;
  background-color: transparent;
  position: absolute;
  top: auto;
  bottom: 35px;
  left: 0;
  right: 0;
  height: 0;
  transition: height 0.2s linear, box-shadow 0.2s linear, background-color 0.2s linear;
  width: 100%;
  text-align: center;
  margin: 0;
  margin-bottom: 0;
}

.sn-variation-slide-up .sn-call-to-action-overlay-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.sn-variation-slide-up .sn-call-to-action-overlay-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1;
}

.sn-variation-slide-up .sn-call-to-action-title {
  margin-bottom: 20px;
}

.sn-variation-slide-up .sn-call-to-action-title:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #CF3338;
  display: block;
  position: absolute;
  bottom: -15px;
  left: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sn-variation-slide-up .sn-call-to-action-title:after {
    bottom: -10px;
  }
}

.sn-variation-slide-up .sn-call-to-action-subtitle {
  opacity: 0;
  transition: opacity 0.2s linear;
}

.sn-variation-slide-up:hover img {
  transform: scale(1);
  transition: transform 0.2s linear;
}

.sn-variation-slide-up:hover:hover img {
  transform: scale(1.1);
}

.sn-variation-slide-up:hover .sn-call-to-action-overlay {
  height: 100%;
  background-color: rgba(207, 51, 56, 0.95);
  margin-bottom: 0;
  bottom: 0;
}

.sn-variation-slide-up:hover .sn-call-to-action-subtitle {
  opacity: 1;
}

.sn-variation-default .sn-call-to-action-title {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  position: relative;
  transition: color 0.2s linear, transform 0.2s linear;
}

.sn-variation-default .sn-call-to-action-title:after {
  content: "";
  display: block;
  right: 0;
  width: 0;
  margin-top: 0;
  background-color: #CF3338;
  height: 2px;
  opacity: 0;
  transition: opacity .2s ease-in-out, width .2s ease-in-out, right .2s linear, left .2s linear;
}

.sn-variation-default:hover .sn-call-to-action-title {
  color: #CF3338;
  transform: translateY(-10px);
}

.sn-variation-default:hover .sn-call-to-action-title:after {
  opacity: 1;
  left: 0;
  right: auto;
  width: 100%;
  margin-top: 5px;
  transition: opacity .2s ease-in-out, width .2s ease-in-out, right .2s linear, left .2s linear, margin .2s linear;
}

.sn-variation-default .aspect-element {
  padding-bottom: 75% !important;
}

.sn-variation-default img {
  transform: scale(1);
  transition: transform 0.2s linear;
}

.sn-variation-default:hover img {
  transform: scale(1.1);
}

.touch-screen .sn-variation-slide-up .sn-call-to-action-overlay, .touch-screen .sn-variation-slide-up:focus .sn-call-to-action-overlay, .touch-screen .sn-variation-slide-up:hover .sn-call-to-action-overlay {
  height: calc(100% - 30px);
  margin-top: 15px;
  margin-bottom: 0;
  bottom: 0;
  transition: none;
}

.touch-screen .sn-variation-slide-up .sn-call-to-action-subtitle, .touch-screen .sn-variation-slide-up:focus .sn-call-to-action-subtitle, .touch-screen .sn-variation-slide-up:hover .sn-call-to-action-subtitle {
  opacity: 1;
  transition: none;
}

.touch-screen .sn-variation-border .sn-call-to-action-overlay, .touch-screen .sn-variation-border:focus .sn-call-to-action-overlay, .touch-screen .sn-variation-border:hover .sn-call-to-action-overlay {
  box-shadow: inset 0 0 0 10px rgba(207, 51, 56, 0.95);
  transition: none;
}

.touch-screen .sn-variation-text-zoom .sn-call-to-action-overlay-text, .touch-screen .sn-variation-text-zoom:focus .sn-call-to-action-overlay-text, .touch-screen .sn-variation-text-zoom:hover .sn-call-to-action-overlay-text {
  transform: scale(1);
  transition: none;
}

.pageEl .textBlockElement table {
  border-style: solid;
  border-color: #999;
}

.pageEl .textBlockElement table caption {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  font-weight: bold;
}

.pageEl .textBlockElement table td,
.pageEl .textBlockElement table th {
  padding: 2px 12px;
}

.pageEl .textBlockElement table:not([border]) {
  border-style: none;
  border-spacing: 2px;
  border-collapse: separate;
}

.pageEl .textBlockElement table:not([border]) th {
  height: 50px;
  padding: 3px 12px;
  background-color: #CF3338;
  white-space: nowrap;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: white;
}

.pageEl .textBlockElement table:not([border]) td {
  height: 40px;
  padding: 2px 12px;
  font-size: 12px;
  background: #eee;
}

.pageEl .textBlockElement table[align="center"] {
  margin-left: auto;
  margin-right: auto;
}

.pageEl .textBlockElement img {
  width: auto;
  max-width: 100%;
  display: block;
  margin: auto;
}

.pageEl .textBlockElement .leftTextImage br,
.pageEl .textBlockElement .originalTextImage br,
.pageEl .textBlockElement .rightTextImage br {
  display: none;
}

.pageEl .textBlockElement .leftTextImage .cutline,
.pageEl .textBlockElement .originalTextImage .cutline,
.pageEl .textBlockElement .rightTextImage .cutline {
  width: 100%;
  margin: 0 auto;
}

.pageEl .textBlockElement p img {
  height: auto !important;
  max-width: 100%;
}

.pageEl .textBlockElement .text ul {
  list-style-type: disc;
  margin-left: 20px;
}

.pageEl .textBlockElement .text ol {
  list-style-type: decimal;
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .codeElement object,
  .codeElement embed,
  .codeElement iframe {
    width: 100%;
  }
}

/* Code Element */
.codeElement object,
.codeElement embed,
.codeElement iframe {
  max-width: 100%;
}

.codeElement code {
  color: inherit;
  background-color: inherit;
}

.displayCodeElement pre {
  padding: 10px;
  border: solid 1px #333;
  background: #666;
  color: #fff;
}

.pageElementAdmin .codeElement pre {
  padding: 10px;
  border: solid 1px #333;
  background: #666;
  color: #fff;
}

.pageElementAdmin .codeElement .codeErrorMessage {
  margin: 5px 0 0;
  padding: 5px 10px;
  background-color: #c51515;
  font-weight: 600;
  font-size: 0.875em;
  color: #fff;
}

.pageElementAdmin .codeElement.codeError {
  background-color: #fdd9d9;
  border: 1px solid #c51515;
  padding: 10px;
  color: #333;
}

@media only screen and (max-width: 480px) {
  /* filter styles */
  .tag-filterForm .tag-filterWrap {
    width: 100%;
    padding: 16px 0;
  }
  .tag-filterForm .tag-filterWrap.button {
    transform: translate(0, 0);
  }
  .tag-filterForm .inputChoice {
    float: right;
  }
  .tag-filterWrap.dropdown label {
    width: 100%;
  }
  .tag-filterWrap.dropdown label select {
    float: right;
    width: 70%;
  }
}

#FOP {
  box-sizing: content-box;
}

#PlayerStatsDiv .sport-tools:nth-child(even) ul.pill-buttons li.selected a,
#TeamStatsDiv .sport-tools:nth-child(even) ul.pill-buttons li.selected a {
  color: #000000;
  border-bottom: 3px solid #000000;
  background-color: transparent;
}

#PlayerStatsDiv .sport-tools:nth-child(even) ul.pill-buttons li a,
#TeamStatsDiv .sport-tools:nth-child(even) ul.pill-buttons li a {
  background-color: transparent;
  border: 0;
}

/* serious displaiy issues in chrome 15 Windows when combined with 3d transforms */
#GameShowScoreboard,
#FOP,
.fopPopUp {
  text-rendering: optimizespeed;
}

.sportTableLabel .teamLogo {
  float: left;
  margin-right: 5px;
}

.sportTableLabel .teamName,
.sportTableLabel h4.teamName {
  padding: 0;
}

.widgetHierarchy {
  overflow-x: auto;
  overflow-y: hidden;
}

.widgetHierarchy > ul li li .seasonDisplay {
  display: none;
}

.widgetHierarchy ul {
  list-style-type: none;
}

.widgetHierarchy .lastLi {
  margin-bottom: 0;
}

.gameXtras {
  display: block;
  list-style-type: none;
  font-size: 10px;
}

.gameXtras li {
  border-top: 1px solid #D5D5D5;
  padding: 2px 0;
}

.gameXtras label {
  font-weight: bold;
  margin-right: 5px;
}

.status_in_progress {
  font-style: italic;
  color: #5e8d0f;
}

.boxscore .status_in_progress {
  color: #a1e32f;
}

#TeamStatsDiv .stat-category-header,
#PlayerStatsDiv .stat-category-header,
#TeamInstanceStatsDiv .stat-category-header {
  margin: 20px 0;
  color: #000000;
  font: bold 38px/1.25 "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-transform: initial;
}

#TeamStatsDiv *[id^="team-sm"],
#PlayerStatsDiv *[id^="player-sm"],
#TeamInstanceStatsDiv *[id^="team-sm"],
#TeamInstanceStatsDiv *[id^="player-sm"] #TeamInstanceStatsDiv *[id^="division-team-sm"] {
  margin-bottom: 15px;
}

#TeamStatsDiv *[id^="team-sm"]:last-child,
#PlayerStatsDiv *[id^="player-sm"]:last-child,
#TeamInstanceStatsDiv *[id^="team-sm"]:last-child,
#TeamInstanceStatsDiv *[id^="player-sm"] #TeamInstanceStatsDiv *[id^="division-team-sm"]:last-child {
  margin-bottom: 0;
}

#TeamStatsDiv *[id^="team-sm"] h3,
#PlayerStatsDiv *[id^="player-sm"] h3,
#TeamInstanceStatsDiv *[id^="team-sm"] h3,
#TeamInstanceStatsDiv *[id^="player-sm"] #TeamInstanceStatsDiv *[id^="division-team-sm"] h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  #TeamStatsDiv *[id^="team-sm"] h3,
  #PlayerStatsDiv *[id^="player-sm"] h3,
  #TeamInstanceStatsDiv *[id^="team-sm"] h3,
  #TeamInstanceStatsDiv *[id^="player-sm"] #TeamInstanceStatsDiv *[id^="division-team-sm"] h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

#pageContentContainer h3.stat-category-header {
  border-top: solid 1px #999;
  background: #666;
  background: linear-gradient(to bottom, #666 0%, #333 100%);
  border-radius: 4px 4px 0px 0px;
  color: #fff;
  height: 2em;
  line-height: 2em;
  margin-bottom: .5em;
  text-indent: 10px;
}

h3.stat-category-header .stat-category-nav {
  position: absolute;
  right: 0;
  font-size: 11px;
  color: #000000;
}

#tab_completegamelist_content .pageElement {
  overflow: auto;
}

/* filter styles */
.tag-filterForm {
  display: block;
  width: 100%;
  padding: 20px 8px;
}

.tag-filterForm label {
  float: left;
  margin-right: 8px;
}

.tag-filterForm label select {
  margin-right: 4px;
}

.tag-filterForm .inputChoice {
  float: left;
  margin: 0 5px 0 0;
  padding: 6px 0 0 0;
}

.tag-filterForm .inputChoice label {
  margin-right: 4px;
  padding: 2px 2px 0 0;
  font-size: .75em;
}

.tag-filterForm .tag-filterWrap {
  display: inline-block;
  padding: 2px 12px 8px 0;
  height: 30px;
}

.tag-filterForm .tag-filterWrap.button {
  transform: translate(0, -10px);
}

.col-md-3 .tag-filterForm .tag-filterWrap.button,
.col-md-4 .tag-filterForm .tag-filterWrap.button {
  margin-top: 10px;
}

#PreviewStatsDiv .next_game {
  background-color: #333;
  color: #FFF;
  overflow: hidden;
  padding: 10px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.2) 100%);
  border-radius: 10px 10px 0 0 / 100% 100% 0 0;
  border-bottom: 1px solid #444;
}

#RosterPlayerStats {
  position: relative;
}

#RosterPlayerStats .next_game_splits {
  border-bottom: 10px solid #333;
  margin-top: 0;
  padding: 0;
}

#RosterPlayerStats .next_game_splits .NginTableControls {
  background-color: #333;
  color: #FFF;
}

#RosterPlayerStats .next_game .info {
  padding-top: 6px;
  text-align: center;
  line-height: 16px;
  width: 20%;
  float: left;
}

#RosterPlayerStats .next_game .header {
  display: block;
  font-size: 18px;
  margin-bottom: 2px;
}

#RosterPlayerStats .next_game .vs_or_at {
  font-size: 30px;
  display: block;
  margin-bottom: 20px;
}

#RosterPlayerStats .next_game .time {
  display: block;
  font-size: 10px;
  color: #999;
}

#RosterPlayerStats .next_game .team {
  float: left;
  width: 32%;
  background: #555;
  padding: 2%;
}

#RosterPlayerStats .next_game .away_team {
  padding-right: 6%;
  text-align: left;
  border-radius: 7px 30% 30% 0 / 100% 100% 0 0;
  box-shadow: inset -10px 2px 20px rgba(0, 0, 0, 0.4), 0 0 1px 1px rgba(255, 255, 255, 0.2);
}

#RosterPlayerStats .next_game .home_team {
  float: right;
  padding-left: 6%;
  text-align: right;
  border-radius: 30% 7px 0 30% / 100% 100% 0 0;
  box-shadow: inset 10px 2px 20px rgba(0, 0, 0, 0.4), 0 0 1px 1px rgba(255, 255, 255, 0.2);
}

#RosterPlayerStats .team_logo {
  display: inline-block;
}

#RosterPlayerStats .team_name {
  font-size: 18px;
  line-height: 20px;
  height: 20px;
  margin: 4px 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#RosterPlayerStats .stat-category-header {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  #RosterPlayerStats .stat-category-header {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

#RosterPlayerStats .statTable {
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

#RosterPlayerStats .statTable .last td {
  font-weight: bold;
  background-color: transparent;
}

#RosterPlayerStats .goToLink {
  color: #CF3338;
  font-size: 13px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 15px;
  padding-left: 0;
  color: #000000;
}

#RosterPlayerStats .goToLink:after {
  color: #000000;
  content: '\f061';
  display: inline-block;
  font-family: 'FontAwesome';
  margin: -.1em 0 0 .4em;
  font-size: .9em;
}

#RosterPlayerStats .goToLink:before {
  display: none;
}

#RosterPlayerStats td.date,
#RosterPlayerStats td.name,
#RosterPlayerStats td.score,
#RosterPlayerStats td.split {
  white-space: nowrap;
}

#RosterPlayerStats td:first-child,
#RosterPlayerStats th:first-child {
  text-align: left;
  padding-left: 10px;
}

#RosterPlayerStats tr[data-toggles] td:first-child {
  padding-left: 1.8em;
}

#RosterPlayerStats tr.totals td {
  background-color: #555;
  color: #FFF;
}

#RosterPlayerStats tr.season td {
  background-color: #DDD;
  border-top: 1px solid #BBB;
}

#RosterPlayerStats tr.subseason td {
  background-color: #F7F7F7;
}

#RosterPlayerStats tr.game_log + tr.subseason td {
  border-top: 1px solid #CCC;
}

#RosterPlayerStats tr.subseason_totals {
  background: #C1C1C1;
}

#RosterPlayerStats tr.subseason td {
  color: #676767;
  font-weight: bold;
}

#GameLogDiv .result {
  width: 7%;
  min-width: 50px;
}

#GameLogDiv .opponent {
  width: 20%;
}

#CareerStatsDiv .statTable td.description {
  text-align: left;
}

#CareerStatsDiv .statTable tr.season td.date {
  padding-left: 20px;
}

#RosterPlayerStats .inactive_notice {
  color: white;
  background-color: #9f171d;
  padding: 10px 0 10px 10px;
  margin-bottom: 15px;
  width: 99%;
}

#RosterPlayerStats .inactive_notice strong {
  text-transform: uppercase;
}

i.designation {
  background-image: url(/app_images/roster_player_designations.gif);
  display: inline-block;
}

i.designation.uncommitted {
  background-position: 0px 0px;
  width: 10px;
  height: 10px;
}

i.designation.suspended {
  background-position: -15px 0px;
  width: 10px;
  height: 10px;
}

i.designation.rookie {
  background-position: -30px 0px;
  width: 10px;
  height: 10px;
}

i.designation.pmj {
  background-position: -45px 0px;
  width: 26px;
  height: 10px;
}

i.designation.injured {
  background-position: -76px 0px;
  width: 10px;
  height: 10px;
}

i.designation.import {
  background-position: -91px 0px;
  width: 18px;
  height: 10px;
}

i.designation.committed {
  background-position: -114px 0px;
  width: 10px;
  height: 10px;
}

i.designation.affiliate {
  background-position: -129px 0px;
  width: 10px;
  height: 10px;
}

.rosterTableOverflow {
  overflow-x: auto;
  overflow-y: visible;
}

.rosterListingTable .photo {
  width: 3%;
}

.rosterListingTable .photo img {
  border-radius: 100%;
  border: 3px solid #ddd;
  display: block;
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.rosterListingTable .number {
  width: 3%;
}

.rosterListingTable tbody td.name {
  text-align: center;
  width: 20%;
}

.rosterListingTable tbody td {
  width: 10%;
}

/* Game Center */
.logo-gradient {
  position: absolute;
  height: 90px;
  width: 100px;
}

.winners-notch {
  background: url("https://app-assets1.sportngin.com/app_images/game_center/winnersNotch.png");
  bottom: 0;
  height: 8px;
  left: 50%;
  margin: 0 0 0 -8px;
  position: absolute;
  width: 16px;
}

/* Game List */
.gameListTeam {
  height: 20px;
  line-height: 21px;
  clear: both;
  margin-bottom: 5px;
  overflow: hidden;
}

.gameListTeam .score {
  float: left;
  font-weight: bold;
  margin-right: 5px;
  padding-left: 10px;
  text-align: right;
  min-width: 6%;
}

GameLiveVideo {
  width: 100%;
  text-align: center;
}

/* In Progress Highlights */
tr.in_progress .game_status {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_center/inprogressBG.png");
  background-image: linear-gradient(to right, rgba(65, 138, 0, 0) 0%, rgba(65, 138, 0, 0.5) 100%);
  /* W3C */
  background-position: right 50%;
  background-repeat: repeat-y;
}

tr.in_progress .game_status img,
.boxscore-extras.in_progress p.details a img {
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.65);
}

/* SPORT NGIN LIVE LINKS */
p.watchLive-live, p.watchLive-recap {
  display: block;
  height: 38px;
  margin: 15px 20px 15px 15px;
  text-decoration: none;
}

p.watchLive-live a, p.watchLive-recap a {
  display: block;
  float: right;
  height: 38px;
  margin: 0 -5px 0 0;
  text-indent: -999999px;
  width: 100%;
}

p.watchLive-live {
  background: url("https://app-assets1.sportngin.com/app_images/live/watchLive-live.png") 0 0;
}

p.watchLive-live a {
  background: url("https://app-assets1.sportngin.com/app_images/live/watchLive-live.png") 100% -38px;
}

p.watchLive-recap {
  background: url("https://app-assets1.sportngin.com/app_images/live/watchLive-recap.png") 0 0;
}

p.watchLive-recap a {
  background: url("https://app-assets1.sportngin.com/app_images/live/watchLive-recap.png") 100% -38px;
}

p.watchLive-live:hover, p.watchLive-recap:hover {
  background-position: 0   -76px;
}

p.watchLive-live:hover a, p.watchLive-recap:hover a {
  background-position: 100% -114px;
}

p.watchLive-live:active, p.watchLive-recap:active {
  background-position: 0  -152px;
}

p.watchLive-live:active a, p.watchLive-recap:active a {
  background-position: 100% -190px;
}

.boxscore-extras p.watchLive-link {
  float: right;
  margin-top: -2px;
}

.boxscore-extras p.watchLive-link a {
  padding: 8px 5px 2px;
}

.theme-game-list-widget .statTable.sn-table-game-list th, .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-score):not(.home-team-score) {
  text-align: left;
}

.theme-game-list-widget .statTable.sn-table-game-list .date-time,
.theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number {
  font-size: 11px;
  text-transform: uppercase;
}

.theme-game-list-widget .statTable.sn-table-game-list .time {
  text-transform: lowercase;
}

.theme-game-list-widget .statTable.sn-table-game-list .team-logo {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -11px 5px -9px 0;
  vertical-align: middle;
}

.theme-game-list-widget .statTable.sn-table-game-list .status a {
  color: #CF3338;
  font-size: 13px;
  text-transform: uppercase;
  font-size: 11px;
  position: relative;
  display: block;
  padding-right: 1em;
}

.theme-game-list-widget .statTable.sn-table-game-list .status a:after {
  color: #000000;
  content: '\f061';
  display: inline-block;
  font-family: 'FontAwesome';
  margin: -.1em 0 0 .4em;
  font-size: .9em;
}

.theme-game-list-widget .statTable.sn-table-game-list .status a:after {
  position: absolute;
  right: 0;
}

@media only screen and (min-width: 1340px) {
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list {
    table-layout: fixed;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list td {
    word-wrap: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score {
    width: 4em;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .date-time-header {
    width: 14.5em;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number-header {
    width: 6em;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .status-header {
    width: 10em;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number {
    white-space: normal;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number > * {
    height: 1.5em;
    display: inline-block;
    vertical-align: middle;
    word-wrap: normal;
    overflow: hidden;
    text-align: right;
    text-overflow: clip;
    direction: rtl;
    max-width: 100%;
  }
  .layout-100 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number > *:after {
    content: "…";
    position: relative;
    top: -1.75em;
    display: inline-block;
    background: #EEE;
    width: 1em;
    height: 1.7em;
    line-height: 2;
    left: -100%;
    margin-right: -.99em;
    margin-top: -.5em;
    margin-bottom: -.5em;
    vertical-align: middle;
  }
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list thead,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list thead,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list thead,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list thead {
  display: none;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list tbody,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list tbody,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list tbody,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list tbody {
  display: block;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list td,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list td,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list td,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list td {
  display: inline-block;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list tr,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list tr,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list tr,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list tr {
  font-size: 11px;
  position: relative;
  overflow: hidden;
  display: block;
  padding-top: 72px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list tr:not(:last-child),
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list tr:not(:last-child),
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list tr:not(:last-child),
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list tr:not(:last-child) {
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
  margin-bottom: 10px;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score),
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score),
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score),
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score) {
  padding: 0;
  background: none;
  height: auto;
  font-size: 11px;
  text-transform: uppercase;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score):not(.status),
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score):not(.status),
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score):not(.status),
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score):not(.status) {
  margin-right: 6px;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .away-team-name,
.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .away-team-name,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .away-team-name,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .away-team-name,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score {
  position: absolute;
  top: 2px;
  left: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 30px;
  width: calc(100% - 4em - 2px);
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score {
  top: 34px;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score {
  width: 4em;
  left: auto;
  right: 0;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number > *:before,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number > *:before,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number > *:before,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number > *:before {
  content: "Game ID: ";
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .status,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .status,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .status,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .status {
  float: right;
  margin-top: 1px;
}

.col-md-3 .theme-game-list-widget .statTable.sn-table-game-list .status a,
.col-md-4 .theme-game-list-widget .statTable.sn-table-game-list .status a,
.col-md-6 .theme-game-list-widget .statTable.sn-table-game-list .status a,
.col-md-8 .theme-game-list-widget .statTable.sn-table-game-list .status a {
  font-size: 11px;
}

@media only screen and (max-width: 1339px) {
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list thead {
    display: none;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list tbody {
    display: block;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list td {
    display: inline-block;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list tr {
    font-size: 11px;
    position: relative;
    overflow: hidden;
    display: block;
    padding-top: 72px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 6px;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list tr:not(:last-child) {
    border-style: solid;
    border-width: 0;
    border-bottom-color: #EDF0F1;
    border-bottom-width: 2px !important;
    margin-bottom: 10px;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score) {
    padding: 0;
    background: none;
    height: auto;
    font-size: 11px;
    text-transform: uppercase;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score):not(.status) {
    margin-right: 6px;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .away-team-name,
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score {
    position: absolute;
    top: 2px;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 30px;
    width: calc(100% - 4em - 2px);
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .home-team-name,
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score {
    top: 34px;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .away-team-score,
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .home-team-score {
    width: 4em;
    left: auto;
    right: 0;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .game-id-game-number > *:before {
    content: "Game ID: ";
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .status {
    float: right;
    margin-top: 1px;
  }
  .col-md-12 .theme-game-list-widget .statTable.sn-table-game-list .status a {
    font-size: 11px;
  }
}

.gameListElement .sn-table-game-list {
  white-space: nowrap;
}

.gameListElement .sn-table-game-list .status a {
  padding-right: 0;
}

.gameListElement .sn-table-game-list .status a:after {
  content: '';
}

.gameListElement .sn-table-game-list .away-team-score > div,
.gameListElement .sn-table-game-list .home-team-score > div {
  min-width: 3em;
}

.gameListElement .sn-table-game-list .away-team-name > div,
.gameListElement .sn-table-game-list .home-team-name > div {
  min-width: 14em;
}

.gameListElement .sn-table-game-list .venue-subvenue > div {
  min-width: 12em;
}

.gameListElement .sn-table-game-list .game-id-game-number > div {
  min-width: 4em;
}

.gameListElement .sn-table-game-list .status > div {
  min-width: 6em;
}

#GameShowScoreboardSimple {
  margin-bottom: 15px;
}

#GameShowScoreboardSimple .game-header {
  margin-bottom: 15px;
}

/* Sport Header
-------------------------------------------------------------------- */
#sport-header {
  display: block;
  width: 100%;
}

#sport-header .teamLogo {
  left: 15px;
  height: 40px;
  width: 120px;
}

#sport-header #snm_advert {
  display: inline-block;
  text-decoration: none;
  padding: 10px;
  background: transparent;
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}

#sport-header #snm_advert:before {
  width: 30px;
  height: 25px;
  position: absolute;
  display: block;
  font: normal 14px/1em "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 1.75em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  background: url("https://app-assets1.sportngin.com/app_images/marketing/mobilePhone.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#sport-header #snm_advert:hover {
  opacity: 1;
}

#sport-header #snm_advert span,
#sport-header #snm_advert strong {
  color: #444;
  font-size: 9px;
  text-align: left;
  display: block;
  line-height: 13px;
  display: block;
  padding-left: 33px;
}

/* Leagues and Levels Breadcrumbs
-------------------------------------------------------------------- */
#sport-crumbs {
  background: #fff;
  position: relative;
  float: left;
  display: block;
  width: 100%;
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

#sport-crumbs.no-crumbs {
  border-image: none;
}

#sport-crumbs .sport-crumbs-btns {
  display: block;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  width: 100%;
  min-height: 46px;
}

#sport-crumbs .sport-crumbs-btns::-webkit-scrollbar {
  display: none;
}

#sport-crumbs ul {
  display: inline-block;
  float: left;
}

#sport-crumbs ul li {
  float: left;
  list-style: none;
}

#sport-crumbs ul li a {
  padding: 0px;
}

#sport-crumbs ul li a:before {
  content: "\f054";
  color: #ccc;
  display: inline-block;
  font: normal 10px/1em FontAwesome;
  padding: 2px;
  position: relative;
  margin: 0 0 0 10px;
}

#sport-crumbs ul li:first-child a:before {
  display: none;
}

#sport-crumbs ul li.crumbSelected {
  background: #fff;
  position: relative;
}

#sport-crumbs ul li.crumbSelected a {
  color: #000;
}

#sport-crumbs ul li.crumbSelected .arrow-down:after {
  content: "\f0d8";
  color: #000;
}

#sport-crumbs .sport-crumbs-list ul li a {
  padding: 10px 20px;
}

#sport-crumbs a {
  color: #666666;
  display: block;
  outline: medium none;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  font-weight: normal;
}

#sport-crumbs a span {
  display: inline-block;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#sport-crumbs a span:after {
  border-radius: 20px;
  font: 16px/1em FontAwesome;
  margin: auto;
  padding: 4px;
  text-align: center;
  color: #000000;
}

#sport-crumbs a .arrow-down {
  padding-left: 0px;
  padding-right: 15px;
}

#sport-crumbs a:hover {
  color: #000;
}

#sport-crumbs .arrow-down:after {
  content: "\f0d7";
  display: inline-block;
}

#sport-crumbs .sport-crumbs-list {
  display: none;
  text-align: left;
  float: left;
  margin: auto;
  position: absolute;
  top: 49px;
  width: 100%;
  max-width: 50%;
  z-index: 20;
  left: 0px;
  border-bottom: 4px solid #CF3338;
}

#sport-crumbs .sport-crumbs-list.thisCrumb {
  display: block !important;
  -webkit-animation: dropdown-season 0.3s ease-in-out;
  animation: dropdown-season 0.3s ease-in-out;
}

#sport-crumbs .sport-crumbs-list ul {
  list-style: none;
  width: 100%;
}

#sport-crumbs .sport-crumbs-list ul li {
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a {
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  display: block;
  font-size: 12px;
  line-height: 24px;
  padding: 5px 10px;
  text-decoration: none;
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a:before {
  display: none;
}

#sport-crumbs .sport-crumbs-list ul li a:hover {
  color: #fff;
  background-color: #000;
}

#sport-crumbs .sport-crumbs-list ul li a img {
  margin: 0 3px -3px 0;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close {
  display: block;
  background: rgba(0, 0, 0, 0.85);
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a {
  display: block;
  float: right;
  text-align: right;
  width: 50px;
  border-bottom: 0px;
  background: transparent;
  padding: 10px 10px 0px 10px;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after {
  content: "\f00d";
  position: relative;
  font: normal normal normal 12px/1em FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
  border-radius: 40px;
  padding: 3px 5px;
  color: #fff;
  opacity: 0.7;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after:hover {
  color: #000;
  border-color: #000;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:hover {
  background: transparent;
  color: #fff;
}

#sport-crumbs a.last_child, #sport-crumbs a.last_child:hover {
  background: none;
}

.dropdown-wrapper:before {
  display: none;
}

.prototip .sport-crumbs-list {
  display: block;
}

/* Sport Tools
-------------------------------------------------------------------- */
#sport-header .sport-tools {
  margin: 10px 0px;
  border-bottom: 3px solid #ececec;
}

#sport-header .sport-tools ul li a:before {
  font: normal normal normal 14px/1em FontAwesome;
  padding: 0px;
  display: none;
}

#sport-header .sport-tools ul li:hover {
  border-bottom: 5px solid #000000;
}

#sport-header .sport-tools ul .selected {
  color: #fff;
  border-bottom: 5px solid #000000;
  border-right: 0px;
}

#sport-header .sport-tools ul #tool-home a:before {
  content: "\f015";
}

#sport-header .sport-tools ul #tool-roster a:before {
  content: "\f007";
}

#sport-header .sport-tools ul #tool-game-schedule a:before {
  content: "\f133";
}

#sport-header .sport-tools ul #tool-player-stats a:before {
  content: "\f080";
}

#sport-header .sport-tools ul #tool-team-stats a:before {
  content: "\f1fe";
}

#sport-header .sport-tools ul #tool-standings a:before {
  content: "\f1b3";
}

#sport-header .sport-tools ul #tool-bracket a:before {
  content: "\f0e8";
}

#sport-header .sport-tools ul #tool-photo a:before {
  content: "\f03e";
}

#sport-header .sport-tools ul #tool-video a:before {
  content: "\f1c8";
}

#sport-header .sport-tools ul #tool-posts a:before {
  content: "\f086";
}

#sport-header .sport-tools ul .tool-yellow-admin a:before {
  content: "\f013";
}

#sport-header .sport-tools ul .tool-yellow-manage-team a:before {
  content: "\f024";
}

#sport-header .sport-tools ul.tools {
  width: 100%;
}

#sport-header .sport-tools .button-tool {
  float: left;
}

#sport-header .sport-tools li,
#sport-header .sport-tools #autoRefreshBar li {
  display: inline;
  float: left;
}

#sport-header .sport-tools li.tool-yellow {
  border-color: #edc52c;
}

#sport-header .sport-tools li.tool-yellow a {
  background: #fddd40 url("https://app-assets1.sportngin.com/app_images/sport-header/btnsBG-yl.gif") 0 0 repeat-x;
  border-color: #ffc;
  color: #807540;
}

#sport-header .sport-tools li.tool-yellow:hover {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:hover a {
  background-color: #fddd40;
  background-position: 0 -80px;
  border-color: #ffc;
  color: #1a1a1a;
}

#sport-header .sport-tools li.tool-yellow:active {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:active a {
  background-color: #fddd40;
  background-position: 0 -160px;
  color: #333;
}

.sport-tools li a {
  list-style: none;
}

.sport-tools li a,
#autoRefreshBar li a,
#autoRefreshBar li label {
  background-color: transparent;
  color: #000000;
  cursor: pointer;
  display: block;
  font: bold 13px/1.1 "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-transform: uppercase;
  outline: medium none;
  padding: 10px;
  text-decoration: none;
  text-shadow: 1px 1px 1px #fff;
  width: auto;
}

.sport-tools li:hover,
#autoRefreshBar li:hover,
#sport-slider li:hover,
.sport-tools li.selected {
  border-bottom: 5px solid #000000;
}

#autoRefreshBar .selected {
  color: #fff;
  border-bottom: 5px solid #000000;
}

.sport-tools li:active,
#autoRefreshBar li:hover {
  color: #333;
}

.sport-tools li:hover a,
#autoRefreshBar li:hover a,
#autoRefreshBar li:hover label {
  background-color: transparent;
  background-position: 0 -80px;
  color: black;
}

.sport-tools li:active a,
#autoRefreshBar li:active a,
#autoRefreshBar li:active label {
  background-color: #f9f9f9;
  background-position: 0 -160px;
  color: #333;
}

.sport-tools .selected a,
#autoRefreshBar .selected a,
#autoRefreshBar .selected label,
.sport-tools li.selected:hover a,
#autoRefreshBar li.selected:hover a,
#autoRefreshBar li.selected:hover label {
  color: #000000;
  font-weight: bold;
  background-position: 0 -240px;
}

body #autoRefreshBar {
  margin: 10px 0 20px;
  padding: 5px 10px 0;
}

body #autoRefreshBar li {
  display: inline-block;
  list-style: none;
}

body #autoRefreshBar p {
  margin-bottom: 0;
  line-height: 34px;
}

/* Admin Menu
-------------------------------------------------------------------- */
.sport-admin-buttons {
  float: right;
  margin: 14px 4px 0 0;
  list-style: none;
}

.sport-admin-buttons ul {
  list-style: none;
}

.sport-admin-buttons ul li {
  display: inline;
}

/* Yes, double ID's because I only want to target this when it's a child of the sport-header. It will be moved elsewhere when the Tip js fires. */
#sport-header #tool-pages-menu,
#sport-header #sport-admin-menu {
  display: none;
}

/* Specific Pills
-------------------------------------------------------------------- */
#tool-home a:hover span {
  background-position: 0 -80px;
}

#tool-home a:active span {
  background-position: 0 -160px;
}

#tool-home.selected span {
  background-position: 0 -240px;
}

#tool-createWidget {
  float: right;
}

#tool-createWidget a {
  background: #fddd40 url("https://app-assets1.sportngin.com/app_images/sport-header/btnsBG-yl.gif") 0 0 repeat-x;
  border-color: #ffc;
}

/* Team Mini Header
-------------------------------------------------------------------- */
.teamMiniHeader {
  padding-bottom: 5px;
}

.teamMiniHeader .teamLogo-small {
  float: left;
}

.teamMiniHeader h4 {
  font-size: 16px;
  margin-left: 5px;
  line-height: 20px;
  float: left;
}

/* Overlay
-------------------------------------------------------------------- */
#sport-tools-overlay {
  background: #fff url(/app_images/loader4.gif) 50% 50% no-repeat;
  opacity: 0.5;
  position: absolute;
  z-index: 2;
}

@media only screen and (max-width: 1024px) {
  #sport-header .sport-tools ul li a {
    font-size: .8em;
  }
}

@media only screen and (max-width: 769px) {
  #sport-header #sport-crumbs ul li.crumbSelected {
    position: static;
  }
  #sport-header #sport-crumbs .sport-crumbs-list {
    max-width: 100%;
  }
  #sport-header #sport-crumbs a span {
    font-size: 0.70em;
  }
  #sport-header #sport-crumbs a span:after {
    font: 10px/1em FontAwesome;
  }
  #sport-header #sport-crumbs a .arrow-down {
    margin-left: 0px;
  }
  #sport-header .sport-tools ul li a {
    padding: 10px 2px;
    font-size: 0em;
  }
  #sport-header .sport-tools ul li a:before {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .teamLogo-medium, .teamLogor-medium {
    width: 70px !important;
    height: 23px !important;
  }
}

/* Sport/League/Level/Team Name
-------------------------------------------------------------------- */
#sport-header {
  /* Team Colors
  -------------------------------------------------------------------- */
  /* .color[0,1,2][r][-sm,-m,-l,-xl] (defaults to left, large) */
  /* Borders -> */
  /* Top */
  /* Right */
  /* Left */
  /* Bottom */
  /* Separator Color */
  /* Primary Color */
  /* Secondary Color */
  /* Edging
  -------------------------------------------------------------------- */
}

#sport-header .sport-colors {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 25px 12.5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#sport-header .sport-colors .team-logo-wrapper {
  display: block;
  height: 75px;
  width: 75px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 769px) {
  #sport-header .sport-colors .team-logo-wrapper {
    width: 44px;
    height: 44px;
  }
}

#sport-header .sport-colors .team-logo-wrapper, #sport-header .sport-colors .team-logo-wrapper:hover {
  color: #ccc;
}

#sport-header .sport-colors .team-logo-wrapper.nologo:after {
  content: '';
  display: block;
  width: 7px;
  height: 100%;
  margin: auto;
  border-left-width: 8px;
  border-right-width: 8px;
  border-left-style: solid;
  border-right-style: solid;
  background: transparent;
}

@media only screen and (max-width: 768px) {
  #sport-header .sport-colors .team-logo-wrapper.nologo:after {
    width: 3px;
    border-right-width: 4px;
    border-left-width: 4px;
  }
}

#sport-header .sport-colors h2 {
  width: 100%;
  max-width: 75%;
  font: bold 38px/1.25 "Gotham SSm A", "Gotham SSm B", sans-serif;
}

@media only screen and (max-width: 768px) {
  #sport-header .sport-colors h2 {
    font: bold 1.3em/1.4em "Gotham SSm A", "Gotham SSm B", sans-serif;
  }
}

#sport-header .sport-colors h2 a {
  display: inline-block;
  text-decoration: none;
}

#sport-header .sport-colors div.player_photo {
  border: 3px solid #000000;
  background-color: #000000;
  left: 10px;
  top: 10px;
  height: 70px;
  width: 70px;
}

#sport-header .sport-colors .teamLogo-small {
  font-size: 18px;
}

#sport-header .abs0,
#sport-header .abs0r {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
}

#sport-header .abs0 {
  left: 0;
}

#sport-header .abs0r {
  right: 0;
}

#sport-header .color0-sm, #sport-header .color0-m {
  border-top: 12px solid #fff;
  border-right: 36px  solid transparent !important;
  border-left: 36px  solid #fff;
  border-bottom: 12px solid transparent !important;
  display: none;
}

#sport-header .color0, #sport-header .color0-l {
  border-top: 22px solid #fff;
  border-right: 66px  solid transparent !important;
  border-left: 66px  solid #fff;
  border-bottom: 22px solid transparent !important;
  display: none;
}

#sport-header .color0-xl {
  border-top: 32px solid #fff;
  border-right: 96px  solid transparent !important;
  border-left: 96px  solid #fff;
  border-bottom: 32px solid transparent !important;
  display: none;
}

#sport-header .color0r-sm, #sport-header .color0r-m {
  border-top: 12px solid #fff;
  border-right: 36px  solid #fff;
  border-left: 36px  solid transparent !important;
  border-bottom: 12px solid transparent !important;
  display: none;
}

#sport-header .color0r, #sport-header .color0r-l {
  border-top: 22px solid #fff;
  border-right: 66px  solid #fff;
  border-left: 66px  solid transparent !important;
  border-bottom: 22px solid transparent !important;
  display: none;
}

#sport-header .color0r-xl {
  border-top: 32px solid #fff;
  border-right: 96px  solid #fff;
  border-left: 96px  solid transparent !important;
  border-bottom: 32px solid transparent !important;
  display: none;
}

#sport-header .color1-sm, #sport-header .color1-m {
  border-top: 10px solid #fff;
  border-right: 30px  solid transparent !important;
  border-left: 30px  solid #fff;
  border-bottom: 10px solid transparent !important;
  display: none;
}

#sport-header .color1, #sport-header .color1-l {
  border-top: 20px solid #fff;
  border-right: 60px  solid transparent !important;
  border-left: 60px  solid #fff;
  border-bottom: 20px solid transparent !important;
  display: none;
}

#sport-header .color1-xl {
  border-top: 30px solid #fff;
  border-right: 90px  solid transparent !important;
  border-left: 90px  solid #fff;
  border-bottom: 30px solid transparent !important;
  display: none;
}

#sport-header .color1r-sm, #sport-header .color1r-m {
  border-top: 10px solid #fff;
  border-right: 30px  solid #fff;
  border-left: 30px  solid transparent !important;
  border-bottom: 10px solid transparent !important;
  display: none;
}

#sport-header .color1r, #sport-header .color1r-l {
  border-top: 20px solid #fff;
  border-right: 60px  solid #fff;
  border-left: 60px  solid transparent !important;
  border-bottom: 20px solid transparent !important;
  display: none;
}

#sport-header .color1r-xl {
  border-top: 30px solid #fff;
  border-right: 90px  solid #fff;
  border-left: 90px  solid transparent !important;
  border-bottom: 30px solid transparent !important;
  display: none;
}

#sport-header .color2-sm, #sport-header .color2-m {
  border-top: 15px solid #fff;
  border-right: 45px  solid transparent !important;
  border-left: 45px  solid #fff;
  border-bottom: 15px solid transparent !important;
  display: none;
}

#sport-header .color2, #sport-header .color2-l {
  border-top: 25px solid #fff;
  border-right: 75px  solid transparent !important;
  border-left: 75px  solid #fff;
  border-bottom: 25px solid transparent !important;
  display: none;
}

#sport-header .color2-xl {
  border-top: 35px solid #fff;
  border-right: 105px solid transparent !important;
  border-left: 105px solid #fff;
  border-bottom: 35px solid transparent !important;
  display: none;
}

#sport-header .color2r-sm, #sport-header .color2r-m {
  border-top: 15px solid #fff;
  border-right: 45px  solid #fff;
  border-left: 45px  solid transparent !important;
  border-bottom: 15px solid transparent !important;
  display: none;
}

#sport-header .color2r, #sport-header .color2r-l {
  border-top: 25px solid #fff;
  border-right: 75px  solid #fff;
  border-left: 75px  solid transparent !important;
  border-bottom: 25px solid transparent !important;
  display: none;
}

#sport-header .color2r-xl {
  border-top: 35px solid #fff;
  border-right: 105px solid #fff;
  border-left: 105px solid transparent !important;
  border-bottom: 35px solid transparent !important;
  display: none;
}

#sport-header .edge {
  background: transparent url("https://app-assets1.sportngin.com/app_images/sport-header/edge.png") 0 0 repeat-x;
  height: 12px;
  width: 100%;
}

#sport-header .edge-tools {
  background: transparent url("https://app-assets1.sportngin.com/app_images/sport-header/tools-edge.png") 0 0 repeat-x;
  height: 12px;
  width: 100%;
}

/* Team Logo
-------------------------------------------------------------------- */
/*
  Logo sizes are...
  20 x 20 - tiny
  60 x 20 - small
  90 x 30 - medium
  120 x 40 - large
  150 x 50 - extra large
  300 x 100 - print
*/
/*Shape*/
.teamLogo,
.teamLogo-tiny,
.teamLogo-small,
.teamLogo-medium,
.teamLogo-large,
.teamLogo-extra_large,
.teamLogo-print,
.teamLogor,
.teamLogor-tiny,
.teamLogor-small,
.teamLogor-medium,
.teamLogor-large,
.teamLogor-extra_large,
.teamLogor-print {
  display: block;
  text-decoration: none;
}

/*Size*/
.teamLogo-tiny,
.teamLogor-tiny {
  background: url("https://app-assets1.sportngin.com/app_images/team_logos/team-tiny.png") 50% 50% no-repeat;
  height: 20px !important;
  width: 20px !important;
}

.teamLogo-small,
.teamLogor-small {
  background: url("https://app-assets1.sportngin.com/app_images/team_logos/team-small.png") 50% 50% no-repeat;
  height: 20px !important;
  width: 20px !important;
}

.teamLogo-medium, .teamLogor-medium {
  background: url("https://app-assets1.sportngin.com/app_images/team_logos/team-medium.png") 50% 50% no-repeat;
  height: 30px !important;
  width: 90px !important;
  border: 1px solid #ddd;
  background-color: #fafafa;
}

.teamLogo,
.teamLogo-large,
.teamLogor,
.teamLogor-large {
  background: url("https://app-assets1.sportngin.com/app_images/team_logos/team-large.png") 50% 50% no-repeat;
  height: 40px !important;
  width: 120px !important;
}

.teamLogo-extra_large,
.teamLogor-extra_large {
  background: url("https://app-assets1.sportngin.com/app_images/team_logos/team-extra_large.png") 50% 50% no-repeat;
  height: 50px !important;
  width: 150px !important;
}

.teamLogo-print,
.teamLogor-print {
  background: url("https://app-assets1.sportngin.com/app_images/team_logos/team-print.png") 50% 50% no-repeat;
  height: 100px !important;
  width: 300px !important;
}

/*Orientation*/
.sport-colors .teamLogo,
.sport-colors .teamLogo-medium,
.sport-colors .teamLogo-large,
.sport-colors .teamLogo-extra_large,
.sport-colors .teamLogo-print {
  left: 15px;
  top: 10px;
}

.sport-colors .teamLogor,
.sport-colors .teamLogor-medium,
.sport-colors .teamLogor-large,
.sport-colors .teamLogor-extra_large,
.sport-colors .teamLogor-print {
  left: auto;
  right: 15px;
  top: 10px;
}

.sport-colors .teamLogo-small {
  left: 5px;
  top: 5px;
}

.sport-colors .teamLogor-small {
  left: auto;
  right: 5px;
  top: 5px;
}

/* In Tables */
table .teamLogo,
table .teamLogo-tiny,
table .teamLogo-small,
table .teamLogo-medium,
table .teamLogo-large,
table .teamLogo-extra_large,
table .teamLogo-print,
.sportTableLabel .teamLogo,
.sportTableLabel .teamLogo-tiny,
.sportTableLabel .teamLogo-small,
.sportTableLabel .teamLogo-medium,
.sportTableLabel .teamLogo-large,
.sportTableLabel .teamLogo-extra_large,
.sportTableLabel .teamLogo-print {
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
  background-color: #fff;
}

table .teamLogo:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
table .teamLogo-tiny:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
table .teamLogo-small:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
table .teamLogo-medium:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
table .teamLogo-large:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
table .teamLogo-extra_large:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
table .teamLogo-print:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
.sportTableLabel .teamLogo:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
.sportTableLabel .teamLogo-tiny:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
.sportTableLabel .teamLogo-small:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
.sportTableLabel .teamLogo-medium:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
.sportTableLabel .teamLogo-large:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
.sportTableLabel .teamLogo-extra_large:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]),
.sportTableLabel .teamLogo-print:not([style*="background-image:url(/app_images/team_logos/team-tiny.png);"]) {
  background-size: 100% auto;
}

table .teamLogor,
table .teamLogor-tiny,
table .teamLogor-small,
table .teamLogor-medium,
table .teamLogor-large,
table .teamLogor-extra_large,
table .teamLogor-print,
.sportTableLabel .teamLogor,
.sportTableLabel .teamLogor-tiny,
.sportTableLabel .teamLogor-small,
.sportTableLabel .teamLogor-medium,
.sportTableLabel .teamLogor-large,
.sportTableLabel .teamLogor-extra_large,
.sportTableLabel .teamLogor-print {
  float: right;
  margin-left: 5px;
}

.pageEl .widgetElement .widgetHierarchy {
  overflow-x: auto;
  overflow-y: hidden;
}

@media only screen and (max-width: 767px) {
  .widgetElement .widget-roster-player table.statTable thead {
    display: block;
    float: left;
    position: relative;
    width: 50%;
  }
  .widgetElement .widget-roster-player table.statTable thead tr {
    float: left;
    position: relative;
    display: block;
    width: 100%;
  }
  .widgetElement .widget-roster-player table.statTable thead tr th {
    display: block;
    float: left;
    height: 30px;
    overflow: hidden;
    padding-top: 8px;
    width: 100%;
  }
  .widgetElement .widget-roster-player table.statTable tbody {
    display: block;
    float: left;
    position: relative;
    width: 50%;
  }
  .widgetElement .widget-roster-player table.statTable tbody tr {
    float: left;
    position: relative;
    display: block;
    width: 100%;
  }
  .widgetElement .widget-roster-player table.statTable tbody tr td {
    display: inline-block;
    float: left;
    height: 30px;
    width: 100%;
    border-left: none;
    border-right: none;
  }
  .widgetElement .widget-gameTable .showMoreGames {
    display: none;
  }
}

/* Widgets - Universal
---------------------------------------------------------------------------------------------------- */
.widgetFooter .teamLogo-tiny {
  float: right;
  margin: 0 5px 0 0;
}

.widgetFooter .linkText {
  float: right;
  line-height: 20px;
}

.widgetFooter .linkArrow {
  float: right;
}

.widgetHierarchy h4 {
  margin: 5px 0;
}

/* Widgets - in Lightbox
---------------------------------------------------------------------------------------------------- */
.widget-source {
  display: block;
  font-size: 1.2em;
  text-transform: uppercase;
}

.widget-data {
  display: block;
  color: #000;
}

.widget-filters {
  display: block;
  color: #666;
  font-size: 0.9em;
}

/* Widget Footer
---------------------------------------------------------------------------------------------------- */
.widgetFooter {
  background: #fefefe;
  border: 1px solid #e6e6e6;
  font-size: 10px;
  margin: 5px 0 0;
  padding: 5px;
}

/* Widget - Roster Player Stats Widget
---------------------------------------------------------------------------------------------------- */
.widget-roster-player ul li {
  padding: 0px;
}

.widget-roster-player ul li.lastLi {
  background: #e6e6e6;
}

.widget-roster-player ul li.lastLi h4 {
  font-size: 1em;
  padding: 15px;
  margin-bottom: 0px;
}

.widget-roster-player ul li.lastLi h4 a {
  text-transform: uppercase;
}

.widget-roster-player ul li.lastLi h4 span {
  color: #333;
}

.widget-roster-player ul li.lastLi .leader-highlight {
  padding-bottom: 15px;
}

.widget-roster-player ul li.lastLi .leader-highlight .sport-colors {
  margin: auto;
  border: 0px;
  width: auto;
  display: inline-block;
}

.widget-roster-player ul li.lastLi .leader-highlight .sport-colors .edge {
  display: none;
}

.widget-roster-player ul li.lastLi .leader-highlight .sport-colors .player_photo {
  overflow: hidden;
  margin: 0px 15px 10px 15px;
  position: relative;
  top: auto;
  left: auto;
  max-height: 70px;
  max-width: 70px;
  width: 100%;
  height: 100%;
  border: 3px solid #000000;
  background-color: #C7C7C7;
}

.widget-roster-player ul li.lastLi .leader-highlight .sport-colors .player_photo a img {
  width: 100%;
  height: 100%;
  display: block;
}

.widget-roster-player ul li.lastLi .leader-highlight .stats {
  margin: auto;
  position: relative;
  float: left;
  display: inline-block;
}

.widget-roster-player ul li.lastLi .leader-highlight .stats p.stats-minor {
  padding: 0px;
  font-size: 15px;
}

.widget-roster-player ul li.lastLi .leader-highlight .stats p.stats-major {
  font-size: 2em;
  padding: 0px;
}

.widget-roster-player ul li.lastLi .leader-highlight .stats p.stats-major:after {
  border: 0px;
  display: none;
}

.widget-roster-player ul li.lastLi .noEntry {
  padding: 15px;
}

.widget-roster-player table {
  background: #fafafa;
  padding: 0;
  width: 100%;
}

.widget-roster-player table .rank {
  width: 1%;
}

.widget-roster-player table .stat {
  font-weight: bold;
  text-align: right;
}

.widget-roster-player .leader-highlight {
  background: #e6e6e6;
  position: relative;
  text-align: center;
  width: 100%;
}

.widget-roster-player .leader-highlight p {
  margin: 0px;
}

.widget-roster-player .leader-highlight p.playerName {
  font-size: 14px;
  text-align: left;
  padding: 5px 10px 10px;
}

[id^="widget_roster_widget"] .sportTableLabel {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  position: relative;
}

@media only screen and (max-width: 767px) {
  [id^="widget_roster_widget"] .sportTableLabel {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

[id^="widget_roster_widget"] .sportTableLabel .teamLogo-tiny {
  left: 14px;
  position: absolute;
  top: 14px;
}

[id^="widget_roster_widget"] .sportTableLabel * {
  color: inherit;
}

[id^="widget_roster_widget"] .sportTableLabel h4.teamName {
  font-size: 24px;
  margin: 0;
  padding-left: 30px;
}

[id^="widget_roster_widget"] .sportTableLabel .seasonDisplay,
[id^="widget_roster_widget"] .sportTableLabel .expandedView {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}

[id^="widget_roster_widget"] .sportTableLabel .linkText {
  display: block;
}

[id^="widget_roster_widget"] .sportTableLabel .season-separator {
  display: none;
}

.activePlayers > h4 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .activePlayers > h4 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

/* Widget - Game List
---------------------------------------------------------------------------------------------------- */
.widget-gameTable th {
  text-align: left;
}

.widget-gameTable a {
  outline: none;
}

.game-location-small-list {
  display: block;
  text-align: right;
  padding: 2px;
  margin-top: 3px;
  border-top: dotted 1px #CCC;
  font-size: 9px;
}

.showMoreGames {
  margin-top: 2px;
}

/* Widget - Team Buttons
---------------------------------------------------------------------------------------------------- */
.widget-teamButtons .sport-colors {
  position: relative;
  line-height: 1.3;
  padding: 0;
}

.widget-teamButtons a {
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: block;
  padding: 17px 47px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  display: block;
  font-size: inherit;
  padding: 12px 12px 10px 65px;
}

.widget-teamButtons a:focus, .widget-teamButtons a:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .widget-teamButtons a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .widget-teamButtons a {
    font-size: 14px;
  }
}

.widget-teamButtons a:hover .teamLogo-small {
  background-color: #000000;
}

.widget-teamButtons .teamLogo-small {
  background-color: black;
  background-size: cover;
  border-radius: 0;
  display: block;
  float: left;
  left: -53px;
  margin-top: -10px;
  overflow: visible;
  position: absolute;
  top: 50%;
  width: 40px !important;
}

.widget-teamButtons .teamLogo-small:after {
  background: #CF3338;
  content: '';
  height: 100%;
  position: absolute;
  right: -5px;
  width: 3px;
}

.widget-teamButtons .teamname {
  font-weight: 600;
}

.widget-teamButtons .teamname-separator {
  padding-left: 2px;
  padding-right: 2px;
}

@media (min-width: 1025px) {
  .col-md-12 .widget-teamButtons.columns-3 .teamBtnCol,
  .col-md-6 .widget-teamButtons.columns-3 .teamBtnCol,
  .col-md-4 .widget-teamButtons.columns-3 .teamBtnCol, .widget-teamButtons.columns-2 .teamBtnCol {
    box-sizing: border-box;
    float: left;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamBtnCol + .teamBtnCol,
  .col-md-6 .widget-teamButtons.columns-3 .teamBtnCol + .teamBtnCol,
  .col-md-4 .widget-teamButtons.columns-3 .teamBtnCol + .teamBtnCol, .widget-teamButtons.columns-2 .teamBtnCol + .teamBtnCol {
    padding-left: 5px;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamBtnCol,
  .col-md-6 .widget-teamButtons.columns-3 .teamBtnCol,
  .col-md-4 .widget-teamButtons.columns-3 .teamBtnCol {
    width: 33.3%;
  }
  .col-md-6 .widget-teamButtons.columns-3,
  .col-md-4 .widget-teamButtons.columns-3,
  .col-md-4 .widget-teamButtons.columns-2,
  .col-md-3 .widget-teamButtons.columns-2,
  .col-md-3 .widget-teamButtons.columns-1 {
    text-align: center;
  }
  .col-md-6 .widget-teamButtons.columns-3 a,
  .col-md-4 .widget-teamButtons.columns-3 a,
  .col-md-4 .widget-teamButtons.columns-2 a,
  .col-md-3 .widget-teamButtons.columns-2 a,
  .col-md-3 .widget-teamButtons.columns-1 a {
    padding-left: 12px;
  }
  .col-md-6 .widget-teamButtons.columns-3 .teamLogo-small,
  .col-md-4 .widget-teamButtons.columns-3 .teamLogo-small,
  .col-md-4 .widget-teamButtons.columns-2 .teamLogo-small,
  .col-md-3 .widget-teamButtons.columns-2 .teamLogo-small,
  .col-md-3 .widget-teamButtons.columns-1 .teamLogo-small {
    display: block;
    float: none;
    left: auto;
    margin: 0 auto 9px;
    position: relative;
    top: auto;
  }
  .col-md-6 .widget-teamButtons.columns-3 .teamLogo-small:after,
  .col-md-4 .widget-teamButtons.columns-3 .teamLogo-small:after,
  .col-md-4 .widget-teamButtons.columns-2 .teamLogo-small:after,
  .col-md-3 .widget-teamButtons.columns-2 .teamLogo-small:after,
  .col-md-3 .widget-teamButtons.columns-1 .teamLogo-small:after {
    height: 3px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -5px;
  }
  .col-md-6 .widget-teamButtons.columns-3 .teamname-separator,
  .col-md-4 .widget-teamButtons.columns-3 .teamname-separator,
  .col-md-4 .widget-teamButtons.columns-2 .teamname-separator,
  .col-md-3 .widget-teamButtons.columns-2 .teamname-separator,
  .col-md-3 .widget-teamButtons.columns-1 .teamname-separator {
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
  .col-md-6 .widget-teamButtons.columns-3,
  .col-md-3 .widget-teamButtons.columns-3 {
    font-size: 13px;
  }
  .col-md-4 .widget-teamButtons.columns-3 {
    font-size: 11px;
  }
  .col-md-4 .widget-teamButtons.columns-3 a {
    padding: 8px 4px 4px;
  }
  .widget-teamButtons.columns-2 .teamBtnCol {
    width: 50%;
  }
  .col-md-6 .widget-teamButtons.columns-2 {
    font-size: 15px;
  }
  .col-md-4 .widget-teamButtons.columns-2,
  .col-md-3 .widget-teamButtons.columns-2 {
    font-size: 13px;
  }
  .col-md-4 .widget-teamButtons.columns-2 a,
  .col-md-3 .widget-teamButtons.columns-2 a {
    padding: 10px 8px 6px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .col-md-12 .widget-teamButtons.columns-3,
  .col-md-4 .widget-teamButtons.columns-3,
  .col-md-3 .widget-teamButtons.columns-3,
  .col-md-4 .widget-teamButtons.columns-2,
  .col-md-3 .widget-teamButtons.columns-2,
  .col-md-4 .widget-teamButtons.columns-1,
  .col-md-3 .widget-teamButtons.columns-1 {
    text-align: center;
  }
  .col-md-12 .widget-teamButtons.columns-3 a,
  .col-md-4 .widget-teamButtons.columns-3 a,
  .col-md-3 .widget-teamButtons.columns-3 a,
  .col-md-4 .widget-teamButtons.columns-2 a,
  .col-md-3 .widget-teamButtons.columns-2 a,
  .col-md-4 .widget-teamButtons.columns-1 a,
  .col-md-3 .widget-teamButtons.columns-1 a {
    padding-left: 12px;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamLogo-small,
  .col-md-4 .widget-teamButtons.columns-3 .teamLogo-small,
  .col-md-3 .widget-teamButtons.columns-3 .teamLogo-small,
  .col-md-4 .widget-teamButtons.columns-2 .teamLogo-small,
  .col-md-3 .widget-teamButtons.columns-2 .teamLogo-small,
  .col-md-4 .widget-teamButtons.columns-1 .teamLogo-small,
  .col-md-3 .widget-teamButtons.columns-1 .teamLogo-small {
    display: block;
    float: none;
    left: auto;
    margin: 0 auto 9px;
    position: relative;
    top: auto;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamLogo-small:after,
  .col-md-4 .widget-teamButtons.columns-3 .teamLogo-small:after,
  .col-md-3 .widget-teamButtons.columns-3 .teamLogo-small:after,
  .col-md-4 .widget-teamButtons.columns-2 .teamLogo-small:after,
  .col-md-3 .widget-teamButtons.columns-2 .teamLogo-small:after,
  .col-md-4 .widget-teamButtons.columns-1 .teamLogo-small:after,
  .col-md-3 .widget-teamButtons.columns-1 .teamLogo-small:after {
    height: 3px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -5px;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamname-separator,
  .col-md-4 .widget-teamButtons.columns-3 .teamname-separator,
  .col-md-3 .widget-teamButtons.columns-3 .teamname-separator,
  .col-md-4 .widget-teamButtons.columns-2 .teamname-separator,
  .col-md-3 .widget-teamButtons.columns-2 .teamname-separator,
  .col-md-4 .widget-teamButtons.columns-1 .teamname-separator,
  .col-md-3 .widget-teamButtons.columns-1 .teamname-separator {
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamBtnCol,
  .col-md-12 .widget-teamButtons.columns-2 .teamBtnCol {
    box-sizing: border-box;
    float: left;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamBtnCol + .teamBtnCol,
  .col-md-12 .widget-teamButtons.columns-2 .teamBtnCol + .teamBtnCol {
    padding-left: 5px;
  }
  .col-md-12 .widget-teamButtons.columns-3 .teamBtnCol {
    width: 33.3%;
  }
  .col-md-12 .widget-teamButtons.columns-2 .teamBtnCol {
    width: 50%;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .widget-teamButtons.columns-3 .teamBtnCol, .widget-teamButtons.columns-2 .teamBtnCol {
    box-sizing: border-box;
    float: left;
  }
  .widget-teamButtons.columns-3 .teamBtnCol + .teamBtnCol, .widget-teamButtons.columns-2 .teamBtnCol + .teamBtnCol {
    padding-left: 5px;
  }
  .widget-teamButtons.columns-3 {
    text-align: center;
  }
  .widget-teamButtons.columns-3 a {
    padding-left: 12px;
  }
  .widget-teamButtons.columns-3 .teamLogo-small {
    display: block;
    float: none;
    left: auto;
    margin: 0 auto 9px;
    position: relative;
    top: auto;
  }
  .widget-teamButtons.columns-3 .teamLogo-small:after {
    height: 3px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -5px;
  }
  .widget-teamButtons.columns-3 .teamname-separator {
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
  .widget-teamButtons.columns-3 .teamBtnCol {
    width: 33.3%;
  }
  .widget-teamButtons.columns-2 .teamBtnCol {
    width: 50%;
  }
}

/* -- Filterable, Sortable Table Variant */
.widget-teamButtons table {
  width: 100%;
}

.widget-teamButtons thead {
  background: #ccc;
  border: 1px solid #bbb;
}

.widget-teamButtons th:first-child {
  padding: 5px;
}

.widget-teamButtons td:first-child {
  min-width: 45px;
}

.widget-teamButtons td .sport-colors {
  border: none;
  min-width: 45px;
}

.widget-teamButtons td .teamname {
  padding: 5px 0;
}

.widget-teamButtons tbody a,
.widget-teamButtons tbody a:hover,
.widget-teamButtons tbody a:active {
  background: none;
  border: none;
  color: #808080;
  display: block;
  margin: 0;
  text-decoration: none;
}

.widget-teamButtons tbody tr {
  background: #f4f4f4 url(<%= asset_path_for("/app_images/widgets/teamButtonBG.gif") %>) 0 0 repeat-x;
  border: 1px solid #bbb;
  color: #808080;
}

.widget-teamButtons tbody tr:hover {
  background-color: #e5e5e5;
  background-position: 0 -140px;
  border: 1px solid #666;
  color: #1a1a1a;
  text-decoration: none;
}

.widget-teamButtons tbody tr:active {
  background-color: #f6f6f6;
  background-position: 0 -280px;
  border: 1px solid #999;
  color: #333;
  text-decoration: none;
}

/* Widget - Team Buttons
---------------------------------------------------------------------------------------------------- */
.singleSurveyResultWidget {
  line-height: 1.5em;
}

.column .singleSurveyResultWidget b {
  opacity: 0.8;
}

.column .singleSurveyResultWidget b,
.column .singleSurveyResultWidget span {
  display: block;
  float: left;
}

.pe100 .singleSurveyResultWidget b,
.pe66 .singleSurveyResultWidget b,
.pe50 .singleSurveyResultWidget b {
  margin: 0 1% 0 0;
  text-align: right;
  width: 25%;
}

.pe100 .singleSurveyResultWidget span,
.pe66 .singleSurveyResultWidget span,
.pe50 .singleSurveyResultWidget span {
  width: 70%;
}

.pe33 .singleSurveyResultWidget b,
.pe33 .singleSurveyResultWidget span,
.pe25 .singleSurveyResultWidget b,
.pe25 .singleSurveyResultWidget span {
  float: none;
}

#lightboxBody .singleSurveyResultWidget {
  margin: 15px 15px -15px;
}

#lightboxBody .singleSurveyResultWidget .odd,
#lightboxBody .singleSurveyResultWidget .even {
  background: none;
}

/* Widget - Gallery
---------------------------------------------------------------------------------------------------- */
.galleryWidget {
  background: #DDD url("https://app-assets1.sportngin.com/app_images/widgets/galleryBG.png") 0 26px repeat-x;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.galleryWidget .scrollable {
  position: relative;
  width: 100%;
  height: auto;
}

.galleryWidget .scrollable .items {
  position: relative;
  width: auto;
  padding: 10px 0px 10px 10px;
}

.galleryWidget .header {
  color: #FFF;
  font-size: 11px;
  background: #333;
  text-align: right;
  width: 100%;
  display: block;
  position: relative;
}

.galleryWidget .header h3 {
  display: inline;
  float: left;
  margin: 0;
  font-size: 11px;
  padding: 5px 10px;
  width: 65%;
}

.galleryWidget .header a {
  color: #999;
  display: inline-block;
  padding: 5px 10px;
  zoom: 1;
  *display: inline;
  text-decoration: none;
  background: #444;
  transition: all 0.3s ease-in-out;
}

.galleryWidget .header a:hover {
  color: #FFF;
}

.galleryWidget .next,
.galleryWidget .prev {
  position: absolute;
  height: 0;
  width: 29px;
  height: 100px;
  bottom: 0;
  cursor: pointer;
  display: none;
}

.galleryWidget .next {
  right: 0;
}

.galleryWidget .prev {
  left: 0;
}

.galleryWidget .next span,
.galleryWidget .prev span {
  position: absolute;
  height: 0;
  width: 9px;
  padding-top: 14px;
  overflow: hidden;
  top: 43px;
  left: 10px;
  background: url("https://app-assets1.sportngin.com/app_images/widgets/galleryNav.png") 0 0 no-repeat;
}

.galleryWidget .prev:hover span {
  background-position: -9px 0;
}

.galleryWidget .next span {
  background-position: -27px 0;
}

.galleryWidget .next:hover span {
  background-position: -36px 0;
}

.galleryWidget .prev.disabled span {
  background-position: -18px 0;
}

.galleryWidget .next.disabled span {
  background-position: -45px 0;
}

.galleryWidget .item {
  background: #FFF;
  width: 75px;
  height: 75px;
  margin: 0px 8px 8px 0px;
  opacity: 0.93;
}

.galleryWidget .item:hover {
  background: #333;
  opacity: 1;
}

.galleryWidget .item .thumb {
  display: block;
  height: 75px;
  overflow: hidden;
  line-height: 0;
  background: #DDD;
  text-align: center;
}

.galleryWidget .item img.vert {
  width: 100%;
}

.galleryWidget .item img.horiz {
  height: 100%;
}

.dropdown-wrapper {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  z-index: 2;
  background: #efefef;
}

.dropdown-wrapper:before {
  content: "";
  height: 100%;
  display: none;
  width: 40px;
  position: absolute;
  left: -40px;
  top: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 100%);
}

.dropdown-wrapper #megaDropDown-season {
  background: #404040;
  display: inline-block;
  margin: auto;
  outline: medium none;
  text-align: left;
  text-decoration: none;
  height: 100%;
  position: relative;
  padding: 0px 50px 10px 10px;
  transition: all 0.5s ease-in-out;
  float: right;
  min-width: 150px;
}

.dropdown-wrapper #megaDropDown-season .season {
  color: #fff;
  display: block;
  font: normal 10px/1em "Gotham SSm A", "Gotham SSm B", sans-serif;
  margin: auto;
  width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-wrapper #megaDropDown-season .subseason {
  color: #fff;
  font: bold 12px/1em "Gotham SSm A", "Gotham SSm B", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-wrapper #megaDropDown-season:after {
  content: "\f0d7";
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #fff;
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
  border-radius: 100px;
  color: #fff;
  font: normal 20px/1em FontAwesome;
  padding: 0px 4px 4px 4px;
}

.dropdown-wrapper #megaDropDown-season-callout {
  background: rgba(0, 0, 0, 0.75);
  color: #ddd;
  padding: 10px;
  text-align: left;
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 230px;
  z-index: 2;
  padding: 15px 15px 20px 15px;
  display: none;
  top: 100%;
  border-bottom: 4px solid #CF3338;
}

.dropdown-wrapper #megaDropDown-season-callout .season-title {
  display: block;
  font: bold 12px "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding: 0 0 5px;
  margin: 5px 0;
}

.dropdown-wrapper #megaDropDown-season-callout .season-title span {
  float: left;
}

.dropdown-wrapper #megaDropDown-season-callout select {
  width: 100%;
  border: 0px;
  background: #333;
  background-image: url("https://app-assets1.sportngin.com/app_images/select-down-arrow.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 27px;
  padding: 10px;
  color: #fff;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dropdown-wrapper #megaDropDown-season-callout a {
  border-radius: 1px;
  transition: all 0.3s ease-in-out;
  display: block;
  font-size: 12px;
  padding: 2px 5px;
  text-decoration: none;
  background: #333;
  text-align: center;
  color: #ddd;
  margin-bottom: 3px;
}

.dropdown-wrapper #megaDropDown-season-callout a:hover {
  background: #222;
}

.dropdown-wrapper #megaDropDown-season-callout a.close {
  background: transparent;
  border-radius: 100%;
  color: #fff;
  display: block;
  float: right;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 0px 6px 2px 6px;
}

.dropdown-wrapper #megaDropDown-season-callout a.close:hover {
  background: transparent;
}

.dropdown-wrapper #megaDropDown-season-callout a.current {
  background: #000;
  border-radius: 1px;
  transition: all 0.3s ease-in-out;
  color: #aaa;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 10px;
  text-align: center;
  letter-spacing: .3px;
}

.dropdown-wrapper #megaDropDown-season-callout a.current:hover {
  background: #333;
}

.dropdown-wrapper #megaDropDown-season-callout .footer {
  margin: 10px 0 0;
}

.dropdown-wrapper #megaDropDown-season-callout optgroup option {
  margin: 0;
  padding: 0 0 0 10px;
}

.dropdown-wrapper #megaDropDown-season.mdd-active {
  cursor: pointer;
  background-color: #222;
}

.dropdown-wrapper #megaDropDown-season.mdd-active:after {
  display: block;
}

.dropdown-wrapper #megaDropDown-season.mdd-active:hover {
  background-color: #333;
}

.dropdown-wrapper.mdd-active-open #megaDropDown-season:after {
  content: "\f0d8";
}

.dropdown-wrapper.mdd-active-open #megaDropDown-season-callout {
  display: block;
  -webkit-animation: dropdown-season 0.3s ease-in-out;
  animation: dropdown-season 0.3s ease-in-out;
}

@media only screen and (max-width: 768px) {
  /* Seasons Mega Drop Down */
  .dropdown-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .dropdown-wrapper:before {
    display: none;
  }
  .dropdown-wrapper #megaDropDown-season .season {
    font-size: 10px;
  }
  .dropdown-wrapper #megaDropDown-season .subseason {
    font-size: 9px;
  }
  .dropdown-wrapper #megaDropDown-season-callout {
    max-width: 100%;
  }
}

#GameShowContent.game_show_nextgen .GameShowDataContainer.play_by_play {
  padding: 0;
}

body .GameShowDataContainer.play_by_play .StatWidget,
body .GameShowDataContainer.play_by_play .StatWidgetColumn .StatWidgetHolder {
  margin: 0 0 0 0;
}

#PBP {
  padding-bottom: 16px;
  background: #eee;
}

#PBP-loader + #PBP {
  display: none;
}

@media screen and (min-width: 800px) {
  #PBP .pbp-list:before {
    content: '';
    position: absolute;
    background-color: #ccc;
    left: 50%;
    top: 0;
    height: 100%;
    width: 1px;
  }
}

#PBP .pbp-header:not(:first-child) {
  margin-top: 48px;
}

#PBP .pbp-header:not(:first-child) .homeTeam,
#PBP .pbp-header:not(:first-child) .awayTeam {
  display: none;
}

#PBP .pbp-team-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

#PBP .pbp-team-header .homeTeam,
#PBP .pbp-team-header .awayTeam {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  width: 50%;
  padding: 10px 6px;
}

@media screen and (min-width: 550px) {
  #PBP .pbp-team-header .homeTeam,
  #PBP .pbp-team-header .awayTeam {
    padding-left: 12px;
    padding-right: 12px;
  }
}

#PBP .pbp-team-header .homeTeam {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#PBP .pbp-team-header .homeTeam .team_logo {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  margin-left: 8px;
}

#PBP .pbp-team-header .awayTeam .team_logo {
  margin-right: 8px;
}

#PBP .pbp-team-header .team_logo {
  display: none;
}

@media screen and (min-width: 400px) {
  #PBP .pbp-team-header .team_logo {
    display: block;
  }
}

#PBP .pbp-team-header .interval {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  background-color: #eee;
  text-transform: capitalize;
  text-align: center;
}

@media screen and (min-width: 550px) {
  #PBP .pbp-team-header .interval {
    font-size: 18px;
  }
}

#PBP .pbp-team-header .interval span {
  display: block;
  margin: 16px 0 8px;
}

#PBP .pbp-team-header .interval br {
  display: none;
}

#PBP .clockTime {
  position: absolute;
  top: 0;
  color: #fff;
  text-align: center;
  min-width: 35%;
}

@media screen and (min-width: 400px) {
  #PBP .clockTime {
    min-width: 18%;
  }
}

@media screen and (min-width: 800px) {
  #PBP .clockTime {
    min-width: 22.5%;
    background-color: #eee;
    color: #666;
  }
}

#PBP .play {
  position: relative;
  margin: 6px;
  width: calc(60% - 6px);
  background: #fff;
  font-size: 12px;
}

@media screen and (min-width: 400px) {
  #PBP .play {
    width: calc(55% - 6px);
  }
}

@media screen and (min-width: 550px) {
  #PBP .play {
    width: calc(55% - 12px);
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 14px;
  }
}

@media screen and (min-width: 800px) {
  #PBP .play {
    width: calc(45% - 12px);
  }
}

#PBP .playAway {
  margin-left: 6px;
}

@media screen and (min-width: 550px) {
  #PBP .playAway {
    margin-left: 12px;
  }
}

@media screen and (min-width: 800px) {
  #PBP .playAway {
    text-align: right;
  }
}

#PBP .playAway .clockTime {
  right: 0;
}

@media screen and (min-width: 800px) {
  #PBP .playAway .clockTime {
    right: -22.5%;
  }
}

@media screen and (min-width: 800px) {
  #PBP .playAway .playerPhoto {
    float: right;
    margin-right: 0;
    margin-left: 8px;
  }
}

#PBP .playHome {
  margin-left: 40%;
  margin-right: 6px;
}

@media screen and (min-width: 400px) {
  #PBP .playHome {
    margin-left: 45%;
  }
}

@media screen and (min-width: 550px) {
  #PBP .playHome {
    margin-right: 12px;
  }
}

@media screen and (min-width: 800px) {
  #PBP .playHome {
    margin-left: 55%;
  }
}

#PBP .playHome .playType {
  text-align: right;
  padding-left: 35%;
}

@media screen and (min-width: 400px) {
  #PBP .playHome .playType {
    padding-left: 18%;
  }
}

@media screen and (min-width: 800px) {
  #PBP .playHome .playType {
    padding-left: 8px;
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  #PBP .playHome .clockTime {
    left: -22.5%;
  }
}

#PBP .playerName {
  margin-bottom: 0;
}

#PBP .playerName br {
  display: none;
}

#PBP .playerName a:after {
  content: ' ';
}

#PBP .playerInfo {
  overflow: hidden;
}

#PBP .playerPhoto {
  float: left;
  margin-right: 8px;
}

#PBP .playType {
  color: #fff;
  background: black;
}

#PBP .playType,
#PBP .playerInfo,
#PBP .extraDetails,
#PBP .clockTime {
  padding: 4px;
}

@media screen and (min-width: 550px) {
  #PBP .playType,
  #PBP .playerInfo,
  #PBP .extraDetails,
  #PBP .clockTime {
    padding: 8px;
  }
}

#PBP .extraDetails {
  padding-top: 0;
}

.pitchTracker > h3 {
  margin-bottom: .5em;
}

.pitchTracker .regulations {
  margin-top: 1em;
}

.pitchTracker .regulations ul {
  margin-top: 1em;
}

.pitchTracker .regulations li {
  margin-left: 2.3em;
}

.siteContainer > #masterScoreboard {
  background: transparent;
  max-width: 940px;
  margin: 18px auto;
}

html.has-mobile-nav .siteContainer > #masterScoreboard {
  margin: 0;
}

#masterScoreboard {
  background: #fff;
}

#masterScoreboard .scoreboardRibbon {
  margin: 0px;
}

.scoreboardRibbon {
  background: rgba(7, 7, 7, 0.75);
  z-index: 0;
}

.pageElement .scoreboardRibbon {
  margin: 0 5px;
}

.scoreboardRibbon {
  height: 151px;
  position: relative;
  text-transform: capitalize;
  overflow: hidden;
  padding-top: 13px;
}

.scoreboardRibbon .next_button, .scoreboardRibbon .previous_button {
  width: 30px;
  height: 125px;
  cursor: pointer;
  z-index: 500;
  color: #CF3338;
  display: block;
  font: .875em FontAwesome;
  line-height: 125px;
  position: absolute;
  text-align: center;
}

.scoreboardRibbon .next_button:hover, .scoreboardRibbon .previous_button:hover {
  background: rgba(7, 7, 7, 0.75);
}

.scoreboardRibbon .next_button::selection, .scoreboardRibbon .previous_button::selection {
  background: transparent;
}

.scoreboardRibbon .next_button::-moz-selection, .scoreboardRibbon .previous_button::-moz-selection {
  background: transparent;
}

.scoreboardRibbon .next_button_disabled, .scoreboardRibbon .previous_button_disabled {
  cursor: default;
}

.scoreboardRibbon .next_button_disabled:before, .scoreboardRibbon .previous_button_disabled:before {
  color: #999;
}

.scoreboardRibbon .next_button_disabled:hover, .scoreboardRibbon .previous_button_disabled:hover {
  background: transparent;
}

.scoreboardRibbon .previous_button {
  left: 8px;
}

.scoreboardRibbon .previous_button:before {
  content: "\f060";
}

.scoreboardRibbon .next_button {
  right: 8px;
}

.scoreboardRibbon .next_button:before {
  content: "\f061";
}

.scoreboardRibbon .container {
  height: 125px;
  overflow: hidden;
  position: absolute;
  left: 45px;
  right: 45px;
  z-index: 490;
  padding: 0px;
  width: auto;
}

.scoreboardRibbon .container ul {
  width: 100000px;
  position: relative;
}

.scoreboardRibbon .game {
  display: block;
  float: left;
  width: 154px;
}

.scoreboardRibbon .gameLink {
  height: 125px;
  width: 100%;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
}

.scoreboardRibbon a > span {
  display: block;
  font-weight: normal;
  color: #666;
  line-height: 1.5em;
  padding-left: 10px;
  padding-right: 10px;
}

.scoreboardRibbon a > span:last-child {
  padding-bottom: 8px;
}

.scoreboardRibbon .date {
  background: #000000;
  color: #fff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 11px;
  letter-spacing: .5px;
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.scoreboardRibbon .team {
  font-size: 12px;
  display: block;
  clear: both;
}

.scoreboardRibbon .game {
  padding-right: 2px;
}

.scoreboardRibbon .game:last-child {
  padding-right: 0;
}

.scoreboardRibbon .game .name {
  float: left;
  clear: left;
  max-width: 105px;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.scoreboardRibbon .game .score {
  float: right;
  font-weight: bold;
}

.scoreboardRibbon .game .status {
  clear: both;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 11px;
  font-weight: 600;
  padding-top: 10px;
  text-transform: uppercase;
}

.scoreboardRibbon .game .boom .name {
  color: #000000;
}

.scoreboardRibbon .game .boom .score {
  color: #000000;
}

.scoreboardRibbon .in_progress .status {
  color: #000000;
  font-style: italic;
  font-weight: bold;
}

.scoreboardRibbon .completed .status {
  font-weight: bold;
}

@media (min-width: 1000px) {
  .col-md-3 .scoreboardRibbon .container {
    left: 25px;
    right: 25px;
  }
  .col-md-3 .scoreboardRibbon .previous_button,
  .col-md-3 .scoreboardRibbon .next_button {
    width: 18px;
  }
  .col-md-3 .scoreboardRibbon .previous_button {
    left: 4px;
  }
  .col-md-3 .scoreboardRibbon .next_button {
    right: 4px;
  }
}

/* Old class name, but I'm not sure all the places it's rendered */
.bracket-container {
  margin: 0;
  overflow: auto;
  position: relative;
  text-align: center;
  background: #FFF;
}

.theme-bracket {
  display: inline-table;
  table-layout: fixed;
  text-align: left;
  font: bold 14px "Gotham SSm A", "Gotham SSm B", sans-serif;
}

.theme-bracket-round {
  display: table-cell;
  min-width: 230px;
  max-width: 330px;
}

.theme-bracket-round-title {
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
}

.theme-bracket-nodes {
  list-style: none;
  padding: 1px 0;
}

.theme-bracket-depth-1 .theme-bracket-node,
.theme-bracket-depth-1 .theme-bracket-gap {
  line-height: 132px;
}

.theme-bracket-depth-2 .theme-bracket-node,
.theme-bracket-depth-2 .theme-bracket-gap {
  line-height: 264px;
}

.theme-bracket-depth-3 .theme-bracket-node,
.theme-bracket-depth-3 .theme-bracket-gap {
  line-height: 528px;
}

.theme-bracket-depth-4 .theme-bracket-node,
.theme-bracket-depth-4 .theme-bracket-gap {
  line-height: 1056px;
}

.theme-bracket-depth-5 .theme-bracket-node,
.theme-bracket-depth-5 .theme-bracket-gap {
  line-height: 2112px;
}

.theme-bracket-depth-6 .theme-bracket-node,
.theme-bracket-depth-6 .theme-bracket-gap {
  line-height: 4224px;
}

.theme-bracket-depth-7 .theme-bracket-node,
.theme-bracket-depth-7 .theme-bracket-gap {
  line-height: 8448px;
}

.theme-bracket-depth-8 .theme-bracket-node,
.theme-bracket-depth-8 .theme-bracket-gap {
  line-height: 16896px;
}

.theme-bracket-depth-9 .theme-bracket-node,
.theme-bracket-depth-9 .theme-bracket-gap {
  line-height: 33792px;
}

.theme-bracket-node {
  position: relative;
  padding: 0 10px;
}

.theme-bracket-node:before {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  top: 50%;
  right: 0;
  border-top: 1px solid #CCC;
}

.theme-bracket-node:after {
  content: "";
  display: block;
  position: absolute;
  width: 23px;
  left: 100%;
  margin-left: -1px;
  border-left: 1px solid #CCC;
}

.theme-bracket-node-upper:after,
.theme-bracket-compressible .theme-bracket-node-lower:after {
  top: 50%;
  border-bottom: 1px solid #CCC;
  bottom: 19px;
}

.theme-bracket-node-lower:after,
.theme-bracket-compressible .theme-bracket-node-upper:after {
  bottom: 50%;
  border-top: 1px solid #CCC;
  top: 21px;
}

.theme-bracket-compressible .theme-bracket-node-lower:after {
  border-top: none;
  bottom: auto;
  height: 20px;
}

.theme-bracket-compressible .theme-bracket-node-upper:after {
  border-bottom: none;
  top: auto;
  height: 20px;
}

.theme-bracket-node-leaf:before,
.theme-bracket-node-leaf:after {
  display: none;
}

.theme-bracket-game {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: -1px 0;
  color: inherit;
  line-height: 1.2;
  font: bold 14px "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-decoration: none !important;
  padding: 15px 15px;
  transition: background-color 0.3s ease-in-out;
}

.theme-bracket-game:hover {
  color: inherit;
  background-color: #EEE;
}

.theme-bracket-game:hover .theme-bracket-team-slot,
.theme-bracket-game:hover .theme-bracket-team-slot > * {
  border-color: #EEE;
  transition: background-color 0.3s ease-in-out;
}

.theme-bracket-team-slot {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
}

.theme-bracket-team-slot ~ .theme-bracket-team-slot {
  padding-top: 10px;
  border-right: 1px solid #CCC;
  transition: background-color 0.3s ease-in-out;
}

.theme-bracket-team-slot > * {
  display: table-cell;
  vertical-align: middle;
  line-height: 30px;
  border-bottom: 1px solid #CCC;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
}

.theme-bracket-team-slot.winner .theme-bracket-team,
.theme-bracket-team-slot.winner .theme-bracket-score,
.theme-bracket-team-slot.winner .theme-bracket-team-name {
  color: #FFF;
  background: #000000;
}

.theme-bracket-team-logo {
  width: 32px;
  border-bottom: none;
}

.theme-bracket-team-logo img {
  display: block;
  height: 30px;
  width: 30px;
  background: #DDD;
}

.theme-bracket-seed {
  display: inline-block;
  width: 1.2em;
  font: normal 12px "Gotham SSm A", "Gotham SSm B", sans-serif;
}

.theme-bracket-team {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px;
}

.theme-bracket-team-name {
  color: #000000;
}

.tbd-team .theme-bracket-team-name {
  color: inherit;
  font-weight: normal;
}

.theme-bracket-score {
  width: 2em;
  padding-right: 6px;
  text-align: right;
}

.theme-bracket-game-details {
  font: normal 12px "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 11px;
  display: table;
  table-layout: fixed;
  height: 30px;
  line-height: 1.2;
  width: 100%;
}

.theme-bracket-game-details > * {
  display: block;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 6px 0 32px;
}

.theme-bracket-game-details > *:first-child {
  padding-top: 5px;
}

.theme-bracket-game-details > *:only-child {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (max-width: 450px) {
  .theme-bracket {
    display: block;
  }
  .theme-bracket-round {
    display: block;
    max-width: initial;
    min-width: initial;
  }
  .theme-bracket-round + .theme-bracket-round .theme-bracket-round-title {
    margin-top: 10px;
  }
  .theme-bracket-node {
    padding: 0;
    line-height: initial !important;
  }
  .theme-bracket-node:last-child .theme-bracket-game {
    border-style: solid;
    border-width: 0;
    border-bottom-color: #EDF0F1;
    border-bottom-width: 2px !important;
  }
  .theme-bracket-game {
    border-style: solid;
    border-width: 0;
    border-top-color: #EDF0F1;
    border-top-width: 2px !important;
  }
  .theme-bracket-team-slot, .theme-bracket-team-slot > * {
    border-width: 0 !important;
  }
  .theme-bracket-node:before,
  .theme-bracket-node:after,
  .theme-bracket-gap {
    display: none;
  }
}

/* Bracket Select Tag */
.descendant_bracket {
  margin: 0 15px 15px;
}

/* Tooltip */
.prototip .darkgrey .content p {
  color: #ccc;
}

/* Lightbox */
#bracket-seed-list-container {
  float: right;
  margin: 10px 0;
  text-align: right;
}

#bracket-teams-container {
  float: left;
  margin: 10px 0;
}

#seed-status {
  color: #666;
  float: left;
  font-size: 10px;
  font-weight: normal;
}

#seed-status b {
  color: #333;
  font-size: 11px;
}

#bracket-seed-list,
#bracket-teams {
  background: #fef4c0;
  border: 1px solid #fef1af;
  margin: 10px 0;
  padding: 10px;
  width: 250px;
  text-align: left;
}

#bracket-seed-list {
  background: #fef4c0;
  padding: 10px 10px 10px 35px;
}

#bracket-teams {
  list-style: none;
}

#bracket-seed-list li {
  background: url("https://app-assets1.sportngin.com/app_images/bracketing/lightbox-team-selected-bottom.png") 0 100% no-repeat;
  cursor: move;
  margin: 0 0 2px;
  padding: 0 0 3px;
}

#bracket-seed-list li p {
  background: url("https://app-assets1.sportngin.com/app_images/bracketing/lightbox-team-selected-top.png") 0 0 no-repeat;
  color: #000;
}

#bracket-teams li {
  background: url("https://app-assets1.sportngin.com/app_images/bracketing/lightbox-team-bottom.png") 0 100% no-repeat;
  cursor: move;
  padding: 0 0 3px;
}

#bracket-teams li p {
  background: url("https://app-assets1.sportngin.com/app_images/bracketing/lightbox-team-top.png") 0 0 no-repeat;
  color: #a5954c;
}

#bracket-seed-list p, #bracket-teams p {
  padding: 5px 5px 2px 10px;
  line-height: 18px;
  margin: 1px 0 !important;
}

/* Seed Links */
#bracket-teams p a.seed,
#bracket-seed-list a.unseed {
  color: #bfb27b;
  cursor: pointer;
  display: inline;
  float: right;
  font-size: 9px;
  line-height: 18px;
  padding: 0 5px;
  text-decoration: underline;
  border-radius: 2px;
}

#bracket-teams p a.seed:hover,
#bracket-seed-list a.unseed:hover {
  background: #fef7d0;
  color: #40391d;
}

#bracket-seed-list a.seed,
#bracket-teams p a.unseed {
  display: none;
}

/* Hover */
#bracket-teams li:hover {
  background-image: url("https://app-assets1.sportngin.com/app_images/bracketing/lightbox-team-selected-bottom.png");
}

#bracket-teams li:hover p {
  background-image: url("https://app-assets1.sportngin.com/app_images/bracketing/lightbox-team-selected-top.png");
}

/* Seed States */
.needsMore #seed-status {
  color: #666;
}

.needsMore #bracket-seed-list {
  background: #fef4c0 url("https://app-assets1.sportngin.com/app_images/bracketing/bracket-lightbox-seed-bg.png") 100% 100% no-repeat;
  padding-bottom: 35px;
}

.tooMuch #bracket-seed-list {
  background: #e7b794;
  border: 1px solid #b93d41;
}

.tooMuch #seed-status,
.tooMuch #seed-status b {
  color: #a20012;
}

.ready #bracket-seed-list {
  background: #fef9dc;
  border: 1px solid #339933;
}

.ready #seed-status {
  color: #339933;
}

/* Roster & Game Center Buttons
  -------------------------------------------------------------------- */
#sport-slider {
  background: #f2f2f2;
  overflow: hidden;
  position: relative;
}

#sport-slider .inner-wrap {
  display: block;
  min-height: 70px;
  /* Slider Track */
}

#sport-slider .inner-wrap #track {
  bottom: 0;
  clear: both;
  height: 10px;
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: #ddd;
  border: 1px solid #ccc;
  display: none;
}

#sport-slider .inner-wrap #track #handle {
  background-color: #888;
  cursor: move;
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100px;
  z-index: 100;
  border-radius: 1px;
}

.sportsPage .prototip {
  display: none !important;
}

#scrollbox {
  position: absolute;
  width: 1%;
}

#track-left {
  background-position: 0 -10px;
  float: left;
}

#track-right {
  background-position: -6px -10px;
  float: right;
}

#track-left,
#track-right {
  height: 10px;
  width: 10px;
}

/* Scrollable Container
  -------------------------------------------------------------------- */
#scroll-container {
  padding: 0 0 15px;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 70px;
}

#scroll-container::-webkit-scrollbar {
  display: none;
}

/* Player/Roster Buttons
  -------------------------------------------------------------------- */
#sport-slider li {
  margin: 0 0 0 2px;
}

/* Game Buttons
  -------------------------------------------------------------------- */
#sport-slider .month {
  background: url("https://app-assets1.sportngin.com/app_images/months.gif") 0 0;
  display: inline-block;
  float: left;
  height: 45px;
  margin: 2px 2px 0 0;
  width: 10px;
  text-indent: -999999px;
}

/* Months */
#sport-slider .mth-jan {
  background-position: 0 0;
}

#sport-slider .mth-feb {
  background-position: -10px 0;
}

#sport-slider .mth-mar {
  background-position: -20px 0;
}

#sport-slider .mth-apr {
  background-position: -30px 0;
}

#sport-slider .mth-may {
  background-position: -40px 0;
}

#sport-slider .mth-jun {
  background-position: -50px 0;
}

#sport-slider .mth-jul {
  background-position: -60px 0;
}

#sport-slider .mth-aug {
  background-position: -70px 0;
}

#sport-slider .mth-sep {
  background-position: -80px 0;
}

#sport-slider .mth-oct {
  background-position: -90px 0;
}

#sport-slider .mth-nov {
  background-position: -100px 0;
}

#sport-slider .mth-dec {
  background-position: -110px 0;
}

#sport-slider .mth-tbd {
  background-position: -120px 0;
}

#sport-slider .games {
  float: left;
  display: inline-block;
}

#sport-slider .games li {
  position: static;
}

#sport-slider .games a {
  padding: 3px 4px 3px;
}

#sport-slider .games span {
  display: block;
  text-align: center;
}

#sport-slider .games .dayOfTheWeek {
  font: normal 8px "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-transform: uppercase;
}

#sport-slider .games .date {
  font: normal 18px "Gotham SSm A", "Gotham SSm B", sans-serif;
}

#sport-slider .games .outcome {
  font-weight: normal;
  margin: 0;
  text-align: center;
}

/* Prototip Tooltips - General
    -------------------------------------------------------------------- */
#tooltips {
  display: none;
}

/* This div is where the tooltips are stored in the markup. Prototip grabs them from here, and clones/displays them in the right place when you hover over the trigger */
.prototip .ngin .tooltip-outer {
  background: #e6e6e6;
  border: 1px solid #333;
  position: relative;
  width: 320px;
}

.prototip .ngin .tooltip-outer h3 {
  background: #363636 url("https://app-assets1.sportngin.com/app_images/tooltips/headerBG.gif") 0 0 repeat-x;
  border-bottom: 1px solid #333;
  color: #fff;
  font-size: 1.1em;
  line-height: 30px;
  padding: 0 10px;
  text-align: left;
}

.prototip .ngin .tooltip-inner {
  height: 94px;
  position: relative;
  width: 200px;
}

.prototip .ngin .mult_games {
  width: 350px;
}

.prototip .ngin .rosterTooltip {
  width: 200px;
}

.prototip_StemWrapper {
  margin: 8px 0 0 !important;
}

/* Player */
.stats {
  margin: 0 0 10px 85px;
}

.stats .stats-major {
  font-weight: bold;
  font-size: 18px;
  display: block;
  padding: 15px 5px 0px 15px;
  width: auto;
}

.stats .stats-major:after {
  content: '';
  display: block;
  max-width: 100px;
  margin: auto;
  border-bottom: 1px solid #ddd;
  padding-top: 5px;
}

.stats .stats-minor {
  display: block;
  padding: 5px 0px 0px 0px;
  width: auto;
}

/* Tool tip */
.prototip .ngin .tooltip-inner .stats {
  float: left;
  margin: 0;
  position: absolute;
  left: 94px;
  top: 25px;
  text-align: center;
}

.prototip .ngin .tooltip-inner .stats .position {
  background: url("https://app-assets1.sportngin.com/app_images/tooltips/bio-groove.png") 50% 100% no-repeat;
  font-weight: bold;
  font-size: 18px;
  display: block;
  padding: 0 0 15px;
  width: 90px;
}

.prototip .ngin .tooltip-inner .stats .bio {
  display: block;
  padding: 10px 0 5px;
  width: 100px;
}

/* Game Score */
.score-home {
  background: #e6e6e6 url("https://app-assets1.sportngin.com/app_images/tooltips/vs-r.png") 0% 50% no-repeat;
  float: right;
  width: 160px;
}

.score-away {
  background: #e6e6e6 url("https://app-assets1.sportngin.com/app_images/tooltips/vs-l.png") 100% 50% no-repeat;
  float: left;
  width: 160px;
}

.mult_games .score-away, .mult_games .score-home {
  width: 175px;
}

.score-away .score, .score-home .score {
  font: bold 48px "Gotham SSm A", "Gotham SSm B", sans-serif;
}

.prototip .ngin .game .tooltip-inner {
  min-height: 120px;
  width: 320px;
}

.prototip .ngin .game {
  width: 320px;
}

.prototip .score {
  border: none;
  text-align: center;
}

/* Team Name */
.score-home .teamName, .score-away .teamName {
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  overflow: hidden;
  text-transform: uppercase;
  text-align: center;
}

.score-home .teamName {
  padding: 0 10px 0 15px;
}

.score-away .teamName {
  padding: 0 15px 0 10px;
}

.mult_games .teamName {
  background: none;
  border: none;
  font-size: 10px;
  height: 30px;
  line-height: 35px;
}

.mult_games .score-away .teamName {
  color: #666;
  text-align: left;
  padding: 0 0 2px 5px;
}

.mult_games .score-home .teamName {
  color: #666;
  text-align: right;
  padding: 0 5px 2px 0;
}

/* Game Listing */
.game-listing {
  background: url("https://app-assets1.sportngin.com/app_images/tooltips/game-listingBG.gif") 0 100% repeat-x;
}

.game-listing .sport-colors {
  min-height: 30px;
  height: 30px;
}

.game-listing .score-home {
  background: transparent url("https://app-assets1.sportngin.com/app_images/tooltips/vs-sm-lt-r.gif") 0 5px no-repeat;
}

.game-listing .score-away {
  background: transparent url("https://app-assets1.sportngin.com/app_images/tooltips/vs-sm-lt-l.gif") 100% 5px no-repeat;
}

.game-listing .score {
  font: normal 24px "Gotham SSm A", "Gotham SSm B", sans-serif;
}

.game-listing .score-home .score {
  background: none;
  margin: 0 75px 0 0;
  float: right;
  padding: 0;
  text-align: right;
}

.game-listing .score-away .score {
  background: none;
  margin: 0 0 0 75px;
  float: left;
  padding: 0;
  text-align: left;
}

.game-listing p {
  background: #ccc;
  border-top: 1px solid #b3b3b3;
  padding: 10px 0;
  text-align: center;
}

/* Page Title
-------------------------------------------------------------------- */
#sport-header .pageTitle {
  font-size: 32px;
  margin: 0 15px;
  padding: 20px 0 10px;
  text-align: center;
}

.rosterPlayerInfoElement {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
}

@media screen and (min-width: 500px) {
  .rosterPlayerInfoElement {
    position: relative;
    padding-bottom: 6px;
    text-align: left;
  }
}

.rosterPlayerInfoElement h3 {
  margin-bottom: 20px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: #666 !important;
  text-transform: none;
  font-size: 2rem;
  position: relative;
}

@media screen and (min-width: 500px) {
  .rosterPlayerInfoElement h3 {
    width: calc(100% - 200px - 20px);
    float: right;
  }
}

.rosterPlayerInfoElement h3 small {
  display: inline-block;
  padding: .25em .5em;
  font-size: 1.25rem;
  background-color: #000000;
  color: #fff;
}

.rosterPlayerInfoElement .RosterPlayerInfoImage img {
  display: block;
  max-height: 250px;
  max-width: 200px;
  margin: auto;
}

@media screen and (min-width: 500px) {
  .rosterPlayerInfoElement .RosterPlayerInfoImage img {
    margin: 0;
    float: left;
  }
}

.rosterPlayerInfoElement table {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 500px) {
  .rosterPlayerInfoElement table {
    max-width: calc(100% - 200px - 20px);
    margin-left: 20px;
    margin-top: 0;
  }
}

.rosterPlayerInfoElement th,
.rosterPlayerInfoElement td {
  font-size: 1rem;
  text-align: left;
}

.rosterPlayerInfoElement tr:not(:first-child) th,
.rosterPlayerInfoElement tr:not(:first-child) td {
  padding-top: 10px;
}

.rosterPlayerInfoElement th {
  padding-right: 20px;
}

.rosterPlayerInfoElement th:after {
  content: ':';
}

#rosterPlayerConnections-search,
.rosterPlayerToConnect {
  background: #fdf4bc;
  border: 1px solid #fde686;
  margin: 10px 0;
  padding: 10px;
}

.rosterPlayerToConnect img {
  float: left;
  height: 50px;
  margin: 0 5px 0 0;
  width: 50px;
}

.rosterPlayerToConnect h2 {
  margin: 0 0 5px 0;
}

.rosterPlayerToConnect h3 {
  margin: 5px 0;
}

#lightboxBody .rosterPlayerConnections p {
  line-height: 140%;
  margin-bottom: 10px;
}

.rosterPlayerConnection-header {
  padding: 5px 0 0;
}

.rosterPlayerConnection-header img {
  margin: -5px 10px 0 0;
  height: 70px;
  width: 70px;
}

.rosterPlayerToConnect .rosterPlayerConnections {
  border-top: 1px dotted #fde686;
  margin: 10px 0 -10px;
  padding: 10px 0 0;
}

.rosterPlayerConnections {
  clear: both;
  line-height: 18px;
}

.rosterPlayerConnections p,
.rosterPlayerConnections h3 {
  clear: both;
}

.rosterPlayerConnections p strong {
  font-size: 13px;
}

.rosterPlayerConnections p img {
  float: left;
  height: 50px;
  margin: 0 10px 0 0;
  width: 50px;
}

#rosterPlayerConnections-search h3,
#rosterPlayerConnections-search-results h3 {
  margin-bottom: 5px;
}

#rosterPlayerConnections-search-results {
  margin: 0 10px;
}

#rosterPlayerConnections-search-results i {
  background: url("https://app-assets1.sportngin.com/app_images/miniLoader.gif") 0 50% no-repeat;
  padding-left: 20px;
}

.rosterPlayerInfoElement-template {
  margin-bottom: 10px;
  position: relative;
  display: block;
  margin: auto;
  width: 100%;
}

.rosterPlayerConnection-checked, .rosterPlayerConnection-unchecked {
  border-radius: 4px;
  margin: 0 5px 0 0;
  padding: 2px 3px;
}

.rosterPlayerConnection-checked {
  background: #fcf0ab;
  border: 1px solid #fde686;
}

.rosterPlayerConnection-unchecked {
  background: #fbe98b;
  border: 1px solid #fccd22;
}

.defaultPlayerInfo h3 {
  padding-top: 31px;
}

#rosterPlayerConnections-button {
  background: #fff;
  color: #333;
  cursor: pointer;
  font-size: 12px;
  line-height: normal;
  padding: 5px 0 5px 0;
  position: absolute;
  top: 0;
  left: 0;
}

#rosterPlayerConnections-button .teamLogo-tiny {
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle;
}

#rosterPlayerConnections-button i {
  display: inline-block;
  margin-left: 4px;
  margin-top: -2px;
  vertical-align: middle;
}

#rosterPlayerConnections-button i:after {
  border-radius: 20px;
  border: 1px solid #777;
  content: "\f078";
  display: inline-block;
  font: 12px/1em FontAwesome;
  padding: 4px;
  text-align: center;
}

#rosterPlayerConnections-button.rpc-open i:after {
  content: "\f077";
}

#rosterPlayerConnections-dropdown {
  box-shadow: none;
  font-size: 12px;
}

#rosterPlayerConnections-dropdown > div {
  background: #fff;
  border: 1px solid #777;
  padding: 8px 10px;
  position: absolute;
  top: 31px;
}

#rosterPlayerConnections-dropdown h3 {
  font: inherit;
  float: none;
  margin: 0;
  padding: 0 0 2px;
  width: auto;
}

#rosterPlayerConnections-dropdown li {
  list-style: none;
  padding: 5px 0 0;
}

#rosterPlayerConnections-dropdown .teamLogo-tiny {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

@media (max-width: 499px) {
  .defaultPlayerInfo h3 {
    padding-top: 0;
  }
  #rosterPlayerConnections-button {
    position: static;
    display: block;
    margin-top: 10px;
  }
  #rosterPlayerConnections-dropdown {
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;
  }
  #rosterPlayerConnections-dropdown > div {
    display: table;
    left: 50%;
    position: relative;
    top: 100%;
    transform: translatex(-50%);
  }
}

@media only screen and (max-width: 1024px) {
  /* Game Show */
  #liveGameSimple {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  /* TEAM PAGES */
  /* Game Show Content */
  #GameShowContent li.tab_menu-tab {
    width: 50%;
  }
  .teamTagFilter label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 480px) {
  /* Stats */
  .StatWidgetContainer {
    padding: 15px 0 0 0;
  }
  .StatWidget {
    margin: 0 0 20px 0;
  }
  .StatWidgetContainer.game_details {
    padding: 20px 10px;
  }
  #GameShowContent ul.player_stats-list-header li h2 {
    font-size: 0.75em;
  }
  #GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list {
    font-size: 0.8em;
  }
  .GameShowDataContainer .StatWidgetGroup {
    margin: 0 10px 20px 0;
  }
  .player_stat-leader .player_name {
    font-size: 1em;
  }
}

/* GAME/SHOW CONTENT */
/* FIELD OF PLAY & LIVE VIDEO */
#LiveGame {
  position: relative;
  margin-bottom: 25px;
  padding-top: 40px;
  clear: both;
}

#LiveGame .background,
#LiveGame .background .graphics {
  background: #000;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

#LiveGame .background .graphics {
  min-height: 535px;
}

#LiveGame .background .graphics * {
  height: 100%;
  left: 50%;
  margin-left: -500px;
  position: absolute;
  top: 0;
  width: 1000px;
}

#LiveGame .background .top {
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/fop_bg_top.png") center top;
  height: 150px;
}

#LiveGame .background .mid {
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/fop_bg_mid.png") center top;
  height: auto;
  top: 150px;
  bottom: 384px;
}

#LiveGame .background .bottom {
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/fop_bg_bottom.png") center top;
  height: 384px;
  top: auto;
  bottom: 0;
}

#LiveGame .background .awayTeamFade {
  width: 700px;
}

#LiveGame .background .homeTeamFade {
  width: 700px;
  margin-left: -200px;
}

.gameShow #GS_Video {
  overflow-x: auto;
  overflow-y: visible;
}

.gameShow #GS_Video > iframe {
  max-width: 100%;
}

#GS_Video {
  text-align: center;
  padding: 0 20px;
  position: relative;
  top: -10px;
  margin: 25px 0;
}

#currentPlay + #GS_Video {
  top: -25px;
}

#GS_VideoWrap {
  display: inline-block;
  zoom: 1;
  *display: inline;
  background: #000;
  border: 13px outset #222;
  -moz-border-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/video_border.png") 30 repeat;
  -webkit-border-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/video_border.png") 30 repeat;
  -o-border-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/video_border.png") 30 repeat;
  border-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/video_border.png") 30 repeat;
  box-shadow: 0 15px 30px #000;
}

#GameLiveVideo {
  background: #000;
}

#GameLiveVideo object {
  display: block;
  margin: 0 auto;
}

/* MAIN SPORT ID */
#GameShowContent {
  margin: 30px 15px 15px;
  overflow: visible;
  position: relative;
  font-size: 100%;
}

#GameShowContent a {
  text-decoration: none;
}

#GameShowContent .rapidContentGeneral,
#GameShowContent #contentTabsLoader {
  width: 70%;
  position: relative;
  z-index: 3;
  background: white;
  /* Old browsers */
  overflow: hidden;
  min-height: 400px;
}

#GameShowContent .tab_menu-listLoader {
  padding: 100px 0;
  min-height: 150px;
}

#GameShowContent #tab_gs3_game_stats_content.rapidContentGeneral {
  width: 100%;
}

#GameShowContent.noComments .rapidContentGeneral, #GameShowContent.noComments #contentTabsLoader {
  width: 100%;
}

#GameShowContent span.team_logo img {
  border-radius: 5px 5px 5px 5px;
  vertical-align: middle;
}

#GameShowContent li {
  list-style: none;
}

/* TOP LEVEL SECTIONS */
#GameShowContent .game_info {
  overflow: hidden;
}

/* TABBED NAVIGATION */
#GameShowContent .tab_menu {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow: hidden;
  width: 70%;
  box-shadow: inset 0 -1px 0 #efefef;
}

#GameShowContent .tab_menu #iscoreGame {
  float: right;
  text-align: right;
  padding-right: 10px;
}

#liveGameSimple {
  padding-top: 0;
  margin-bottom: 10px;
}

#GameShowContent.game_show_nextgen #scorekeeperGame {
  float: right;
  text-align: right;
  padding-top: 7px;
  padding-right: 8px;
  color: #bbb;
}

#GameShowContent .tab_menu #iscoreTimestamp {
  color: #aaa;
}

/* MAIN GAME DATA COLUMN */
#GameShowContent ul.tab_menu-list {
  padding: 0 10px;
  list-style-type: none;
  float: left;
  position: relative;
  z-index: 4;
  overflow: hidden;
}

#GameShowContent li.tab_menu-tab {
  float: left;
  border-top: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  text-align: center;
}

#GameShowContent li.tab_menu-tab a {
  display: block;
  padding: 12px 10px 8px;
  color: #666;
  font-weight: bold;
  background: #E7E7E7;
}

#GameShowContent li.tab_menu-tab.selected a {
  background-color: #fff;
  color: #333;
  border-bottom: none;
}

#GameShowContent li.tab_menu-tab a:hover {
  color: #333;
}

/* SIDEBAR */
.GameShowDataColumn {
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  background: #DDD;
  position: absolute;
  top: 75px;
  bottom: 25px;
  right: 0;
  width: 30%;
  overflow: auto;
  border-bottom: solid 1px #fff;
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.GameShowDataColumn .gameShowColumnSection {
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-flex: 100;
  -moz-box-flex: 100;
  box-flex: 100;
  width: 100%;
}

.GameShowDataColumn .gameShowColumnHeader {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  margin: 0;
  padding: 1px;
  background: #ccc;
  /* Old browsers */
}

#GameShowContent .GameShowDataColumn .gameShowColumnHeader h3 {
  padding: 10px;
  margin: 0;
}

#GameShowContent .GameShowDataColumn .gameShowColumnHeader h3 .action {
  float: right !important;
  margin-top: -2px;
}

#GameShowContent .GameShowDataColumn .gameShowColumnHeader.collapsable_handle h3 {
  background-image: url("https://app-assets1.sportngin.com/app_images/user/expand_arrows.png");
  background-position: -92px -39px;
  background-repeat: no-repeat;
  padding-left: 24px;
}

#GameShowContent .GameShowDataColumn .collapsed .gameShowColumnHeader.collapsable_handle h3 {
  background-image: url("https://app-assets1.sportngin.com/app_images/user/expand_arrows.png");
  background-position: -142px 11px;
}

.GameShowDataColumn .gameShowColumnContent {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  overflow: auto;
  padding2: 0 5px;
  width: 100%;
}

.GameShowDataColumn .gameShowColumnSection.news {
  box-flex: 0;
  max-height: 50%;
}

.GameShowDataColumn .gameShowColumnSection:first-child .gameShowColumnHeader {
  border-radius: 0 5px 0 0;
}

.GameShowDataColumn .gameShowColumnSection:last-child .gameShowColumnContent {
  border-radius: 0 0 5px 0;
}

/* MAIN CONTENT CONTAINERS */
.GameShowDataContainer {
  padding: 20px 0 0 20px;
}

.GameShowDataContainer .StatWidgetGroup {
  background: #FFF;
  /* Old browsers */
  margin: 0 10px 10px 10px;
  overflow: hidden;
  position: relative;
  z-index: 25;
}

.GameShowDataContainer.game_stats .StatWidgetGroup {
  border-radius: 5px;
}

.GameShowDataContainer .StatWidgetGroup.inset {
  border: 1px solid #efefef;
}

.GameShowDataContainer.game_summary .StatWidgetGroup.inset,
.GameShowDataContainer.game_preview .StatWidgetGroup.inset {
  background: #FFF;
  /* Old browsers */
}

.StatWidgetTabsGroup {
  margin: -10px 20px 0 0;
  overflow: hidden;
  padding-top: 10px;
}

/*////////// MAIN STAT WIDGET CONTAINERS //////////*/
.StatWidgetContainer {
  display: block;
  float: left;
  overflow: hidden;
  padding: 20px 0 0 20px;
  width: 100%;
}

.StatWidgetContainer-sub {
  overflow: hidden;
}

.StatWidgetColumn {
  display: block;
  float: left;
}

.StatWidget {
  display: block;
  margin: 0 20px 20px 0;
}

.StatWidgetHolder .StatWidgetContainer-sub {
  margin-right: 0;
}

/*////////// INDIVIDUAL STAT WIDGETS (SPORT AGNOSTIC) //////////*/
.z10 {
  z-index: 10;
}

.z9 {
  z-index: 9;
}

.z8 {
  z-index: 8;
}

.z7 {
  z-index: 7;
}

.z6 {
  z-index: 6;
}

.z5 {
  z-index: 5;
}

.z4 {
  z-index: 4;
}

.z3 {
  z-index: 3;
}

.z2 {
  z-index: 2;
}

.z1 {
  z-index: 1;
}

.width25 {
  width: 25%;
}

.width33 {
  width: 33.333333%;
}

.width50 {
  width: 50%;
}

.width66 {
  width: 66.666666%;
}

.width75 {
  width: 75%;
}

.width100 {
  width: 100%;
}

#GameShowContent .StatWidgetHolder p.noEntry,
#GameShowContent .StatWidget span.no_stats {
  border-radius: 5px;
  display: block;
  width: 100%;
  padding: 5px 0;
  text-align: center;
}

#GameShowContent .team_stats-list .noEntry {
  display: block;
  border-top: none;
  font-style: normal;
  font-size: 9px;
  background-color: #cccccc;
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/diagonal_stripes.png");
}

#GameShowContent .StatWidgetTabsGroup-inset {
  background: #666666;
  /* Old browsers */
  border-radius: 5px;
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  margin: 0 20px 0 0;
}

#GameShowContent ul.StatWidgetTabs-inset {
  border-bottom: solid 1px #fff;
  list-style-type: none;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
}

#GameShowContent ul.StatWidgetTabs-inset.tabs-1 li {
  width: 100%;
}

/* Sets tab width for 1-list item */
#GameShowContent ul.StatWidgetTabs-inset.tabs-2 li {
  width: 50%;
}

/* Sets tab width for 2-list items */
#GameShowContent ul.StatWidgetTabs-inset.tabs-3 li {
  width: 33.333333%;
}

/* Sets tab width for 3-list items */
#GameShowContent ul.StatWidgetTabs-inset a:link,
#GameShowContent ul.StatWidgetTabs-inset a:visited {
  color: #ddd;
}

#GameShowContent ul.StatWidgetTabs-inset a:hover,
#GameShowContent ul.StatWidgetTabs-inset a:active {
  color: #fff;
}

#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li:before {
  content: none;
}

#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li {
  background: rgba(255, 255, 255, 0.35);
  color: #ccc;
  display: block;
  float: left;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
}

#GameShowContent ul.StatWidgetTabs-inset.stats_toggle li {
  color: #ddd;
  cursor: pointer;
}

#GameShowContent ul.StatWidgetTabs-inset.stats_toggle li:hover {
  color: #fff;
}

#GameShowContent .GameShowDataContainer.play_by_play .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li {
  width: 20%;
}

#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li.selected,
#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li:hover,
#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li:active {
  background: none;
  color: #fff;
  position: relative;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.5);
}

#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li.selected a {
  color: #fff;
}

#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/border-black.png"), url("https://app-assets1.sportngin.com/app_images/game_show_3/border-white.png");
  background-repeat: repeat-y;
  background-position: right, left;
  position: relative;
}

#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li:first-child {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/border-black.png");
  background-repeat: repeat-y;
  background-position: right;
}

#GameShowContent .StatWidgetTabsGroup-inset ul.StatWidgetTabs-inset li:last-child {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/border-white.png");
  background-repeat: repeat-y;
  background-position: left;
}

.StatWidgetTabs-inset .carrot {
  display: none;
}

.StatWidgetTabs-inset li.selected .carrot {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/carrot-up.png");
  background-repeat: no-repeat;
  background-position: 50% bottom;
  display: block;
  height: 38px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#GameShowContent ul.StatWidgetTabs {
  list-style-type: none;
  width: 100%;
}

#GameShowContent ul.StatWidgetTabs li {
  background: #b2b2b2;
  border-radius: 5px 0px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: #666;
  display: block;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 20px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  height: 3em;
  line-height: 3em;
  position: relative;
}

#GameShowContent ul.StatWidgetTabs li h3 {
  display: block;
  float: left;
}

#GameShowContent ul.StatWidgetTabs li span {
  display: block;
  float: left;
  height: 43px;
  left: 100%;
  width: 69px;
  position: absolute;
  top: -7px;
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/tab-edge-right.png");
  background-color: transparent;
}

x-#GameShowContent ul.StatWidgetTabs li:before {
  content: url("https://app-assets1.sportngin.com/app_images/game_show_3/tab-edge-right-gray.png");
  position: absolute;
  left: 100%;
  top: -7px;
}

#GameShowContent ul.StatWidgetTabs li.selected {
  background: #fff;
  position: relative;
  z-index: 50;
}

#GameShowContent ul.StatWidgetTabs li.more_stats {
  background: none;
  box-shadow: none;
  display: block;
  float: right;
  opacity: .5;
  filter: Alpha(opacity=50);
  padding: 0;
  text-shadow: 1px 1px 0px white;
}

#GameShowContent ul.StatWidgetTabs li.more_stats:before {
  content: none;
}

#GameShowContent ul.StatWidgetTabs li.live_game_sheet {
  background: #cccccc;
  background-image: #898989;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 5px;
  border-top: solid 1px #e5e5e5;
  color: black;
  font-size: 10px;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.25);
  opacity: 1;
  height: 20px;
  line-height: 20px;
  border: solid 1px #666666;
  border-top: initial;
  border-top: solid 1px #333333;
  border-bottom: solid 1px white;
  float: right;
}

#GameShowContent ul.StatWidgetTabs li.live_game_sheet:hover {
  background-image: #424242;
  color: #fff;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.25);
}

#GameShowContent ul.StatWidgetTabs-mini {
  list-style-type: none;
  width: 100%;
}

/* COMBINE THESE -mini STYLES WITH REGULAR STYLES WHERE POSSIBLE */
#GameShowContent ul.StatWidgetTabs-mini li {
  background: #b2b2b2;
  border-radius: 5px 0px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: #666;
  display: block;
  float: left;
  margin: 0 20px 0 0;
  padding: 0 0 0 1em;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  height: 2.5em;
  line-height: 2.5em;
  position: relative;
}

#GameShowContent ul.StatWidgetTabs-mini li:before {
  content: url("https://app-assets1.sportngin.com/app_images/game_show_3/tab-edge-right-gray.png");
  position: absolute;
  left: 100%;
  top: -7px;
}

#GameShowContent ul.StatWidgetTabs-mini li.selected {
  background: #fff;
  position: relative;
  z-index: 50;
}

#GameShowContent ul.StatWidgetTabs-mini li.selected:before {
  content: url("https://app-assets1.sportngin.com/app_images/game_show_3/tab-edge-right.png");
  position: absolute;
  left: 100%;
  top: -7px;
}

#GameShowContent ul.StatWidgetTabs-mini li.more_stats {
  background: none;
  box-shadow: none;
  display: block;
  float: right;
  opacity: .5;
  filter: Alpha(opacity=50);
  padding: 0;
  text-shadow: 1px 1px 0px white;
}

#GameShowContent ul.StatWidgetTabs-mini li.more_stats:before {
  content: none;
}

/* PLAYER STAT WIDGET - LEADER */
.StatWidget.player_stat-leader-header {
  background: #fff;
  color: #444;
  margin: 0 30px 0 10px;
  padding: 0;
  text-align: left;
}

.StatWidget.player_stat-leader-header div {
  display: block;
  height: 100%;
  padding: 5px 0;
  width: 100%;
}

span.player_stat-leader-header {
  font-size: 1.125em;
  font-weight: 600;
  color: #000000;
  text-align: center;
  width: 100%;
}

#GameShowContent .player_stat-leader {
  background: white;
  /* Old browsers */
  color: #fff;
  font-weight: bold;
  padding: 10px;
  overflow: hidden;
  text-transform: uppercase;
}

.player_stat-leader div {
  overflow: hidden;
  position: relative;
}

.player_stat-leader .player_photo {
  display: block;
  margin: 0 0 10px 0;
  position: absolute;
  width: 105px;
  height: 105px;
  top: 0;
  left: 0;
}

.player_stat-leader .player_photo span.player_photo_image {
  display: block;
  width: 100px;
  height: 100px;
  border: 3px solid #f0f0f0;
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
}

.player_stat-leader .player_photo span.player_photo_image img.portrait {
  display: block;
  width: 105%;
  margin: -2% 0 0 -2%;
}

.player_stat-leader .player_photo span.player_photo_image img.landscape {
  height: 100%;
  vertical-align: middle;
  margin: -9999px;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_photo span.player_photo_image {
  line-height: 58px;
}

.player_stat-leader .player_statbox {
  display: block;
  margin: 0 0 5px 110px;
  height: 100px;
}

.player_stat-leader .player_statbox span.statbox_stat {
  font-size: 2.5em;
  height: 60%;
  color: #333;
  background: #fff;
  line-height: 70px;
}

.player_stat-leader .player_statbox span.statbox_category {
  font-size: .875em;
  height: 40%;
  color: #000000;
  background: #fff;
  line-height: 16px;
  text-decoration: none;
}

.player_stat-leader .player_statbox span.statbox_category span {
  display: inline-block;
  line-height: 100%;
  vertical-align: middle;
}

.player_stat-leader .player_statbox span {
  display: block;
  text-align: center;
}

.player_stat-leader ul {
  font-size: 12px;
  height: 30px;
}

.player_stat-leader ul li {
  line-height: 30px;
  text-indent: 10px;
  vertical-align: middle;
}

.player_stat-leader .player_no {
  clear: left;
  display: block;
  float: left;
  text-align: center;
  text-indent: -4px;
  text-transform: none;
  width: 50px;
}

.playerStatLeaderElement .player_stat-leader .player_name {
  display: block;
  font-size: 1.125em;
  font-weight: 600;
  color: #000000;
  background: #fff;
  float: left;
  max-height: 30px;
  text-transform: none;
  overflow: hidden;
  text-indent: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GameShowDataContainer.game_preview .player_stat-leader ul li.player_name {
  max-width: 185px;
}

.GameShowDataContainer.game_summary .player_stat-leader ul li.player_name {
  max-width: 120px;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_name {
  max-width: 120px;
}

.GameShowDataContainer.game_preview .player_stat-leader .player_name {
  max-width: 180px;
}

.player_stat-leader .player_stats-extra {
  clear: left;
  display: block;
  float: left;
  width: 100%;
}

.player_stat-leader .player_stats-extra ul {
  background-image: none;
  color: #333;
  font-weight: normal;
  margin-top: 10px;
  text-shadow: none;
}

.player_stat-leader .player_stats-extra ul:before {
  display: table;
}

.player_stat-leader .player_stats-extra ul:after {
  clear: both;
}

.player_stat-leader .player_stats-extra ul li {
  display: block;
  float: left;
  font-size: .875em;
  background-color: #666;
  color: #fff;
  font-weight: 600;
  height: 56px;
  margin-left: 0px;
  text-align: center;
  text-indent: 0;
}

.player_stat-leader .player_stats-extra ul li span {
  display: block;
  height: 50%;
  vertical-align: middle;
  width: 100%;
}

.player_stat-leader .player_stats-extra ul li span.stat {
  background-color: #666;
  color: #333;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 400;
}

.player_stat-leader .player_stats-extra ul li:first-child {
  margin-left: 0;
}

.player_stat-leader .player_stats-extra.stat_5 ul li {
  width: 20%;
}

.player_stat-leader .player_stats-extra.stat_4 ul li {
  width: 25%;
}

.player_stat-leader .player_stats-extra.stat_3 ul li {
  width: 33.333333%;
}

.player_stat-leader .player_stats-extra.stat_2 ul li {
  width: 50%;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_stats-extra {
  display: none;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_photo {
  display: block;
  margin: 0 0 10px 0;
  position: absolute;
  width: 60px;
  height: 60px;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_statbox {
  height: 60px;
  margin: 0 0 10px 70px;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_statbox span.statbox_stat {
  font-size: 21px;
  height: 50%;
  line-height: 25px;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_statbox span.statbox_category {
  font-size: 10px;
  height: 50%;
  line-height: 30px;
}

.GameShowDataContainer.game_summary .player_stat-leader ul {
  background-position: 0 50%;
  font-size: 10px;
  height: 30px;
}

.GameShowDataContainer.game_summary .player_stat-leader ul li {
  line-height: 30px;
  text-indent: 5px;
}

.GameShowDataContainer.game_summary .player_stat-leader .player_no {
  background-position: -20px 50%;
  text-align: center;
  text-indent: -5px;
  width: 35px;
}

/* TEAM STAT WIDGET - COMPARISON */
#GameShowContent .StatWidgetContainer.team_stat-comparison {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/vertical_divider.png") repeat-y 50% 50%;
}

#GameShowContent .homeTeam, #GameShowContent .awayTeam {
  background: transparent;
  background-color: #fff;
}

.team_stat-comparison .stat_category {
  text-transform: uppercase;
}

#GameShowContent .team_stat-comparison h3 {
  margin-bottom: 5px;
}

#GameShowContent .team_stat-comparison .stat-bar_graph {
  margin-bottom: 5px;
  overflow: hidden;
  width: 100%;
}

.team_stat-comparison .stat-bar_graph .awayTeam,
.team_stat-comparison .stat-bar_graph .homeTeam {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/stat_comparison-shine.png");
  background-repeat: repeat-x;
  background-position: 50% 50%;
  display: block;
  float: left;
  height: 30px;
  max-width: 98%;
  min-width: 2%;
}

#tab_gs3_game_summary_content .team_stat-comparison .stat-bar_graph .awayTeam,
#tab_gs3_game_summary_content .team_stat-comparison .stat-bar_graph .homeTeam {
  max-width: 88%;
  min-width: 12%;
}

#tab_gs3_player_stats_content .player_stats-list-header .teamLogo-tiny {
  margin-top: 4px;
}

.team_stat-comparison .stat-bar_graph .awayTeam {
  border-radius: 5px 0px 0px 5px;
}

.team_stat-comparison .stat-bar_graph .homeTeam {
  border-radius: 0px 5px 5px 0px;
}

.team_stat-comparison .stat_totals {
  display: block;
  float: left;
  text-transform: uppercase;
}

.team_stat-comparison .stat_totals:last-child {
  float: right;
  text-align: right;
}

.team_stat-comparison .stat_totals h2 {
  color: #333;
  display: block;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.75);
}

.team_stat-comparison .stat-bar_graph .awayTeam span img,
.team_stat-comparison .stat-bar_graph .homeTeam span img {
  padding: 5px;
  vertical-align: middle;
}

.team_stat-comparison .stat-bar_graph .homeTeam span img {
  float: right;
}

#GameShowContent .vertical_graph-container {
  background: #ccc;
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
  padding: 10px;
  width: 100%;
}

#GameShowContent .vertical_graph-category {
  display: block;
  float: left;
  text-align: center;
  width: 50%;
}

#GameShowContent .vertical_graph-category h3 {
  clear: both;
  display: block;
  text-transform: uppercase;
}

#GameShowContent .vertical_graph-single {
  display: block;
  float: left;
}

#GameShowContent .vertical_graph-bar {
  display: block;
  height: 50px;
  width: 30px;
}

/* PLAYER STAT WIDGET - LIST */
#GameShowContent .table_container table thead th {
  white-space: nowrap;
  padding: 0 5px;
}

#GameShowContent .table_container table td.player_name {
  white-space: nowrap;
  padding-right: 20px;
}

#GameShowContent .table_container {
  background: #ffffff;
  margin: auto;
  padding: 10px 20px 20px 20px;
  border: 1px solid #efefef;
}

.GameShowDataContainer.play_by_play .table_container {
  margin: 0 20px 20px 0;
}

.GameShowDataContainer.play_by_play .StatWidgetColumn .StatWidgetHolder {
  margin-right: 20px;
}

#GameShowContent ul.player_stats-list-header.awayTeam, #GameShowContent ul.player_stats-list-header.homeTeam {
  background: #fff;
  color: #333;
  overflow: hidden;
  width: 100%;
  padding: 0 0 5px 0;
  text-align: center;
}

#GameShowContent ul.player_stats-list-header.awayTeam li h3, #GameShowContent ul.player_stats-list-header.homeTeam li h3 {
  padding: 10px;
}

#GameShowContent ul.player_stats-list-header li {
  display: inline-block;
  width: 100%;
  padding: 0 0 10px;
  text-transform: uppercase;
}

#GameShowContent ul.player_stats-list-header li h2 {
  color: #333;
}

.right-column-ad-content ul.player_stats-list-header li h2 {
  font-size: 24px;
}

/* TEAM STAT WIDGET - LIST */
#GameShowContent ul.team_stats-list-header {
  background: #666;
  color: #fff;
  overflow: hidden;
  width: 100%;
}

#GameShowContent ul.team_stats-list-header li h3 {
  padding: 10px;
}

#GameShowContent ul.team_stats-list-header li {
  display: block;
  float: left;
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}

#GameShowContent ul.team_stats-list-header li.team_logo {
  float: right;
  padding: 0;
  text-align: center;
  width: 10%;
}

#GameShowContent ul.team_stats-list-header li.team_logo span {
  padding: 7px 0;
  vertical-align: middle;
}

#GameShowContent ul.team_stats-list-header li.team_logo span a {
  margin: 0 auto;
}

#GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1em;
}

#GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list:first-child {
  margin-top: -10px;
}

#GameShowContent ul.team_stats-list li ul {
  overflow: hidden;
  border-bottom: 2px solid #f0f0f0;
}

#GameShowContent ul.team_stats-list li ul li {
  display: block;
  float: left;
  line-height: 1.5em;
  padding: 8px 0;
  text-align: center;
  vertical-align: middle;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li {
  line-height: 1.5em;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list span.team_logo {
  display: block;
  height: 20px;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li.stat-bar_graph .awayTeam span {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  position: relative;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li.stat-bar_graph .homeTeam span {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  position: relative;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list span.team_logo a {
  display: none;
}

#GameShowContent ul.team_stats-list li ul li.stat_category {
  width: 33.3%;
}

#GameShowContent ul.team_stats-list li ul li.stat-bar_graph {
  display: none;
}

#GameShowContent ul.team_stats-list li ul li.stat-bar_graph span a {
  border: none;
}

#GameShowContent ul.team_stats-list li ul li.team_stat {
  float: right;
  text-align: center;
  width: 33.3%;
  font-weight: 400;
}

#GameShowContent span.ts1,
#GameShowContent span.ts2 {
  font-size: .65em;
  text-transform: uppercase;
  display: none;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li.stat_category {
  overflow: hidden;
  text-indent: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 30%;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li.stat-bar_graph {
  width: 40%;
  display: none;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li.stat-bar_graph span img {
  display: none;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li.team_stat,
#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list-header li.team_logo {
  width: 15%;
}

#GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li.stat-bar_graph span {
  height: 20px;
}

#GameShowContent .GameShowDataContainer.game_summary .team_stat-comparison .StatWidget {
  margin-bottom: 15px;
}

.GameShowDataContainer.game_summary span.ts1,
.GameShowDataContainer.game_summary span.ts2 {
  clear: left;
  color: #333;
  display: block;
  font-weight: normal;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul {
  overflow: hidden;
  border-bottom: none;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat_category {
  font-weight: bold;
  height: 20px;
  line-height: 15px;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat-bar_graph {
  clear: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat-bar_graph .awayTeam,
#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat-bar_graph .homeTeam {
  height: 30px;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat-bar_graph span {
  height: 30px;
  padding: 5px;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat-bar_graph .awayTeam span {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  position: relative;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat-bar_graph .homeTeam span {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  position: relative;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.stat-bar_graph .homeTeam span a {
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.team_stat {
  font-size: 1.5em;
  font-weight: bold;
  height: 30px;
  line-height: 35px;
  padding: 0;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.75);
  width: 75px;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.team_stat.ts1 {
  float: left;
  text-align: left;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.team_stat.ts2 {
  float: right;
  text-align: right;
}

#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.team_stat.ts1 span,
#GameShowContent .GameShowDataContainer.game_summary ul.team_stats-list li ul li.team_stat.ts2 span {
  height: 15px;
  line-height: 15px;
}

#GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list li ul li.stat-bar_graph .awayTeam,
#GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list li ul li.stat-bar_graph .homeTeam {
  height: 30px;
}

#GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list li ul li.stat-bar_graph span a {
  display: none;
}

#GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list li ul li.stat-bar_graph .awayTeam span {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  height: 30px;
  margin: 0;
  position: relative;
}

#GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list li ul li.stat-bar_graph .homeTeam span {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  height: 30px;
  margin: 0;
  position: relative;
}

#GameShowContent .stat-bar_graph div.awayTeam {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/stat_comparison-shine.png");
  background-repeat: repeat-x;
  background-position: 50% 50%;
  border-radius: 5px 0px 0px 5px;
  display: block;
  float: left;
  height: 20px;
  width: 35%;
}

#GameShowContent .stat-bar_graph div.homeTeam {
  background-position: 50% 50%;
  border-radius: 0px 5px 5px 0px;
  display: block;
  float: left;
  height: 20px;
  width: 65%;
}

/* GAME DETAILS */
#GameShowContent .StatWidgetContainer.header {
  background: rgba(0, 0, 0, 0.25);
  color: #999;
  margin: 0;
  /* padding: 10px 0 10px 20px; */
  text-transform: uppercase;
}

#GameShowContent .StatWidgetContainer-sub.header {
  margin: 0 0 -15px 0;
}

#GameShowContent ul.game_details li {
  border-bottom: 1px solid #999;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #999;
  font-size: 10px;
  padding: .25em 0;
}

#GameShowContent ul.game_details li h3 {
  padding: .5em 0;
  text-transform: uppercase;
}

#GameShowContent ul.game_details li.subcategory {
  text-indent: 1.25em;
}

#GameShowContent ul.game_details a,
#GameShowContent ul.game_details a:visited {
  color: #ccc;
  text-decoration: none;
}

#GameShowContent ul.game_details a:hover,
#GameShowContent ul.game_details a:active {
  color: #fff;
  text-decoration: underline;
}

#GameShowContent .StatWidgetContainer.game_details.header {
  padding: 10px 0;
  text-indent: 20px;
}

#GameShowContent .StatWidgetContainer li.play_details.away {
  float: left;
}

#GameShowContent .StatWidgetContainer li.play_details.home {
  float: right;
  padding-right: 10px;
}

#GameShowContent .StatWidgetContainer li.team_score.home {
  float: left;
  padding-left: 10px;
}

#GameShowContent .StatWidgetContainer li.team_score.away {
  float: right;
}

#GameShowContent .StatWidgetContainer li.shooutout_total {
  font-size: 1.5em;
  height: 30px;
  line-height: 35px;
  padding: 0 10px;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.75);
  width: 55px;
  text-align: center;
}

#GameShowContent .StatWidgetContainer li.shooutout_total.away {
  float: right;
}

/* GAME PREVIEW SPECIFIC STYLES */
.StatWidgetGroupContainer.game_preview-header .StatWidgetContainer {
  padding: 10px 0 0 10px;
}

.StatWidgetGroupContainer.game_preview-header .StatWidgetContainer-sub {
  margin-right: 10px;
}

.StatWidgetGroupContainer.game_preview-header .StatWidgetContainer-sub.has_team_widgets {
  float: right;
  width: 66.6%;
  margin-right: 0;
}

.StatWidgetGroupContainer.game_preview-header .StatWidgetContainer-sub .StatWidgetColumn.width50 {
  width: 50%;
}

.StatWidgetGroupContainer.game_preview-header .StatWidget {
  margin: 0 10px 10px 0;
}

.StatWidgetGroupContainer.game_preview-column .StatWidgetGroup {
  margin: 0;
}

.StatWidgetGroupContainer.game_preview-header .carrot.down {
  background: #000;
  display: block;
  float: left;
  height: 25px;
  width: 100%;
}

.GameShowDataContainer.game_preview .StatWidgetHolder .StatWidget.game_preview {
  margin-right: 0;
}

.GameShowDataContainer.game_preview span.team_logo {
  display: inline-block;
  margin: 0 auto;
}

.GameShowDataContainer.game_preview ul.player_stats-list-header {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/scoring_summary-shine.png") repeat-x 50% 50%;
}

/* GAME STATS SPECIFIC STYLES */
.GameShowDataContainer.game_stats .team_stats-list-header span.team_logo {
  display: block;
}

.GameShowDataContainer.game_stats .player_stats-list-header span.team_logo {
  display: block;
  margin-top: 8px;
}

.GameShowDataContainer.game_stats .player_stats-list-header.sub span.team_logo {
  display: block;
  margin-top: 5px;
}

.StatWidgetGroupContainer.player_stats .table_container {
  margin-top: 0;
  padding: 10px;
}

.StatWidgetGroupContainer.player_stats .carrot {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/carrot-player_stats_list-header.png") no-repeat 50% 50%;
  display: block;
  height: 10px;
  margin: 0 auto;
  padding: 0;
  top: 0;
  width: 24px;
}

.StatWidgetGroupContainer.player_stats .player_stats_header {
  margin-bottom: 0;
}

.StatWidgetGroupContainer.player_stats table.player_stats-list sortable th {
  font-size: 9px;
}

.GameShowDataContainer.game_stats .player_stats .category_separator {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/player_stat-separator.png") repeat-x;
  display: block;
  float: left;
  height: 10px;
  width: 100%;
}

.StatWidgetContainer-sub .StatWidgetContainer:last-child .category_separator {
  background-image: none;
}

/* SCORING SUMMARY */
.game_boxscore > h3:first-child {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  margin-bottom: 2px;
}

@media only screen and (max-width: 767px) {
  .game_boxscore > h3:first-child {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.game_boxscore .scoring_summary li li {
  float: left;
  overflow-x: hidden;
  padding: 3px 1px;
  white-space: nowrap;
}

.game_boxscore .scoring_summary li li:first-child {
  padding-left: 6px;
}

.game_boxscore .scoring_summary .interval_row,
.game_boxscore .scoring_summary .scoring_info {
  font-size: 14px;
  margin-bottom: 2px;
  position: relative;
}

.game_boxscore .scoring_summary .interval_row:after,
.game_boxscore .scoring_summary .scoring_info:after {
  content: '';
  display: block;
  clear: both;
}

.game_boxscore .scoring_summary .interval_row {
  background: #000000;
  color: #fff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-transform: uppercase;
}

.game_boxscore .scoring_summary .interval_row li:first-child {
  width: calc(100% - 130px);
}

.game_boxscore .scoring_summary .interval_row li:empty {
  display: none;
}

.game_boxscore .scoring_summary .scoring_info {
  background: #eee;
}

.game_boxscore .scoring_summary .game_clock {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 800;
  width: 55px;
}

.game_boxscore .scoring_summary .team_name {
  color: #000000;
  font-weight: 700;
  text-overflow: ellipsis;
  width: 125px;
}

.game_boxscore .scoring_summary .score_type {
  overflow: visible;
  width: 35px;
}

.game_boxscore .scoring_summary .play_details {
  white-space: normal;
  width: calc(100% - 345px);
}

.game_boxscore .scoring_summary .play_details:first-child {
  width: calc(100% - 130px);
}

.game_boxscore .scoring_summary .team_score {
  text-align: center;
  text-overflow: ellipsis;
  width: 65px;
}

@media (max-width: 568px) {
  .game_boxscore .scoring_summary .team_name {
    width: calc(100% - 220px);
  }
  .game_boxscore .scoring_summary .play_details {
    padding-left: 6px;
    padding-right: 6px;
    width: 100%;
  }
  .game_boxscore .scoring_summary .scoring_info .team_score {
    position: absolute;
    right: 65px;
    top: 0;
  }
  .game_boxscore .scoring_summary .scoring_info .team_score:last-child {
    right: 0;
  }
}

@media (max-width: 340px) {
  .game_boxscore .scoring_summary .interval_row li:first-child {
    width: calc(100% - 110px);
  }
  .game_boxscore .scoring_summary .scoring_info .team_score {
    right: 55px;
  }
  .game_boxscore .scoring_summary .team_name {
    width: calc(100% - 193px);
  }
  .game_boxscore .scoring_summary .score_type {
    text-align: right;
    width: 28px;
  }
  .game_boxscore .scoring_summary .team_score {
    width: 55px;
  }
}

@media (min-width: 768px) {
  .game_boxscore .StatWidgetColumn {
    padding: 0;
  }
  .game_boxscore .StatWidgetColumn:nth-of-type(3n+1) {
    padding-right: 7px;
  }
  .game_boxscore .StatWidgetColumn:nth-of-type(3n+2) {
    padding-left: 7px;
  }
}

.game_boxscore .spacer {
  max-width: 100%;
  overflow: auto;
}

/* GALLERIES */
#gameGalleryHeader {
  overflow: hidden;
  padding: 10px 10px 10px 3%;
  border-bottom: 1px solid #DDD;
}

#gameGalleryHeader .count {
  float: left;
  margin-left: 10px;
  color: #999;
  line-height: 36px;
}

#gameGalleryHeader .button {
  float: left;
  margin-top: 8px;
}

#gameGalleryHeader .tool_pagination {
  padding: 10px 10px 0;
  border-top: none;
}

#GameShowContent .galleryItemList {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #BBB;
  min-height: 324px;
}

/* GAME SHOW NEXT GEN STYLES */
#GameShowContent.game_show_nextgen {
  margin: 0;
}

#GameShowContent.game_show_nextgen .extra_game_details {
  width: 50%;
  float: left;
}

#GameShowContent.game_show_nextgen .list_officials {
  width: 50%;
  float: left;
}

#GameShowContent.game_show_nextgen .extra_game_details .pageElement {
  margin: 0 15px 15px 15px;
}

#GameShowContent.game_show_nextgen .list_officials .pageElement {
  margin: 0 15px 15px 0;
}

#GameShowContent.game_show_nextgen #game_details {
  padding: 0px 0px 10px 0px;
}

#GameShowContent.game_show_nextgen #game_details ul {
  font-size: 11px;
}

#GameShowContent.game_show_nextgen #game_details li {
  padding-left: 5px;
}

#GameShowContent.game_show_nextgen .game_boxscore h3 {
  font-size: 16px;
  line-height: 24px;
}

#GameShowContent.game_show_nextgen #game_details h3 {
  font-size: 16px;
  line-height: 24px;
}

#GameShowContent.game_show_nextgen #gameTotal .even td, #GameShowContent.game_show_nextgen .game_details .even {
  background-color: #FFFFFF;
}

#GameShowContent.game_show_nextgen #gameTotal .odd td, #GameShowContent.game_show_nextgen .game_details .odd {
  background-color: #EFEFEF;
}

#GameShowContent.game_show_nextgen #gameTotal .stat_table td {
  width: 70px;
}

#GameShowContent.game_show_nextgen .tab_menu {
  width: 100%;
}

#GameShowContent.game_show_nextgen .live_game_sheet {
  float: right;
  text-align: right;
  padding: 5px 0;
}

#GameShowContent.game_show_nextgen .sport-tools {
  background: none;
  margin: 0 5px;
}

#GameShowContent.game_show_nextgen .rapidContentGeneral,
#GameShowContent.game_show_nextgen #contentTabsLoader {
  width: 100%;
}

#GameShowContent.game_show_nextgen {
  overflow: visible;
  position: relative;
  font-size: 100%;
}

#GameShowContent.game_show_nextgen ul.tab_menu-list {
  list-style-type: none;
  float: left;
  position: relative;
  z-index: 4;
  overflow: hidden;
  font-size: 12px;
}

#GameShowContent.game_show_nextgen #rapidTabs {
  padding: 0px;
}

#GameShowContent.game_show_nextgen .GameShowDataContainer {
  padding: 20px 10px 0 10px;
}

#tab_nextgen_comments_content .GameShowDataColumn {
  position: inherit !important;
  width: 100% !important;
  background: none;
}

#tab_nextgen_comments_content .GameShowDataColumn .commentStreamContainer {
  min-height: 100px !important;
}

.game_header_v2 {
  margin: 0 15px;
  clear: both;
}

.game_header_v2 .away, .game_header_v2 .home {
  clear: both;
  display: block;
  float: left;
  padding-bottom: 20px;
  width: 100%;
}

.game_header_v2 .away {
  margin-bottom: 5px;
}

.game_header_v2 .home {
  margin-bottom: 10px;
}

.game_header_v2 h3, .game_header_v2 .team_logo {
  float: left;
}

.game_header_v2 h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;
  padding-left: 5px;
}

.game_header_v2 .score {
  font-weight: bold;
}

#GameShowContent .teamHeadline {
  border-bottom: solid 2px;
  font-size: 14px;
  line-height: 170%;
  padding: 5px 0;
  display: block;
}

#GameShowContent .teamHeadline .team_logo {
  float: left;
  padding-right: 5px;
}

#GameShowContent .line_score_v2 .team {
  width: 250px;
  text-align: left;
}

#GameShowContent .stat_table {
  font-size: 10px;
  line-height: 120%;
  margin-bottom: 15px;
}

#GameShowContent .stat_table td {
  width: 56px;
}

#GameShowContent .stat_table .odd td {
  background: #FFFFFF;
}

#GameShowContent .stat_table .totals {
  font-weight: bold;
}

#GameShowContent.nextgen {
  margin-right: 0;
  margin-left: -10px;
}

#GameShowContent.nextgen #rapidTabs {
  padding: 0;
}

@media (min-width: 768px) {
  .StatWidgetColumn:nth-of-type(odd) {
    padding-right: 7px;
  }
  .StatWidgetColumn:nth-of-type(even) {
    padding-left: 7px;
  }
  .StatWidgetColumn:nth-of-type(even) .player_stats-list {
    margin-left: 0;
  }
}

#GameShowScoreboardSimple {
  padding-bottom: 30px;
  position: relative;
}

#GameShowScoreboardSimple .game_info_bar_v2 {
  bottom: 0;
  position: absolute;
  width: 100%;
}

#GameShowScoreboardSimple .stat_table .team {
  color: #000000;
  font-weight: 600;
  text-align: left;
}

.game_info_bar_v2 {
  font: 600 12px/14px "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding: 8px 12px;
}

.game_info_bar_v2 li {
  float: left;
  list-style: none;
}

.game_info_bar_v2 li + li {
  padding-left: .5em;
}

.game_info_bar_v2 .date {
  text-transform: uppercase;
}

.game_info_bar_v2 .status {
  color: #000000;
  float: right;
}

/* Simpler Scoreboard */
#GameShowContent span.pitcher_record_stats {
  color: #FF0000;
}

/* Game Notes */
.gameShow #GameShowContent .game_notes {
  font-size: 12px;
}

#GameShowContent .game_notes h1 {
  font-size: 32px;
}

#GameShowContent .game_notes h2 {
  font-size: 24px;
}

#GameShowContent .game_notes h3 {
  font-size: 18px;
}

#GameShowContent .game_notes h4 {
  font-size: 16px;
}

#GameShowContent .game_notes h5 {
  font-size: 14px;
}

#GameShowContent .game_notes h6 {
  font-size: 12px;
}

#GameShowContent .game_notes p {
  margin-bottom: 10px;
  line-height: 150%;
}

#GameShowContent .game_notes ul, #GameShowContent .game_notes ol {
  padding-left: 40px;
  margin-bottom: 10px;
}

#GameShowContent .game_notes li {
  list-style: inherit;
  line-height: 150%;
}

#GameShowContent .game_notes a {
  text-decoration: underline;
}

#GameShowContent .game_notes blockquote {
  padding-left: 10px;
  margin-left: 30px;
  margin-bottom: 10px;
  font-style: italic;
  border-left: 3px solid #ccc;
}

#GameShowContent .game_notes hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

.StatWidgetGroupContainer.game_preview-header .StatWidgetContainer-sub {
  position: relative;
}

.StatWidgetGroupContainer.game_preview-header .StatWidgetContainer-sub:before {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  background: #000000;
  bottom: 7px;
  left: 0;
  width: 49%;
}

.StatWidgetGroupContainer.game_preview-header .StatWidgetContainer-sub:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  background: #000000;
  bottom: 7px;
  right: 0;
  width: 49%;
}

/*///////////////////////
////// SCOREBOARD ///////
///////////////////////*/
/* FIELD POSITION */
body.football #GameShowScoreboard li.down {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/li-down.png") no-repeat 50% 50%;
  width: 18px;
}

body.football #GameShowScoreboard li.togo {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/li-togo.png") no-repeat 50% 50%;
  width: 30px;
}

body.football #GameShowScoreboard li.ballon {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/li-ballon.png") no-repeat 50% 50%;
  margin-right: 5px;
  width: 30px;
}

body.football #GameShowScoreboard li.dd-start {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/dd-start.png") no-repeat 50% 50%;
  width: 20px;
}

body.football #GameShowScoreboard li.dd-end {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/dd-end.png") no-repeat 50% 50%;
  width: 22px;
}

/* POSSESSION */
body.football #GameShowScoreboard .away_team .possession.active {
  background-position: right -61px;
}

body.football #GameShowScoreboard .home_team .possession.active {
  background-position: left -61px;
}

/* TIME OUTS */
body.football #GameShowScoreboard .timeoutsAway {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/bg-timeouts-away.png") no-repeat 50% 50%;
  display: block;
  height: 58px;
  left: 150px;
  width: 148px;
}

body.football #GameShowScoreboard .timeoutsHome {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/bg-timeouts-home.png") no-repeat 50% 50%;
  display: block;
  height: 58px;
  right: 150px;
  width: 148px;
}

body.football #GameShowScoreboard .timeoutsAway .timeouts-remaining {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat 0 -1px;
  display: block;
  height: 50px;
  left: 22px;
  position: relative;
  top: 11px;
  width: 100px;
}

body.football #GameShowScoreboard .timeoutsHome .timeouts-remaining {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat -100px -1px;
  display: block;
  height: 50px;
  right: -25px;
  position: relative;
  top: 11px;
  width: 100px;
}

body.football #GameShowScoreboard .timeoutsAway .timeouts-remaining.zero {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat 0 -1px;
}

body.football #GameShowScoreboard .timeoutsHome .timeouts-remaining.zero {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat -100px -1px;
}

body.football #GameShowScoreboard .timeoutsAway .timeouts-remaining.one {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat 0 -51px;
}

body.football #GameShowScoreboard .timeoutsHome .timeouts-remaining.one {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat -100px -51px;
}

body.football #GameShowScoreboard .timeoutsAway .timeouts-remaining.two {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat 0 -101px;
}

body.football #GameShowScoreboard .timeoutsHome .timeouts-remaining.two {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat -100px -101px;
}

body.football #GameShowScoreboard .timeoutsAway .timeouts-remaining.three {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat 0 -150px;
}

body.football #GameShowScoreboard .timeoutsHome .timeouts-remaining.three {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts.png") no-repeat -100px -150px;
}

/*///////////////////////
///// PLAY BY PLAY //////
///////////////////////*/
#GameShowContent ul.play_by_play-filters {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
}

#GameShowContent ul.play_by_play-start_period {
  background: #ccc;
  /* Old browsers */
  background: linear-gradient(to bottom, #cccccc 0%, #e5e5e5 100%);
  /* W3C */
  border-radius: 5px 5px 0px 0px;
  color: #666;
  font-size: 10px;
  margin-top: 5px;
  overflow: hidden;
  padding: 5px;
  text-transform: uppercase;
}

.play_by_play-carrot {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/carrot-period-start.png") no-repeat 50% top;
  clear: left;
  display: block;
  height: 5px;
  margin: 0 auto;
  width: 100%;
}

#GameShowContent ul.play_by_play-filters li {
  display: block;
  float: left;
  height: 3em;
  line-height: 3em;
  padding: 0 0 0 10px;
}

#GameShowContent ul.play_by_play-filters li.single_player {
  float: right;
  padding-right: 10px;
}

#GameShowContent table.play_by_play {
  width: 100%;
}

#GameShowContent table.play_by_play th {
  background: #ccc;
  color: #333;
  padding: 10px 10px 10px 0;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: left;
}

#GameShowContent table.play_by_play td {
  border-bottom: solid 1px #ccc;
  font-size: 10px;
  padding: 5px 2px;
  vertical-align: top;
}

#GameShowContent table.play_by_play tr.possessionStart td {
  padding: 0;
}

#GameShowContent table.play_by_play tr.columnHeaders th {
  font-size: 9px;
  height: 20px;
  line-height: 20px;
  padding: 0 2px;
}

table.play_by_play .play-icon {
  width: 42px;
  height: 30px;
}

table.play_by_play .play-downAndDistance,
table.play_by_play .play-ballOn,
table.play_by_play .play-gameClock {
  width: 55px;
}

table.play_by_play .play-playDetails {
  width: auto;
}

table.play_by_play tr.touchdown .play-icon {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/play_icon-td.png") no-repeat 50% 50%;
}

table.play_by_play tr.made_field_goal .play-icon {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/play_icon-fg.png") no-repeat 50% 50%;
}

table.play_by_play tr.conversion_success .play-icon {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/play_icon-2p.png") no-repeat 50% 50%;
}

table.play_by_play tr.safety .play-icon {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/play_icon-sf.png") no-repeat 50% 50%;
}

table.play_by_play tr.extra_point_made .play-icon {
  background: url("https://app-assets1.sportngin.com/app_images/game_show_3/play_icon-xp.png") no-repeat 50% 50%;
}

#GameShowContent ul.play_by_play-drive-header {
  margin: 10px 0 0 0;
  overflow: hidden;
}

#GameShowContent ul.play_by_play-drive-header li {
  padding: 0 10px;
  display: block;
  float: left;
  font-weight: bold;
  height: 44px;
  line-height: 44px;
  text-transform: uppercase;
}

#GameShowContent ul.play_by_play-drive-header li.drive-start,
#GameShowContent ul.play_by_play-drive-header li.drive-summary {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/play_by_play-arrow.png");
  background-repeat: no-repeat;
  background-position: left 50%;
  text-indent: 4px;
}

/*///////////////////////
///// FIELD OF PLAY /////
///////////////////////*/
body.football #FOP {
  padding: 50px 16px 0;
  margin: -50px 0 0;
  height: 425px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.2) 32%, rgba(0, 0, 0, 0) 100%);
  /* W3C */
  z-index: 100;
  clear: both;
}

body.football #FOP .field {
  padding: 0 8.2%;
  height: 400px;
  background: #060;
  border: 6px solid #FFF;
  margin: 0;
  position: relative;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.699219) 0px 6px 8px -4px;
  transition: all 1000ms ease-in-out;
}

body.football #FOP .in_bounds {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: visible;
}

body.football #FOP .line_of_scrimmage,
body.football #FOP .first_down_line {
  position: absolute;
  top: 0;
  left: -1000px;
  width: 3px;
  height: 100%;
  background: #4BF;
  z-index: 3;
  margin-left: -1px;
}

body.football #FOP .first_down_line {
  background: #FC0;
}

body.football #FOP .grass {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
}

/* Yard Lines */
body.football #FOP ul.yardlines {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  list-style: none;
}

body.football #FOP ul.yardlines li {
  opacity: .7;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  z-index: 2;
}

body.football #FOP ul.yardlines li._5yd {
  border-left: 1px solid #FFF;
}

body.football #FOP ul.yardlines li:nth-child(10n+6) {
  border-left: 1px solid #FFF;
  background: rgba(0, 0, 0, 0.2);
  width: 5%;
  z-index: 1;
}

body.football #FOP ul.yardlines li._5yd span {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  color: #FFF;
  font: bold 16px "Arial Black",sans-serif;
  letter-spacing: 5px;
  display: block;
  position: absolute;
  top: 40%;
  bottom: 40%;
  height: 20%;
  margin-left: -2px;
  width: 3px;
  white-space: nowrap;
  overflow: visible;
}

body.football #FOP ul.yardlines li._5yd span span {
  border: none;
  top: auto;
  bottom: -170%;
  height: auto;
  margin-left: 0;
  text-indent: -12px;
}

body.football #FOP ul.yardlines li._5yd span span + span {
  top: -170%;
  bottom: auto;
  transform: rotate(180deg);
}

body.football #FOP ul.yardlines li._1yd,
body.football #FOP ul.yardlines li._1yd span {
  background: none;
  height: 20%;
  top: 40%;
  margin-top: -2px;
  border-top: 3px solid #FFF;
  border-bottom: 3px solid #FFF;
}

body.football #FOP ul.yardlines li._1yd span {
  width: 1px;
  height: auto;
  position: absolute;
  top: -200%;
  bottom: -200%;
  margin: -1px 0 1px;
}

body.football #FOP ul.yardlines li.first span span {
  text-indent: 2px;
  border: none;
}

body.football #FOP ul.yardlines li.first span span + span {
  text-indent: -12px;
}

body.football #FOP ul.yardlines li.last {
  margin-left: -1px;
}

/* G */
body.football #FOP ul.yardlines li.last span {
  left: auto;
  right: 0;
  border: none;
  text-indent: -15px;
}

body.football #FOP ul.yardlines li.last span span + span {
  text-indent: 5px;
}

/* End Zone */
body.football #FOP .endzone {
  display: block;
  width: 8.333%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
}

body.football #FOP .endzone img {
  display: block;
  width: 100%;
  height: 100%;
}

body.football #FOP .endzone span {
  font: bold 30px "Arial Black",Verdana,sans-serif;
  white-space: nowrap;
  position: absolute;
  /*top:50%;*/
  top: 0;
  left: 50%;
  /*margin:-25px 0 0 -200px;*/
  margin: 0 0 0 -25px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #FFF;
  width: 400px;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(90deg) translate(175px, 175px);
}

body.football #FOP .endzone:first-child {
  left: 0;
  right: auto;
}

body.football #FOP .endzone:first-child span {
  transform: rotate(-90deg) translate(-175px, -175px);
}

/*/////// Play String //////////*/
body.football #FOP .playStringContainer {
  position: absolute;
  top: 0;
  left: 50%;
  width: 230px;
  padding: 0 50px 20px;
  height: 70px;
  line-height: 60px;
  margin-left: -165px;
  opacity: .6;
  z-index: 5;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/football/field_of_play_nav.png") 0 -150px no-repeat;
  transition: opacity 300ms ease-in-out;
}

body.football #FOP .playStringContainer:hover,
body.football #FOP.view_3d .playStringContainer {
  opacity: 1;
}

body.football #FOP .playString {
  cursor: default;
  color: #DDD;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  padding: 5px 0;
}

/*////////// PLAY NAVIGATION BUTTONS //////////*/
body.football #FOP #PlayNavigationButtons {
  z-index: 5;
  position: absolute;
  bottom: 25px;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  opacity: .6;
  text-align: center;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%);
  /* W3C */
  transition: all 300ms linear;
}

body.football #FOP #PlayNavigationButtons:hover {
  opacity: 1;
}

body.football #FOP #PlayNavigationButtons div {
  position: relative;
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 60px;
  height: 0;
  margin: 0 -8px;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/football/field_of_play_nav.png") 0 1000px no-repeat;
  vertical-align: middle;
  overflow: hidden;
}

body.football #FOP #PlayNavigationButtons .prevDrive {
  padding-top: 50px;
  background-position: 0 -10px;
}

body.football #FOP #PlayNavigationButtons .nextDrive {
  padding-top: 50px;
  background-position: -240px -10px;
}

body.football #FOP #PlayNavigationButtons .prevPlay {
  padding-top: 60px;
  background-position: -60px -5px;
  z-index: 2;
}

body.football #FOP #PlayNavigationButtons .nextPlay {
  padding-top: 60px;
  background-position: -180px -5px;
  z-index: 2;
}

body.football #FOP #PlayNavigationButtons .latestPlay {
  padding-top: 70px;
  background-position: -120px 0;
  margin: 0 -12px;
  z-index: 3;
}

body.football #FOP #PlayNavigationButtons .prevDrive:active {
  background-position: 0 -80px;
}

body.football #FOP #PlayNavigationButtons .nextDrive:active {
  background-position: -240px -80px;
}

body.football #FOP #PlayNavigationButtons .prevPlay:active {
  background-position: -60px -75px;
}

body.football #FOP #PlayNavigationButtons .nextPlay:active {
  background-position: -180px -75px;
}

body.football #FOP #PlayNavigationButtons .latestPlay:active {
  background-position: -120px -70px;
}

body.football #FOP #FOPbuttons {
  position: absolute;
  top: 70px;
  right: 20px;
  opacity: .4;
  z-index: 5;
  transition: opacity 300ms ease-in-out;
}

body.football #FOP #FOPbuttons div {
  cursor: pointer;
  width: 50px;
  height: 0;
  padding-top: 35px;
  overflow: hidden;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/football/field_of_play_nav.png") 0 1000px no-repeat;
}

body.football #FOP #FOPbuttons:hover {
  opacity: 1;
}

body.football #FOP #FOPbuttons .viewToggle {
  background-position: -300px -2px;
}

body.football #FOP #FOPbuttons .viewToggle:active {
  background-position: -300px -36px;
}

body.football #FOP #FieldOfPlay.view_3d #FOPbuttons .viewToggle {
  background-position: -300px -70px;
}

body.football #FOP #FieldOfPlay.view_3d #FOPbuttons .viewToggle:active {
  background-position: -300px -104px;
}

/*/////////// DRIVES ///////////*/
body.football #FOP #DrivesContainer {
  z-index: 4;
  height: 400px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  overflow: hidden;
}

body.football #FOP #Drives {
  height: 100%;
  top: 400px;
  position: relative;
  overflow: visible;
}

/*////////// INDIVIDUAL PLAY LENGTHS //////////*/
body.football #FOP .yds0 {
  width: 0%;
}

body.football #FOP .yds1 {
  width: 1%;
}

body.football #FOP .yds2 {
  width: 2%;
}

body.football #FOP .yds3 {
  width: 3%;
}

body.football #FOP .yds4 {
  width: 4%;
}

body.football #FOP .yds5 {
  width: 5%;
}

body.football #FOP .yds6 {
  width: 6%;
}

body.football #FOP .yds7 {
  width: 7%;
}

body.football #FOP .yds8 {
  width: 8%;
}

body.football #FOP .yds9 {
  width: 9%;
}

body.football #FOP .yds10 {
  width: 10%;
}

body.football #FOP .yds11 {
  width: 11%;
}

body.football #FOP .yds12 {
  width: 12%;
}

body.football #FOP .yds13 {
  width: 13%;
}

body.football #FOP .yds14 {
  width: 14%;
}

body.football #FOP .yds15 {
  width: 15%;
}

body.football #FOP .yds16 {
  width: 16%;
}

body.football #FOP .yds17 {
  width: 17%;
}

body.football #FOP .yds18 {
  width: 18%;
}

body.football #FOP .yds19 {
  width: 19%;
}

body.football #FOP .yds20 {
  width: 20%;
}

body.football #FOP .yds21 {
  width: 21%;
}

body.football #FOP .yds22 {
  width: 22%;
}

body.football #FOP .yds23 {
  width: 23%;
}

body.football #FOP .yds24 {
  width: 24%;
}

body.football #FOP .yds25 {
  width: 25%;
}

body.football #FOP .yds26 {
  width: 26%;
}

body.football #FOP .yds27 {
  width: 27%;
}

body.football #FOP .yds28 {
  width: 28%;
}

body.football #FOP .yds29 {
  width: 29%;
}

body.football #FOP .yds30 {
  width: 30%;
}

body.football #FOP .yds31 {
  width: 31%;
}

body.football #FOP .yds32 {
  width: 32%;
}

body.football #FOP .yds33 {
  width: 33%;
}

body.football #FOP .yds34 {
  width: 34%;
}

body.football #FOP .yds35 {
  width: 35%;
}

body.football #FOP .yds36 {
  width: 36%;
}

body.football #FOP .yds37 {
  width: 37%;
}

body.football #FOP .yds38 {
  width: 38%;
}

body.football #FOP .yds39 {
  width: 39%;
}

body.football #FOP .yds40 {
  width: 40%;
}

body.football #FOP .yds41 {
  width: 41%;
}

body.football #FOP .yds42 {
  width: 42%;
}

body.football #FOP .yds43 {
  width: 43%;
}

body.football #FOP .yds44 {
  width: 44%;
}

body.football #FOP .yds45 {
  width: 45%;
}

body.football #FOP .yds46 {
  width: 46%;
}

body.football #FOP .yds47 {
  width: 47%;
}

body.football #FOP .yds48 {
  width: 48%;
}

body.football #FOP .yds49 {
  width: 49%;
}

body.football #FOP .yds50 {
  width: 50%;
}

body.football #FOP .yds51 {
  width: 51%;
}

body.football #FOP .yds52 {
  width: 52%;
}

body.football #FOP .yds53 {
  width: 53%;
}

body.football #FOP .yds54 {
  width: 54%;
}

body.football #FOP .yds55 {
  width: 55%;
}

body.football #FOP .yds56 {
  width: 56%;
}

body.football #FOP .yds57 {
  width: 57%;
}

body.football #FOP .yds58 {
  width: 58%;
}

body.football #FOP .yds59 {
  width: 59%;
}

body.football #FOP .yds60 {
  width: 60%;
}

body.football #FOP .yds61 {
  width: 61%;
}

body.football #FOP .yds62 {
  width: 62%;
}

body.football #FOP .yds63 {
  width: 63%;
}

body.football #FOP .yds64 {
  width: 64%;
}

body.football #FOP .yds65 {
  width: 65%;
}

body.football #FOP .yds66 {
  width: 66%;
}

body.football #FOP .yds67 {
  width: 67%;
}

body.football #FOP .yds68 {
  width: 68%;
}

body.football #FOP .yds69 {
  width: 69%;
}

body.football #FOP .yds70 {
  width: 70%;
}

body.football #FOP .yds71 {
  width: 71%;
}

body.football #FOP .yds72 {
  width: 72%;
}

body.football #FOP .yds73 {
  width: 73%;
}

body.football #FOP .yds74 {
  width: 74%;
}

body.football #FOP .yds75 {
  width: 75%;
}

body.football #FOP .yds76 {
  width: 76%;
}

body.football #FOP .yds77 {
  width: 77%;
}

body.football #FOP .yds78 {
  width: 78%;
}

body.football #FOP .yds79 {
  width: 79%;
}

body.football #FOP .yds80 {
  width: 80%;
}

body.football #FOP .yds81 {
  width: 81%;
}

body.football #FOP .yds82 {
  width: 82%;
}

body.football #FOP .yds83 {
  width: 83%;
}

body.football #FOP .yds84 {
  width: 84%;
}

body.football #FOP .yds85 {
  width: 85%;
}

body.football #FOP .yds86 {
  width: 86%;
}

body.football #FOP .yds87 {
  width: 87%;
}

body.football #FOP .yds88 {
  width: 88%;
}

body.football #FOP .yds89 {
  width: 89%;
}

body.football #FOP .yds90 {
  width: 90%;
}

body.football #FOP .yds91 {
  width: 91%;
}

body.football #FOP .yds92 {
  width: 92%;
}

body.football #FOP .yds93 {
  width: 93%;
}

body.football #FOP .yds94 {
  width: 94%;
}

body.football #FOP .yds95 {
  width: 95%;
}

body.football #FOP .yds96 {
  width: 96%;
}

body.football #FOP .yds97 {
  width: 97%;
}

body.football #FOP .yds98 {
  width: 98%;
}

body.football #FOP .yds99 {
  width: 99%;
}

body.football #FOP .yds100 {
  width: 100%;
}

/*////////// TEAM COLORS //////////*/
.homeTeam,
.awayTeam {
  background-color: #999;
}

/*////////// DRIVE BARS aka GROUP OF PLAYS //////////*/
body.football #FOP .driveContainer {
  float: left;
  padding: 10px 8.2%;
  width: 83.6%;
  overflow: hidden;
  margin: 3px 0;
}

body.football #FOP .play {
  position: relative;
  height: 15px;
  margin: 3px 0;
  overflow: visible;
  display: none;
}

body.football #FOP .bar {
  width: 100%;
  height: 100%;
}

body.football #FOP .driveBar {
  position: relative;
  height: 15px;
  background: url("https://app-assets1.sportngin.com/app_images/utils/black-30.png");
  background: rgba(0, 0, 0, 0.3);
  padding: 9999px 30px;
  margin: -9999px -30px;
  cursor: pointer;
}

body.football #FOP .current .driveBar {
  cursor: default;
  height: 0;
  margin-bottom: -10002px;
}

body.football #FOP .current .driveBar * {
  display: none;
}

body.football #FOP .current .driveBar .teamlogo {
  display: block;
}

body.football #FOP .driveBar .bar,
body.football #FOP .play .bar {
  box-shadow: rgba(0, 0, 0, 0.292969) 0px 1px 6px, rgba(255, 255, 255, 0.5) 0px -2px 10px -6px inset;
  cursor: pointer;
}

body.football #FOP .play.current .bar {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/football/play_bar.png");
  background-position: center center;
  /* first two rules are for IE 7-9 */
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(0, 0, 0, 0.2) 51%, rgba(0, 0, 0, 0) 100%);
  /* W3C */
  box-shadow: rgba(255, 255, 255, 0.6) 0px 2px 10px, rgba(255, 255, 255, 0.5) 0px -2px 10px 2px inset;
  transition: all 300ms linear;
}

body.football #FOP div.play div.icon_right {
  width: 12px;
  height: 15px;
  position: absolute;
  top: 0;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/drivebar/arrow-right.png") no-repeat right top;
  left: 100%;
}

body.football #FOP div.driveBar div.icon_right {
  width: 12px;
  height: 15px;
  position: absolute;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/drivebar/arrow-right.png") no-repeat;
  right: 15px;
}

body.football #FOP div.play div.icon_left {
  width: 12px;
  height: 15px;
  position: absolute;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/drivebar/arrow-left.png") no-repeat right top;
  left: -15px;
}

body.football #FOP div.driveBar div.icon_left {
  width: 12px;
  height: 15px;
  position: absolute;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/drivebar/arrow-left.png") no-repeat;
  left: 18px;
}

body.football #FOP .teamlogo {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 9999px;
}

body.football #FOP .teamlogo.awayTeam {
  left: 5px;
}

body.football #FOP .teamlogo.homeTeam {
  right: 5px;
}

body.football #FOP .driveContainer.current.expanded div.play,
body.football #FOP .driveContainer.current.expanded {
  display: block;
}

/*////////// PERSPECTIVE ////////////*/
html.csstransforms3d body.football #FOP.view_3d .field {
  /* right now, only webkit supports 3d */
  transform: perspective(600) rotateX(35deg) translate3d(0, -102px, -80px);
}

/* We need to temporarily remove 3d transforms when calculating positioning for scrolling */
html.csstransforms3d body.football #FOP .noTransform {
  transform: none !important;
}

html.csstransforms3d body.football #FOP .noTransition {
  transition: none !important;
}

/* Remove when current play is ready */
body.hockeyish #FOP .current_play {
  display: none;
}

body.hockeyish #FOP .play_area {
  margin-top: 0 !important;
}

body.hockeyish #FOP.view_3d .play_area {
  margin-top: -80px !important;
}

/* Remove when Power Plays status is implemented */
body.hockeyish #GameShowScoreboard .possession {
  display: none;
}

/*///////////////////////
///// SCOREBOARD ////////
///////////////////////*/
/* SHOTS ON GOAL */
body.hockeyish #GameShowScoreboard li.sog {
  background-image: url("https://app-assets1.sportngin.com/app_images/scoreboard/li-sog.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 10px;
  text-align: center;
  width: 50px;
}

body.hockeyish #GameShowScoreboard li.sog span {
  font-size: 20px;
}

body.hockeyish #GameShowScoreboard li.sog div.sog {
  color: #fff;
  display: block;
  float: left;
  height: 26px;
  line-height: 26px;
  text-align: center;
  width: 24px;
}

body.hockeyish #GameShowScoreboard li.sog div.logo-mini {
  border-radius: 2px;
  display: block;
  float: left;
  height: 16px;
  width: 16px;
  margin: 5px;
}

body.hockeyish #GameShowScoreboard li.sog div.logo-mini a {
  border: none;
  background-size: 16px;
  background-position: left top;
  display: block;
  width: 16px !important;
  height: 16px !important;
}

/* POSSESSION / POWER PLAY */
body.hockeyish #GameShowScoreboard .away_team .posession .pp {
  background: none no-repeat 50% 50%;
}

body.hockeyish #GameShowScoreboard .home_team .posession .pp {
  background: none no-repeat 50% 50%;
}

/* TIME OUTS */
body.hockeyish #GameShowScoreboard .timeoutsAway {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/bg-timeouts-away.png") no-repeat 50% 50%;
  display: none;
  height: 76px;
  left: 110px;
  position: absolute;
  top: 100px;
  width: 164px;
  z-index: 113;
}

/* Switch to display:block when implemented */
body.hockeyish #GameShowScoreboard .timeoutsHome {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/bg-timeouts-home.png") no-repeat 50% 50%;
  display: none;
  height: 76px;
  right: 110px;
  position: absolute;
  top: 100px;
  width: 164px;
  z-index: 113;
}

/* Switch to display:block when implemented */
body.hockeyish #GameShowScoreboard .timeoutsAway .timeouts-remaining {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts-hockey.png") no-repeat 25px -1px;
  display: block;
  height: 50px;
  left: 25px;
  position: relative;
  top: 23px;
  width: 100px;
}

body.hockeyish #GameShowScoreboard .timeoutsHome .timeouts-remaining {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts-hockey.png") no-repeat -75px -1px;
  display: block;
  height: 50px;
  right: -35px;
  position: relative;
  top: 23px;
  width: 100px;
}

body.hockeyish #GameShowScoreboard .timeoutsAway .timeouts-remaining.zero {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts-hockey.png") no-repeat 25px -1px;
}

body.hockeyish #GameShowScoreboard .timeoutsHome .timeouts-remaining.zero {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts-hockey.png") no-repeat -75px -1px;
}

body.hockeyish #GameShowScoreboard .timeoutsAway .timeouts-remaining.one {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts-hockey.png") no-repeat 25px -51px;
}

body.hockeyish #GameShowScoreboard .timeoutsHome .timeouts-remaining.one {
  background: url("https://app-assets1.sportngin.com/app_images/scoreboard/timeouts-hockey.png") no-repeat -75px -51px;
}

/*///////////////////////
///// FIELD OF PLAY /////
///////////////////////*/
/*////////// TIMELINE SLIDER ////////////*/
#FOP-timeline-container {
  height: 110px;
  margin: -60px -25px 70px;
  overflow: visible;
  position: relative;
  top: 68px;
  z-index: 101;
}

#FOP-timeline {
  background: #222;
  border-top: solid 1px #000;
  border-bottom: solid 1px #666;
  height: 6px;
  border-radius: 2px;
  height: 8px;
  margin: 6px 100px;
  position: relative;
}

#FOP-timeline .ui-slider-handle {
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/hockeyish/slider-handle.png") no-repeat 50% bottom;
  bottom: -6px;
  display: block;
  height: 35px;
  overflow: visible;
  position: absolute;
  width: 30px;
  z-index: 10;
}

#FOP-timeline .ui-slider-handle.left {
  margin-left: -30px;
}

#FOP-timeline .ui-state-focus {
  outline: none;
}

#FOP-timeline .ui-slider-range {
  background: #6c602d;
  height: 100%;
  margin: 0;
  position: absolute;
}

#FOP-timeline .ui-slider-pattern {
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/hockeyish/slider-pattern.png") repeat-x 50%;
  height: 100%;
  position: absolute;
  width: 100%;
}

#FOP-timeline .ui-slider-pattern:before {
  content: url("https://app-assets1.sportngin.com/app_images/field_of_play/hockeyish/slider-grooves.png");
  position: absolute;
  top: -3px;
  left: -70px;
}

#FOP-timeline .ui-slider-pattern:after {
  content: url("https://app-assets1.sportngin.com/app_images/field_of_play/hockeyish/slider-grooves.png");
  position: absolute;
  top: -3px;
  right: -70px;
}

#FOP-timeline .interval {
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/hockeyish/interval-divider.png") no-repeat 50%;
  display: block;
  width: 4px;
  height: 100%;
  position: relative;
  float: left;
  height: 10px;
  margin-left: 33.3333333%;
  top: -1px;
  z-index: 5;
}

/*/ Time Markers /*/
#FOP-timeline-start,
#FOP-timeline-stop {
  background: #ccc;
  border-radius: 4px;
  display: block;
  font-size: 9px;
  font-weight: bold;
  margin: 0;
  padding: 2px;
  position: relative;
  text-align: center;
  top: -30px;
  width: 30px;
}

#FOP-timeline-start {
  margin-left: 8px;
}

.ui-slider-handle.left .arrow,
.ui-slider-handle.right .arrow {
  border-color: #ccc transparent transparent transparent;
  border-style: solid;
  border-width: 5px;
  display: block;
  height: 0px;
  margin: 0 0 0 20px;
  position: relative;
  top: -30px;
  width: 0px;
}

.ui-slider-handle.right .arrow {
  margin-left: 12px;
}

/*////////// PLAY MARKERS ////////////*/
#FOP-timeline-markers {
  position: relative;
  height: 18px;
  margin: 0 100px;
  padding-top: 15px;
  box-sizing: content-box;
}

/*////////// FILTERS ////////////*/
#FOP-timeline-filters {
  height: 51px;
  line-height: 31px;
  margin: -21px 50px 0;
  text-align: center;
  padding-top: 21px;
}

#FOP-timeline-filters div {
  margin: auto;
  overflow: hidden;
  width: auto;
  display: inline-block;
}

#FOP-timeline-filters span.filters,
#FOP-timeline-filters label {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 5px 10px;
  display: inline-block;
  float: left;
  margin: auto;
}

#FOP-timeline-filters span.filters {
  background: none;
  color: #000;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.25);
}

#FOP-timeline-filters label {
  background-repeat: repeat-x;
  background-position: 50%;
  color: #ccc;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  border: 1px solid #ccc;
}

#FOP-timeline-filters label.deselected {
  background: #ddd;
  color: #777;
}

#FOP-timeline-filters label.deselected:hover {
  background: #fff;
  color: #444;
}

#FOP-timeline-filters label span {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
}

#FOP-timeline-filters label.homeTeam span,
#FOP-timeline-filters label.awayTeam span {
  height: 26px;
}

#FOP-timeline-filters label span.filterIcon {
  height: 30px;
}

#FOP-timeline-filters label.homeTeam span a,
#FOP-timeline-filters label.awayTeam span a {
  margin: 0 auto;
}

#FOP-timeline-filters input[type=checkbox] {
  position: absolute;
  left: -999px;
}

/*/////// TIMELINE CAPS ////////*/
#FOP-timeline-container .cap {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 84px;
}

#FOP-timeline-container .cap.left {
  left: -5px;
}

#FOP-timeline-container .cap.right {
  right: -9px;
}

#FOP-timeline-container .cap span {
  display: block;
  height: 30px;
  position: absolute;
  width: 80px;
}

#FOP-timeline-container .cap.right span {
  right: 0;
}

/*////////// PLAY AREA ////////////*/
body.hockeyish #FOP a {
  text-decoration: none;
}

body.hockeyish #FOP {
  padding: 24px 16px 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.2) 32%, rgba(0, 0, 0, 0) 100%);
  /* W3C */
  z-index: 100;
  clear: both;
}

body.hockeyish #FOP .play_area {
  border-radius: 50px;
  height: 452px;
  margin: 80px 0 0 0;
  margin: 0;
  /* temp until current play is added */
  position: relative;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.699219) 0px 6px 8px -4px;
}

body.hockeyish #FOP .plays,
body.hockeyish #FOP .texture2,
body.hockeyish #FOP .texture1 {
  height: 100%;
  width: 100%;
  overflow: visible;
  position: absolute;
  z-index: 1;
  display: block;
  margin: 0;
}

body.hockeyish #FOP .plays {
  z-index: 3;
}

body.hockeyish #FOP .texture2 {
  z-index: 2;
}

body.hockeyish #FOP .sponsor {
  display: none;
}

/*////////// HOCKEY PLAY ICONS ////////////*/
body.hockeyish #FOP #no_location.goal b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/goal.png");
}

body.hockeyish #FOP #no_location.shot b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/shot.png");
}

body.hockeyish #FOP #no_location.shotongoal b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/shot.png");
}

body.hockeyish #FOP #no_location.shotofftarget b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/lacrosse/missed-shot.png");
}

body.hockeyish #FOP #no_location.blockedshot b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/block.png");
}

body.hockeyish #FOP #no_location.penalty b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/penalty.png");
}

body.hockeyish #FOP #no_location.hit b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/hit.png");
}

body.hockeyish #FOP #no_location.faceoff b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/faceoff.png");
}

body.hockeyish #FOP #no_location.looseball b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/lacrosse/loose-ball.png");
}

body.hockeyish #FOP #no_location.turnover b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/turnover.png");
}

body.hockeyish #FOP #no_location.goaliechange b {
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/goalie-change.png");
}

body.hockeyish #FOP #no_location.goal b {
  padding-left: 30px;
}

body.hockeyish #FOP #no_location {
  padding: 10px;
  border: 1px solid #666;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 10;
  top: 45%;
  width: 200px;
  left: 50%;
  margin-left: -110px;
  border-radius: 6px;
  display: none;
  text-align: center;
  opacity: .8;
}

body.hockeyish #FOP #no_location b {
  padding: 5px 0 5px 21px;
  background-repeat: no-repeat;
  background-position: left center;
}

/*////////// PERSPECTIVE ////////////*/
html.csstransforms3d body.hockeyish #FOP {
  -webkit-perspective: 700px;
  -moz-perspective: 700px;
  -o-perspective: 700;
  -ms-perspective: 700;
  perspective: 700;
}

html.csstransforms3d body.hockeyish #FOP .play.new .shadow {
  opacity: 0;
}

html.csstransforms3d body.hockeyish #FOP .play_area,
html.csstransforms3d body.hockeyish #FOP .shadow {
  transition: all 0.1s ease-in-out;
}

html.csstransforms3d body.hockeyish #FOP .play_area {
  margin: 0 0 -130px;
  margin: -80px 0 -130px;
  /* temp until current play is added */
  transform: rotateX(50deg) scale(0.9) translate3d(0, -150px, -50px);
}

/*////////// FOP POP UPS ////////////*/
.NGIN_tip.hockeyish_fop_popup .container {
  overflow: visible;
}

.hockeyish_fop_popup .NGIN_tip .close_tip {
  font: 11px bold Verdana, Arial, sans-ersif;
  cursor: pointer;
  display: block;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 12px;
  height: 12px;
  border: 2px solid #FFF;
  background: #111;
  color: #FFF;
  font-weight: bold;
  text-align: center;
  line-height: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: content-box;
}

.hockeyish_fop_popup .close_tip span {
  display: block;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
}

.fopPopUp {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  background: #fff url("https://app-assets1.sportngin.com/app_images/game_show_3/diagonal_stripes.png") left bottom;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  overflow: hidden;
  padding: 7px;
  position: relative;
  text-transform: uppercase;
  margin: 20px 0;
  width: 120px;
}

.fopPopUp.expanded {
  width: 350px;
  margin-left: -115px;
}

.fopPopUp .carrot-top,
.fopPopUp .carrot-bottom {
  border-width: 0;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  background: transparent;
  display: block;
  height: 0px;
  left: 0;
  margin: 0 auto;
  position: relative;
  top: 0;
  width: 0;
}

.fopPopUp .carrot-top {
  border-bottom-color: transparent;
}

.fopPopUp .carrot-bottom {
  border-top-color: transparent;
}

.fopPopUp .playerName .carrot-bottom {
  border-width: 2px 4px 4px 4px;
}

/*///// Play Details /////*/
.fopPopUp .playInfo {
  background-image: url("https://app-assets1.sportngin.com/app_images/game_show_3/stat_comparison-shine.png");
  background-repeat: repeat-x;
  background-position: center;
  border-radius: 5px;
  position: relative;
  padding: 0 4px 0 7px;
  height: 28px;
  line-height: 30px;
}

.fopPopUp .playInfo .playType {
  font-size: 18px;
}

.fopPopUp .timeStamp {
  border-left: solid 1px #fff;
  float: right;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  padding-left: 3px;
  margin: 7px 0 0 0;
  text-align: left;
}

.fopPopUp .timeStamp .interval,
.fopPopUp .timeStamp .clock {
  font-size: 8px;
  line-height: 8px;
}

.fopPopUp .timeStamp .interval {
  font-weight: bold;
}

.fopPopUp .backToPlay {
  height: 0;
  background: #EEE;
  text-align: center;
  margin: 0;
  position: relative;
  top: -4px;
  border-radius: 5px;
  margin: 0 3px 0 -3px;
  box-shadow: inset 0 -1px 5px 2px rgba(255, 255, 255, 0.3), inset 0 0px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font: 9px normal "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 11px;
}

.fopPopUp .backToPlay:hover {
  background: #333;
}

.fopPopUp .backToPlay span {
  text-transform: uppercase;
  color: #999;
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/backToPlay.png") -2px 2px no-repeat;
  padding-left: 18px;
}

/* Two-Player Popups */
.fopPopUp.two-player {
  width: 247px;
}

.fopPopUp.two-player.expanded {
  width: 350px;
  margin-left: -51px;
}

.fopPopUp.Faceoff .winner {
  background: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/faceOff-won.png") no-repeat 50%;
  float: left;
  height: 26px;
  width: 45px;
}

.fopPopUp.Faceoff .playInfo {
  text-align: center;
}

/* PLAYER CARD */
.fopPopUp .playerCard {
  border-radius: 0 0 11px 11px;
  margin: 0 -6px -6px;
  border: 6px solid #FFF;
  border-top: none;
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/playerDetails-bg.png");
  background-repeat: repeat-x;
  overflow: visible;
  float: left;
  /* required for animation */
  height: 78px;
  width: 120px;
}

.fopPopUp .playerCard + .playerCard {
  margin-left: 1px;
  margin-right: -50%;
}

.fopPopUp .playerCard.collapsed + .playerCard {
  margin-left: -6px;
}

.fopPopUp .playerCard > .playerName {
  color: #222;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  background: #FFF;
  height: 18px;
  padding: 6px 6px 0;
  margin: 0 -6px;
}

.fopPopUp .playerInfo {
  border-radius: 11px 11px 0 0;
  margin: -6px;
  border: 6px solid #FFF;
  border-bottom: none;
  padding: 7px 7px 6px;
  text-align: right;
  line-height: 20px;
  min-width: 106px;
  height: 41px;
}

.fopPopUp .playerInfo > .playerName {
  float: left;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  text-align: left;
  text-indent: 7px;
}

.fopPopUp .playerInfo > .playerName .firstName {
  display: block;
  font-size: 16px;
}

.fopPopUp .playerInfo > .playerName .lastName {
  display: block;
  font-size: 26px;
}

.fopPopUp .playerPhoto {
  border-radius: 5px;
  height: 40px;
  width: 40px;
  float: left;
  background: #666 none center center;
}

.fopPopUp .playerInfo .teamLogo-small {
  display: inline-block;
  vertical-align: middle;
  border: none;
}

.fopPopUp .playerInfo .playerNumber {
  font-size: 53px;
  line-height: 43px;
  height: 40px;
  text-indent: -50px;
  overflow: hidden;
  text-align: right;
  float: right;
  opacity: .5;
}

.fopPopUp .playerInfo .playerSpecs {
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  overflow: hidden;
  width: auto;
  position: relative;
  top: 4px;
}

.fopPopUp .playerInfo .playerSpecs.small {
  width: 60px;
  margin: 0 0 0 auto;
}

.fopPopUp .playerInfo .playerSpecs.large {
  text-align: right;
  display: none;
}

/* Game Stats */
.fopPopUp .playerStats {
  height: 0;
}

.fopPopUp .playerStatLine {
  padding: 7px 7px 0;
  line-height: 26px;
  white-space: nowrap;
}

.fopPopUp .gameStatsList {
  border-radius: 3px;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.3);
  width: 74%;
}

.fopPopUp .gameStatsList table {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  width: 100%;
}

.fopPopUp .gameStatsList td {
  padding: 0 10px 0 0;
  color: #FFF;
  white-space: nowrap;
}

.fopPopUp .gameStatsList td.gameStats {
  padding: 0;
  width: 1%;
}

.fopPopUp .gameStatsList td.gameStats span {
  display: block;
  background: #000;
  border-radius: 3px 0 0 3px;
  padding: 0 5px 0 10px;
}

.fopPopUp .gameStatsList td.separator span {
  display: block;
  height: 0;
  border-color: transparent transparent transparent #000;
  border-width: 13px 0 13px 7px;
  border-style: solid;
}

.fopPopUp .gameStatsList .statCategory {
  vertical-align: middle;
}

.fopPopUp .gameStatsList .singleStat {
  vertical-align: middle;
  font-size: 18px;
}

.fopPopUp .profileButton {
  width: 24%;
  float: right;
  font-size: 12px;
}

.fopPopUp .profileButton a {
  line-height: 13px;
  border-radius: 3px;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/btn-out.png");
  background-repeat: no-repeat;
  background-position: right center;
  display: block;
  padding: 7px;
  color: #FFF;
}

.fopPopUp .profileButton a:hover {
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Game Plays */
.fopPopUp .gamePlays {
  color: #FFF;
  overflow: hidden;
  background: #111;
  margin-bottom: 7px;
}

.fopPopUp .playList {
  margin: 0 7px;
  height: 0;
}

.fopPopUp .totalPlays {
  border-bottom: dotted 1px #666;
  font-size: 14px;
  padding: 0 8px 4px;
}

.fopPopUp .singlePlay {
  border-bottom: dotted 1px #666;
  font-size: 14px;
  line-height: 30px;
  padding: 5px;
}

.fopPopUp .singlePlay .playType {
  background: #c82027;
  background-image: url("https://app-assets1.sportngin.com/app_images/black.png");
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  width: 90px;
}

.fopPopUp .singlePlay .role {
  margin-left: 10px;
}

.fopPopUp .singlePlay .time {
  float: right;
  margin-right: 10px;
}

/*/////////// EXPANDED ///////////*/
.fopPopUp.noTransitions {
  transition: none !important;
}

/* Transitions */
.fopPopUp,
.fopPopUp .playInfo,
.fopPopUp .playerInfo2,
.fopPopUp .playerInfo > .playerName,
.fopPopUp .playerInfo .playerNumber,
.fopPopUp .playerCard,
.fopPopUp .playerCard > .playerName,
.fopPopUp .playerStats,
.fopPopUp .gamePlays,
.fopPopUp .playList,
.fopPopUp .backToPlay {
  overflow: hidden;
}

/* Hiden When Expanded */
.fopPopUp.expanded .playInfo,
.fopPopUp.expanded .playerCard > .playerName {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.fopPopUp.expanded .playerInfo .playerSpecs.small {
  display: none;
}

.fopPopUp.expanded .playerCard {
  width: 350px;
  height: 255px;
}

.fopPopUp .playerCard.collapsed {
  width: 0;
  height: 0;
}

/* Visible When Expanded */
.fopPopUp.expanded .playerStats {
  height: 40px;
}

.fopPopUp.expanded .gamePlays {
  height: auto;
}

.fopPopUp.expanded .playList {
  height: 140px;
  overflow: auto;
}

.fopPopUp.expanded .playerInfo .playerSpecs.large {
  display: block;
}

.fopPopUp.expanded .playerInfo > .playerName {
  width: 200px;
  margin-right: -200px;
}

.fopPopUp.expanded .playerInfo .playerNumber {
  text-indent: 5px;
}

.fopPopUp.expanded .gamePlays .carrot-top,
.fopPopUp.expanded .gamePlays .carrot-bottom {
  border-width: 5px;
}

.fopPopUp.expanded .backToPlay {
  height: 12px;
}

/* HOVER STATE */
.fopPopUp .playerCard.expandable:hover {
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIzMiUiIHN0b3AtY29sb3I9IiNjNGM0YzQiIHN0b3Atb3BhY2l0eT0iMCIvPgogICAgPHN0b3Agb2Zmc2V0PSI2NyUiIHN0b3AtY29sb3I9IiNmZGZkZmQiIHN0b3Atb3BhY2l0eT0iMC40NiIvPgogICAgPHN0b3Agb2Zmc2V0PSI2OCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMC4yMyIvPgogICAgPHN0b3Agb2Zmc2V0PSI2OSUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMzYiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background-image: linear-gradient(to bottom, rgba(196, 196, 196, 0) 32%, rgba(253, 253, 253, 0.46) 67%, rgba(255, 255, 255, 0.23) 68%, rgba(255, 255, 255, 0) 69%, rgba(255, 255, 255, 0.36) 100%);
  /* W3C */
}

.fopPopUp .playerCard.expandable:hover .playerInfo {
  box-shadow: inset 0 0 15px 2px rgba(255, 255, 255, 0.6);
}

.fopPopUp.expanded .playerCard.expandable:hover {
  cursor: default;
  background-image: url("https://app-assets1.sportngin.com/app_images/field_of_play/ice_hockey/playerDetails-bg.png");
}

.fopPopUp.expanded .playerCard.expandable:hover .playerInfo {
  box-shadow: none;
}

html.csstransforms3d body.hockeyish #FOP .play {
  position: absolute;
  width: 35px;
  transition: opacity 0ms linear;
  transform: translate3d(-17.5px, 15px, 1px);
  transform-style: preserve-3d;
  background: url("https://app-assets1.sportngin.com/app_images/live/hockey_icon_shadow.png") bottom no-repeat;
}

html.csstransforms3d body.hockeyish .svg-pin {
  height: 48px;
  transform-origin: 0 48px 0;
  transform: rotateX(-90deg) translate3d(1px, 0, -15px);
}

html.csstransforms3d body.hockeyish .svg-pin svg {
  height: 48px;
  width: 35px;
}

html.csstransforms3d body.hockeyish .svg-pin svg .position-pin {
  stroke: #000;
}

html.csstransforms3d body.hockeyish .svg-pin.homeTeam, .svg-pin.awayTeam {
  background-color: transparent;
}

/* Play Action Activation on SVG */
body.hockeyish #FOP .play {
  cursor: pointer;
  display: none;
}

html.csstransforms3d body.hockeyish .play svg .action-icon {
  opacity: 0;
  fill: #fff;
  stroke: #000;
}

#FOP-timeline-filters,
.fopPopUp,
.fopPopUp .playInfo,
.fopPopUp .playerInfo,
.fopPopUp .playerInfo2,
.fopPopUp .playerInfo > .playerName,
.fopPopUp .playerInfo .playerNumber,
.fopPopUp .playerCard,
.fopPopUp .playerCard > .playerName,
.fopPopUp .playerStats,
.fopPopUp .gamePlays,
.fopPopUp .playList,
.fopPopUp .backToPlay {
  box-sizing: content-box;
}

html.csstransforms3d body.hockeyish #FOP .play.penalty svg .penalty,
html.csstransforms3d body.hockeyish #FOP .play.hit svg .hit,
html.csstransforms3d body.hockeyish #FOP .play.shot svg .shot,
html.csstransforms3d body.hockeyish #FOP .play.blockedshot svg .block,
html.csstransforms3d body.hockeyish #FOP .play.faceoff svg .faceoff,
html.csstransforms3d body.hockeyish #FOP .play.turnover svg .turnover,
html.csstransforms3d body.hockeyish #FOP .play.goal svg .goal {
  opacity: 1.0;
}

html.csstransforms3d body.hockeyish #FOP .play.new .svg-pin {
  transform-origin: 0 100px 100px;
  opacity: 0;
}

html.csstransforms3d body.hockeyish #FOP .play_area,
html.csstransforms3d body.hockeyish #FOP .plays,
html.csstransforms3d body.hockeyish #FOP .play {
  transform-style: preserve-3d;
}

html.csstransforms3d body.hockeyish #FOP .play .shadow {
  display: none;
}

#FOP-timeline-markers .marker.filter_show.slider_show.team_show,
body.hockeyish #FOP .play.filter_show.slider_show.team_show {
  display: block;
}

/* MARKERS */
#FOP-timeline-markers .marker {
  position: absolute;
  width: 6px;
  height: 24px;
  bottom: 10px;
  background: none;
  margin-left: -3px;
  overflow: visible;
  display: none;
}

#liveGameSimple:hover .marker svg,
#liveGameSimple.dim_plays .marker svg,
body.hockeyish #liveGameSimple.dim_plays .play .svg-pin,
body.hockeyish #liveGameSimple:hover .play .svg-pin {
  opacity: .2;
}

body.hockeyish #liveGameSimple.dim_plays .play.active .svg-pin,
body.hockeyish #liveGameSimple:hover .play .svg-pin:hover,
body.hockeyish #liveGameSimple.dim_plays .marker.active {
  opacity: 1;
  z-index: 499;
}

#FOP-timeline-markers .marker:hover svg,
#FOP-timeline-markers .marker.active svg {
  opacity: 1.0;
}

#liveGameSimple.no-nested-3d-transforms {
  display: none;
}

.games-slider li.selected a, .games-slider a:hover {
  background: #000000;
  box-shadow: 0 2px #000000 inset, 0 -2px #000000 inset;
  text-decoration: none;
}

.games-slider li.selected a *, .games-slider a:hover * {
  color: #fff;
}

.games-slider {
  background-color: #eee;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  margin: 5px 0 15px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.games-slider ul {
  color: #666;
  display: table;
  font-size: 10px;
  list-style: none;
  text-align: center;
}

.games-slider li {
  display: table-cell;
}

.games-slider li:first-child a {
  border-left-width: 2px;
}

.games-slider li:last-child a {
  border-right-width: 2px;
}

.games-slider a {
  background: #fff;
  box-shadow: 0 2px #b5b5b5 inset, 0 -2px #b5b5b5 inset;
  border-color: #b5b5b5;
  border-style: solid;
  border-width: 0 1px;
  box-sizing: border-box;
  color: #666;
  display: block;
  height: 63px;
  padding: 9px 0 0;
  position: relative;
  transition: background .25s, border .25s, box-shadow .25s, color .25s;
  vertical-align: middle;
  width: 50px;
}

.games-slider-inner {
  display: table;
}

.games-slider-group {
  display: table-cell;
  overflow: hidden;
  padding-left: 17px;
  position: relative;
}

.games-slider-month {
  background: #666;
  box-sizing: border-box;
  color: #fff;
  font-size: 10px;
  height: 17px;
  line-height: 16px;
  padding: 1px 0 1px 6px;
  position: absolute;
  text-transform: uppercase;
  transform: rotatez(-90deg) translate(-100%, -100%);
  transform-origin: 0 0;
  white-space: nowrap;
  width: 63px;
}

.games-slider-date {
  display: block;
  font-size: 22px;
  font-weight: 900;
  line-height: 1em;
  padding: 4px 0;
}

.games-slider-day,
.games-slider-count,
.games-slider-time,
.games-slider-outcome {
  display: block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  line-height: .7em;
}

.games-slider-day {
  font-size: 13px;
  text-transform: uppercase;
}

.games-slider-count,
.games-slider-time {
  font-size: 10px;
  color: #999;
}

.games-slider-outcome {
  font-size: 10px;
  color: #000000;
  text-transform: uppercase;
}

.game-header {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.game-header > * {
  height: 100%;
}

.game-header .game-title {
  display: block;
  line-height: 1;
}

.game-header .datetime {
  display: block;
  font-size: 14px;
  border-top: 1px solid #ccc;
  padding-top: .3em;
  line-height: 1.2;
}

.game-header .datetime * {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.game-header .team-abbrev,
.game-header .team-name {
  color: #000000;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
}

.game-header .team-abbrev {
  font-size: 14px;
  text-transform: uppercase;
  display: none;
  line-height: 1;
  margin-top: 8px;
}

.game-header .team-name {
  box-sizing: border-box;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  text-align: right;
  padding: 0 15px 0 0;
  font-size: 20px;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-header .team-name ~ .team-name {
  padding: 0 0 0 15px;
  text-align: left;
}

.game-header .vs,
.game-header .scores {
  display: block;
  overflow: hidden;
  font: 36px "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 900;
}

.game-header .scores .separator {
  font-weight: 600;
  margin: 0 -0.1em;
}

.game-header .status {
  font-size: 14px;
  display: block;
  border-top: 1px solid #ccc;
}

.game-header .team-logo {
  padding-bottom: 75%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.game-header .team-logo:before {
  content: '';
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
}

.game-header .team-crest {
  box-sizing: border-box;
  -webkit-flex: 0 0 106px;
  -ms-flex: 0 0 106px;
  flex: 0 0 106px;
}

.game-header .team-crest.away .team-logo {
  margin-right: 6px;
}

.game-header .team-crest.away .team-logo:before {
  right: -6px;
}

.game-header .team-crest.home .team-logo {
  margin-left: 6px;
}

.game-header .team-crest.home .team-logo:before {
  left: -6px;
}

.game-header .game-header-center {
  box-sizing: border-box;
  -webkit-flex: 0 0 11em;
  -ms-flex: 0 0 11em;
  flex: 0 0 11em;
  padding: 0 15px;
}

.game-header ~ .boxscore-inner-container {
  margin-top: 8px;
}

@media only screen and (min-width: 1000px) {
  .layout-66-33 .column-1 .game-header .team-name,
  .layout-33-66 .column-2 .game-header .team-name,
  .layout-50-25-25 .column-1 .game-header .team-name,
  .layout-25-50-25 .column-2 .game-header .team-name,
  .layout-25-25-50 .column-3 .game-header .team-name,
  .layout-50-50 .game-header .team-name {
    display: none;
  }
  .layout-66-33 .column-1 .game-header .team-abbrev,
  .layout-33-66 .column-2 .game-header .team-abbrev,
  .layout-50-25-25 .column-1 .game-header .team-abbrev,
  .layout-25-50-25 .column-2 .game-header .team-abbrev,
  .layout-25-25-50 .column-3 .game-header .team-abbrev,
  .layout-50-50 .game-header .team-abbrev {
    display: block;
  }
  .layout-66-33 .column-1 .game-header .team-crest,
  .layout-33-66 .column-2 .game-header .team-crest,
  .layout-50-25-25 .column-1 .game-header .team-crest,
  .layout-25-50-25 .column-2 .game-header .team-crest,
  .layout-25-25-50 .column-3 .game-header .team-crest,
  .layout-50-50 .game-header .team-crest {
    box-sizing: border-box;
    -webkit-flex: 0 1 106px;
    -ms-flex: 0 1 106px;
    flex: 0 1 106px;
  }
  .layout-33-66 .column-1 .game-header,
  .layout-66-33 .column-2 .game-header,
  .layout-33-33-33 .game-header,
  .layout-50-25-25 .column:not(.column-1) .game-header,
  .layout-25-50-25 .column:not(.column-2) .game-header,
  .layout-25-25-50 .column:not(.column-3) .game-header {
    padding-top: calc(36% + 14px + 8px);
  }
  .layout-33-66 .column-1 .game-header.has-title,
  .layout-66-33 .column-2 .game-header.has-title,
  .layout-33-33-33 .game-header.has-title,
  .layout-50-25-25 .column:not(.column-1) .game-header.has-title,
  .layout-25-50-25 .column:not(.column-2) .game-header.has-title,
  .layout-25-25-50 .column:not(.column-3) .game-header.has-title {
    padding-top: calc(36% + 24px + 14px + 8px);
  }
  .layout-33-66 .column-1 .game-header.has-title .team-crest,
  .layout-66-33 .column-2 .game-header.has-title .team-crest,
  .layout-33-33-33 .game-header.has-title .team-crest,
  .layout-50-25-25 .column:not(.column-1) .game-header.has-title .team-crest,
  .layout-25-50-25 .column:not(.column-2) .game-header.has-title .team-crest,
  .layout-25-25-50 .column:not(.column-3) .game-header.has-title .team-crest {
    top: 24px;
  }
  .layout-33-66 .column-1 .game-header .game-header-center,
  .layout-66-33 .column-2 .game-header .game-header-center,
  .layout-33-33-33 .game-header .game-header-center,
  .layout-50-25-25 .column:not(.column-1) .game-header .game-header-center,
  .layout-25-50-25 .column:not(.column-2) .game-header .game-header-center,
  .layout-25-25-50 .column:not(.column-3) .game-header .game-header-center {
    box-sizing: border-box;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 0;
  }
  .layout-33-66 .column-1 .game-header .team-abbrev,
  .layout-66-33 .column-2 .game-header .team-abbrev,
  .layout-33-33-33 .game-header .team-abbrev,
  .layout-50-25-25 .column:not(.column-1) .game-header .team-abbrev,
  .layout-25-50-25 .column:not(.column-2) .game-header .team-abbrev,
  .layout-25-25-50 .column:not(.column-3) .game-header .team-abbrev {
    display: block;
  }
  .layout-33-66 .column-1 .game-header .team-name,
  .layout-66-33 .column-2 .game-header .team-name,
  .layout-33-33-33 .game-header .team-name,
  .layout-50-25-25 .column:not(.column-1) .game-header .team-name,
  .layout-25-50-25 .column:not(.column-2) .game-header .team-name,
  .layout-25-25-50 .column:not(.column-3) .game-header .team-name {
    display: none;
  }
  .layout-33-66 .column-1 .game-header .team-crest,
  .layout-66-33 .column-2 .game-header .team-crest,
  .layout-33-33-33 .game-header .team-crest,
  .layout-50-25-25 .column:not(.column-1) .game-header .team-crest,
  .layout-25-50-25 .column:not(.column-2) .game-header .team-crest,
  .layout-25-25-50 .column:not(.column-3) .game-header .team-crest {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 4px);
    height: auto;
  }
  .layout-33-66 .column-1 .game-header .team-crest ~ .team-crest,
  .layout-66-33 .column-2 .game-header .team-crest ~ .team-crest,
  .layout-33-33-33 .game-header .team-crest ~ .team-crest,
  .layout-50-25-25 .column:not(.column-1) .game-header .team-crest ~ .team-crest,
  .layout-25-50-25 .column:not(.column-2) .game-header .team-crest ~ .team-crest,
  .layout-25-25-50 .column:not(.column-3) .game-header .team-crest ~ .team-crest {
    left: auto;
    right: 0;
  }
  .layout-33-66 .column-1 .game-header .game-title,
  .layout-66-33 .column-2 .game-header .game-title,
  .layout-33-33-33 .game-header .game-title,
  .layout-50-25-25 .column:not(.column-1) .game-header .game-title,
  .layout-25-50-25 .column:not(.column-2) .game-header .game-title,
  .layout-25-25-50 .column:not(.column-3) .game-header .game-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 16px;
  }
  .layout-33-66 .column-1 .game-header .scores,
  .layout-66-33 .column-2 .game-header .scores,
  .layout-33-33-33 .game-header .scores,
  .layout-50-25-25 .column:not(.column-1) .game-header .scores,
  .layout-25-50-25 .column:not(.column-2) .game-header .scores,
  .layout-25-25-50 .column:not(.column-3) .game-header .scores {
    overflow: hidden;
    bottom: 22px;
  }
  .layout-33-66 .column-1 .game-header .scores .home,
  .layout-33-66 .column-1 .game-header .scores .away,
  .layout-66-33 .column-2 .game-header .scores .home,
  .layout-66-33 .column-2 .game-header .scores .away,
  .layout-33-33-33 .game-header .scores .home,
  .layout-33-33-33 .game-header .scores .away,
  .layout-50-25-25 .column:not(.column-1) .game-header .scores .home,
  .layout-50-25-25 .column:not(.column-1) .game-header .scores .away,
  .layout-25-50-25 .column:not(.column-2) .game-header .scores .home,
  .layout-25-50-25 .column:not(.column-2) .game-header .scores .away,
  .layout-25-25-50 .column:not(.column-3) .game-header .scores .home,
  .layout-25-25-50 .column:not(.column-3) .game-header .scores .away {
    display: block;
    float: left;
    width: calc(50% - 4px);
  }
  .layout-33-66 .column-1 .game-header .scores .home,
  .layout-66-33 .column-2 .game-header .scores .home,
  .layout-33-33-33 .game-header .scores .home,
  .layout-50-25-25 .column:not(.column-1) .game-header .scores .home,
  .layout-25-50-25 .column:not(.column-2) .game-header .scores .home,
  .layout-25-25-50 .column:not(.column-3) .game-header .scores .home {
    float: right;
  }
  .layout-33-66 .column-1 .game-header .scores .separator,
  .layout-66-33 .column-2 .game-header .scores .separator,
  .layout-33-33-33 .game-header .scores .separator,
  .layout-50-25-25 .column:not(.column-1) .game-header .scores .separator,
  .layout-25-50-25 .column:not(.column-2) .game-header .scores .separator,
  .layout-25-25-50 .column:not(.column-3) .game-header .scores .separator {
    display: none;
  }
  .layout-33-66 .column-1 .game-header:not(:last-child) .datetime,
  .layout-33-66 .column-1 .game-header:not(:last-child) .status,
  .layout-66-33 .column-2 .game-header:not(:last-child) .datetime,
  .layout-66-33 .column-2 .game-header:not(:last-child) .status,
  .layout-33-33-33 .game-header:not(:last-child) .datetime,
  .layout-33-33-33 .game-header:not(:last-child) .status,
  .layout-50-25-25 .column:not(.column-1) .game-header:not(:last-child) .datetime,
  .layout-50-25-25 .column:not(.column-1) .game-header:not(:last-child) .status,
  .layout-25-50-25 .column:not(.column-2) .game-header:not(:last-child) .datetime,
  .layout-25-50-25 .column:not(.column-2) .game-header:not(:last-child) .status,
  .layout-25-25-50 .column:not(.column-3) .game-header:not(:last-child) .datetime,
  .layout-25-25-50 .column:not(.column-3) .game-header:not(:last-child) .status {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-33-33-33 .game-header,
  .layout-33-66 .column-1 .game-header,
  .layout-66-33 .column-2 .game-header,
  .layout-25-50-25 .game-header {
    padding-top: calc(36% + 14px + 8px);
  }
  .layout-33-33-33 .game-header.has-title,
  .layout-33-66 .column-1 .game-header.has-title,
  .layout-66-33 .column-2 .game-header.has-title,
  .layout-25-50-25 .game-header.has-title {
    padding-top: calc(36% + 24px + 14px + 8px);
  }
  .layout-33-33-33 .game-header.has-title .team-crest,
  .layout-33-66 .column-1 .game-header.has-title .team-crest,
  .layout-66-33 .column-2 .game-header.has-title .team-crest,
  .layout-25-50-25 .game-header.has-title .team-crest {
    top: 24px;
  }
  .layout-33-33-33 .game-header .game-header-center,
  .layout-33-66 .column-1 .game-header .game-header-center,
  .layout-66-33 .column-2 .game-header .game-header-center,
  .layout-25-50-25 .game-header .game-header-center {
    box-sizing: border-box;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 0;
  }
  .layout-33-33-33 .game-header .team-abbrev,
  .layout-33-66 .column-1 .game-header .team-abbrev,
  .layout-66-33 .column-2 .game-header .team-abbrev,
  .layout-25-50-25 .game-header .team-abbrev {
    display: block;
  }
  .layout-33-33-33 .game-header .team-name,
  .layout-33-66 .column-1 .game-header .team-name,
  .layout-66-33 .column-2 .game-header .team-name,
  .layout-25-50-25 .game-header .team-name {
    display: none;
  }
  .layout-33-33-33 .game-header .team-crest,
  .layout-33-66 .column-1 .game-header .team-crest,
  .layout-66-33 .column-2 .game-header .team-crest,
  .layout-25-50-25 .game-header .team-crest {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 4px);
    height: auto;
  }
  .layout-33-33-33 .game-header .team-crest ~ .team-crest,
  .layout-33-66 .column-1 .game-header .team-crest ~ .team-crest,
  .layout-66-33 .column-2 .game-header .team-crest ~ .team-crest,
  .layout-25-50-25 .game-header .team-crest ~ .team-crest {
    left: auto;
    right: 0;
  }
  .layout-33-33-33 .game-header .game-title,
  .layout-33-66 .column-1 .game-header .game-title,
  .layout-66-33 .column-2 .game-header .game-title,
  .layout-25-50-25 .game-header .game-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 16px;
  }
  .layout-33-33-33 .game-header .scores,
  .layout-33-66 .column-1 .game-header .scores,
  .layout-66-33 .column-2 .game-header .scores,
  .layout-25-50-25 .game-header .scores {
    overflow: hidden;
    bottom: 22px;
  }
  .layout-33-33-33 .game-header .scores .home,
  .layout-33-33-33 .game-header .scores .away,
  .layout-33-66 .column-1 .game-header .scores .home,
  .layout-33-66 .column-1 .game-header .scores .away,
  .layout-66-33 .column-2 .game-header .scores .home,
  .layout-66-33 .column-2 .game-header .scores .away,
  .layout-25-50-25 .game-header .scores .home,
  .layout-25-50-25 .game-header .scores .away {
    display: block;
    float: left;
    width: calc(50% - 4px);
  }
  .layout-33-33-33 .game-header .scores .home,
  .layout-33-66 .column-1 .game-header .scores .home,
  .layout-66-33 .column-2 .game-header .scores .home,
  .layout-25-50-25 .game-header .scores .home {
    float: right;
  }
  .layout-33-33-33 .game-header .scores .separator,
  .layout-33-66 .column-1 .game-header .scores .separator,
  .layout-66-33 .column-2 .game-header .scores .separator,
  .layout-25-50-25 .game-header .scores .separator {
    display: none;
  }
  .layout-33-33-33 .game-header:not(:last-child) .datetime,
  .layout-33-33-33 .game-header:not(:last-child) .status,
  .layout-33-66 .column-1 .game-header:not(:last-child) .datetime,
  .layout-33-66 .column-1 .game-header:not(:last-child) .status,
  .layout-66-33 .column-2 .game-header:not(:last-child) .datetime,
  .layout-66-33 .column-2 .game-header:not(:last-child) .status,
  .layout-25-50-25 .game-header:not(:last-child) .datetime,
  .layout-25-50-25 .game-header:not(:last-child) .status {
    display: none;
  }
  .layout-33-66 .column-2 .game-header .team-name,
  .layout-66-33 .column-1 .game-header .team-name,
  .layout-50-50 .game-header .team-name,
  .layout-25-25-50 .column:not(.column-3) .game-header .team-name,
  .layout-50-25-25 .column:not(.column-1) .game-header .team-name {
    display: none;
  }
  .layout-33-66 .column-2 .game-header .team-abbrev,
  .layout-66-33 .column-1 .game-header .team-abbrev,
  .layout-50-50 .game-header .team-abbrev,
  .layout-25-25-50 .column:not(.column-3) .game-header .team-abbrev,
  .layout-50-25-25 .column:not(.column-1) .game-header .team-abbrev {
    display: block;
  }
  .layout-33-66 .column-2 .game-header .team-crest,
  .layout-66-33 .column-1 .game-header .team-crest,
  .layout-50-50 .game-header .team-crest,
  .layout-25-25-50 .column:not(.column-3) .game-header .team-crest,
  .layout-50-25-25 .column:not(.column-1) .game-header .team-crest {
    box-sizing: border-box;
    -webkit-flex: 0 1 106px;
    -ms-flex: 0 1 106px;
    flex: 0 1 106px;
  }
}

@media only screen and (max-width: 767px) {
  .game-header .team-name {
    display: none;
  }
  .game-header .team-abbrev {
    display: block;
  }
  .game-header .team-crest {
    box-sizing: border-box;
    -webkit-flex: 0 1 106px;
    -ms-flex: 0 1 106px;
    flex: 0 1 106px;
  }
}

@media only screen and (max-width: 400px) {
  .game-header {
    padding-top: calc(36% + 14px + 8px);
  }
  .game-header.has-title {
    padding-top: calc(36% + 24px + 14px + 8px);
  }
  .game-header.has-title .team-crest {
    top: 24px;
  }
  .game-header .game-header-center {
    box-sizing: border-box;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 0;
  }
  .game-header .team-abbrev {
    display: block;
  }
  .game-header .team-name {
    display: none;
  }
  .game-header .team-crest {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 4px);
    height: auto;
  }
  .game-header .team-crest ~ .team-crest {
    left: auto;
    right: 0;
  }
  .game-header .game-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 16px;
  }
  .game-header .scores {
    overflow: hidden;
    bottom: 22px;
  }
  .game-header .scores .home,
  .game-header .scores .away {
    display: block;
    float: left;
    width: calc(50% - 4px);
  }
  .game-header .scores .home {
    float: right;
  }
  .game-header .scores .separator {
    display: none;
  }
  .game-header:not(:last-child) .datetime,
  .game-header:not(:last-child) .status {
    display: none;
  }
}

#postList div.postElement {
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
  padding-left: 100px;
}

#postList div.postElement:last-of-type {
  border: none;
}

#postList div.postElement .postUser img {
  border: 2px solid #ddd;
  height: 60px;
  margin-left: -80px;
  width: auto;
}

#postList div.postElement .postUser img:not(:first-of-type) {
  display: none;
}

#postList div.postElement .postUser .name {
  display: block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  line-height: 1.3em;
}

#postList div.postElement .postUser .date {
  color: #666;
  display: block;
  font-size: 10px;
  margin: .25em 0 1.5em;
  text-transform: uppercase;
}

#postList div.postElement .button-content {
  background: transparent;
  border: none;
}

#postList div.postElement .button-content:hover {
  background: transparent;
}

#postList div.postElement .button-image-right {
  display: none;
}

#postList div.postElement .button-image-right ~ .button-content {
  padding: 0;
}

@media (max-width: 414px) {
  #postList div.postElement {
    padding-left: 10px;
    padding-right: 10px;
  }
  #postList div.postElement .postUser {
    padding-left: 80px;
  }
  #postList div.postElement .postContent {
    clear: left;
    padding-top: 10px;
  }
}

.toolsLightbox #postContent {
  padding: 15px 15px 0;
}

.toolsLightbox #postContent .postElement {
  margin-bottom: 0;
}

.toolsLightbox #postComments {
  margin-top: 0;
  padding: 0 15px 15px;
}

.widget-multi-stat-filterable [id^="multi_stat"] {
  overflow-x: auto;
}

.widget-multi-stat-filterable [id^="multi_stat"] + div {
  border-style: solid;
  border-width: 0;
  border-bottom-color: #EDF0F1;
  border-bottom-width: 2px !important;
  padding: 6px 12px;
}

.widget-multi-stat-filterable [id^="multi_stat"] + div a {
  color: #CF3338;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  text-align: right;
}

.widget-multi-stat-filterable [id^="multi_stat"] + div a:after {
  color: #000000;
  content: '\f061';
  display: inline-block;
  font-family: 'FontAwesome';
  margin: -.1em 0 0 .4em;
  font-size: .9em;
}

.widget-multi-stat-filterable .sportTableLabel {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .widget-multi-stat-filterable .sportTableLabel {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.widget-multi-stat-filterable .sportTableLabel .teamLogo-tiny {
  left: 14px;
  position: absolute;
  top: 14px;
}

.widget-multi-stat-filterable .sportTableLabel * {
  color: inherit;
}

.widget-multi-stat-filterable .sportTableLabel h4.teamName {
  font-size: 24px;
  margin: 0;
  padding-left: 30px;
}

.widget-multi-stat-filterable .sportTableLabel .seasonDisplay,
.widget-multi-stat-filterable .sportTableLabel .expandedView {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}

.widget-multi-stat-filterable .sportTableLabel .linkText {
  display: block;
}

.widget-multi-stat-filterable .sportTableLabel .season-separator {
  display: none;
}

.widget-multi-stat-filterable .statTable th {
  text-align: center;
}

.widget-multi-stat-filterable .statTable th img {
  display: none;
}

.widget-multi-stat-filterable .statTable th:first-child {
  text-align: center;
  width: 30px;
}

.widget-multi-stat-filterable .statTable td {
  text-align: center;
  white-space: nowrap;
}

.widget-multi-stat-filterable .statTable td:first-child {
  font-weight: 700;
}

.widget-multi-stat-filterable .statTable .statPlayer {
  text-align: left;
}

.widget-multi-stat-filterable .statTable .statPlayer a {
  display: inline-block;
  min-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

@media (min-width: 768px) {
  .col-md-3 [id^="multi_stat"] .sportTableLabel,
  .col-md-4 [id^="multi_stat"] .sportTableLabel {
    max-width: 100%;
    padding-left: 10px;
  }
  .col-md-3 [id^="multi_stat"] .sportTableLabel .teamLogo-tiny,
  .col-md-4 [id^="multi_stat"] .sportTableLabel .teamLogo-tiny {
    display: none;
  }
  .col-md-3 [id^="multi_stat"] .sportTableLabel h4 > *,
  .col-md-4 [id^="multi_stat"] .sportTableLabel h4 > * {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .layout-25-50-25 .col-md-3 [id^="multi_stat"] .sportTableLabel,
  .layout-25-50-25 .col-md-6 [id^="multi_stat"] .sportTableLabel {
    max-width: 100%;
    padding-left: 10px;
  }
  .layout-25-50-25 .col-md-3 [id^="multi_stat"] .sportTableLabel .teamLogo-tiny,
  .layout-25-50-25 .col-md-6 [id^="multi_stat"] .sportTableLabel .teamLogo-tiny {
    display: none;
  }
  .layout-25-50-25 .col-md-3 [id^="multi_stat"] .sportTableLabel h4 > *,
  .layout-25-50-25 .col-md-6 [id^="multi_stat"] .sportTableLabel h4 > * {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Widget - Stat Leader
---------------------------------------------------------------------------------------------------- */
.widget-leader .sportTableLabel {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .widget-leader .sportTableLabel {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.widget-leader .sportTableLabel .teamLogo-tiny {
  left: 14px;
  position: absolute;
  top: 14px;
}

.widget-leader .sportTableLabel * {
  color: inherit;
}

.widget-leader .sportTableLabel h4.teamName {
  font-size: 24px;
  margin: 0;
  padding-left: 30px;
}

.widget-leader .sportTableLabel .seasonDisplay,
.widget-leader .sportTableLabel .expandedView {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}

.widget-leader .sportTableLabel .linkText {
  display: block;
}

.widget-leader .sportTableLabel .season-separator {
  display: none;
}

.widget-leader .statTable {
  width: 100%;
}

.widget-leader .statTable th:nth-child(1), .widget-leader .statTable td:nth-child(1) {
  text-align: center;
}

.widget-leader .statTable th:nth-child(2), .widget-leader .statTable td:nth-child(2) {
  text-align: left;
}

.widget-leader .statTable th:nth-child(3), .widget-leader .statTable td:nth-child(3) {
  text-align: center;
}

.widget-leader .statTable th:nth-child(1) {
  width: 30px;
}

.widget-leader .statTable td {
  white-space: nowrap;
}

.widget-leader .statTable td:nth-child(1) {
  font-weight: 700;
}

.widget-leader .leaderName {
  display: inline-block;
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.widget-leader .leader-highlight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  background-color: #e6e6e6;
}

.widget-leader .stats {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 0 0 10px;
  text-align: center;
}

.widget-leader .stats-major,
.widget-leader .stats-minor {
  margin: 0;
  padding: 0;
}

.widget-leader .player_photo {
  overflow: hidden;
  height: 70px;
  border: 3px solid #000000;
  background-color: #C7C7C7;
}

.widget-leader .player_photo img {
  display: block;
  width: 70px;
}

[id^="widget_standings_widget_"] .sportTableLabel,
#tab_division_standings_content .sportTableLabel {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  position: relative;
}

@media only screen and (max-width: 767px) {
  [id^="widget_standings_widget_"] .sportTableLabel,
  #tab_division_standings_content .sportTableLabel {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

[id^="widget_standings_widget_"] .sportTableLabel .teamLogo-tiny,
#tab_division_standings_content .sportTableLabel .teamLogo-tiny {
  left: 14px;
  position: absolute;
  top: 14px;
}

[id^="widget_standings_widget_"] .sportTableLabel *,
#tab_division_standings_content .sportTableLabel * {
  color: inherit;
}

[id^="widget_standings_widget_"] .sportTableLabel h4.teamName,
#tab_division_standings_content .sportTableLabel h4.teamName {
  font-size: 24px;
  margin: 0;
  padding-left: 30px;
}

[id^="widget_standings_widget_"] .sportTableLabel .seasonDisplay,
[id^="widget_standings_widget_"] .sportTableLabel .expandedView,
#tab_division_standings_content .sportTableLabel .seasonDisplay,
#tab_division_standings_content .sportTableLabel .expandedView {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}

[id^="widget_standings_widget_"] .sportTableLabel .linkText,
#tab_division_standings_content .sportTableLabel .linkText {
  display: block;
}

[id^="widget_standings_widget_"] .sportTableLabel .season-separator,
#tab_division_standings_content .sportTableLabel .season-separator {
  display: none;
}

[id^="widget_standings_widget_"] ul ul,
#tab_division_standings_content ul ul {
  padding-top: 2px;
  padding-left: 25px;
  position: relative;
}

[id^="widget_standings_widget_"] ul ul:before,
#tab_division_standings_content ul ul:before {
  background: #999;
  content: '';
  height: calc(100% - 4px);
  left: 2px;
  position: absolute;
  width: 3px;
}

[id^="widget_standings_widget_"] ul ul h4.teamName:before,
#tab_division_standings_content ul ul h4.teamName:before {
  background: #999;
  content: '';
  height: 3px;
  left: -22px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 20px;
}

@media (max-width: 400px) {
  [id^="widget_standings_widget_"] ul ul,
  #tab_division_standings_content ul ul {
    padding-left: 4px;
  }
  [id^="widget_standings_widget_"] ul ul h4.teamName:before,
  #tab_division_standings_content ul ul h4.teamName:before {
    display: none;
  }
}

[id^="widget_standings_widget_"] th:not(.name),
#tab_division_standings_content th:not(.name) {
  text-align: center;
}

tr[id^="standing_"] td.name {
  text-align: left;
}

tr[id^="standing_"] .teamLogo-tiny {
  background: #fff;
  margin-right: 10px;
  position: relative;
}

tr[id^="standing_"] .teamLogo-tiny:after {
  background: #CF3338;
  content: '';
  height: 100%;
  position: absolute;
  right: -5px;
  width: 3px;
}

.NginTableControls {
  display: none;
}

[id^="widget_team_stats_widget_"] .sportTableLabel {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
  position: relative;
  position: relative;
}

@media only screen and (max-width: 767px) {
  [id^="widget_team_stats_widget_"] .sportTableLabel {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

[id^="widget_team_stats_widget_"] .sportTableLabel .teamLogo-tiny {
  left: 14px;
  position: absolute;
  top: 14px;
}

[id^="widget_team_stats_widget_"] .sportTableLabel * {
  color: inherit;
}

[id^="widget_team_stats_widget_"] .sportTableLabel h4.teamName {
  font-size: 24px;
  margin: 0;
  padding-left: 30px;
}

[id^="widget_team_stats_widget_"] .sportTableLabel .seasonDisplay,
[id^="widget_team_stats_widget_"] .sportTableLabel .expandedView {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}

[id^="widget_team_stats_widget_"] .sportTableLabel .linkText {
  display: block;
}

[id^="widget_team_stats_widget_"] .sportTableLabel .season-separator {
  display: none;
}

[id^="widget_team_stats_widget_"] .statTable td:first-child,
[id^="widget_team_stats_widget_"] .statTable tr:nth-child(2) th:first-child {
  text-align: center;
}

@media (min-width: 768px) {
  .col-md-3 [id^="widget_team_stats_widget_"] .sportTableLabel,
  .col-md-4 [id^="widget_team_stats_widget_"] .sportTableLabel {
    max-width: 100%;
    padding-left: 10px;
  }
  .col-md-3 [id^="widget_team_stats_widget_"] .sportTableLabel .teamLogo-tiny,
  .col-md-4 [id^="widget_team_stats_widget_"] .sportTableLabel .teamLogo-tiny {
    display: none;
  }
  .col-md-3 [id^="widget_team_stats_widget_"] .sportTableLabel h4 > *,
  .col-md-4 [id^="widget_team_stats_widget_"] .sportTableLabel h4 > * {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .layout-25-50-25 .col-md-3 [id^="widget_team_stats_widget_"] .sportTableLabel,
  .layout-25-50-25 .col-md-6 [id^="widget_team_stats_widget_"] .sportTableLabel {
    max-width: 100%;
    padding-left: 10px;
  }
  .layout-25-50-25 .col-md-3 [id^="widget_team_stats_widget_"] .sportTableLabel .teamLogo-tiny,
  .layout-25-50-25 .col-md-6 [id^="widget_team_stats_widget_"] .sportTableLabel .teamLogo-tiny {
    display: none;
  }
  .layout-25-50-25 .col-md-3 [id^="widget_team_stats_widget_"] .sportTableLabel h4 > *,
  .layout-25-50-25 .col-md-6 [id^="widget_team_stats_widget_"] .sportTableLabel h4 > * {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.pageElement > h3:first-child {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 36px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .pageElement > h3:first-child {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.pageElement > h3:only-child {
  margin-bottom: 0;
}

.edit_mode .custom-footer-extra {
  position: relative;
}

.edit_mode .custom-footer-extra:after {
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border: 1px dashed #C1C3C5;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.custom-footer-extra .global-component-edit-link {
  top: 10px;
  left: 10px;
  right: 10px;
}

.custom-footer-extra:empty {
  display: none;
}

.snFooterContainer {
  background-color: #000000;
}

/**
 * 
 */
.custom-footer-extra {
  padding-top: 50px;
  /**
   * '.footer-button'
   */
  /**
   * hr styles
   */
  /**
   * '.footer-navigation' psuedo class before & after flex based row wrapping can handle 3 rows at desktop viewport width
   */
  /**
   * '.footer-logo'
   */
  /**
   * '.footer-social'
   */
  /**
   * '.legal-footer-links'
   */
}

.custom-footer-extra .footer-text {
  margin-bottom: 10px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .custom-footer-extra .footer-text {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.custom-footer-extra .footer-text h1, .custom-footer-extra .footer-text h2, .custom-footer-extra .footer-text h3, .custom-footer-extra .footer-text h4, .custom-footer-extra .footer-text h5, .custom-footer-extra .footer-text h6,
.custom-footer-extra .footer-text p {
  color: #ffffff;
  font-weight: 700;
}

.custom-footer-extra .footer-text h4 {
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 24px;
}

.custom-footer-extra .footer-text p {
  font-size: 14px;
  line-height: 14px;
}

.custom-footer-extra .footer-button {
  margin-bottom: 40px;
}

.custom-footer-extra .footer-button .linkElement {
  text-align: center;
}

.custom-footer-extra .footer-button .linkElement h4 {
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  .custom-footer-extra .footer-button {
    /** '.footer-button' Styles */
    margin-bottom: 30px;
  }
}

.custom-footer-extra hr {
  height: 1px;
  background-color: #EEEEEE;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {
  .custom-footer-extra hr {
    /** 'hr' Styles */
    margin-bottom: 20px;
  }
}

.custom-footer-extra .footer-navigation {
  margin-bottom: 44px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-footer-extra .footer-navigation .navigation-list {
  padding: 0 20px;
  /**
       * parentNavItem styles
       */
  /**
       * Link styles
       */
}

.custom-footer-extra .footer-navigation .navigation-list .parentNavItem {
  margin-top: 39px;
}

.custom-footer-extra .footer-navigation .navigation-list h3 {
  font-size: 16px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 8px;
  transition: text-decoration 0.2s linear;
}

.custom-footer-extra .footer-navigation .navigation-list a {
  font-size: 12px;
  font-weight: 400;
  line-height: 36px;
  color: white;
  display: block;
  transition: text-decoration 0.2s linear;
}

.custom-footer-extra .footer-navigation .navigation-list a:hover {
  text-decoration: underline;
}

.custom-footer-extra .footer-navigation .navigation-list a:hover h3 {
  color: #FF595A;
}

.custom-footer-extra .footer-navigation .navigation-list .childNavItem {
  display: none;
}

@media screen and (max-width: 1024px) {
  .custom-footer-extra .footer-navigation {
    /** Footer Navigation Styles */
    flex-wrap: wrap;
    margin-bottom: 14px;
  }
  .custom-footer-extra .footer-navigation .navigation-list {
    flex-basis: 100%;
    display: inline-block;
    text-align: center;
    max-width: 100%;
    /** hide child nav items*/
  }
  .custom-footer-extra .footer-navigation .navigation-list .parentNavItem {
    margin-top: 20px;
  }
  .custom-footer-extra .footer-navigation .navigation-list .childNavItem {
    display: none;
  }
}

.custom-footer-extra .footer-logo {
  margin-bottom: 22px;
}

.custom-footer-extra .footer-logo img {
  max-width: 252px;
}

@media screen and (max-width: 1024px) {
  .custom-footer-extra .footer-logo {
    /** '.footer-logo' Styles */
    margin-bottom: 13px;
  }
}

.custom-footer-extra .footer-social {
  text-align: center;
}

.custom-footer-extra .footer-social .sn-social-media-list a[class*="icon"] {
  background: transparent;
  margin: 0;
  width: initial;
  height: 20px;
}

.custom-footer-extra .footer-social .sn-social-media-list a[class*="icon"]:hover {
  background-color: transparent;
}

.custom-footer-extra .footer-social .sn-social-media-list a[class*="icon"]:hover:before {
  color: #FF595A;
}

.custom-footer-extra .footer-social .sn-social-media-list a[class*="icon"]:before {
  display: block;
  font-size: 20px;
  line-height: 20px;
  width: 20px;
  left: 0;
  padding-right: 10px;
  padding-left: 10px;
  transition: color 0.2s linear;
}

.custom-footer-extra .legal-footer-links {
  text-align: center;
}

.custom-footer-extra .legal-footer-links li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.custom-footer-extra .legal-footer-links a {
  line-height: 12px;
  font-size: 12px;
  color: #CCCCCC;
  transition: color 0.2s linear;
}

.custom-footer-extra .legal-footer-links a:hover {
  color: #FF595A;
}

.padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .padding-mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.padding-top {
  padding-top: 40px;
}

@media screen and (max-width: 767px) {
  .padding-top {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .padding-top-mobile {
    padding-top: 20px;
  }
}

.padding-bottom {
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .padding-bottom {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .padding-bottom-mobile {
    padding-bottom: 20px;
  }
}

.padding-large {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .padding-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .padding-large-mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.padding-large-top {
  padding-top: 80px;
}

@media screen and (max-width: 767px) {
  .padding-large-top {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .padding-large-top-mobile {
    padding-top: 40px;
  }
}

.padding-large-bottom {
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .padding-large-bottom {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .padding-large-bottom-mobile {
    padding-bottom: 40px;
  }
}

.padding-small {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .padding-small {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .padding-small-mobile {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.padding-small-top {
  padding-top: 20px;
}

@media screen and (max-width: 767px) {
  .padding-small-top {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .padding-small-top-mobile {
    padding-top: 10px;
  }
}

.padding-small-bottom {
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .padding-small-bottom {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .padding-small-bottom-mobile {
    padding-bottom: 10px;
  }
}

.padding-collapse-x.layoutContainer,
.padding-collapse-x > .column {
  padding-left: 0;
  padding-right: 0;
}

.padding-collapse-y.layoutContainer,
.padding-collapse-y > .column {
  padding-top: 0;
  padding-bottom: 0;
}

.compact.pageEl .pageElement,
.compact .pageEl .pageElement {
  margin-bottom: 0;
  margin-top: 0;
}

.compact-top.pageEl .pageElement,
.compact-top .pageEl .pageElement {
  margin-top: 0;
}

.compact-bottom.pageEl .pageElement,
.compact-bottom .pageEl .pageElement {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .user_mode .hidden-on-mobile {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .user_mode .hidden-on-desktop {
    display: none;
  }
}

/**
 * Race Search Component
 */
html {
  scroll-behavior: smooth;
}

* {
  scroll-behavior: smooth;
}

.filter-menu-open .user-options {
  display: flex;
  margin-top: 0;
  overflow: scroll;
  box-shadow: 0;
  border-bottom: 0;
}

.mobileFilterMenuContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: #ffffff;
  overflow: scroll;
  will-change: transform;
}

.showAll {
  display: block;
  padding: 17px 47px;
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.showAll:focus, .showAll:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .showAll {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .showAll {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .showAll {
    display: flex;
  }
}

.close-container {
  display: none;
  align-items: center;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
}

.filter-menu-open .close-container {
  display: flex;
}

.close-container .select-filters {
  display: flex;
  align-items: center;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  margin-bottom: 0;
  color: #2F373B;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.close-container button.close-filters {
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 15px;
}

.close-container button.close-filters #Home-Page-Mobile---Menu {
  fill: #CF3338;
}

.user-options {
  max-width: 1340px;
  background: #ffffff;
  border: 1px solid #F7F7F7;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .user-options {
    padding-left: 20px;
    padding-right: 20px;
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .user-options {
    margin-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1024px) {
  .user-options {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.user-options .custom-search-form {
  display: flex;
  align-items: center;
  height: 100px;
  border-top: 1px solid #EDF0F1;
  border-bottom: 1px solid #EDF0F1;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form {
    flex-direction: column;
    height: 100%;
    padding-left: 0;
    border-bottom: 0;
  }
}

.user-options .custom-search-form .keyword-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.user-options .custom-search-form #custom-search-keyword {
  border-radius: 0;
  -webkit-appearance: none;
  border: 1px solid #F7F7F7;
  width: 100%;
  max-width: 576px;
  max-height: 50px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
}

.user-options .custom-search-form #custom-search-keyword::placeholder {
  font-style: italic;
  font-weight: 400;
}

.user-options .custom-search-form #custom-search-keyword:focus {
  outline: 0;
}

.user-options .custom-search-form #custom-search-keyword.active {
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.user-options .custom-search-form .custom-search-submit {
  border: 1px solid #F7F7F7;
  border-radius: 0;
  border-left: 0;
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #F8F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form .custom-search-submit {
    margin-right: 0;
  }
}

.user-options .custom-search-form .custom-search-submit.active {
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.user-options .custom-search-form .custom-search-submit:before {
  content: '\f002';
  font-family: 'fontawesome';
  font-size: 12px;
  color: #CF3338;
  width: 100%;
  display: block;
}

.user-options .custom-search-form .custom-filters {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form .custom-filters {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.user-options .custom-search-form .custom-filters div {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-around;
  color: #999999;
  height: 100%;
  border-left: 1px solid #EDF0F1;
  border-right: 1px solid #EDF0F1;
  position: relative;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form .custom-filters div {
    color: #666666;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    text-indent: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-left: 1px solid #EDF0F1;
    border-right: 1px solid #EDF0F1;
    border-bottom: 1px solid #EDF0F1;
  }
}

.user-options .custom-search-form .custom-filters div:after {
  content: '\f107';
  display: inline-block;
  font-family: 'fontawesome';
  font-size: inherit;
  color: #CF3338;
  transition: transform 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form .custom-filters div:after {
    font-size: 24px;
    position: absolute;
    top: 5px;
    right: 15px;
  }
}

.user-options .custom-search-form .custom-filters div .locationOptions,
.user-options .custom-search-form .custom-filters div .seriesOptions,
.user-options .custom-search-form .custom-filters div .monthOptions,
.user-options .custom-search-form .custom-filters div .distanceOptions,
.user-options .custom-search-form .custom-filters div .medalOptions {
  display: block;
  height: 0;
  opacity: 0;
  list-style: none;
  width: 0;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #EEEEEE;
  border: 1px solid #DFE4E6;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: -1;
  transition: height 0.6s ease-in, opacity 0.3s ease-in-out, width 0.6s ease-in;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form .custom-filters div .locationOptions,
  .user-options .custom-search-form .custom-filters div .seriesOptions,
  .user-options .custom-search-form .custom-filters div .monthOptions,
  .user-options .custom-search-form .custom-filters div .distanceOptions,
  .user-options .custom-search-form .custom-filters div .medalOptions {
    display: none;
    list-style: none;
    width: 100%;
    max-height: auto;
    overflow: auto;
    position: inherit;
    top: unset;
    left: unset;
    min-width: unset;
    border: 0;
    box-shadow: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .user-options .custom-search-form .custom-filters div .locationOptions,
  .user-options .custom-search-form .custom-filters div .seriesOptions,
  .user-options .custom-search-form .custom-filters div .monthOptions,
  .user-options .custom-search-form .custom-filters div .distanceOptions,
  .user-options .custom-search-form .custom-filters div .medalOptions {
    right: 0;
    left: unset;
  }
}

.user-options .custom-search-form .custom-filters div.active {
  padding-bottom: 0px;
}

.user-options .custom-search-form .custom-filters div.active:after {
  transform: rotate(180deg);
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form .custom-filters div.active:after {
    transform: none;
    content: '\f106';
  }
}

.user-options .custom-search-form .custom-filters div.active ul {
  display: block;
  opacity: 1;
  height: auto;
  width: 100%;
  min-width: 275px;
  z-index: 10;
  margin-top: 10px;
}

.user-options .custom-search-form .custom-filters div.active ul:hover {
  cursor: pointer;
}

.user-options .custom-search-form .custom-filters div.active ul li {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  color: #666666;
  transition: color 0.3s ease-in-out;
  text-indent: 0;
}

.user-options .custom-search-form .custom-filters div.active ul li:hover {
  color: #CF3338;
  cursor: pointer;
}

.user-options .custom-search-form .custom-filters div.active ul li.selected {
  color: #CF3338;
}

.user-options .custom-search-form .custom-filters div .locationOptions ul {
  list-style: none;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}

.user-options .custom-search-form .custom-filters div .locationOptions ul li {
  text-transform: none;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  color: #999999;
  padding-left: 5px;
  transition: color 0.3s ease-in-out;
}

.user-options .custom-search-form .custom-filters div .locationOptions ul li.regionTitle {
  font-size: inherit;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}

.user-options .custom-search-form .custom-filters div .locationOptions ul .locationTitle {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 600;
  line-height: 2;
  color: #000000;
  padding-left: 0;
  text-transform: uppercase;
}

.user-options .custom-search-form .custom-filters .locationDropdown {
  max-width: 216px;
}

.user-options .custom-search-form .custom-filters .distancesDropdown {
  max-width: 216px;
}

.user-options .custom-search-form .custom-filters .dateDropdown {
  max-width: 200px;
}

.user-options .custom-search-form .custom-filters .medalDropdown {
  max-width: 288px;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-form .custom-filters .locationDropdown,
  .user-options .custom-search-form .custom-filters .distancesDropdown,
  .user-options .custom-search-form .custom-filters .dateDropdown,
  .user-options .custom-search-form .custom-filters .medalDropdown {
    max-width: initial;
  }
}

.user-options .custom-search-utilities {
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .user-options .custom-search-utilities {
    flex-direction: column;
  }
}

.user-options .custom-search-utilities .user-selected-filters {
  display: flex;
}

.user-options .custom-search-utilities .user-selected-filters p {
  margin-bottom: 0;
  display: flex;
  word-wrap: none;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}

.user-options .custom-search-utilities .user-selected-filters .locationCrumb, .user-options .custom-search-utilities .user-selected-filters .distancesCrumb, .user-options .custom-search-utilities .user-selected-filters .dateCrumb {
  padding-left: 3px;
}

.user-options .custom-search-utilities .clear-all {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.user-options .custom-search-utilities .clear-all button {
  border: 0;
  -webkit-appearance: none;
  color: #CF3338;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
}

.user-options .custom-search-utilities .clear-all button:focus {
  outline: 0;
}

.race-count {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-top: 50px;
  display: flex;
}

.filter-menu-open .race-count {
  display: none;
}

@media only screen and (max-width: 1319px) {
  .race-count {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.race-count p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #999999;
  line-height: 1.58;
  flex: 1;
  margin-bottom: 0;
}

.race-count p.paginationView {
  text-align: right;
}

.no-results {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 0;
}

.no-results h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.53;
  color: #000000;
}

.no-results p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  color: #999999;
}

.no-results button {
  display: block;
  padding: 17px 47px;
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-size: 18px;
  font-weight: 700;
}

.no-results button:focus, .no-results button:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .no-results button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .no-results button {
    font-size: 14px;
  }
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .no-results {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.noResultsFound .paginationView, .noResultsFound .paginationButtons {
  display: none;
}

#eventPage #panelTwo {
  background-color: transparent;
}

.race-results {
  max-width: 100%;
  padding: 0;
}

@media only screen and (max-width: 1319px) {
  .race-results {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.race-results .column {
  padding-left: 0;
  padding-right: 0;
}

.race-results .pageElement {
  margin-top: 0;
  margin-bottom: 0;
}

.race-card {
  display: flex;
  background-color: #ffffff;
  min-height: 325px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .race-card {
    flex-direction: column;
  }
}

.race-card:hover {
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.race-card a {
  display: flex;
  background-size: cover;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #555;
}

@media only screen and (max-width: 767px) {
  .race-card a {
    padding: 100px 0;
  }
}

.race-card .race-image {
  max-height: 325px;
  max-width: 325px;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .race-card .race-image {
    max-width: 100%;
    max-height: fit-content;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1249px) {
  .race-card .race-image {
    max-width: 200px;
    max-height: 200px;
  }
}

.race-card .race-image img {
  transition: transform 0.3s ease-in-out;
  width: 65%;
  height: auto;
}

.race-card .race-image img.fallbackImage {
  background-image: url("../images/race-icons/default-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.race-card .race-image .race-date {
  background-color: #CF3338;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 97px;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-image .race-date {
    height: 94px;
    width: 81px;
  }
}

.race-card .race-image .race-date p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 400;
}

.race-card .race-image .race-date .race-month {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-image .race-date .race-month {
    font-size: 12px;
  }
}

.race-card .race-image .race-date .race-day {
  font-size: 28px;
  line-height: 28px;
  font-weight: 800;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-image .race-date .race-day {
    font-size: 36px;
  }
}

.race-card .race-image .race-date .race-year {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.race-card [style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

.race-card [style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  .race-card [style*="--aspect-ratio"] {
    position: relative;
  }
  .race-card [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  .race-card [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.race-card .race-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .race-card .race-info {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info {
    height: calc(100% - 62px);
  }
}

.race-card .race-info .race-info-details {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .race-info-details {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info .race-info-details {
    flex-wrap: nowrap;
    margin-top: 0;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 900px) {
  .race-card .race-info .race-info-details {
    flex-wrap: wrap;
  }
}

.race-card .race-info .details-left {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info .details-left {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .details-left {
    align-items: center;
    text-align: center;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.race-card .race-info .details-left p.race-presented-by,
.race-card .race-info .details-left p.race-title-sponsor {
  color: #999999;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info .details-left p.race-presented-by,
  .race-card .race-info .details-left p.race-title-sponsor {
    margin-top: 10px;
  }
}

.race-card .race-info .details-left .race-date {
  color: #CF3338;
  font-size: 24px;
  font-weight: 700;
}

.race-card .race-info .details-left .race-date span {
  padding-right: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info .details-left .race-date {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .details-left .race-date {
    font-size: 18px;
  }
}

.race-card .race-info .details-left .race-title-sponsor {
  font-size: 24px;
  line-height: 29px;
  font-weight: 800;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info .details-left .race-title-sponsor {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .details-left .race-title-sponsor {
    font-size: 18px;
  }
}

.race-card .race-info .details-left .race-presented-by {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .details-left .race-presented-by .dash {
    display: none;
  }
  .race-card .race-info .details-left .race-presented-by .presented-by {
    display: block;
  }
}

.race-card .race-info .details-left h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 42px;
  font-weight: 800;
  line-height: 36px;
  color: #000000;
  margin-bottom: 3px;
  max-width: 600px;
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .details-left h3 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info .details-left h3 {
    font-size: 24px;
  }
}

.race-card .race-info .details-left .race-location {
  font-weight: 700;
  color: #CF3338;
  text-transform: uppercase;
}

.race-card .race-info .race-details-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info .race-details-right {
    min-width: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .race-details-right {
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
}

.race-card .race-info .race-details-right p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #999999;
  line-height: 1.25;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .race-details-right p {
    text-align: left;
  }
}

.race-card .race-info .race-details-right p.soldOut {
  color: #CF3338;
}

.race-card .race-info .race-details-right a {
  display: block;
  padding: 17px 47px;
  background-color: #ffffff;
  -webkit-appearance: none;
  text-decoration: none;
  color: #FF595A;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  z-index: 1;
  border-radius: 31px;
  border: 2px solid #FF595A;
  transition: color 0.2s linear, background-color 0.2s linear;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
}

.race-card .race-info .race-details-right a:focus, .race-card .race-info .race-details-right a:hover {
  background-color: #FF595A;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .race-card .race-info .race-details-right a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info .race-details-right a {
    font-size: 14px;
  }
}

.race-card .race-info-icons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-self: flex-end;
  border-bottom: 1px solid #F7F7F7;
}

@media only screen and (max-width: 767px) {
  .race-card .race-info-icons {
    justify-content: center;
    border-bottom: none;
  }
}

.race-card .race-info-icons > div {
  display: flex;
  padding-bottom: 20px;
  padding-right: 40px;
}

.race-card .race-info-icons > div:last-of-type {
  padding-right: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info-icons > div {
    padding-right: 20px;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info-icons > div {
    padding-right: 20px;
    flex-direction: column;
    align-items: center;
  }
}

.race-card .race-info-icons > div img {
  margin-right: 10px;
  height: 100%;
  width: 100%;
  max-height: 40px;
  max-width: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info-icons > div img {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info-icons > div img {
    margin-right: 0;
  }
}

.race-card .race-info-icons > div p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin-bottom: 0;
  color: #666666;
  line-height: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info-icons > div p {
    font-size: 11px;
  }
  .page-manager-visible .race-card .race-info-icons > div p {
    font-size: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .race-card .race-info-icons > div p {
    align-items: center;
  }
}

.race-card .race-info-icons > div span {
  color: #000000;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 20px;
  font-weight: 800;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card .race-info-icons > div span {
    font-size: 13px;
  }
  .page-manager-visible .race-card .race-info-icons > div span {
    font-size: 11px;
  }
}

.race-card .race-details-list {
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .race-card .race-details-list {
    display: none;
  }
}

.race-card .race-details-list p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #000000;
  font-size: 14px;
  line-height: 28px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0;
}

.race-card .race-details-list span {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #CF3338;
  font-weight: 800;
  font-size: 14px;
  text-transform: initial;
  line-height: 17px;
  padding-right: 10px;
  padding-left: 10px;
}

.race-card .race-details-list .distancesArrow {
  padding-right: 7px;
  padding-left: 3px;
}

.race-card#worldChampionship {
  min-height: 440px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card#worldChampionship {
    min-height: 309px;
  }
}

.race-card#worldChampionship .race-image {
  max-width: 50%;
}

@media only screen and (max-width: 767px) {
  .race-card#worldChampionship .race-image {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card#worldChampionship .race-image {
    max-width: 50%;
    max-height: calc(100% - 62px);
  }
}

.race-card#worldChampionship .race-date {
  background-color: #DFE4E6;
}

.race-card#worldChampionship .race-date p {
  color: #000000;
}

.race-card#worldChampionship .race-info-details {
  flex-wrap: nowrap;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-card#worldChampionship .race-info-details {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .race-card#worldChampionship .race-info-details {
    flex-wrap: wrap;
  }
}

.race-card#worldChampionship .race-info-details .details-left {
  max-width: 310px;
}

.race-card#worldChampionship .race-info-icons > div {
  flex-basis: calc(100%/3);
}

.paginationButtons {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.paginationButtons button {
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.paginationButtons .nextPageButton, .paginationButtons .prePageButton {
  outline: 0;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #999999;
  display: flex;
  flex: 1;
  border: none;
  -webkit-appearance: none;
  transition: color 0.2s linear;
}

.paginationButtons .nextPageButton:hover, .paginationButtons .prePageButton:hover {
  color: #FF595A;
  cursor: pointer;
}

.paginationButtons .nextPageButton {
  justify-content: flex-end;
}

.paginationButtons .nextPageButton:after {
  content: '\f105';
  font-family: 'fontawesome';
  font-size: inherit;
  display: block;
  color: inherit;
  z-index: 1;
  position: relative;
  margin-left: 5px;
}

.paginationButtons .nextPageButton.hidden {
  opacity: 0.3;
  z-index: -1;
  position: relative;
}

.paginationButtons .prePageButton {
  justify-content: flex-start;
}

.paginationButtons .prePageButton:before {
  content: '\f104';
  font-family: 'fontawesome';
  font-size: inherit;
  display: block;
  color: inherit;
  z-index: 1;
  position: relative;
  margin-right: 5px;
}

.paginationButtons .prePageButton.hidden {
  opacity: 0.3;
  z-index: -1;
  position: relative;
}

.paginationButtons .pageButtons {
  display: flex;
  flex-wrap: wrap;
}

.paginationButtons .pageButtons div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #999999;
  margin-right: 12px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease-in-out, color 0.3s ease-in-out;
}

.paginationButtons .pageButtons div:hover, .paginationButtons .pageButtons div.selected {
  cursor: pointer;
  color: #CF3338;
  border-bottom: 2px solid #CF3338;
}

.mobileFilterButton {
  background: #ffffff;
  width: 80%;
  margin: 0 auto;
  min-width: 320px;
  border: 1px solid #EDF0F1;
  display: none;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .mobileFilterButton {
    display: flex;
  }
}

.mobileFilterButton p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  line-height: 2;
  color: #999999;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.mobileFilterButton p::before {
  content: '';
  display: inline-block;
  background-image: url("../images/race-page-filter-ui/filterIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.ironman .series-logo {
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: 0;
  right: -1px;
  background-image: url("../images/race-icons/IRONMANTag.svg");
  background-position: 0 10px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ironman .series-logo {
    height: 45px;
    width: 50px;
    background-position: 4px 4px;
  }
}

.ironman-half .series-logo {
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: 0;
  right: -1px;
  background-image: url("../images/race-icons/IRONMAN703Tag.svg");
  background-position: 0 10px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ironman-half .series-logo {
    height: 45px;
    width: 50px;
    background-position: 4px 4px;
  }
}

.short-course .series-logo, .triathlon-series .series-logo {
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: 0;
  right: -1px;
  background-image: url("../images/race-icons/shortCourse.svg");
  background-position: 0 10px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .short-course .series-logo, .triathlon-series .series-logo {
    height: 45px;
    width: 50px;
    background-position: 4px 4px;
  }
}

.rolling:before {
  background-image: url("../images/race-icons/hilly.svg");
}

.hilly:before {
  background-image: url("../images/race-icons/steep.svg");
}

.steep:before {
  background-image: url("../images/race-icons/steep.svg");
}

.flat:before {
  background-image: url("../images/race-icons/flat.svg");
}

.river:before {
  background-image: url("../images/race-icons/river.svg");
}

.lake:before {
  background-image: url("../images/race-icons/lake.svg");
}

.ocean:before {
  background-image: url("../images/race-icons/ocean.svg");
}

.reservoir:before {
  background-image: url("../images/race-icons/reservoir.svg");
}

.bay:before {
  background-image: url("../images/race-icons/bay.svg");
}

.waterTemp:before {
  background-image: url("../images/race-icons/water-temp.svg");
}

.airTemp:before {
  background-image: url("../images/race-icons/air-temp.svg");
}

.airport:before {
  background-image: url("../images/race-icons/airport.svg");
}

.user_mode .hero-video .heroPhotoElement {
  display: none;
}

.user_mode .hero-video .search-video .pageElement {
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-video .search-video {
    display: none;
  }
  .user_mode .hero-video .heroPhotoElement {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/**
 * Hero Slider Component
 */
.hero-slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_mode .hero-slider {
  padding-left: 0;
  padding-right: 0;
}

.user_mode .hero-slider .column {
  padding-left: 0;
  padding-right: 0;
}

.user_mode .hero-slider .column .hero-slider-text {
  display: none;
}

.user_mode .hero-slider .slick-slider {
  margin-bottom: 0;
}

.user_mode .hero-slider .slick-slider .slick-prev,
.user_mode .hero-slider .slick-slider .slick-next {
  height: 80px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
  z-index: 3;
  transition: height 0.2s linear, width 0.2s linear;
}

.user_mode .hero-slider .slick-slider .slick-prev {
  left: 30px !important;
}

@media only screen and (min-width: 1440px) {
  .has-main-nav .user_mode .hero-slider .slick-slider .slick-prev {
    margin-left: calc((100% - 1340px) / 2);
    left: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-slider .slick-slider .slick-prev {
    left: 10px !important;
  }
}

.user_mode .hero-slider .slick-slider .slick-prev:before {
  content: url(../images/slick-slider-ui/arrow-left-huge.png);
}

.user_mode .hero-slider .slick-slider .slick-next {
  right: 30px !important;
}

@media only screen and (min-width: 1440px) {
  .has-main-nav .user_mode .hero-slider .slick-slider .slick-next {
    margin-right: calc((100% - 1340px) / 2);
    right: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-slider .slick-slider .slick-next {
    right: 10px !important;
  }
}

.user_mode .hero-slider .slick-slider .slick-next:before {
  content: url(../images/slick-slider-ui/arrow-right-huge.png);
}

.user_mode .hero-slider .slick-slider .slick-slide {
  height: 534px;
  position: relative;
}

.user_mode .hero-slider .slick-slider .slick-slide:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(135deg, #000000 0%, black 100%);
  z-index: 1;
  opacity: 0.1;
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-slider .slick-slider .slick-slide {
    height: 125px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .user_mode .hero-slider .slick-slider .slick-slide {
    height: 256px;
  }
}

.user_mode .hero-slider .slick-slider .slick-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.user_mode .hero-slider .slick-slider .slick-slide .pageElement {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.user_mode .hero-slider .slick-slider .slick-slide .pageElement:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.hero-slider .heroSlidetext {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.hero-slider .heroSlidetext h1,
.hero-slider .heroSlidetext h2,
.hero-slider .heroSlidetext h3,
.hero-slider .heroSlidetext p {
  text-align: center !important;
  color: #ffffff;
  margin-bottom: 0;
}

.hero-slider .heroSlidetext h3 span {
  font-size: 16px;
  letter-spacing: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 600;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .hero-slider .heroSlidetext h3 span {
    font-size: 10px !important;
    letter-spacing: 6.25px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-slider .heroSlidetext h3 span {
    font-size: 14px !important;
    letter-spacing: 8.75px !important;
  }
}

@media only screen and (max-width: 1320px) {
  .hero-slider .heroSlidetext h3 span {
    font-size: 16px;
    letter-spacing: 9px;
  }
}

.hero-slider .heroSlidetext h1,
.hero-slider .heroSlidetext h1 span {
  font-size: 60px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
  line-height: 75px;
  max-width: calc(893.33333px);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .hero-slider .heroSlidetext h1,
  .hero-slider .heroSlidetext h1 span {
    font-size: 28px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-slider .heroSlidetext h1,
  .hero-slider .heroSlidetext h1 span {
    font-size: 42px !important;
  }
}

@media only screen and (max-width: 1320px) {
  .hero-slider .heroSlidetext h1,
  .hero-slider .heroSlidetext h1 span {
    font-size: 54px;
  }
}

.hero-slider .heroSlidetext p {
  font-size: 24px;
  line-height: 36px;
}

.hero-slider .slick-dots {
  display: flex !important;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .hero-slider .slick-dots {
    display: none !important;
  }
}

.hero-slider .slick-dots li {
  margin: 0 7px;
  margin-bottom: 83px;
  height: 15px;
  width: 15px;
}

.hero-slider .slick-dots li button {
  background: #ffffff;
  height: 15px;
  width: 15px;
  border-radius: 100%;
}

.hero-slider .slick-dots li button:before {
  content: none;
}

.hero-slider .slick-dots li.slick-active button {
  background: #CF3338;
}

body:not(.custom-search-page) .heroSlidetext h3 {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body:not(.custom-search-page) .heroSlidetext h3 {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  body:not(.custom-search-page) .heroSlidetext h3 {
    margin-bottom: 0;
  }
}

body:not(.custom-search-page) .heroSlidetext h3 span {
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: initial;
  text-transform: none;
}

@media only screen and (max-width: 767px) {
  body:not(.custom-search-page) .heroSlidetext h3 span {
    display: none;
  }
}

body:not(.custom-search-page) .heroSlidetext .text {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

body:not(.custom-search-page) .heroSlidetext .text h1 {
  font-size: 72px;
  line-height: 82px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body:not(.custom-search-page) .heroSlidetext .text h1 {
    font-size: 42px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  body:not(.custom-search-page) .heroSlidetext .text h1 {
    font-size: 20px;
    line-height: 1.1;
  }
}

body:not(.custom-search-page) .heroSlidetext .text h2 {
  font-size: 36px;
  line-height: 42px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body:not(.custom-search-page) .heroSlidetext .text h2 {
    font-size: 16px;
    line-height: 1.625;
  }
}

@media only screen and (max-width: 767px) {
  body:not(.custom-search-page) .heroSlidetext .text h2 {
    font-size: 12px;
    line-height: 1.25;
  }
}

body:not(.custom-search-page) .heroSlidetext .text h3 {
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body:not(.custom-search-page) .heroSlidetext .text h3 {
    font-size: 12px;
    line-height: 1.25;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  body:not(.custom-search-page) .heroSlidetext .text h3 {
    display: none;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

body:not(.custom-search-page) .heroSlidetext .text p {
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.55;
}

.user_mode body:not(.custom-search-page) .heroSlidetext .text p {
  display: none;
}

.user_mode body:not(.custom-search-page) .heroSlidetext .text .raceDate {
  display: none;
}

body:not(.custom-search-page) .race-date-container {
  position: absolute;
  bottom: 0;
  background-color: #CF3338;
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.55;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body:not(.custom-search-page) .race-date-container {
    font-size: 16px;
    line-height: 1.625;
  }
}

@media only screen and (max-width: 767px) {
  body:not(.custom-search-page) .race-date-container {
    font-size: 12px;
    line-height: 1.5;
  }
}

.edit_mode body:not(.custom-search-page) .race-date-container {
  display: none;
}

/**
 * Race Page Card Component
 */
.race-info-band,
.race-card-badge-images {
  display: none;
}

.race-page-card {
  position: relative;
  display: flex;
  margin-top: -43px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  max-width: 1340px;
  border-radius: 10px;
  background-color: #ffffff;
}

@media screen and (max-width: 1380px) {
  .race-page-card {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.race-page-card .has-mobile-nav {
  margin-top: 20px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .race-page-card {
    margin-top: 20px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1440px) {
  .logged_in .race-page-card {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.race-page-card .race-card-badge {
  border-radius: 10px;
  max-width: 295px;
  max-height: 265px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-badge {
    max-width: none;
    padding: 20px 0;
  }
}

.race-page-card .race-card-badge img {
  height: 206px;
  width: auto;
}

.race-page-card .race-card-content {
  width: 100%;
  height: 100%;
  min-height: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 46px;
  margin-right: 46px;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 66px;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor {
    text-align: center;
    margin-bottom: 24px;
  }
}

.race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-location,
.race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-presented-by {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-location,
  .race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-presented-by {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
}

.race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-date {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-date {
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 10px;
  }
}

.race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-location-and-event-sponsor {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #999999;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-location-and-event-sponsor {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-page-card .race-card-content .event-date-and-event-location-and-event-sponsor .event-location-and-event-sponsor {
    margin-bottom: 10px;
  }
}

.race-page-card .race-card-content .event-stat-icons {
  display: flex;
  align-items: flex-start;
}

.race-page-card .race-card-content .event-stat-icons .stat-group {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-stat-icons .stat-group {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.race-page-card .race-card-content .event-stat-icons .stat-group:last-of-type {
  margin-right: 0;
}

.race-page-card .race-card-content .event-stat-icons .stat-icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-stat-icons .stat-icon {
    height: 32px;
    width: 32px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .race-page-card .race-card-content .event-stat-icons .stat-icon {
    margin-bottom: 5px;
  }
}

.race-page-card .race-card-content .event-stat-icons .number-of-distances .stat-icon {
  background-image: url(../images/race-page-card-ui/map-icon.svg);
}

.race-page-card .race-card-content .event-stat-icons .avg-air-temps .stat-icon {
  background-image: url(../images/race-page-card-ui/air-temp.svg);
}

.race-page-card .race-card-content .event-stat-icons .airport .stat-icon {
  background-image: url(../images/race-page-card-ui/airport.svg);
}

.race-page-card .race-card-content .event-stat-icons .stat-content {
  display: inline-block;
}

.race-page-card .race-card-content .event-stat-icons .stat-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  color: #666666;
}

.race-page-card .race-card-content .event-stat-icons .stat {
  font-size: 21px;
  font-weight: 700;
  line-height: 21px;
  color: #000000;
}

.race-page-card .race-card-content .event-distance-crumbs {
  margin-top: 5px;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-distance-crumbs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-page-card .race-card-content .event-distance-crumbs {
    margin-top: 10px;
  }
}

.race-page-card .race-card-content .event-distance-crumbs .distance-crumb-title,
.race-page-card .race-card-content .event-distance-crumbs .distance-crumb {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-distance-crumbs .distance-crumb-title {
    margin-bottom: 15px;
  }
}

.race-page-card .race-card-content .event-distance-crumbs .distance-crumb {
  position: relative;
  color: #CF3338;
  margin-left: 8px;
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-distance-crumbs .distance-crumb {
    padding-bottom: 14px;
  }
}

.race-page-card .race-card-content .event-distance-crumbs .distance-crumb-arrow {
  display: inline-block;
  height: 11px;
  width: 6px;
  background-image: url(../images/race-page-card-ui/distances-arrow-right.png);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-content .event-distance-crumbs .distance-crumb-arrow {
    display: none;
  }
}

.race-page-card .race-card-register-link-and-language-toggle {
  text-align: center;
  width: 100%;
  max-width: 294px;
  border-left: 2px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .race-page-card .race-card-register-link-and-language-toggle {
    border-left: none;
    max-width: none;
    padding-top: 20px;
  }
}

.race-page-card .race-card-register-link-and-language-toggle .register-link {
  display: inline-block;
}

.race-page-card .race-card-register-link-and-language-toggle .register-link .pageElement {
  margin-bottom: 30px;
  margin-top: 0;
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  list-style: none;
  cursor: pointer;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .race-page-card .race-card-register-link-and-language-toggle .language-toggle {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .race-page-card .race-card-register-link-and-language-toggle .language-toggle {
    padding-top: 15px;
  }
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle span {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  height: 45px;
  width: 165px;
  border: 1px solid #666666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle span:after {
  content: "\f107";
  display: inline-block;
  font-family: "fontawesome";
  font-size: inherit;
  color: inherit;
  transition: transform 0.3s ease-in-out;
  margin-left: 20px;
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle ul {
  display: block;
  height: 0;
  opacity: 0;
  list-style: none;
  width: 0;
  max-height: 300px;
  max-width: 150px;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #F8F9F9;
  border: 1px solid #DFE4E6;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: -1;
  transition: height 0.6s ease-in, opacity 0.3s ease-in-out, width 0.6s ease-in;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .race-page-card .race-card-register-link-and-language-toggle .language-toggle ul {
    top: 100%;
    width: 100%;
    position: initial;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1180px) {
  .race-page-card .race-card-register-link-and-language-toggle .language-toggle ul {
    top: 100%;
  }
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle.active span:after {
  transform: rotate(180deg);
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle.active ul {
  display: block;
  opacity: 1;
  height: auto;
  width: 100%;
  min-width: 165px;
  z-index: 10;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .race-page-card .race-card-register-link-and-language-toggle .language-toggle.active ul {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle.active ul:hover {
  cursor: pointer;
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle.active ul li {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 2;
  color: #999999;
  transition: color 0.3s ease-in-out;
  text-indent: 0;
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle.active ul li:hover {
  color: #CF3338;
  cursor: pointer;
}

.race-page-card .race-card-register-link-and-language-toggle .language-toggle.active ul li.selected {
  color: #CF3338;
}

/**
 * Hide .container-background-image in .user_mode
 * rely on CSS class container-with-background-image created by _container_background_image.js javascript component
 *
 * Adds CSS class 'container-background-image'
 * 
 * @requires {_container_background_image.js}
 *
 * @example …
 *
 */
.user_mode .container-background-image {
  display: none;
}

.user_mode .container-with-background-image {
  background-size: cover;
  background-position: 0 100%;
}

.edit_mode .container-background-image img {
  width: auto;
  max-height: 150px;
}

/*
 * Left Rail Component
 * Copied from ironman theme
 */
.left-rail-layout.user_mode .layoutContainer.row.layout-100.hero-slider-text {
  display: none;
}

.left-rail-layout #panelTwo {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}

.left-rail-layout #panelTwo .leftRail {
  border-bottom: none;
  width: 100%;
  max-width: 322px;
  height: auto;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #ffffff;
  margin-right: 2px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .left-rail-layout #panelTwo .leftRail {
    border-bottom: 2px solid #EDF0F1;
    margin-right: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.left-rail-layout #panelTwo .leftRail .left-sub-nav {
  width: 100%;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .left-rail-layout #panelTwo .leftRail .left-sub-nav .column {
    display: none;
  }
}

.left-rail-layout #panelTwo .leftRail .layoutContainer,
.left-rail-layout #panelTwo .leftRail .column {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .left-rail-layout #panelTwo .leftRail .layoutContainer,
  .left-rail-layout #panelTwo .leftRail .column {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.left-rail-layout #panelTwo .yieldPageContent {
  background-color: #ffffff;
}

.left-rail-layout #errorNotice {
  display: none;
}

.left-rail-layout .left-rail-device-container {
  display: none;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .left-rail-layout .left-rail-device-container {
    background-color: white;
    display: block;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }
  .left-rail-layout .left-rail-device-container h3 {
    margin-top: 20px;
    text-align: center !important;
  }
}

.left-rail-layout .yieldPageContent {
  width: 100%;
}

.has-main-nav .user_mode.left-rail-layout .yieldPageContent {
  max-width: calc(100% - 324px);
  flex: 1 1 auto;
  padding-top: 30px;
}

.has-mobile-nav .user_mode.left-rail-layout .yieldPageContent {
  width: 100%;
  flex: 1 1 auto;
  padding-top: 15px;
}

/*
 * Left Sub Nav Component
 * Copied from ironman theme
 */
.left-sub-nav .leftNav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 38px;
}

.left-sub-nav .leftNav h3 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 14px;
  font-size: 16px;
  color: #000000;
  padding-top: 50px;
  padding-bottom: 20px;
}

.left-sub-nav .leftNav h3.has-mobile-nav {
  padding-top: 25px;
}

.left-sub-nav .leftNav h3.parentPage a {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
  line-height: 14px;
  font-size: 16px;
  color: #000000;
  padding: 0;
  margin-left: 0;
  transition: color 0.3s ease-in-out;
}

.left-sub-nav .leftNav h3.parentPage a:after {
  bottom: -4px;
}

.left-sub-nav .leftNav h3.parentPage a:hover {
  color: #FF595A;
}

.left-sub-nav .leftNav h3.parentPage a:hover:after {
  width: calc(100%);
  left: 0px;
}

.left-sub-nav .leftNav a {
  position: relative;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: #666666;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 20px;
  width: fit-content;
  transition: padding-left 0.2s linear, color 0.2s linear;
}

.left-sub-nav .leftNav a:after {
  content: "";
  width: 0%;
  height: 2px;
  background: transparent;
  bottom: 8px;
  position: absolute;
  left: 0px;
  transition: background 0.2s linear, width 0.2s linear, left 0.2s linear;
}

.left-sub-nav .leftNav a:hover {
  color: #FF595A;
  padding-left: 10px;
}

.left-sub-nav .leftNav a:hover:after {
  background-color: #CF3338;
  width: calc(100% - 30px);
  left: 10px;
}

.left-sub-nav .leftNav a.true {
  color: #FF595A;
  padding-left: 10px;
}

.left-sub-nav .leftNav a.true:after {
  left: 10px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .left-sub-nav .leftNav {
    justify-content: center;
    padding-bottom: 15px;
  }
  .left-sub-nav .leftNav h3, .left-sub-nav .leftNav a {
    align-self: center;
  }
  .left-sub-nav .leftNav a {
    margin-left: 20px;
  }
}

.left-sub-nav .pageElement > h3:first-child {
  font-size: 24px;
}

.left-sub-nav .adElement,
.left-sub-nav .heroPhotoElement {
  margin-bottom: 50px;
}

/**
 * Custom Utility Classes to be used on page elements and enable additional theme functionality
 */
.no-margin {
  margin: 0;
}

.no-margin .pageElement {
  margin: 0;
}

.user_mode .subtitle-color-white .pageElement > h3:first-child {
  color: #ffffff;
}

.user_mode .text-color-white .pageElement > h3:first-child {
  color: #ffffff;
}

.user_mode .text-color-white.layoutContainer h1,
.user_mode .text-color-white.layoutContainer h2,
.user_mode .text-color-white.layoutContainer h3,
.user_mode .text-color-white.layoutContainer h4,
.user_mode .text-color-white.layoutContainer h5,
.user_mode .text-color-white.layoutContainer h6,
.user_mode .text-color-white.layoutContainer p,
.user_mode .text-color-white.layoutContainer .pageEl > :not(.linkElement) a:not([name="button"]) {
  color: #ffffff;
}

.no-padding {
  padding: 0px;
}

.no-vertical-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-top-padding {
  padding-top: 0px;
}

.no-bottom-padding {
  padding-bottom: 0px;
}

.extra-vertical-padding {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .extra-vertical-padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.extra-top-padding {
  padding-top: 70px;
}

.extra-bottom-padding {
  padding-bottom: 70px;
}

.justify-left .documentElement h3 {
  justify-content: flex-start;
}

.justify-left .linkElement h4 {
  text-align: left;
}

.justify-center .documentElement h3 {
  justify-content: flex-end;
}

.justify-center .linkElement h4 {
  text-align: center;
}

.justify-right .documentElement h3 {
  justify-content: flex-end;
}

.justify-right .linkElement h4 {
  text-align: right;
}

.inline-width .linkElement a {
  display: inline-flex;
}

/**
 * Sliders In Tab Element Component
 */
.user_mode .sliders-in-tab-element .contentTabs:not(.rapidTabsConstruction) {
  flex-flow: column;
}

@media only screen and (min-width: 768px) {
  .user_mode .sliders-in-tab-element .contentTabs:not(.rapidTabsConstruction) {
    flex-flow: row;
  }
}

.user_mode .sliders-in-tab-element .cutline {
  font-size: 16px;
  font-style: normal;
  text-transform: uppercase;
  color: #000000;
  background-color: transparent;
  text-align: center;
  padding: 0;
  line-height: 28px;
}

.user_mode .sliders-in-tab-element .tabContainer .layoutContainer .column {
  overflow: hidden;
  padding-top: 5px;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 767px) {
  .user_mode .sliders-in-tab-element .tabContainer .layoutContainer .column {
    padding-left: 0;
    padding-right: 0;
  }
}

.user_mode .sliders-in-tab-element .tabContainer .layoutContainer .column:not(.slick-slider) {
  display: flex;
  justify-content: center;
}

.user_mode .sliders-in-tab-element .tabContainer .layoutContainer .column .pageEl {
  width: 240px;
}

.user_mode .sliders-in-tab-element .tabContainer > div {
  margin: auto;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-list {
  width: 100%;
  margin-left: 1px;
  margin-right: 1px;
  overflow: hidden;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-prev,
.user_mode .sliders-in-tab-element .slick-slider .slick-next {
  height: 100%;
  width: 45px;
  background-color: white;
  z-index: 3;
  transition: height 0.2s linear, width 0.2s linear;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-prev:before,
.user_mode .sliders-in-tab-element .slick-slider .slick-next:before {
  font-family: initial;
  font-size: initial;
  line-height: initial;
  color: initial;
  opacity: initial;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .user_mode .sliders-in-tab-element .slick-slider .slick-prev:before,
  .user_mode .sliders-in-tab-element .slick-slider .slick-next:before {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .user_mode .sliders-in-tab-element .slick-slider .slick-prev:before,
  .user_mode .sliders-in-tab-element .slick-slider .slick-next:before {
    font-size: 10px;
  }
}

.user_mode .sliders-in-tab-element .slick-slider .slick-prev {
  left: 0;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-prev:before {
  content: url(../images/slick-slider-ui/arrow-left-tab-slider.png);
  opacity: .6;
  transition: opacity 0.2s linear;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-prev:hover:before {
  opacity: 1;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-next {
  right: 0;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-next:before {
  content: url(../images/slick-slider-ui/arrow-right-tab-slider.png);
  opacity: .6;
  transition: opacity 0.2s linear;
}

.user_mode .sliders-in-tab-element .slick-slider .slick-next:hover:before {
  opacity: 1;
}

.edit_mode .sliders-in-tab-element .heroPhotoElement img {
  max-width: 150px;
}

/**
 * General Race Page Styles
 */
@media only screen and (min-width: 0) and (max-width: 1024px) {
  .leftNav {
    display: none;
    height: 0;
    opacity: 0;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: none;
    transition: height 0.3s ease-in-out, opacity 0.2s linear;
  }
  .leftNav.active {
    display: flex;
    height: 100%;
    opacity: 1;
  }
}

.race-details-toggle {
  display: none;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .race-details-toggle {
    display: flex;
  }
}

.race-details-toggle span {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
}

.race-details-toggle span:after {
  content: '\f107';
  display: inline-block;
  font-family: 'fontawesome';
  font-size: inherit;
  color: #CF3338;
  transition: transform 0.3s ease-in-out;
  margin-left: 10px;
}

.race-details-toggle.open span:after {
  transform: rotate(180deg);
}

.race-page.user_mode .layoutContainer {
  padding-left: 30px;
  padding-right: 30px;
}

.race-page.user_mode .layoutContainer .layoutContainer {
  padding-right: 0;
  padding-left: 0;
}

.race-page.user_mode .race-page-top .layoutContainer {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.race-page.user_mode .layoutContainer[class*="lng-"] {
  display: none;
}

.race-page.user_mode .layoutContainer[class*="lng-"].active-language {
  display: flex;
}

/**
 * Race Page News Aggregator Styles
 */
.race-page .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded {
  min-height: 215px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-page .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader {
    flex-direction: column;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-page .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader > a {
    position: absolute;
    max-width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-page .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader .newsAuthor {
    align-items: flex-start !important;
    height: min-content;
    margin-left: 330px;
  }
}

@media only screen and (max-width: 767px) {
  .race-page .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader .newsAuthor {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-page .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemHeader > h2 a {
    margin-left: 330px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .race-page .pageEl:not(.news-card-slider) .newsAggregatorElement .expanded .newsItemElement > h4 {
    margin-left: 330px;
  }
}

.edit_mode.race-page .layoutContainer[class*="lng-"]:before {
  display: flex;
  max-width: 100%;
  justify-content: flex-start;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  background-color: #C3C3C3;
  border: dashed 1px solid #DDA301;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  text-transform: uppercase;
}

.edit_mode.race-page .layoutContainer.lng-filipino:before {
  content: 'Filipino';
}

.edit_mode.race-page .layoutContainer.lng-euskera:before {
  content: 'Basque (Euskera)';
}

.edit_mode.race-page .layoutContainer.lng-catalan:before {
  content: 'Català (Catalan)';
}

.edit_mode.race-page .layoutContainer.lng-中文chinese:before {
  content: 'Chinese';
}

.edit_mode.race-page .layoutContainer.lng-danish:before {
  content: 'Danish';
}

.edit_mode.race-page .layoutContainer.lng-deutsch:before {
  content: 'Deutsch';
}

.edit_mode.race-page .layoutContainer.lng-estonian:before {
  content: 'Eesti (Estonian)';
}

.edit_mode.race-page .layoutContainer.lng-english:before {
  content: 'English';
}

.edit_mode.race-page .layoutContainer.lng-español:before {
  content: 'Español';
}

.edit_mode.race-page .layoutContainer.lng-français:before {
  content: 'Français';
}

.edit_mode.race-page .layoutContainer.lng-german:before {
  content: 'German';
}

.edit_mode.race-page .layoutContainer.lng-italiano:before {
  content: 'Italiano ';
}

.edit_mode.race-page .layoutContainer.lng-japanese:before {
  content: '日本語 (Japanese)';
}

.edit_mode.race-page .layoutContainer.lng-korean:before {
  content: '한국어 (Korean)';
}

.edit_mode.race-page .layoutContainer.lng-portuguese:before {
  content: 'Portuguese';
}

.edit_mode.race-page .layoutContainer.lng-russian:before {
  content: 'русский (Russian)';
}

.edit_mode.race-page .layoutContainer.lng-slovenian:before {
  content: 'Slovenian';
}

.edit_mode.race-page .layoutContainer.lng-swedish:before {
  content: 'Svenska (Swedish)';
}

.edit_mode.race-page .layoutContainer.lng-tawainesemandarin:before {
  content: '國語 (Tawainese Mandarin)';
}

.edit_mode.race-page .layoutContainer.lng-thai:before {
  content: 'Thai';
}

.edit_mode.race-page .layoutContainer.lng-vietnamese:before {
  content: 'Tiếng Việt (Vietnamese)';
}

.edit_mode.race-page .layoutContainer.lng-croatian:before {
  content: 'Croatian';
}

.edit_mode.race-page .layoutContainer.lng-dutch:before {
  content: 'Dutch';
}

/**
 * Custom Accordion SCSS, copied from Ironman codebase
 * @requires {_custom_accordion.js}
 */
.accordion {
  position: relative;
  min-height: 0;
  overflow: hidden;
  width: 100%;
  box-sizing: content-box;
  border-top: 1px solid #cdcdcd;
}

.accordion:last-of-type {
  border-bottom: 1px solid #cdcdcd;
}

.accordion:hover h3 span {
  color: #CF3338;
}

.accordion .pageElement {
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.accordion .text {
  color: #192D1A;
  display: none;
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 1px;
  overflow: hidden;
  position: relative;
  height: auto;
  box-sizing: content-box;
}

.accordion .text p {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.accordion h3 {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #ffffff;
}

.accordion h3:hover {
  cursor: pointer;
}

.accordion h3:after {
  content: url(../images/accordion-ui/accordion-arrow-down.png);
  transition: transform 0.3s ease-in-out;
  line-height: 0;
  font-size: 0;
  height: 24px;
  width: 24px;
  right: 20px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 5px;
}

.accordion h3.open:after {
  transform: rotate(180deg);
}

.accordion h3.open span {
  color: #CF3338;
}

.accordion h3 span {
  display: inline-block;
  align-items: center;
  color: #000000;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: none;
  flex: 1 1 auto;
  transition: color 0.2s linear;
}

.user_mode .accordion-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.user_mode .accordion-container .accordion-container-title {
  display: none;
}

.accordion-container .accordion-header {
  display: flex;
  align-items: center;
  margin-bottom: 1px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #ffffff;
}

.accordion-container .accordion-header:hover {
  cursor: pointer;
}

.accordion-container .accordion-header:after {
  content: url(../images/accordion-ui/accordion-arrow-down.png);
  transition: transform 0.3s ease-in-out;
  line-height: 0;
  font-size: 0;
  height: 24px;
  width: 24px;
  right: 20px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 5px;
}

.accordion-container .accordion-header.open:after {
  transform: rotate(180deg);
}

.accordion-container .accordion-header span {
  display: inline-block;
  align-items: center;
  color: #000000;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.6;
  text-transform: none;
  flex: 1 1 auto;
}

/**
 * Copied from Ironman theme
 * Adds sponsor-row CSS class to theme
 */
.sponsor-50-50 .column {
  padding-left: 0;
  padding-right: 0;
}

.sponsor-50-50 .pageElement.textBlockElement {
  margin-bottom: 5px;
}

.sponsor-50-50 .pageElement.textBlockElement > h3:first-child {
  font-size: 22px;
  line-height: 1.6;
}

.sponsor-50-50 .heroPhotoElement {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 133px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-left: 10px;
  transition: filter 0.2s linear;
  filter: grayscale(100%);
  position: relative;
  z-index: 10;
}

.sponsor-50-50 .heroPhotoElement:hover {
  filter: grayscale(0);
  z-index: 100;
}

.sponsor-50-50 .heroPhotoElement a {
  overflow: hidden;
  /* needed for ie 11 */
}

.sponsor-50-50 .heroPhotoElement img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.sponsor-50-50 .heroPhotoElement .cutline {
  display: none;
}

@media only screen and (min-width: 768px) {
  .sponsor-50-50 .column-1 {
    width: 50%;
    padding-right: 15px;
  }
  .sponsor-50-50 .column-2 {
    padding-left: 15px;
  }
}

.sponsor-row {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sponsor-row .column-1 {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.sponsor-row .column-1 .pageEl {
  flex-basis: 50%;
}

@media only screen and (min-width: 768px) {
  .sponsor-row .column-1 .pageEl {
    flex-basis: 33.3333%;
  }
}

@media only screen and (min-width: 1024px) {
  .sponsor-row .column-1 .pageEl {
    flex-basis: 25%;
  }
}

@media only screen and (min-width: 1249px) {
  .sponsor-row .column-1 .pageEl {
    flex-basis: 20%;
  }
}

.sponsor-row .column-1 .pageEl:first-of-type {
  flex-basis: 100%;
}

.sponsor-row .column-1 .pageEl:first-of-type .pageElement.textBlockElement {
  margin-bottom: 5px;
}

.sponsor-row .column-1 .pageEl:first-of-type .pageElement.textBlockElement > h3:first-child {
  font-size: 22px;
  line-height: 1.6;
}

.sponsor-row .column-1 .pageEl .heroPhotoElement {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 1px;
  transition: filter 0.2s linear, opacity 0.2s linear;
  filter: grayscale(100%);
  opacity: 0.6;
  position: relative;
  z-index: 10;
  height: 115px;
}

@media only screen and (min-width: 768px) {
  .sponsor-row .column-1 .pageEl .heroPhotoElement {
    height: 155px;
  }
}

.sponsor-row .column-1 .pageEl .heroPhotoElement:hover {
  filter: grayscale(0);
  opacity: 1;
  z-index: 100;
}

.sponsor-row .column-1 .pageEl .heroPhotoElement img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.sponsor-row .column-1 .pageEl .heroPhotoElement .cutline {
  display: none;
}

.sponsor-row .column-1 .pageEl .heroPhotoElement a {
  height: 100%;
}

/**
 * Quick Link SCSS Component
 * @description - Adds CSS class 'quick-link' to available user facing theme classes
 */
.quick-link:first-of-type .pageElement {
  margin-top: 10px;
  margin-bottom: 1px;
}

@media screen and (max-width: 767px) {
  .quick-link:first-of-type .pageElement {
    margin-top: 10px;
  }
}

.quick-link + .quick-link .pageElement {
  margin-top: 1px;
  margin-bottom: 1px;
}

.quick-link:last-of-type .pageElement {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .quick-link:last-of-type .pageElement {
    margin-bottom: 10px;
  }
}

.quick-link .linkElement h4 a {
  position: relative;
  display: block;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: left;
  border: none;
  border-radius: unset;
  padding-top: 14px;
  padding-right: 15px;
  padding-bottom: 13px;
  padding-left: 15px;
  background-color: #0093c8;
  transition: background-color 0.2s linear;
}

.quick-link .linkElement h4 a:focus, .quick-link .linkElement h4 a:hover {
  background-color: #007ba8;
}

.quick-link .linkElement h4 a:after {
  position: absolute;
  content: url(../images/quick-link-ui/quick-link-arrow-right.png);
  right: 15px;
  top: 14px;
}

/**
 * Race Event Card Container SCSS Component
 * @description Adds 'race-event-card-container' CSS class to available front end theme classes
 * @requires {_race_event_card_container.js}
 * @requires {Site Builder Configuration} - CSS class should be applied to layout container, LC is intended to house one text block element and one link element per column. Text Block Element is expected to use H4 and P tags for title and description information.
 */
.user_mode .race-event-card-container {
  display: flex;
  gap: 20px;
}

.user_mode .race-event-card-container .column {
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding-top: 12px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-bottom: 0;
}

.user_mode .race-event-card-container .column:empty {
  border: none;
}

@media only screen and (max-width: 767px) {
  .user_mode .race-event-card-container .column {
    margin-bottom: 20px;
  }
}

.user_mode .race-event-card-container h4 {
  margin-bottom: 0px;
}

/**
 * CSS styles for News Article Pages
 * @description - CSS styles for News Article Pages
 * CSS is used to set order of article header content
 * @requires {_news_article_page.js} - JS file manipulates DOM to resize article image
 */
.newsPage .site-background {
  background-image: url("../images/news-article-page-ui/map-background-large.jpg") !important;
}

.newsPage .siteContainer {
  padding-bottom: 0;
}

.newsPage .siteContainer #panelTwo {
  margin-top: 0;
}

.newsPage .siteContainer .yieldPageContent {
  padding-top: 60px;
}

.newsPage .newsItemColumn .newsItemElement {
  display: flex;
  flex-wrap: wrap;
}

.newsPage .newsItemColumn .newsItemElement .newsArticleThumbnail,
.newsPage .newsItemColumn .newsItemElement .titleText {
  width: 100%;
}

.newsPage .newsItemColumn .newsItemElement .authorDetails,
.newsPage .newsItemColumn .newsItemElement .sn-share-icons {
  display: flex;
  flex-grow: 1;
  align-items: center;
  order: 1;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #F7F7F7;
}

.newsPage .newsItemColumn .newsItemElement .sn-share-icons {
  justify-content: flex-end;
}

.newsPage .newsItemColumn .newsArticleThumbnail {
  height: 100%;
  border: none;
  float: none;
  margin: 0;
  padding-bottom: 60px;
}

.newsPage .newsItemColumn .titleText {
  font-size: 42px;
  line-height: 42px;
  font-weight: 400;
  color: #353E43;
}

.newsPage .newsItemColumn .teaserText {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #475157;
}

.newsPage .newsItemColumn .authorDetails {
  font-size: 0;
  line-height: 0;
}

.newsPage .newsItemColumn .newsAuthor {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.newsPage .newsItemColumn .newsAuthor:before {
  content: "";
  background-image: url(../images/news-article-page-ui/user-circle-solid.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 14px;
  font-size: 12px;
  margin-right: 1ch;
  display: inline-block;
}

.newsPage .newsItemColumn .sn-share-icons {
  font-size: 12px;
  line-height: 12px;
  color: #A7B3BA;
}

.newsPage .newsItemColumn .newsDate,
.newsPage .newsItemColumn hr,
.newsPage .newsItemColumn .previewText {
  display: none;
}

.newsPage .newsContentNode + .column {
  padding-top: 60px;
  padding-bottom: 105px;
}

.newsPage .newsContentNode + .column .newsTags {
  font-size: 12px;
  font-weight: 300;
  color: #A7B3BA;
}

.newsPage .newsContentNode + .column .newsTags a {
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #DFE4E6;
  color: #2F373B;
  transition: color 0.2s linear, background-color 0.2s linear;
}

.newsPage .newsContentNode + .column .newsTags a:hover {
  color: #ffffff;
  background-color: #FF595A;
}

.newsPage .newsContentNode + .column .bottomOptions {
  display: flex;
  color: #A7B3BA;
}

@media screen and (max-width: 767px) {
  .newsPage .newsContentNode + .column .bottomOptions {
    flex-direction: column;
  }
}

.newsPage .newsContentNode + .column .bottomOptions .sharingOptions {
  width: 50%;
  border-top: 0;
  text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .newsPage .newsContentNode + .column .bottomOptions .sharingOptions {
    width: 100%;
    text-align: center;
  }
}

.newsPage .newsContentNode + .column .bottomOptions .pageOptions {
  width: 50%;
  align-content: center;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .newsPage .newsContentNode + .column .bottomOptions .pageOptions {
    width: 100%;
    justify-content: center;
  }
}

.newsPage .newsContentNode + .column .bottomOptions .pageOptions a {
  color: #A7B3BA;
}

/**
 * Sponsor Slider Component CSS
 * @requires {SiteBuilder Configuration} - layout container needs to have the CSS class 'sponsor-slider' 
 * @see {_sponsor_slider.js}
 */
.sponsor-slider {
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.sponsor-slider {
  background: #fff;
  padding: 93px 100px;
}

.sponsor-slider .slick-slider .slick-prev,
.sponsor-slider .slick-slider .slick-next {
  height: 50px;
  width: 20px;
  background-color: transparent;
  z-index: 1;
}

.sponsor-slider .slick-slider .slick-prev:before,
.sponsor-slider .slick-slider .slick-next:before {
  color: #ddd;
  font-size: 36px;
  font-weight: 400;
  transition: color 0.2s linear;
}

.sponsor-slider .slick-slider .slick-prev:hover:before,
.sponsor-slider .slick-slider .slick-next:hover:before {
  color: #CF3338;
}

.sponsor-slider .slick-slider .slick-prev {
  left: -20px;
}

.sponsor-slider .slick-slider .slick-prev:before {
  content: url(../images/slick-slider-ui/arrow_left_gray.png);
  opacity: .6;
  transition: opacity 0.2s linear;
}

.sponsor-slider .slick-slider .slick-prev:hover:before {
  opacity: 1;
}

.sponsor-slider .slick-slider .slick-next {
  right: -20px;
}

.sponsor-slider .slick-slider .slick-next:before {
  content: url(../images/slick-slider-ui/arrow_right_gray.png);
  opacity: .6;
  transition: opacity 0.2s linear;
}

.sponsor-slider .slick-slider .slick-next:hover:before {
  opacity: 1;
}

.sponsor-slider .slick-slider .slick-slide {
  margin-left: 30px;
  margin-right: 30px;
}

.sponsor-slider .slick-slider .slick-slide .heroPhotoElement img {
  max-height: 75px;
  max-width: 100%;
  width: auto;
  filter: grayscale(1) opacity(0.5);
  transition: filter 0.2s linear, transform 100ms linear;
}

.sponsor-slider .slick-slider .slick-slide:hover img {
  filter: grayscale(0) opacity(1);
}

.sponsor-slider .slick-slider .slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.sponsor-slider .column {
  padding: 0;
}

.edit_mode .sponsor-slider .heroPhotoElement img {
  max-height: 75px;
  width: auto;
}

.has-mobile-nav .sponsor-slider img {
  max-height: 75px;
  width: auto;
}

@media only screen and (max-width: 768px) {
  .sponsor-slider {
    padding: 50px 20px;
  }
  .sponsor-slider .slick-slider .slick-prev {
    left: 30px;
  }
  .sponsor-slider .slick-slider .slick-next {
    right: 30px;
  }
}

/**
 * Column Gradient Container Component
 * @requires {Layout Container} - expected to be used on layout container to define padding and gradient background style
 */
.column-gradient-container:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: calc(100% - (100% - 1340px));
  background: linear-gradient(0deg, transparent, #dddddd);
  border-radius: 10px 10px 0px 0px;
}

.column-gradient-container .column:first-of-type {
  padding-left: 35px;
  padding-right: 15px;
}

.column-gradient-container .column {
  padding-left: 15px;
  padding-right: 15px;
}

.column-gradient-container .column:last-of-type {
  padding-left: 15px;
  padding-right: 35px;
}

.column-gradient-container .pageElement {
  margin-top: 30px;
}

.column-gradient-container .videoElement {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .column-gradient-container .column:first-of-type,
  .column-gradient-container .column,
  .column-gradient-container .column:last-of-type {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 1380px) {
  .user_mode .column-gradient-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .user_mode .column-gradient-container:before {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 1440px) {
  .logged_in.user_mode .column-gradient-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .logged_in.user_mode .column-gradient-container:before {
    width: calc(100% - 40px);
  }
}

.logged_in.edit_mode .column-gradient-container .column:first-of-type,
.logged_in.edit_mode .column-gradient-container .column,
.logged_in.edit_mode .column-gradient-container .column:last-of-type {
  padding-left: 0px;
  padding-right: 0px;
}

/**
 * News Card Slider Component
 */
.news-card-slider .slick-slider {
  padding-left: 30px;
  padding-right: 30px;
}

.news-card-slider .slick-slider .slick-prev,
.news-card-slider .slick-slider .slick-next {
  height: 42px;
  width: 20px;
  background-color: transparent;
  z-index: 3;
}

.news-card-slider .slick-slider .slick-prev:before,
.news-card-slider .slick-slider .slick-next:before {
  font-family: initial;
  font-size: initial;
  line-height: initial;
  color: initial;
  opacity: initial;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .news-card-slider .slick-slider .slick-prev,
  .news-card-slider .slick-slider .slick-next {
    height: 42px;
    width: 20px;
  }
  .news-card-slider .slick-slider .slick-prev:before,
  .news-card-slider .slick-slider .slick-next:before {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .news-card-slider .slick-slider .slick-prev,
  .news-card-slider .slick-slider .slick-next {
    height: 42px;
    width: 20px;
  }
  .news-card-slider .slick-slider .slick-prev:before,
  .news-card-slider .slick-slider .slick-next:before {
    font-size: 10px;
  }
}

.news-card-slider .slick-slider .slick-prev {
  left: 0px;
}

.news-card-slider .slick-slider .slick-prev:before {
  content: url(../images/slick-slider-ui/arrow_left_white.png);
  transition: opacity 0.2s linear;
  opacity: .6;
}

.news-card-slider .slick-slider .slick-prev:hover:before {
  opacity: 1;
}

.news-card-slider .slick-slider .slick-next {
  right: 0px;
}

.news-card-slider .slick-slider .slick-next:before {
  content: url(../images/slick-slider-ui/arrow_right_white.png);
  transition: opacity 0.2s linear;
  opacity: .6;
}

.news-card-slider .slick-slider .slick-next:hover:before {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .news-card-slider .newsAggregatorElement .item {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .news-card-slider .newsAggregatorElement .item {
    max-width: unset;
  }
}

/**
 * Image With Text Container & Text With Image Container Component
 * @requires expected to be used with page element applying background image as solid color
 * @requires _image_with_text_container.js
 */
.image-with-text-container,
.text-with-image-container {
  display: flex;
}

.user_mode .image-with-text-container, .user_mode
.text-with-image-container {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.user_mode .image-with-text-container.container-with-background-image, .user_mode
.text-with-image-container.container-with-background-image {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.user_mode .image-with-text-container.container-with-background-image .column:not(.column-content-container), .user_mode
.text-with-image-container.container-with-background-image .column:not(.column-content-container) {
  padding: 0;
}

.user_mode .image-with-text-container.container-with-background-image .column:not(.column-content-container) .pageElement, .user_mode
.text-with-image-container.container-with-background-image .column:not(.column-content-container) .pageElement {
  margin-top: 0;
  margin-bottom: 0;
}

.user_mode .image-with-text-container .column-content-container, .user_mode
.text-with-image-container .column-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  height: auto;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .user_mode .image-with-text-container .column-content-container, .user_mode
  .text-with-image-container .column-content-container {
    height: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  .image-with-text-container,
  .text-with-image-container {
    flex-direction: column;
  }
  .image-with-text-container .column,
  .text-with-image-container .column {
    width: 100%;
  }
}

.side-by-side-link .linkWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: calc(100% - 60px);
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .side-by-side-link .linkWrapper {
    justify-content: center;
    width: 100%;
  }
}

/**
 * Text Block Call To Action Component
 */
.text-block-call-to-action .textBlockElement {
  position: relative;
}

.text-block-call-to-action [class$="TextImage"] {
  display: flex;
  flex-flow: column;
  float: none;
  margin: 0;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.text-block-call-to-action [class$="TextImage"] img {
  position: relative;
  width: 100%;
  border-radius: 10px;
  transition: transform 0.2s linear;
  z-index: 0;
}

.text-block-call-to-action [class$="TextImage"] a {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.text-block-call-to-action [class$="TextImage"] a:before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: .2;
  background-color: #000000;
  transition: opacity 0.2s linear;
  z-index: 10;
}

.text-block-call-to-action [class$="TextImage"] a .pageElement > h3:first-child {
  margin-bottom: 0;
  color: #ffffff;
  position: absolute;
  font-size: 24px;
  bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 1;
  width: 100%;
}

.text-block-call-to-action [class$="TextImage"] a .content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  padding-left: 30px;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.text-block-call-to-action [class$="TextImage"] a .content-wrapper > h3 {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1;
  transition: color 0.2s linear, margin 0.2s linear;
}

.text-block-call-to-action [class$="TextImage"] a .content-wrapper > h3:after {
  content: "";
  height: 2px;
  background-color: #CF3338;
  width: 100%;
  display: block;
  margin-top: 5px;
  opacity: 0;
  transition: opacity 0.2s linear, margin 0.2s linear;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .text-block-call-to-action [class$="TextImage"] a .content-wrapper > h3 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text {
  width: 100%;
}

.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text h1,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text h2,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text h3,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text h4,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text h5,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text h6,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text p,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper .text a {
  opacity: 1;
}

.text-block-call-to-action [class$="TextImage"] a .content-wrapper h1,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper h2,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper h3,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper h4,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper h5,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper h6,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper p,
.text-block-call-to-action [class$="TextImage"] a .content-wrapper a {
  color: #ffffff;
}

.text-block-call-to-action [class$="TextImage"] a:hover:before {
  opacity: .5;
}

.text-block-call-to-action [class$="TextImage"] a:hover img {
  transform: scale(1.02);
}

.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper > h3 {
  color: #CF3338;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper > h3 {
    margin-bottom: 20px;
  }
}

.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper > h3:after {
  opacity: 1;
  margin-top: 10px;
}

.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper .text h1,
.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper h2,
.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper h3,
.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper h4,
.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper h5,
.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper h6,
.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper p,
.text-block-call-to-action [class$="TextImage"] a:hover .content-wrapper a {
  opacity: 1;
}

.text-block-call-to-action [class$="TextImage"]:hover {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
}

/**
 * Four Column Container Component
 */
.edit_mode .four-column-container .columnBounds {
  display: flex;
  flex-wrap: wrap;
}

.edit_mode .four-column-container .columnBounds .addPageElementTop {
  width: 100%;
}

.edit_mode .four-column-container .columnBounds .drag {
  width: 25%;
}

@media screen and (max-width: 1024px) {
  .edit_mode .four-column-container .columnBounds .drag {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .edit_mode .four-column-container .columnBounds .drag {
    width: 100%;
  }
}

.user_mode .four-column-container .column {
  display: flex;
  flex-wrap: wrap;
}

.user_mode .four-column-container .column .pageEl {
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 1024px) {
  .user_mode .four-column-container .column .pageEl {
    width: 50%;
  }
  .user_mode .four-column-container .column .pageEl.text-block-call-to-action {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .user_mode .four-column-container .column .pageEl {
    width: 100%;
  }
}

.user_mode .featured-events-container {
  background-color: #141414;
}

.user_mode .featured-events-container .featured-events-cards {
  max-width: 1340px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.user_mode .featured-events-container .featured-events-cards .event-card {
  transition: transform 0.2s linear;
}

.user_mode .featured-events-container .featured-events-cards .event-card:hover {
  transform: translate(0, -10px);
}

.user_mode .featured-events-container .featured-events-cards .event-card a {
  font-size: 1rem;
  display: flex;
  flex-flow: column;
  background-color: #555;
  background-size: 62%, 100%;
  background-position: center 38%, center center;
  background-repeat: no-repeat;
  height: 0;
  max-width: 400px;
  padding-bottom: calc(100% - 30px);
  margin: 0 15px;
  border-radius: 10px;
  color: #fff;
  overflow: hidden;
  transition: box-shadow 0.2s linear;
}

@media only screen and (max-width: 510px) {
  .user_mode .featured-events-container .featured-events-cards .event-card a {
    background-size: 55%, 100%;
    margin: 0 auto;
  }
}

.user_mode .featured-events-container .featured-events-cards .event-card a:hover {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
}

.user_mode .featured-events-container .featured-events-cards .event-card a .status {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  pointer-events: none;
}

.user_mode .featured-events-container .featured-events-cards .event-card a .status span {
  font-size: clamp(0.1rem, 1vw, 1rem);
  font-weight: 700;
  letter-spacing: 7px;
  text-transform: uppercase;
  background-color: #0093c8;
  padding: 4px 20px 4px 23px;
  max-height: 30px;
  border-radius: 0 0 5px 5px;
}

@media only screen and (max-width: 940px) {
  .user_mode .featured-events-container .featured-events-cards .event-card a .status span {
    font-size: clamp(0.1rem, 1.6vw, 1rem);
    letter-spacing: 5px;
    padding: 3px 15px;
  }
}

@media only screen and (max-width: 510px) {
  .user_mode .featured-events-container .featured-events-cards .event-card a .status span {
    font-size: clamp(0.5rem, 3vw, 1rem);
    padding: 4px 20px 4px 20px;
  }
}

.user_mode .featured-events-container .featured-events-cards .event-card a .details {
  line-height: 1.2;
  font-weight: 900;
  position: absolute;
  bottom: 0;
  padding-left: clamp(0.5rem, 2vw, 1.875rem);
  padding-bottom: clamp(0.5rem, 2vw, 1.875rem);
  pointer-events: none;
}

.user_mode .featured-events-container .featured-events-cards .event-card a .details .event-name {
  font-size: clamp(1rem, 1.85vw, 1.875rem);
}

.user_mode .featured-events-container .featured-events-cards .event-card a .details .event-date {
  font-size: clamp(0.8rem, 1.107vw, 1.125rem);
}

@media only screen and (max-width: 940px) {
  .user_mode .featured-events-container .featured-events-cards .event-card a .details .event-name {
    font-size: clamp(1rem, 3vw, 1.875rem);
  }
  .user_mode .featured-events-container .featured-events-cards .event-card a .details .event-date {
    font-size: clamp(0.8rem, 2vw, 1.125rem);
  }
}

@media only screen and (max-width: 510px) {
  .user_mode .featured-events-container .featured-events-cards .event-card a .details {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .user_mode .featured-events-container .featured-events-cards .event-card a .details .event-name {
    font-size: clamp(1.5rem, 3vw, 1.875rem);
  }
  .user_mode .featured-events-container .featured-events-cards .event-card a .details .event-date {
    font-size: clamp(1.2rem, 2vw, 1.125rem);
  }
}

.featured-events-container .slick-slider {
  padding-left: 30px;
  padding-right: 30px;
}

.featured-events-container .slick-slider .slick-track {
  padding-top: 10px;
}

.featured-events-container .slick-slider .slick-prev,
.featured-events-container .slick-slider .slick-next {
  height: 42px;
  width: 20px;
  background-color: transparent;
  z-index: 3;
}

.featured-events-container .slick-slider .slick-prev:before,
.featured-events-container .slick-slider .slick-next:before {
  font-family: initial;
  font-size: initial;
  line-height: initial;
  color: initial;
  opacity: initial;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}

@media only screen and (max-width: 940px) {
  .featured-events-container .slick-slider .slick-prev,
  .featured-events-container .slick-slider .slick-next {
    height: 42px;
    width: 20px;
  }
  .featured-events-container .slick-slider .slick-prev:before,
  .featured-events-container .slick-slider .slick-next:before {
    font-size: 16px;
  }
}

@media only screen and (max-width: 510px) {
  .featured-events-container .slick-slider .slick-prev,
  .featured-events-container .slick-slider .slick-next {
    height: 42px;
    width: 20px;
  }
  .featured-events-container .slick-slider .slick-prev:before,
  .featured-events-container .slick-slider .slick-next:before {
    font-size: 10px;
  }
}

.featured-events-container .slick-slider .slick-prev {
  left: 0px;
}

.featured-events-container .slick-slider .slick-prev:before {
  content: url(../images/slick-slider-ui/arrow_left_white.png);
  opacity: 0.6;
  transition: opacity 0.2s linear;
}

.featured-events-container .slick-slider .slick-prev:hover:before {
  opacity: 1;
}

.featured-events-container .slick-slider .slick-next {
  right: 0px;
}

.featured-events-container .slick-slider .slick-next:before {
  content: url(../images/slick-slider-ui/arrow_right_white.png);
  opacity: 0.6;
  transition: opacity 0.2s linear;
}

.featured-events-container .slick-slider .slick-next:hover:before {
  opacity: 1;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .featured-events-container .slick-dots {
    display: none !important;
  }
}

.featured-slider .heroPhotoElement {
  max-width: 400px;
  width: 100%;
  max-height: 400px;
}

.featured-slider .heroPhotoElement img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.featured-slider .slick-slide {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .featured-slider .slick-dots {
    display: none;
  }
}

.featured-slider .slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: transparent;
  z-index: 10;
  transition: border 0.2s linear;
}

@media only screen and (max-width: 767px) {
  .featured-slider .slick-arrow {
    border: 0;
    background-color: transparent;
  }
  .featured-slider .slick-arrow:before {
    opacity: 1;
  }
}

.featured-slider .slick-arrow:before {
  color: #000000;
  opacity: 0.5;
  font-size: 36px;
}

.featured-slider .slick-arrow.slick-next:before {
  content: '\f105';
  font-family: 'fontawesome';
}

.featured-slider .slick-arrow.slick-prev:before {
  content: '\f104';
  font-family: 'fontawesome';
}

.featured-slider .slick-arrow:hover:before {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .featured-slider .slick-arrow:hover {
    border: 0;
  }
}

/**
 * Merge Into Container SCSS
 */
.layoutContainer .merge-into-container-above > .column,
.layoutContainer .merge-into-container-below > .column {
  float: left;
}

/**
 * Detect Ad Blocking SCSS Component
 * Adds CSS class 'adblocker-alternative' to available theme CSS classes
 * @requires {_detect_adblocking.js}
 * @requires {blockadblock.js}
 */
.user_mode .adblocker-alternative {
  display: none;
}

/**
 * Copied from ironman theme
 */
.user_mode .hero-home {
  padding: 0;
}

.user_mode .hero-home .column {
  height: 100%;
}

.user_mode .hero-home .column .tagline-container {
  position: absolute;
  z-index: 1;
  background-color: white;
  right: 0;
  max-width: 450px;
  height: 190px;
  overflow: hidden;
  bottom: -95px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 65px;
  padding-right: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-home .column .tagline-container {
    bottom: -200px;
  }
}

.user_mode .hero-home .column .tagline-container .textBlockElement {
  display: flex;
  justify-content: center;
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text {
  text-align: center;
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: #A7B3BA;
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-home .column .tagline-container .textBlockElement .text p {
    font-size: 12px;
  }
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text p u {
  font-size: 20px;
  color: #CF3338;
  font-weight: 600;
  text-decoration: none;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-home .column .tagline-container .textBlockElement .text p u {
    font-size: 18px;
  }
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text ol,
.user_mode .hero-home .column .tagline-container .textBlockElement .text ul {
  display: flex;
  justify-content: space-around;
  margin: 0;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-align: left;
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text ol li,
.user_mode .hero-home .column .tagline-container .textBlockElement .text ul li {
  list-style: none;
  padding: 0;
  width: 33%;
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text ol {
  font-size: 14px;
  color: #A7B3BA;
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text ul {
  font-size: 36px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 20px;
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text ul li {
  font-size: 16px;
  line-height: 0.5;
}

.user_mode .hero-home .column .tagline-container .textBlockElement .text ul li strong {
  font-size: 36px;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .user_mode .hero-home .column .tagline-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    left: 0;
    height: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/**
 * Custom Video Component
 * Pulled from ironman theme & modified
 * expected layout for hero text and sub headline text allows us to add a h1 which will be font-weight:700; and subsequent h heading elements will be font-weight:400;
 */
.hero-home {
  margin-bottom: 0;
  position: relative;
  background-size: contain;
}

@media only screen and (max-width: 767px) {
  .hero-home {
    margin-bottom: 0;
  }
}

.hero-home .column {
  padding-left: 0;
  padding-right: 0;
}

.hero-home .home-video {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
}

.has-se-bar .hero-home .home-video {
  max-height: calc(100vh - 60px);
}

@media only screen and (max-width: 767px) {
  .hero-home .home-video {
    display: none;
  }
}

.hero-home .home-video .pageElement {
  margin-top: 0;
  margin-bottom: 0;
}

.hero-home .home-video .codeElement:after {
  content: "";
  background-image: linear-gradient(135deg, #000000 0%, black 100%);
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  opacity: 0.3;
  top: 0;
  left: 0;
}

.hero-home .home-video video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: relative;
}

@media screen and (min-width: 768px) {
  .user_mode .hero-home .home-video + .video-overlay-text .pageElement {
    position: absolute;
    background-size: 0 0;
  }
}

.user_mode .hero-home .video-overlay-text .pageElement {
  max-width: 100%;
  padding-left: calc((100% - 1040px) / 2);
  padding-right: calc((100% - 1040px) / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 2;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  background-size: cover;
}

.user_mode .hero-home .video-overlay-text .pageElement .leftTextImage,
.user_mode .hero-home .video-overlay-text .pageElement .originalTextImage {
  margin: 0;
}

.user_mode .hero-home .video-overlay-text .pageElement .leftTextImage img,
.user_mode .hero-home .video-overlay-text .pageElement .originalTextImage img {
  display: none;
}

@media screen and (max-width: 1140px) {
  .user_mode .hero-home .video-overlay-text .pageElement {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 767px) {
  .user_mode .hero-home .video-overlay-text .pageElement:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
  }
}

@media screen and (max-width: 768px) {
  .user_mode .hero-home .video-overlay-text .pageElement {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .user_mode .hero-home .video-overlay-text .pageElement {
    min-height: 208px;
  }
}

.user_mode .hero-home .video-overlay-text h3 span {
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 700;
  font-size: 60px;
  display: flex;
  align-items: flex-start;
  line-height: 1.167;
  margin-bottom: 0;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-home .video-overlay-text h3 span {
    font-size: 27px;
    line-height: 1.333;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .user_mode .hero-home .video-overlay-text h3 span {
    font-size: 42px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .user_mode .hero-home .video-overlay-text h3 span {
    font-size: 54px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .user_mode .hero-home .video-overlay-text h3 span {
    font-size: 64px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .user_mode .hero-home .video-overlay-text .text h1 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-home .video-overlay-text .text h1 {
    font-size: 28px;
    line-height: 36px;
  }
}

.user_mode .hero-home .video-overlay-text .text h1 + h2,
.user_mode .hero-home .video-overlay-text .text h1 + h3,
.user_mode .hero-home .video-overlay-text .text h1 + h4,
.user_mode .hero-home .video-overlay-text .text h1 + h5,
.user_mode .hero-home .video-overlay-text .text h1 + h6 {
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-home .video-overlay-text .text h1 + h2,
  .user_mode .hero-home .video-overlay-text .text h1 + h3,
  .user_mode .hero-home .video-overlay-text .text h1 + h4,
  .user_mode .hero-home .video-overlay-text .text h1 + h5,
  .user_mode .hero-home .video-overlay-text .text h1 + h6 {
    display: none;
  }
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .user_mode .hero-home .video-overlay-text .text h1 + h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .user_mode .hero-home .video-overlay-text h3 {
    display: block;
  }
}

.user_mode .hero-home .video-overlay-text p {
  color: #ffffff;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
}

@media only screen and (min-width: 0) and (max-width: 1024px) {
  .user_mode .hero-home .video-overlay-text p:first-of-type {
    display: none;
  }
}

.hero-home .scrollDownIndicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15%;
}

.hero-home .scrollDownIndicator .loaderArrow {
  font-size: 48px;
  color: #CF3338;
}

body.custom-search-page #panelTwo {
  background-color: transparent;
}

.custom-search-page .theme-sub-nav {
  display: none !important;
}

.has-main-nav.has-sub-nav .custom-search-page #topNavPlaceholder {
  height: 125px;
}

.custom-search-page .race-page-top .hero-slider {
  flex-wrap: wrap;
}

/**
 * Promotions Page Component
 */
.promotions-page .theme-sub-nav {
  display: none !important;
}

.has-main-nav.has-sub-nav .promotions-page #topNavPlaceholder {
  height: 125px;
}

.promotions-page .tabbedElement .tabContainer {
  border: 1px solid #cccccc;
  padding: 40px;
  font-size: 14px;
  color: #192d1a;
  margin-top: -1px;
}

.promotions-page .tabbedElement .contentTabs:not(.rapidTabsConstruction) a {
  border-radius: 0;
  margin-right: 0;
  padding: 12px 18px;
}

.promotions-page .promotions-hero .column-content-container {
  max-width: 616px;
}

.promotions-page .promotions-hero .text h2 {
  font-size: 44px;
  line-height: 54px;
}

.promotions-page .promotions-hero .text p {
  font-size: 17px;
  line-height: 36px;
}

.promotions-page .promotions-hero .linkElement h4 a:focus, .promotions-page .promotions-hero .linkElement h4 a:hover {
  border-color: white;
}

.container-button.pageEl .linkElement {
  text-align: center;
  margin-top: 15px;
}

.container-button.pageEl .linkElement h4 {
  display: inline-block;
}

.container-button.pageEl .linkElement h4 a {
  font-size: 16px;
  line-height: 20px;
  padding: 9px 25px;
}

.grd-series-container {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}

.grd-series-container .column {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .grd-series-container .column {
    padding: 8px;
  }
}

.grd-series-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  border-radius: 15px;
  text-align: initial;
}

.grd-series-card a {
  display: flex;
  flex-flow: column;
  flex-basis: 100%;
  overflow: hidden;
}

.grd-series-card .card-background {
  height: 100%;
  width: fit-content;
  border-radius: 15px;
  position: absolute;
}

.grd-series-card .card-header {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px 0 20px 0;
  position: relative;
}

.grd-series-card .card-header h4 {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 42px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  color: #fff;
}

.grd-series-card .card-image {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  text-align: center;
  position: relative;
  width: 45%;
  margin: 0 auto 10px auto;
}

.grd-series-card .card-image img {
  width: 100%;
}

.grd-series-card .card-footer {
  flex: 0;
  background-color: #000;
  text-align: center;
  padding: 10px 0 10px 0;
  border-radius: 0 0 15px 15px;
  position: relative;
}

.grd-series-card .card-footer p {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 30px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  color: #fff;
  margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .grd-series-card {
    font-size: 12px;
  }
  .grd-series-card .card-header {
    padding: 15px 0 10px 0;
  }
  .grd-series-card .card-header h4 {
    font-size: 14px;
  }
  .grd-series-card .card-image {
    width: 100%;
    margin: auto;
  }
  .grd-series-card .card-footer p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.grd-race-container {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  flex-direction: row;
}

.grd-race-container .column {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .grd-race-container {
    flex-direction: column;
  }
  .grd-race-container .column {
    flex: 1;
    padding: 8px;
  }
}

.grd-race-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  border-radius: 15px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 24px;
  line-height: 1.156;
  font-weight: 600;
  text-transform: none;
  color: #ffffff;
}

.grd-race-card a {
  display: flex;
  flex-flow: column;
  flex-basis: 100%;
  overflow: hidden;
}

.grd-race-card .left {
  float: left;
}

.grd-race-card .right {
  float: right;
}

.grd-race-card .card-header {
  flex: 1;
  text-align: left;
  padding: 26px 0 18px 26px;
}

.grd-race-card .card-header p {
  margin-bottom: 0;
}

.grd-race-card .card-header .headline {
  font-size: 24px;
  line-height: 1;
  padding: 0 0 12px 0;
}

.grd-race-card .card-header .sub-head {
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}

.grd-race-card .card-image {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.grd-race-card .card-image img {
  width: 100%;
  height: 100%;
  margin: 0 0 -8px 0;
}

.grd-race-card .card-footer {
  flex: 0;
  background-color: #000000;
  padding: 20px 0 22px 0;
  border-radius: 0 0 15px 15px;
}

.grd-race-card .card-footer span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
}

.grd-race-card .card-footer p {
  font-size: 18px;
  line-height: 1.156;
  color: #ffffff;
  padding: 0 26px 0 26px;
  margin-bottom: 0;
}

.grd-race-card .card-footer i {
  margin-right: 8px;
  font-size: 18px;
  height: 18px;
}

@media screen and (max-width: 1024px) {
  .grd-race-card {
    font-size: 14px;
  }
  .grd-race-card .card-header .headline {
    font-size: 18px;
  }
  .grd-race-card .card-header .sub-head {
    font-size: 14px;
  }
  .grd-race-card .card-footer .cta p {
    padding: 0 0 0 10px;
    font-size: 14px;
  }
  .grd-race-card .card-footer .register p {
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  .grd-race-card .card-footer i {
    font-size: 14px;
    height: 14px;
  }
}
